/*----------------------------------------
c-ttl
----------------------------------------*/
.c-ttl {
  position: relative;
  z-index: 2;
  text-align: center;

  &.is-left {
    text-align: left;

    .c-ttl__sub::before {
      transform: translateX(0);
      left: 0;
    }

    .p-index__blog & {
      @include is-tablet {
        text-align: center;

        .c-ttl__sub::before {
          transform: translateX(-50%);
          left: 50%;
        }
      }

    }
  }

  &__main {
    @include fz(25);
    display: block;
    line-height: 1.2;

    @include is-mobile {
      @include fz(20);
    }

  }

  &__sub {
    display: block;
    font-family: $font-en;
    color: $blue-dark;
    font-weight: $bold;
    padding-top: 30px;
    position: relative;

    @include is-mobile {
      @include fz(12);
      padding-top: 20px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 15px;
      left: 50%;
      transform: translateX(-50%);
      height: 2px;
      width: 40px;
      background: $blue-dark;

      @include is-mobile {
        width: 30px;
        top: 10px;
      }
    }

  }

}

.c-page__ttl {
  height: 200px;
  padding: 30px #{$side-padding * 2};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background: $blue-pale;
  flex-direction: column;

  @include is-tablet {
    padding: 30px $side-padding;
  }

  @include is-mobile {
    height: 150px;
    padding: 40px $side-padding-mobile;
  }

  &-main {
    @include fz(25);
    text-indent: .05rem;

    @include is-mobile {
      @include fz(20);
    }
  }

  &-sub {
    @include fz(14);
    text-indent: .05rem;
    font-family: $font-en;
    font-weight: $semibold;
    color: $blue-dark;
    padding-top: 25px;
    position: relative;

    @include is-mobile {
      @include fz(12);
      padding-top: 20px;
    }

    &::before {
      content: '';
      width: 40px;
      height: 2px;
      background: $blue-dark;
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translate(-50%);

      @include is-mobile {
        width: 30px;
        top: 7px;
      }
    }
  }

}

.c-page__content {
  &-ttl {
    margin-bottom: 25px;
    padding: 0 10px 15px;
    position: relative;
    border-bottom: 1px solid $blue-dark;
    text-align: left;

    @include is-mobile {
      padding: 0 5px 15px;
      margin-bottom: 15px;
    }

    &-txt {
      @include fz(18);
      @include ls(1);

      @include is-mobile {
        @include fz(16);
      }
    }
  }
}