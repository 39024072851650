@charset "UTF-8";

/*----------------------------------------
c-faq
----------------------------------------*/
.c-faq {
  text-align: left;

  &__item {
    margin: 0 0 20px;
  }

  &__ttl {
    @include fz(18);
    font-family: $font-jp;
    font-weight: $bold;
    position: relative;
    padding: 25px 50px 25px 0;
    border-bottom: 1px solid rgba($black, .2);
    display: flex;
    align-items: center;

    @include is-mobile {
      @include fz(14);
      padding: 10px 30px 10px 0;
    }

    &::before,
    &::after {
      background: $primary;
      width: 20px;
      height: 1px;
      position: absolute;
      top: 50%;
      right: 7.5px;
      transition: transform .3s $ease, opacity .3s $ease;

      @include is-mobile {
        right: 5px;
        width: 15px;
      }
    }

    &::after {
      transform: translateY(0) rotate(90deg);
      opacity: 1;
    }

    &.is-active {
      &::after {
        transform: translateY(10px) rotate(90deg);
        opacity: 0;
      }
    }

    &.js-accrodion-btn {
      cursor: pointer;

      &::before,
      &::after {
        content: "";
        display: block;
      }
    }

    &-icon {
      @include fz(28);
      color: $blue;
      line-height: 1;
      font-family: $font-en;
      font-weight: $bold;
      margin: 0 25px 0 0;

      @include is-mobile {
        @include fz(20);
        margin-right: 10px;
      }
    }
  }

  &__desc {
    padding: 25px 0;

    @include is-mobile {
      padding: 10px 0;
    }

    &.js-accrodion-target {
      display: none;
    }

    &-inner {
      display: flex;
    }

    p:not([class]) {
      @include fz(14);
      text-align: justify;

      @include is-mobile {
        @include fz(12)
      }

    }

    a:not([class]) {
      text-decoration: underline;

      &:hover {
        color: $red;
      }
    }
  }
}
