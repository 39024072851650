.g-hamburger {
  &__container {
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    transition: opacity .4s ease;
    opacity: 0;
    padding: 210px #{$side-padding * 2} 120px;
    display: flex;
    align-items: center;
    z-index: 100;

    @include is-hamburger {
      padding: 210px $side-padding 120px;
    }

    @include is-tablet {
      padding: $header_height_tablet $side-padding;
    }

    @include is-mobile {
      padding: $header_height_mobile $side-padding-mobile 25px;
    }

    [data-menu='active'] & {
      pointer-events: initial;
      opacity: 1;
    }


    &::before {
      content: '';
      background: $gray-black;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: $blue-dark;
    }
  }

  &__logo {
    text-align: left;
    position: absolute;
    top: 0;
    left: 0;
    height: 100px;
    display: flex;
    align-items: center;
    padding: 0 0 0 20px;

    @include is-mobile {
      height: $header_height_mobile;
      padding-left: 0;
    }

    &-link {
      display: block;
      padding: 4px 25px;
      opacity: 1;
      transition: opacity .2s ease;
      line-height: 1;

      @include is-mobile {
        padding: 4px 16px;
      }

      &:hover {
        opacity: .7;

      }

    }

    &-img {
      max-width: 480px;
      max-height: 35px;

      @include is-mobile {
        max-width: 250px;
        max-height: 25px;
      }
    }
  }

  &__othermenu {
    margin-top: 25px;
    padding-top: 30px;
    position: relative;
    display: flex;

    @include is-tablet {
      margin-top: 40px;
      flex-wrap: wrap;
      justify-content: center;
    }

    @include is-mobile {
      margin-top: 15px;
      padding-top: 20px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 1px;
      background: $white;
      opacity: .3;
    }
  }

  &__help {
    margin-left: 40px;
    position: relative;

    @include is-tablet {
      width: 100%;
      margin-top: 10px;
      margin-left: 0;
    }

    &-list {
      margin: -10px;
      display: flex;
      
      @include is-tablet {
        margin: -10px -10px 0;
        flex-direction: column;
      }

      @include is-mobile {
        margin: -6px -6px 0;
      }

      &-item {
        &:not(:last-child) {
          margin-right: 15px;

          @include is-tablet {
            margin-right: 0;
          }
        }

        &-link {
          @include fz(13);
          text-align: left;
          color: $white;
          display: block;
          padding: 10px;
          transition: opacity .2s ease;
          opacity: 1;
          text-decoration: underline;

          @include is-tablet {
            text-align: center;
          }

          @include is-mobile {
            @include fz(12);
            padding: 6px;
          }

          &:hover {
            @include is-laptop {
              opacity: .7;
            }
          }
        }

      }

    }

  }

  &__sns {

    &-list {
      display: flex;
      margin: -10px;

      @include is-tablet {
        justify-content: center;
        margin: -10px -10px 0;
      }

      &-item {
        &-link {
          font-size: 0;
          color: $white;
          padding: 10px;
          display: block;
          position: relative;
          transition: opacity .2s ease;

          &:hover {
            @include is-laptop {
              opacity: .8;
            }
          }

          &-icon {
            @include fz(20);
            letter-spacing: normal;
          }
        }

      }
    }
  }

  &__menu {
    width: 100%;

    @include is-tablet {
      width: 100%;
    }

    &-container {
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0 20px;
      height: 100%;
      position: relative;

      @include is-tablet {
        padding: 0;
      }
    }

    &-toplist {
      display: none;
      border-top: 1px solid rgba($white,.1);
      border-bottom: 1px solid rgba($white,.1);

      @include is-tablet {
        display: flex;
        justify-content: center;
        margin: 0 0 40px;
      }

      @include is-mobile {
        margin-bottom: 15px;
      }

      &-item {
        &-link {
          @include fz(20);
          display: flex;
          align-items: center;
          text-indent: .1rem;
          padding: 30px 30px 26px;
          color: $white;
          font-family: $font-en;
          font-weight: $semibold;

          @include is-mobile {
            @include fz(13);
            padding: 15px 15px 12px;
          }
        }

        svg {
          margin-top: -2px;

          &:not(:last-child) {
            margin-right: 5px;
          }
        }

        &-favorite {
          
          &-svg {
            width: 14px;
            height: 14px;
          }
  
          &-path {
            fill: $white;
            fill-rule: evenodd;
          }
        }

        &-mypage {
          
          &-svg {
            width: 14px;
            height: 14px;
          }
  
          &-path {
            fill: $white;
            fill-rule: evenodd;
          }
        }
      }

    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      margin: -25px -45px 0;

      @include is-hamburger {
        margin: -20px -20px 0;
      }

      @include is-tablet-large {
        margin: -15px -15px 0;
      }

      @include is-mobile {
        margin: -7.5px -7.5px 0;
      }

      &-item {
        width: 33.3333%;

        .g-hamburger__menu-toplist & {
          width: auto;
          display: inline-block;
        }

        @include is-tablet {
          width: 100%;
          justify-content: center;
        }

        &-link {
          padding: 25px 45px;
          display: inline-block;
          text-align: left;
          width: 100%;
          transition: opacity .2s ease;

          &:hover {
            opacity: .7;
          }

          &::after {
            width: calc(100% - 80px);
            left: 45px;

            @include is-hamburger {
              width: calc(100% - 40px); 
              left: 20px;
            }
          }
  
          @include is-hamburger {
            padding: 20px;
          }
  
          @include is-tablet-large {
            padding: 15px;
          }
  
          @include is-mobile {
            padding: 7.5px;
          }
  
          .g-hamburger__menu-toplist & {
            @include is-tablet-large {
              padding: 30px;
            }
    
            @include is-mobile {
              padding: 15px;
            }
          }
        }
      }

      &-txt {

        &-main {
          @include fz(23);
          @include ls(.5);
          line-height: 1.2;
          display: block;
          color: $white;

          @include is-mobile {
            @include fz(13);
          }
        }

        &-sub {
          @include fz(11);
          line-height: 1;
          font-family: $font-en;
          display: block;
          font-weight: $bold;
          color: rgba($white, .3);
          padding-top: 20px;
          position: relative;
          overflow: hidden;

          @include is-mobile {
            @include fz(10);
            padding-top: 10px;
          }

          &::before {
            content: "";
            width: 40px;
            height: 1px;
            background: $white;
            position: absolute;
            top: 8px;
            left: 0;
            transform-origin: left;

            .g-hamburger__menu-list-item-link:hover & {

              @include is-laptop {
                animation: border-reverse .5s ease forwards;
              }
            }

            @include is-mobile {
              top: 3px;
              width: 25px;
            }
          }
        }

      }
    }
  }

}


@keyframes border-reverse {
  0% {
    transform: scaleX(1);
    transform-origin: right;
  }

  50% {
    transform: scaleX(0);
    transform-origin: right;
  }

  51% {
    transform-origin: left;
  }

  100% {
    transform: scaleX(1);
    transform-origin: left;
  }
}