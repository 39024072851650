@charset "UTF-8";

/*----------------------------------------
c-flow-list
----------------------------------------*/
.c-flow-list {
  text-align: justify;
  position: relative;
  margin: -25px 0;

  @include is-mobile {
    margin: -15px 0;
  }

  &__row {
    position: relative;
    z-index: 1;
    padding: 25px 10px;
    counter-increment: c-o-list-child-number;

    @include is-mobile {
      padding: 15px 5px;
    }
  }

  &__txt {
    overflow: hidden;
  }

  &__ttl {
    @include fz(16);
    @include ls(1);
    margin-bottom: 20px;
    padding-left: 40px;
    position: relative;
    line-height: 30px;

    @include is-mobile {
      @include fz(15);
      margin-bottom: 15px;
      padding-left: 30px;
      line-height: 20px;
    }

    &::before {
      @include fz(18);
      text-indent: .12rem;
      content: counter(c-o-list-child-number);
      background: $blue-dark;
      font-family: $font-en;
      width: 30px;
      height: 30px;
      color: $white;
      line-height: 32px;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;

      @include is-mobile {
        @include fz(13);
        width: 20px;
        height: 20px;
        line-height: 22px;
      }

    }
  }

  &__desc {
    @include ls(1);
    line-height: 1.8;
    padding-left: 40px;

    @include is-tablet {
      padding-left: 0;
    }

    &-sub {
      @include ls(1);
      line-height: 1.5;
      margin: 50px 0 5px;
      display: block;
      font-weight: $bold;

      @include is-tablet {  
        margin: 20px 0 0;
      }

      &:first-child {
        margin: 0 0 10px;

      }
    }
  }
}
