@charset "UTF-8";

/*----------------------------------------
Variables
----------------------------------------*/

/*
# Color */
$black: #000;
/* $black: #111; */
$white: #fff;
/* $white: #fff; */
$gray-black: #333;
/* $gray-black: #333; */
$gray-black2: #555555;
/* $gray-black2: #555555; */
$primary: #343434;
/* $primary: #343434; */
$primary-white: #777;
/* $primary-white: #777; */
$white-gray: #f5f5f5;
/* $white-gray: #f5f5f5; */
$white-gray2: #f9f9f9;
/* $white-gray2: #f9f9f9; */
$white-gray3: #eee;
/* $white-gray3: #eee; */
$gray-pale2: #efefef;
/* $gray-pale2: #efefef; */
$gray-pale3: #e2e2e2;
/* $gray-pale3: #e2e2e2; */
$gray-pale: #e3e5e8;
/* $gray-pale: #e9e9e9; */
$gray-light04: #BFBFBF;
/* $gray-light04: #BFBFBF; */
$gray-light: #c4c8cf;
/* $gray-light: #d0d0d0; */
$gray-light02: #a2a2a2;
/* $gray-light02: #b0b0b0; */
$gray-light03: #dfdfdf;
/* $gray-light03: #dfdfdf; */
$gray: #cccccc;
/* $gray: #cccccc; */
$gray02: #666;
/* $gray02: #666; */
$gray-dull: #707070;
/* $gray-dull: #707070; */
$gray-dark: #909090;
/* $gray-dark: #909090; */
$blue: #0086d3;
/* $blue: #0086d3; */
$blue-pale: #e7ecef;
/* $blue-pale: #e7ecef; */
$blue-light: #d5e8f3;
/* $blue-light: #d5e8f3; */
$blue-gray-dark: #88919e;
/* $blue-gray: #88919e; */
$blue-gray: #cad5d9;
/* $blue-gray: #cad5d9; */
$blue-deepdark: #1a0dab;
/* $blue-deepdark: #1a0dab; */
$blue-dark: #38475e;
/* $blue-dark: #38475e; */
$blue-dull: #4C6FA5;
/* $blue-dull: #4C6FA5; */
$red: #cb4e4e;
/* $red: #ab2d2d; */
$red-gray: #8C514E;
/* $red-gray: #8C514E; */
$red-light: #bf3333;
/* $red-light: #bf3333; */
$red-black: #751313;
/* $red-black: #751313; */
$red-white: #d23d3d;
/* $red-white: #d23d3d; */
$orange: #ff5722;
/* $orange: #ff5722; */
$pink1: #dd4688;
/* $pink1: #dd4688; */
$pink2: #d46abf;
/* $pink2: #d46abf; */
$pink3: #cc8ef5;
/* $pink3: #cc8ef5; */
$green: #4caf50;
/* $green: #4caf50; */

$green-gray: #81a3a1;
/* $green-gray: #81a3a1;*/
$green-gray2: #91b3b9;
/* $green-gray2: #91b3b9; */

$yellow-gray: #BE9456;
/* $yellow-gray: #BE9456; */

$gold: #d2a727;
/* $gold: #d2a727; */
$silver: #b1aea5;
/* $silver: #b1aea5; */
$bronze: #d89056;
/* $bronze: #d89056; */


/*

# Border */
$color-border: #ddd;
/* $color-border: #ddd; */
$color-border-black: #111;
/* $color-border-black: #111; */
$color-border-gray: #707070;
/* $color-border-gray: #707070; */

/*

/*

# Cat */

$cat01: #81A3A1;
$cat02: #93AF71;
$cat03: #BE9456;
$cat04: #BC707B;

/*

# Shadow */
$shadow: rgba(#777, .1);
/* $shadow: rgba(#777, .1); */
$shadow-hover: rgba(#777, .3);
/* $shadow-hover: rgba(#777, .3); */

/*

# Hover */
$opacity: .7;
/* $opacity: .7; */

/*

# Font Family */
$font: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
/* $font: 游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif */
$font-en: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
/* $font-en: "Playfair Display", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif; */
$font-jp: "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
/* $font-jp: "Noto Sans JP", Arial, Helvetica, sans-serif; */
$font-cn: "Microsoft Yahei", "PingHei";
/* $font-cn: "Microsoft Yahei", "PingHei"; */

/*

# Font Weight */
$light: 300;
/* $light: 300; */
$regular:400;
/* $regular:400; */
$medium: 500;
/* $medium: 500; */
$semibold: 700;
/* $medium: 700; */
$bold: 900;
/* $bold: 900; */

/*

# Width */
$outer-width: 1920px;
/* $outer-width: 1920px; */
$content-width: 1280px;
/* $content-width: 1280px; */

/*

# Padding */
$side-double-padding: 200px;
/*$side-double-padding: 200px;*/
$side-padding: 80px;
/* $side-padding: 80px; */

$side-double-padding-break1: 150px;
/* $side-double-padding-break: 150px; */
$side-padding-break1: 75px;
/*$side-padding-break1: 75px; */

$side-double-padding-break2: 100px;
/* $side-double-padding-break: 100px; */
$side-padding-break2: 50px;
/*$side-padding-break1: 50px; */


$side-padding-mobile: 16px;
/* $side-padding-mobile: 16px; */

$header_height_tablet: 100px;
$header_height_mobile: 60px;

/*

# Easing */
$ease: cubic-bezier(.19, 1, .22, 1);
/* $ease: cubic-bezier(.19, 1, .22, 1); */

