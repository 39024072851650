@charset "UTF-8";

/*----------------------------------------
c-item-style
----------------------------------------*/
.c-item-style {
  $this: &;
  position: relative;

  &.is-sold-out {
    #{$this}__figure {
      &::after {
        @include fz(20);
        @include ls(4);
        content: "SOLD OUT";
        font-family: $font-en;
        color: $white;
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        z-index: 0;
        transform: translateY(-50%);
        text-align: center;

        @include is-mobile {
          @include fz(16);
        }

        @include is-mobile-small {
          @include fz(14);
        }
      }

      &-img {
        &::before {
          background: rgba($black, .3);
        }
      }
    }
  }

  &__favorite {
    width: 40%;
    position: absolute;
    top: 0;
    left: 0;

    @include is-hamburger {
      width: 200px;
    }

    @include is-tablet {
      width: 120px;
    }
  }

  &__link {
    display: flex;
    justify-content: space-between;
    text-align: left;

    &:hover {
      #{$this}__figure-img {
        transform: scale(1.1);
      }
    }
  }

  &__figure {
    width: 40%;
    overflow: hidden;
    position: relative;

    @include is-hamburger {
      width: 200px;
    }

    @include is-tablet {
      width: 120px;
    }

    &-img {
      background: no-repeat center;
      background-size: cover;
      transition: transform .5s $ease;

      &::before {
        content: "";
        display: block;
        padding: 118% 0 0;
      }
    }

    &-src {
      display: none;
    }
  }

  &__txt {
    width: 55%;

    @include is-hamburger {
      width: calc(100% - 200px);
      padding: 0 0 0 40px;
    }

    @include is-tablet {
      width: calc(100% - 120px);
      padding: 0 0 0 20px;
    }
  }

  &__ttl {
    @include fz(14);
    font-weight: $bold;

    @include is-mobile {
      @include fz(11);
      line-height: 1.8;
    }
  }

  &__desc {
    @include fz(14);
    line-height: 2;
    padding: 15px 0;

    @include is-mobile {
      @include fz(11);
      line-height: 1.8;
      padding: 6px 0;
    }
  }

  &__price {
    @include fz(16);
    @include ls(1.5);

    @include is-mobile {
      @include fz(14);
    }

    &.is-discount {
      text-decoration: line-through;
    }

    small {
      @include fz(12);
      margin: 0 0 0 5px;

      @include is-mobile {
        @include fz(11);
      }
    }
  }

  &__discount {
    @include fz(16);
    @include ls(1.5);
    color: $red;
    font-weight: $bold;

    @include is-mobile {
      @include fz(14);
    }

    span:not([class]) {
      display: inline-block;
      margin: 0 5px 0 0;
    }

    small {
      @include fz(12);
      margin: 0 0 0 2px;

      @include is-mobile {
        @include fz(11);
      }
    }
  }
}
