@charset "UTF-8";

/*----------------------------------------
faq
----------------------------------------*/
.p-faq {
  &__list {
    border-top: 1px solid $blue-dark;

    &-item {
      border-bottom: 1px solid $blue-dark;
      padding: 20px 10px;

      @include is-mobile {
        padding: 15px 10px;
      }

      &-ttl {
        position: relative;
        text-align: left;
        cursor: pointer;
        line-height: 30px;
        display: flex;
        align-items: flex-start;

        @include is-mobile {
          line-height: 20px;
        }

        &::before {
          @include fz(18);
          content: 'Q';
          width: 30px;
          height: 30px;
          text-align: center;
          background: $blue-dark;
          color: $white;
          font-family: $font-en;
          font-weight: $semibold;
          display: flex;
          justify-content: center;
          line-height: 33px;

          @include is-mobile {
            @include fz(15);
            width: 20px;
            height: 20px;
            line-height: 23px;
          }
        }

        &-txt {
          @include fz(18);
          @include ls(1);
          margin-left: 10px;
          margin-right: 50px;
          display: block;
          width: calc(100% - 60px);

          @include is-mobile {
            @include fz(15);
            margin-left: 10px;
            margin-right: 30px;
            width: calc(100% - 40px);
          }

        }
      }

      &-desc {
        text-align: left;
        padding-top: 20px;
        display: none;

        &-txt {
          display: flex;
          align-items: flex-start;

          &::before {
            @include fz(18);
            content: 'A';
            width: 30px;
            height: 30px;
            text-align: center;
            background: $blue-pale;
            color: $blue-dark;
            font-family: $font-en;
            font-weight: $semibold;
            display: flex;
            justify-content: center;
            line-height: 33px;

            @include is-mobile {
              @include fz(15);
              width: 20px;
              height: 20px;
              line-height: 23px;
            }
          }

          &-inner {
            @include ls(1);
            margin-left: 10px;
            line-height: 30px;
            display: block;
            width: calc(100% - 40px);

            @include is-mobile {
              line-height: 20px;
              width: calc(100% - 30px);
            }
          }

        }
      }

      &-btn {
        position: absolute;
        top: 50%;
        right: 0;
        width: 16px;
        height: 16px;
        border: none;
        background: none;
        transform: translateY(-50%);

        @include is-mobile {
          width: 10px;
          height: 10px;
        }

        .is-active & {
          &::before {
            transform: rotate(360deg);
          }

          &::after {
            transform: rotate(360deg);
          }
        }

        &::before {
          content: '';
          width: 100%;
          height: 1px;
          position: absolute;
          top: 50%;
          left: 0;
          background: $gray-black;
          display: block;
          transition: transform .3s ease;
        }

        &::after {
          content: '';
          width: 100%;
          height: 1px;
          position: absolute;
          background: $gray-black;
          top: 50%;
          left: 0;
          transform: rotate(90deg);
          transform-origin: center center;
          display: block;
          transition: transform .3s ease;
        }
      }
    }

  }

}
