@charset "UTF-8";

/*----------------------------------------
c-style-book
----------------------------------------*/

.c-style-book-list {
  position: relative;
  padding-left: calc(50% + 15px);
  margin: 0 -15px;

  @include is-tablet {
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
  }

  @include is-mobile {
    margin: 0 -7.5px;
  }

  &__article {
    height: 100%;
  }

  &.is-short {
    padding: 0;

    &:nth-child(2n) {
      padding: 0;

      .c-style-book-list__item {
        float: left;
        width: 25%;

        @include is-tablet {
          float: none;
          width: 50%;
        }
      }
    }

    .c-style-book-list__item {
      width: 25%;

      @include is-tablet {
        width: 50%;
      }
    }

  }

  &__item {
    float: left;
    width: 50%;
    padding: 15px;

    @include is-tablet {
      float: none;
      order: 2;

      .is-short & {
        order: initial;
      }
    }

    @include is-mobile {
      padding: 7.5px;
    }

    &.is-large-item {
      float: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;

      @include is-tablet {
        position: static;
        width: 100%;
        height: auto;
        order: 1;
      }
    }
  }

  &:nth-child(2n) {
    padding-right: calc(50% + 15px);
    padding-left: 0;

    @include is-tablet {
      padding-right: 0;
    }

    .c-style-book-list__item {
      float: right;

      @include is-tablet {
        float: none;
      }

      &.is-large-item {
        float: none;
        top: 0;
        left: auto;
        right: 0;
      }
    }

  }

  &__figure {
    @extend %img-clip;

      .is-large-item & {
        height: 100%;

      &::after {
        padding-bottom: 0;

        @include is-tablet {
          padding-bottom: 60%;
        }
      }
    }
  }

  &__bg {

    &.c-img-bg {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-color: $white-gray;
    }
  }

  &__ttl {
    @include fz(20);
    @include ls(2);
    margin-bottom: 30px;
    line-height: 1;

    @include is-mobile {
      @include fz(15);
      margin-bottom: 20px;
    }
  }

}

.c-style-book-header {
  display: flex;
  margin: 40px 0 60px;
  position: relative;
  height: 700px;
  justify-content: space-between;

  @include is-tablet {
    flex-wrap: wrap;
    height: auto;
    width: 100vw;
    margin: 40px -80px 60px;
  }

  @include is-mobile {
    margin: 30px -16px 40px;

  }
}

.c-style-book-photo {
  width: 78.125%;
  height: 100%;
  position: relative;
  margin: 0;

  @include is-tablet {
    width: 100%;
  }

  .swiper-wrapper {
    height: 100%;
  }

  &-thumb {
    height: 100%;
    width: 18%;
    margin: 0;

    @include is-tablet {
      width: 100%;
      margin-top: 30px;
    }

    &-item {
      cursor: pointer;

      @include is-tablet {
        width: 29%;
      }

      &::before {
        content: '';
        background: rgba($black,.5);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        opacity: 0;
        transition: opacity .5s ease;
        pointer-events: none;
      }

      &.swiper-slide-thumb-active {

        &::before {
          pointer-events: initial;
          opacity: 1;
        }
      }
    }
    
    &-figure {
      @extend %img-clip;
      height: 100%;

      &::after {
        padding-bottom: 0;

        @include is-tablet {
          padding-bottom: 60%;
        }
      }
    }
  }

  &-figure {
    @extend %img-clip;
    height: 100%;

    &::after {
      padding-bottom: 0;

      @include is-tablet {
        padding-bottom: 60%;
      }

    }
  }

  &-nav {

    &-next {
      position: absolute;
      top: 50%;
      right: 0;
      width: 50px;
      height: 50px;
      background: $black;
      cursor: pointer;
      margin-left: 1px;
      z-index: 1;
      transform: translateY(-50%);

      @include is-mobile {
        width: 38px;
        height: 38px;
      }

      &:hover {
        @include is-laptop {
          &::before {
            transform: translateY(-50%) translateX(-50%) rotate(135deg);
          }
        }
      }


      &::before {
        content: "";
        display: inline-block;
        width: 9px;
        height: 9px;
        border: solid $white;
        border-width: 1px 0 0 1px;
        transform: translateY(-50%) translateX(-75%) rotate(135deg);
        transform-origin: center center;
        position: absolute;
        top: 50%;
        left: 50%;
        transition: transform .2s ease;
      }
    }

    &-prev {
      position: absolute;
      top: 50%;
      left: 0;
      width: 50px;
      height: 50px;
      background: $black;
      cursor: pointer;
      z-index: 1;
      transform: translateY(-50%);
      
      @include is-mobile {
        width: 38px;
        height: 38px;
      }

      &:hover {
        @include is-laptop {
          &::before {
            transform: translateY(-50%) translateX(-50%) rotate(315deg);
          }
        }
      }

      &::before {
        content: "";
        display: inline-block;
        width: 9px;
        height: 9px;
        border: solid $white;
        border-width: 1px 0 0 1px;
        transform: translateY(-50%) translateX(-25%) rotate(315deg);
        transform-origin: center center;
        position: absolute;
        top: 50%;
        left: 50%;
        transition: transform .2s ease;
      }
    }
  }


}
