@charset "UTF-8";

/*----------------------------------------
c-notice
----------------------------------------*/
.c-notice {
  margin: 0 $side-padding;
  padding: 80px 0;
  background: $white-gray;
  border: 1px solid $color-border;

  @include is-tablet-large {
    margin: 0 $side-padding-break1;

  }

  @include is-tablet {
    margin: 0 $side-padding-break2;

  }

  @include is-mobile {
    margin: 0 $side-padding-mobile;

  }

  &.is-blog {
    margin-top: 30px;
  }
}
