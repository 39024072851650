@charset "UTF-8";

/*----------------------------------------
c-search-header
----------------------------------------*/
.c-search-header {
  color: $primary;
  position: relative;

  &__form {
    display: table;
    width: 100%;
    position: relative;
    z-index: 1;
  }

  &__row {
    width: 100%;
    display: table-row;
  }

  &__cell {
    display: table-cell;
    padding: 12.5px 0;
    text-align: left;

    &:not(:last-child) {
      padding-right: 20px;

      @include is-mobile {
        padding-right: 15px;
      }
    }

    @include is-tablet {
      padding: 10px 0;
    }
  }

  &__ttl {
    @include fz(15);
    @include ls(3.2);
    white-space: nowrap;
    width: 8%;

  }

  &__result {

    &-num {
      @include fz(20);
      font-weight: $bold;
    }

  }

  &__category {
    @include is-mobile {
      max-height: 72px;
      overflow: hidden;
    }

    &.is-active {
      max-height: 100%;
      margin-bottom: -10px;
    }

    &-toggle {
      @include fz(12);
      color: $gray;
      font-family: $font;
      text-decoration: underline;
      width: 100%;
      background: none;
      border: 0;
      text-align: right;
      outline: none;
      display: none;

      @include is-mobile {
        display: block;
      }
    }

    &-item {
      background: $gray-pale;
      display: inline-block;
      padding: 4px 10px;
      margin: 0 4px 10px 0;
      cursor: pointer;

      @include is-tablet {
        margin: 0 5px 10px 0;
      }

      &:hover {
        background: $white-gray3;
      }

      &-txt {
        @include fz(14);
        margin: 0 10px 0 0;

      }

      &-icon {
        position: relative;
        width: 10px;
        height: 10px;
        display: inline-block;

        &::before {
          content: '';
          display: block;
          width: 10px;
          height: 1px;
          background: $black;
          transform: translateY(-50%) rotate(45deg);
          position: absolute;
          top: 50%;
          right: 0;
        }

        &::after {
          content: '';
          display: block;
          width: 10px;
          height: 1px;
          background: $black;
          transform: translateY(-50%) rotate(-45deg);
          position: absolute;
          top: 50%;
          right: 0;
        }
      }
    }
  }

  &__select {
    position: relative;
    display: inline-block;

    &::after {
      content: "";
      display: block;
      width: 7px;
      height: 7px;
      border: solid $gray-dark;
      border-width: 0 1px 1px 0;
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translateY(-75%) rotate(45deg);
      pointer-events: none;
    }

    select {
      @include fz(14);
      font-family: $font;
      border: 1px solid $gray-dark;
      padding: 7.5px 30px 7.5px 12.5px;
      background: $white;

      @include is-mobile {
        padding: 6px 25px 6px 12px;
      }

    }
  }
}
