@charset "UTF-8";

/*----------------------------------------
c-post
----------------------------------------*/
.c-post {
  $this: &;
  position: relative;

  &__container {
    display: flex;
    justify-content: center;

    @include is-tablet-large {
      flex-wrap: wrap;
    }
  }

  &__main {
    width: calc(100% - 420px);

    @include is-tablet-large {
      width: 100%;
    }
  }

  &__side {
    width: 420px;
    padding-left: $side-padding;

    @include is-tablet-large {
      width: 100%;
      padding-left: 0;
      margin-top: 50px;
    }

    &-block {
      background: $blue-pale;
      padding: 20px;
    }

    &-ttl {
      @include fz(18);
      @include ls(1);
      font-family: $font-en;
      font-weight: $semibold;
      margin-bottom: 8px;
      padding-bottom: 10px;
      border-bottom: 1px solid $blue-dark;
      color: $blue-dark;
      text-align: left;
    }

    &-list {
      &-item {
        position: relative;
        padding-left: 15px;
        text-align: left;

        &::before {
          content: '';
          width: 8px;
          height: 2px;
          position: absolute;
          top: 16px;
          left: 0;
          background: $blue-dark;
        }

        &-link {
          @include ls(1);
          color: $primary;
          padding: 7.5px 0;
          display: inline-block;
          transition: color .2s ease;

          .c-post__side-list-item.is-child-show & {
            padding: 7.5px 0 4px;
          }

          &:hover {
            @include is-laptop {
              color: $red;
            }
          }
        }
      }
    }

    &-child {
      &-list {

        &-item {
          padding-left: 10px;
          position: relative;

          &::before {
            content: "";
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 3px 3px 3px;
            border-color: transparent transparent $blue-gray-dark transparent;
            transform: rotate(90deg);
            margin-right: 3px;
            position: absolute;
            top: 13px;
            left: 0;
          }

          &-link {
            padding: 5px 0;
            display: inline-block;
            transition: color .2s ease;

            &:hover {
              @include is-laptop {
                color: $red;
              }
            }
  
          }
        }


      }
    }
  }

  &__link {
    position: relative;
    text-align: left;
    display: flex;
    box-shadow: 0 0 10px 0 rgba($black, .05);
    background: $white;
    text-overflow: ellipsis;

    &:hover {
      @include is-laptop {
        .c-post__figure-img {
          transform: scale(1.1);
        }
      }
    }

    &.is-news {
      height: auto;
      box-shadow: none;
      align-items: center;
      padding: 20px 30px;
      background: rgba($white, .5);
      transition: color .2s ease;

      &:hover {
        @include is-laptop {
          color: $blue;
        }
      }

      @include is-tablet {
        flex-wrap: wrap;
      }

      @include is-mobile {
        padding: 15px $side-padding-mobile 10px;
      }
    }

    .is-blog-page & {
      display: block;
    }

    .is-campaign-page & {
      display: block;
    }
  }

  &__meta {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;

    @include is-mobile {
      margin: 10px 0;
    }
  }

  &__time {
    @include fz(15);
    @include ls(2);
    font-family: $font-en;
    color: $gray-light04;
    display: block;
    font-weight: $semibold;
    line-height: 1;
    position: absolute;
    left: 20px;
    bottom: 20px;

    @include is-mobile {
      @include fz(14);
      left: 10px;
    }

    .is-news & {
      width: 142px;
      position: static;
      color: $blue-dark;
      padding-right: 45px;

      @include is-tablet {
        width: 100%;
      }
    }
  }

  &__figure {
    overflow: hidden;
    width: 50%;
    position: relative;

    .is-blog-page & {
      width: 100%;
    }

    .is-campaign-page & {
      width: 100%;
    }


    &::before {
      content: '';
      padding-bottom: 100%;
      display: block;

      .is-blog-page & {
        padding-bottom: 56%;
      }

      .is-campaign-page & {
        padding-bottom: 56%;
      }
    }

    &-img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: no-repeat center;
      background-size: cover;
      transition: transform .5s $ease;
    }

    &-src {
      display: none;
    }
  }

  &__txt {
    width: 50%;
    z-index: 1;
    padding: 20px 20px 70px;
    position: relative;

    .is-blog-page & {
      width: 100%;
    }

    .is-campaign-page & {
      width: 100%;
    }

    @include is-mobile {
      padding: 10px 10px 40px;
    }

  }

  &__category {
    @include fz(12);
    padding: 5px 8px;
    display: inline-block;
    color: $white;
    background: $blue-dark;
    line-height: 1;

    @include is-mobile {
      margin-bottom: 5px;
    }

    &.is-present {
      background: $cat01;
    }

    &.is-product {
      background: $cat02;
    }

    &-container {
      margin-bottom: 10px;
    }

    &-child {
      @include fz(12);
      color: $blue-dark;
      margin-top: 6px;
      display: flex;
      align-items: center;

      &.is-present {
        color: $cat01;

        &::before {
          border-color: transparent transparent $cat01 transparent;
        }
      }

      &.is-product {
        color: $cat02;

        &::before {
          border-color: transparent transparent $cat02 transparent;
        }
      }

      &::before {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 5px 5px 5px;
        border-color: transparent transparent $blue-dark transparent;
        transform: rotate(90deg);
        margin-right: 3px;
      }
    }
  }

  &__ttl {
    width: 100%;
    text-align: justify;
    line-height: 1.8;
    transition: background-size .5s ease;

    .is-news & {
      width: calc(100% - 142px);

      @include is-tablet {
        width: 100%;
      }
    }
  }
}
