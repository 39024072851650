@charset "UTF-8";

/*----------------------------------------
c-style
----------------------------------------*/
.c-style {
  $this: &;

  &__link {
    display: block;

    &:hover {
      #{$this}__figure-img {
        transform: scale(1.1);
      }
    }
  }

  &__figure {
    overflow: hidden;

    &-img {
      background: no-repeat center $white-gray;
      background-size: contain;
      transition: transform .5s $ease;

      &::before {
        content: "";
        display: block;
        padding: 60% 0 0;
      }
    }

    &-src {
      display: none;
    }
  }
}
