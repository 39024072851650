@charset "UTF-8";

/*----------------------------------------
c-category-header
----------------------------------------*/
.c-category-header {
  color: $primary;
  margin: 0 0 26px;

  @include is-tablet {
    padding: 10px 0 0;
  }

  @include is-mobile {
    padding: 0;
    margin: 0 0 20px;
  }

  &__form {
    display: table;
    width: 100%;
  }

  &__row {
    width: 100%;
    display: table-row;
  }

  &__cell {
    display: table-cell;
    text-align: left;
  }

  &__category {
    @include is-mobile {
      max-height: 72px;
      overflow: hidden;
    }

    &.is-active {
      max-height: 100%;
    }

    &-item {
      background: $white-gray2;
      display: inline-block;
      padding: 4px 16px;
      margin: 0 20px 10px 0;
      border-radius: 50px;
      cursor: pointer;

      @include is-tablet {
        margin: 0 5px 10px 0;
      }

      &:hover {
        background: $white-gray3;
      }

      &-txt {
        @include fz(14);
      }

      &-icon {
        color: $gray-black;
      }
    }
  }
}
