
/*----------------------------------------
Keyframes
----------------------------------------*/

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes activeBtn {
  0%,
  100% {
    color: $red;
    transform: scale(1);
  }

  25% {
    transform: scale(1.2);
  }

  50% {
    color: $pink1;
  }
}

@keyframes activeBtnCircle {
  0% {
    transform: scale(0);
  }

  50% {
    border-color: $pink1;
    opacity: 1;
  }

  80% {
    border-color: $pink2;
    border-width: 0;
    transform: scale(1);
    opacity: 0;
  }

  100% {
    border-color: $pink3;
    border-width: 0;
    transform: scale(.8);
    opacity: 0;
  }
}

@keyframes activeBtnIcon {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }

  25% {
    transform: translate(-50%, -50%) scale(0);
  }

  50% {
    transform: translate(-50%, -50%) scale(1.2);
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
