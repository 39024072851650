@charset "UTF-8";

/*----------------------------------------
category
----------------------------------------*/
.p-category {

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: -10px;

    &-item {
      width: 25%;

      @include is-tablet {
        width: 50%;
      }

      &-link {
        display: block;
        padding: 10px;

      }

      &-ttl {
        @include fz(18);
        @include ls(1);
        padding-top: 15px;
        padding-left: 10px;
        font-weight: $bold;
        text-align: left;
        color: $primary-white;

        @include is-mobile {
          @include fz(15);
        }

      }

      &-figure {
        @extend %img-clip;

        &::after {
          padding-bottom: 50%;
        }
      }
    }
  }
}
