@charset "UTF-8";

/*----------------------------------------
c-btn-choices
----------------------------------------*/
.c-btn-choices {
  font-size: 0;
  text-align: center;
  padding: 20px 0;

  @include is-mobile {
    padding: 0;
  }

  &__body {
    display: inline-block;
    width: 320px;
    max-width: 100%;
    padding: 20px 0;
    margin: 0 20px;
    color: $white;
    background: $gray-black2;
    border: 0;
    box-shadow: none;
    position: relative;
    cursor: pointer;
    transition: background-color .2s ease;

    &:hover {
      @include is-laptop {
        background-color: $primary;
      }
    }

    @include is-tablet {
      width: 100%;
      margin: 10px 0;
    }

    &.is-primary {
      background: $red;
    &:hover {
      @include is-laptop {
        background-color: $red-black;
      }
    }

    }

    &-txt {
      @include fz(18);
      font-family: $font;
      padding: 0 20px;
      position: relative;
      z-index: 1;

      @include is-mobile {
        @include fz(16);
      }
    }
  }
}
