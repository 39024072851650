@charset "UTF-8";

/*----------------------------------------
c-blog-categories
----------------------------------------*/
.c-blog-categories {
  margin: 0 0 30px;
  position: relative;
  z-index: 2;
  text-align: left;
  background: $white;

  @include is-tablet {
    width: 100%;
  }

  @include is-mobile {
    margin: 0 0 15px;
  }

  &__list {
    display: inline-flex;
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 100%;
    margin: 0 -5px;

    @include is-tablet {
      width: 100%;
      flex-wrap: wrap;
    }

    @include is-mobile {
      margin: 0 -3px;
    }

    &-item {
      position: relative;
      padding: 5px;

      @include is-mobile {
        padding: 3px;
      }

      &-link {
        display: block;
        padding: 10px 30px;
        color: $blue-dark;
        border: 1px solid $blue-dark;
        transition: color .2s ease, background-color .2s ease;

        @include is-mobile {
          padding: 6px 20px;
        }

        &.is-current {
          color: $white;
          background: $blue-dark;
        }

        @include is-laptop {
          &:hover {
            color: $white;
            background: $blue-dark;

          }
        }

        &-txt {
          @include is-mobile {
            @include fz(11);
          }
        }
      }
    }
  }
}
