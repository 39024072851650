@charset "UTF-8";

/*----------------------------------------
c-detail-images
----------------------------------------*/
.c-detail-images {
  &__outer {
    position: relative;

    .c-favorite-btn {
      width: 68px;
      height: 68px;
      top: 20px;
      right: 20px;

      @include is-tablet {
        width: 46px;
        height: 46px;
        top: 10px;
        right: 10px;
      }

      &::before {
        border-width: 34px;

        @include is-mobile {
          border-width: 23px;
        }
      }

      &::after {
        @include fz(30);

        @include is-tablet {
          @include fz(24);
        }
      }
    }
  }

  &__slide {
    &-link {
      background: no-repeat center;
      background-size: cover;
      display: block;

      &::after {
        content: "";
        display: block;
        padding: 120% 0 0;
      }
    }
  }

  &__thumbnails {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0;
    margin: 0 -10px;

    @include is-tablet {
      display: block;
      padding: 6px;
      margin: 0;
      overflow-x: auto;
      white-space: nowrap;
    }

    &-item {
      $this: &;
      width: 20%;
      padding: 10px;

      @include is-tablet {
        width: 100px;
        display: inline-block;
        white-space: normal;
        padding: 6px;
      }

      &-btn {
        width: 100%;
        display: block;
        background: none;
        border: 0;
        outline: none;
        cursor: pointer;

        &.is-active {
          #{$this}-figure-img {
            box-shadow: 0 0 0 3px $red;
          }
        }
      }

      &-figure {
        position: relative;

        &-img {
          background: no-repeat center;
          background-size: cover;
          margin: 0 0 10px;
          transition: box-shadow .3s $ease;

          &::after {
            content: "";
            display: block;
            padding: 120% 0 0;
          }
        }

        &-src {
          opacity: 0;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }

        &-caption {
          @include fz(14);
          text-align: left;

          @include is-tablet {
            @include fz(10);
          }
        }
      }
    }
  }

  &__controls {
    position: absolute;
    top: 50%;
    right: -50px;
    left: -50px;
    pointer-events: none;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
    z-index: 10;

    @include is-tablet {
      right: 0;
      left: 0;
    }

    &-next,
    &-prev {
      width: 50px;
      height: 50px;
      display: block;
      background: none;
      border: 0;
      outline: none;
      cursor: pointer;
      pointer-events: auto;

      @include is-tablet {
        background: rgba($white, .5);
        width: 40px;
        height: 80px;
      }

      &::after {
        content: "";
        display: inline-block;
        width: 30px;
        height: 30px;
        border: solid $color-border-gray;
        border-width: 2px 2px 0 0;
        transform: rotate(45deg);

        @include is-tablet {
          width: 18px;
          height: 18px;
          border-width: 1px 1px 0 0;
        }
      }
    }

    &-next {
      @include is-tablet {
        padding: 0 10px 0 0;
      }
    }

    &-prev {
      @include is-tablet {
        padding: 0 0 0 10px;
      }

      &::after {
        transform: rotate(-135deg);
      }
    }
  }
}
