@charset "UTF-8";

/*----------------------------------------
content
----------------------------------------*/
.p-content {
  &__container {
    background: $gray-pale2;
    padding: 60px $side-padding;

    &:not(:last-child) {
      margin-bottom: 120px;

      @include is-mobile {
        margin-bottom: 60px;
      }
    }

    @include is-hamburger {
      padding: 60px $side-padding-break1;
    }

    @include is-tablet-large {
      padding: 60px $side-padding-break2;
    }

    @include is-mobile {
      padding: 40px $side-padding-mobile 20px;
    }

    &.is-contact {
      background: none;
      position: relative;
      z-index: 1;
      padding-bottom: 0;
    }

    &.is-blog {
      background: none;
      position: relative;
      z-index: 1;
      padding-top: 30px;
      padding-bottom: 0;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 60px;
        background: $gray-pale2;
      }
    }

    &.is-topics {
      background: none;
      position: relative;
      z-index: 1;
      padding-bottom: 0;

      @include is-tablet {
        padding-top: 30px;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 185px;
        background: $gray-pale2;

        @include is-tablet {
          height: 60px;
        }
      }
    }

    &.is-categories {
      background: none;
      position: relative;
      z-index: 1;
      padding-bottom: 0;
      margin-bottom: 60px;

      @include is-hamburger {
        padding: 60px $side-padding-break1 0;
      }  

      @include is-tablet-large {
        padding: 0 $side-padding-break2 0;
      }
  
      @include is-mobile {
        padding: 0 $side-padding-mobile 0;
        margin-bottom: 40px;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 120px;
        background: $gray-pale2;

        @include is-tablet {
          content: none;
        }
      }
    }

    &-inner {
      background: $white;
      padding: 95px;

      @include is-mobile {
        padding: 35px 16px;
      }

      .is-contact & {
        padding: 65px 150px 0;
        background: $white;

        @include is-tablet {
          padding: 65px 100px 0;
        }

        @include is-mobile {
          padding: 20px 16px 0;
        }
      }

      .is-blog & {
        padding: 0;
      }

      .is-topics & {
        padding: 0;
      }

      .is-categories & {
        position: relative;
        z-index: 2;
        padding: 20px 20px 0;

        @include is-tablet {
          padding: 0;
        }
      }
    }
  }

  &__section {

    &:not(:last-child) {
      margin: 0 0 80px;

      @include is-mobile {
        margin: 0 0 40px;
      }
    }
  }
}
