@charset "UTF-8";

/*----------------------------------------
c-o-list
----------------------------------------*/
.c-o-list {
  text-align: justify;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 100px;

    @include is-mobile {
      margin-bottom: 40px;
    }
  }

  &__item {
    counter-increment: c-o-list-number;
    margin: 0 0 35px;
    padding: 0 10px 0 35px;
    position: relative;
    z-index: 1;

    @include is-mobile {
      padding-left: 20px;
      margin-bottom: 20px;
    }

    &:last-child {
      margin-bottom: 70px;

      @include is-mobile {
        margin-bottom: 40px;
      }
    }

    @include is-mobile {
      margin-bottom: 30px;
    }

    &::before {
      content: counter(c-o-list-number);
      line-height: 1;
      display: block;
      position: absolute;
      top: 0;
      left: 5px;
    }

    &-txt {
      line-height: 1.8;
    }

  }
}
