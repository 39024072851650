@charset "UTF-8";

/*----------------------------------------
c-u-list-child
----------------------------------------*/
.c-u-list-child {
  text-align: justify;
  padding: 5px 0;

  &__item {
    @include fz(14);
    color: $gray-black;
    line-height: 1.8;
    padding: 0 0 0 10px;
    position: relative;

    @include is-mobile {
      @include fz(12);
    }

    &::before {
      content: "";
      display: block;
      width: 3px;
      height: 3px;
      border-radius: 50px;
      background: $gray-black;
      position: absolute;
      top: 10px;
      left: 2px;
    }
  }
}
