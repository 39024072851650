@charset "UTF-8";

/*----------------------------------------
c-sidebar
----------------------------------------*/
.c-sidebar {
  padding: 10px 0 0;
  overflow-y: auto;
  overflow-x: hidden;

  @include is-tablet {
    padding: 0;
  }

  .c-nav {
    &__categories {
      width: 100%;
      margin: -20px;

      @include is-tablet {
        display: none;
      }

      &-child {
        width: 100%;
        text-align: left;
        padding: 0;

        &-ttl {
          $this: &;
          position: relative;
          cursor: pointer;

          &::before,
          &::after {
            background: $gray02;
            width: 10px;
            height: 1px;
            position: absolute;
            top: 50%;
            right: 0;
            transition: transform .3s $ease;
          }

          &::before {
            transform: rotate(90deg);
          }

          &.is-active {
            &::before {
              transform: rotate(180deg);
            }
          }

          &.js-accrodion-btn {
            &::before,
            &::after {
              content: "";
              display: block;
            }

            &:hover {
              .c-nav__categories-child-ttl-link {
                color: $red;
              }
            }

            .c-nav__categories-child-ttl-link {
              pointer-events: none;
            }
          }

          &-link {
            display: block;
            padding: 20px;
            transition: color .5s $ease;

            @include is-mobile {
              @include fz(14);
            }

            &:hover {
              color: $red;
            }
          }
        }
      }

      &-list {
        display: none;
        position: relative;
        z-index: 1;

        &-item {
          position: relative;

          &-link {
            color: $gray02;
            padding: 10px 20px 10px 40px;
            display: block;
            transition: color .5s $ease;
            position: relative;

            @include is-mobile {
              @include fz(14);
            }

            &:hover {
              color: $red;
            }

            &::before {
              content: "";
              height: 1px;
              width: 4px;
              position: absolute;
              top: 50%;
              left: 20px;
              background: $gray02;
            }

            &-container {
              &::before,
              &::after {
                background: $gray02;
                width: 10px;
                height: 1px;
                position: absolute;
                top: 17.5px;
                right: 0;
                transition: transform .3s $ease;
              }

              &::before {
                transform: rotate(90deg);
              }

              &.is-active {
                &::before {
                  transform: rotate(180deg);
                }
              }

              &.js-accrodion-btn {
                cursor: pointer;

                &::before,
                &::after {
                  content: "";
                  display: block;
                }

                &:hover {
                  .c-nav__categories-list-item-link {
                    color: $red;
                  }
                }

                .c-nav__categories-list-item-link {
                  pointer-events: none;
                }
              }
            }

          }
        }

        .c-nav__categories-list {
          padding-left: 20px;
        }
      }
    }

    &__pages {
      width: 100%;
      padding: 20px 0 0;

      @include is-tablet {
        display: none;
      }

      &-list {
        &-item {
          margin: 10px 0 0;

          &-link {
            @include fz(18);
            font-weight: $bold;
            padding: 20px 0;
            display: block;
            text-align: left;
            border-bottom: 1px solid $primary;
            transition: color .5s $ease;

            @include is-mobile {
              @include fz(16);
              font-weight: $bold;
            }

            &:hover {
              color: $red;
            }
          }
        }
      }
    }

    &__sns {
      margin-top: 45px;

      @include is-tablet {
        display: none;
      }

      &-list {
        margin: -15px;

        &-item {
          text-align: left;

          &-link {
            font-family: $font-en;
            padding: 15px;
            display: block;
            vertical-align: middle;
            transition: color .2s ease;

            @include is-laptop {
              &:hover {
                color: $red;

                i {
                  color: $red;
                }
              }
            }

            i {
              @include fz(17);
              margin-right: 20px;
              width: 17px;
              text-align: center;
              transition: color .2s ease;
              
            }
          }
        }

      }
    }
  }

  &__banner {
    $this: &;
    padding: 30px 0 0;

    @include is-tablet {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
    }

    &-item {
      padding: 30px 0 0;

      @include is-tablet {
        width: 100%;
        padding: 0;

        &:not(:first-child) {
          margin-top: 30px;
        }
      }
    }

    &-link {
      font-size: 0;
      display: block;
      transition: opacity .3s $ease;

      &-img {
        width: 100%;
      }

      &:hover {
        opacity: .8;
      }
    }
  }

  &__ttl {
    @include fz(24);
    @include ls(2);
    font-family: $font-en;
    margin-bottom: 30px;
    text-align: left;

    @include is-mobile {
      @include fz(20);
    }
  }

  &__section {
    &:not(:first-child) {
      margin-top: 90px;

      @include is-mobile {
        margin-top: 50px;
      }
    }
  }

  &__categories {

    &-list {
      margin: -15px;

      @include is-mobile {
        margin: -12px;
      }

      &-item {
        text-align: left;

        &-link {
          @include fz(15);
          @include ls(2);
          padding: 15px;
          display: block;
          line-height: 1.2;
          transition: color .2s ease;

          &:hover {
            @include is-laptop {
              color: $red;
            }
          }

          @include is-mobile {
            padding: 12px;
            @include fz(13);
          }
        }
      }

    }
  }

  &__new {

    &-link {
      display: block;
      position: relative;

      &:hover {
        .c-sidebar__new-figure-img {
          transform: scale(1.1);
        }
      }
    }

    &-figure {
      @extend %img-clip;

      &-img {
        transform: scale(1);
        transition: transform .2s ease;
      }
    }

    &-category {
      @include fz(13);
      @include ls(2);
      color: $white;

      @include is-mobile {
        @include fz(12);
      }

      &-container {
        position: absolute;
        top: 0;
        left: 0;
        background: $black;
        padding: 10px;
      }
    }

    &-txt {
      padding: 20px 10px 30px;

      @include is-mobile {
        padding: 15px 0 30px;

        .c-sidebar__new-item:last-child & {
          padding: 15px 0 0;
        }
      }
    }

    &-ttl {
      @include fz(15);
      @include ls(2);
      margin-bottom: 10px;
      text-align: left;

      @include is-mobile {
        @include fz(14);

      }
    }

    &-time {
      @include fz(15);
      @include ls(2);
      text-align: left;
      font-family: $font-en;
      display: block;
      color: rgba($black,.5);

      @include is-mobile {
        @include fz(14);

      }
    }
  }
}
