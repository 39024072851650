@charset "UTF-8";

/*----------------------------------------
c-sale
----------------------------------------*/
.c-sale {
  display: block;
  height: 80px;

  @include is-mobile {
    height: 50px;
  }

  &__body {
    background: repeat-x center $red-white;
    background-size: auto 100%;
    height: 80px;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;

    @include is-mobile {
      height: 50px;
    }
  }
}
