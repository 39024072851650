@charset "UTF-8";

/*----------------------------------------
404
----------------------------------------*/
.p-404 {
  &__section {
    margin: 0 0 40px;

    @include is-mobile {
      padding: 20px 0 0;
    }

    .c-guid-desc {
      margin: 0 0 40px;

      p:not([class]) {
        margin: 0 0 20px;
      }
    }
  }
}
