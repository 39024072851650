@charset "UTF-8";

/*----------------------------------------
c-detail-header
----------------------------------------*/
.c-detail-header {
  text-align: left;
  opacity: 1;
  transition: opacity .3s $ease;

  @include is-tablet {
    padding: 20px 0 0;
  }

  &[data-status="fixed"] {
    position: fixed;

    @include is-tablet {
      position: static;
    }
  }

  &[data-status="fadeout"] {
    pointer-events: none;
    position: fixed;
    opacity: 0;

    @include is-tablet {
      pointer-events: auto;
      position: static;
      opacity: 1;
    }
  }

  &__ttl {
    @include fz(22);
    margin: 0 0 10px;

    @include is-tablet {
      @include fz(16);
    }
  }

  &__price {
    @include fz(28);
    @include ls(2);
    margin: 0 0 10px;
    font-weight: $bold;

    @include is-tablet {
      @include fz(22);
    }

    &.is-variation {
      @include fz(20);
      @include ls(1);
      line-height: 1.2;
      padding: 8px 20px 10px;
      background: $white-gray;

      @include is-tablet {
        @include fz(16);
      }

      small {
        @include fz(14);

        @include is-tablet {
          @include fz(12);
        }
      }
    }

    &.is-discount {
      @include fz(22);
      text-decoration: line-through;
      margin: 0;

      @include is-tablet {
        @include fz(18);
      }

      small {
        @include fz(16);

        @include is-tablet {
          @include fz(14);
        }
      }
    }

    &.is-sale {
      color: $red;
    }

    + :not([class^="c-detail-header__price"]) {
      padding: 20px 0 0;
    }

    small {
      @include fz(18);

      @include is-tablet {
        @include fz(16);
      }

      &:last-child {
        margin: 0 0 0 5px;
      }
    }
  }

  &__colors {
    margin: 0 0 10px;

    @include is-tablet {
      margin: 0;
    }

    &-ttl {
      font-weight: $bold;
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;

      &-item {
        $this: &;
        padding: 10px;

        &-btn {
          display: block;
          border: 1px solid $color-border;
          background: none;
          outline: none;
          width: 42px;
          height: 42px;
          cursor: pointer;

          &-radio {
            display: none;

            &:checked {
              + #{$this}-btn-color {
                border: 3px solid $red;
                box-shadow: 0 0 0 4px $white inset;
              }
            }
          }

          &-color {
            width: 100%;
            height: 100%;
            display: block;
            transition: border .3s $ease, opacity .3s $ease;
          }
        }
      }
    }
  }

  &__form {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px 40px;

    @include is-tablet {
      margin: 0 -10px 20px;
    }

    .c-error {
      margin: 10px 0 0;
    }

    &-item {
      width: 50%;
      padding: 10px 20px;

      @include is-tablet {
        padding: 10px;
      }

      &-ttl {
        font-weight: $bold;
        display: block;
        margin: 0 0 10px;
      }
    }

    input {
      &[type="number"] {
        @include fz(16);
        font-family: $font;
        width: 100%;
        border: 1px solid $gray;
        padding: 10px 20px;

        @include is-mobile {
          @include fz(14);
          padding: 10px 15px;
        }
      }
    }

    select {
      @include fz(16);
      font-family: $font;
      width: 100%;
      border: 1px solid $gray;
      background: $white;
      padding: 10px 40px 10px 20px;

      @include is-mobile {
        padding: 10px 40px 10px 15px;
      }
    }

    &-select {
      position: relative;

      &::after {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        border: solid $gray;
        border-width: 0 1px 1px 0;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-75%) rotate(45deg);
        pointer-events: none;
      }
    }
  }

  .c-cart-btn {
    margin: 0 0 20px;
  }

  &__nav {
    &-item {
      padding: 10px 0;

      &-link {
        text-decoration: underline;
      }
    }
  }
}
