@charset "UTF-8";

/*----------------------------------------
c-item
----------------------------------------*/
.c-item {
  $this: &;
  position: relative;
  height: 100%;
  box-shadow: 0px 6px 10px -4px rgba(0,0,0,.05);

  &.is-sold-out {
    #{$this}__figure {
      &::after {
        @include fz(20);
        @include ls(4);
        content: "SOLD OUT";
        font-family: $font-en;
        color: $white;
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        z-index: 0;
        transform: translateY(-50%);
        text-align: center;

        @include is-mobile {
          @include fz(16);
        }

        @include is-mobile-small {
          @include fz(14);
        }
      }

      &-img {
        &::before {
          background: rgba($black, .3);

          @include is-mobile {
            height: 100%;
          }
        }

      }
    }


  }

  &__rank {
    @include fz(24);
    font-family: $font-en;
    color: $white;
    line-height: 1;
    display: block;
    position: absolute;
    top: 5px;
    left: 10px;
    z-index: 1;
    margin-top: 0;
    text-align: center;

    @include is-mobile {
      @include fz(16);
      left: 5px;
    }
  }

  &__link {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    background-color: $white;

    .is-ranking & { 
      &::after {
        content: '';
      }
    }

    &::after {
      content: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 70px 70px 0 0;
      border-color: $blue-dark transparent transparent transparent;

      @include is-mobile {
        border-width: 50px 50px 0 0;
      }

      .c-grid__item:nth-child(1) & {
        border-top-color: $gold;
      }
      
      .c-grid__item:nth-child(2) & {
        border-top-color: $green-gray;
      }

      .c-grid__item:nth-child(3) & {
        border-top-color: $blue-dark;
      }
    }
  }


  &__figure {
    overflow: hidden;
    position: relative;

    @include is-tablet {
      .c-grid__item.is-ranking & {
        height: 100%;
        width: 100%;
      }
    }

    &-img {
      background: no-repeat center $white;
      background-size: contain;
      transition: transform .5s $ease;

      .c-item__link:hover & {
        @include is-laptop {
          transform: scale(1.1);
        }
      }

      @include is-mobile {
        .c-grid__item.is-ranking & {
          height: 100%;
        }
      }

      &.is-contain {
        background-color: $white-gray;
        background-size: contain;
      }

      &::before {
        content: "";
        display: block;
        padding: 56% 0 0;

        @include is-mobile {
          padding-top: 56.8%;
        }
      }
    }

    &-src {
      display: none;
    }
  }

  &__txt {
    padding: 9px 9px 16px;
    position: relative;
    text-align: left;

    .is-ranking & {
      padding: 18px 18px 25px;

      @include is-tablet {
        padding: 18px 18px 25px;
        width: 100%;
      }

      &::before {
        content: '';
      }
    }

    &::before {
      content: none;
      border: 1px solid $gray-pale;
      position: absolute;
      top: 9px;
      right: 9px;
      bottom: 9px;
      left: 9px;
    }
  }

  &__ttl {
    @include ls(1.2);
    margin: 0 0 10px;

    .c-grid.is-new & {
      &::before {
        @include fz(11);
        @include ls(2);
        text-indent: .2rem;
        font-weight: $bold;
        content: 'NEW';
        color: $red;
        border: 1px solid $red;
        line-height: 1;
        padding: 3px 6px;
        margin-right: 5px;

        @include is-mobile {
          @include fz(10);
          padding: 2px 5px 1px;
          vertical-align: middle;
        }
      }
    }

    &.is-sales {
      margin: 0;
      padding: 20px;
      font-weight: $bold;

      @include is-mobile {
        padding: 20px $side-padding-mobile 8px;
      }
    }

    @include is-mobile {
      margin-bottom: 10px;
    }
  }

  &__desc {
    @include fz(12);
    margin-top: 15px;
  }

  &__price,
  &__discount {
    @include fz(15);
    @include ls(1);

    @include is-tablet {
      padding-right: 0;
    }

    @include is-mobile {
      @include fz(14);
    }

    &.is-nearly {
      @include fz(13);
      @include ls(2);

      @include is-mobile {
        @include fz(12);
      }
    }

    span:not([class]) {
      display: inline-block;
      margin: 0 5px 0 0;
    }

    small {
      @include fz(12);
      @include ls(.12);
      font-weight: normal;

      @include is-mobile {
        @include fz(10);
      }
    }

    &-price {
      @include is-tablet {
        display: block; 
      }
    }

    &-parcent {
      @include ls(1);
      text-indent: .1rem;
      text-align: center;
      vertical-align: middle;
      padding: 8px 10px;
      background: $red;
      color: $white;
      line-height: 1;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateY(-100%);
      font-weight: $medium;


      @include is-mobile {
        @include fz(12);
      }

      small {
        @include fz(14);
        @include ls(1);
        text-indent: .1rem;

        @include is-mobile {
          @include fz(12);
        }
      }

    }
  }

  &__discount {
    color: $red;
    font-weight: $bold;
  }

  &__colors {
    display: flex;
    flex-wrap: wrap;
    margin: -4px;
    padding: 15px 0 0;

    @include is-mobile {
      margin: -2px;
    }

    &-item {
      width: 14px;
      height: 14px;
      border: 1px solid $gray-dull;
      margin: 4px;

      &.is-last {
        position: relative;

        &::after {
          position: absolute;
          content: "";
          background: $black;
          width: 10px;
          height: 1px;
          top: 50%;
          right: -20px;
          transform: translateY(-50%);
        }

        &::before {
          position: absolute;
          content: "";
          background: $black;
          width: 10px;
          height: 1px;
          top: 50%;
          right: -20px;
          transform: translateY(-50%) rotate(90deg);
        }
      }

    }
  }

  .c-cart-btn {
    margin: 20px 0 0;
  }

  &__cat {
    @include fz(12);
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 5px 8px;
    line-height: 1;
    color: $white;
    display: inline-block;
    background: $blue-dark;

    @include is-mobile {
      padding: 5px 8px;
    }

    &.is-aged-day {
      background: $cat01;
    }

    &.is-birthday {
      background: $cat02;
    }

    &.is-father {
      background: $cat03;
    }

    &.is-mother {
      background: $cat04;
    }

    &-container {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
