/*----------------------------------------
news-list
----------------------------------------*/
.c-news__list {
  padding: 0 $side-padding;

  @include is-hamburger {
    padding: 0 $side-padding-break1;
  }

  @include is-tablet-large {
    padding: 0 $side-padding-break2;
  }

  @include is-tablet {
    padding: 0;
  }

  &-item {
    &:first-child {
      border-top: 1px solid rgba($black,.1);
    }

    border-bottom: 1px solid rgba($black,.1);
  }

  &-link {
    padding: 30px 10px;
    display: block;

    @include is-mobile {
      padding: 15px 0;
    }

    &:hover {
      .c-news__list-ttl {
        color: $blue;
      }
      .c-news__list-time {
        color: $blue;
      }
    }
  }

  &-time {
    @include fz(17);
    position: relative;
    color: $gray-black;
    text-align: left;
    line-height: 1.2;
    transition: color .2s ease;
    width: 110px;
    font-weight: $bold;
    font-family: $font-en;

    @include is-tablet {
      width: 100%;
      margin-bottom: 20px;
    }

    @include is-mobile {
      @include fz(14);
      margin-bottom: 10px;
    }
  }

  &-txt {
    display: flex;
    align-items: center;

    @include is-tablet {
      flex-wrap: wrap;
    }
  }

  &-ttl {
    @include fz(14);
    padding-left: 30px;
    text-align: left;
    transition: color .2s ease;
    line-height: 1.2;

    @include is-tablet {
      padding-left: 0;
    }

    @include is-mobile {
      @include fz(13);
    }
  }
}