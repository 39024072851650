@charset "UTF-8";

/*----------------------------------------
c-data-table
----------------------------------------*/
.c-data-table {
  text-align: justify;

  &__row {
    text-align: justify;
  }

  &__ttl {
    @include fz(18);
    @include ls(1);
    padding: 0 5px 10px;
    position: relative;
    border-bottom: 1px solid $blue-dark;

    @include is-mobile {
      @include fz(15);
    }
  }

  &__desc {
    line-height: 1.8;
    padding: 10px 10px 30px;

    @include is-mobile {
      padding: 10px 5px 20px;
    }

    a:not([class]) {
      text-decoration: underline;
      color: $blue-dull;

      &:hover {
        color: $red;
      }
    }

  }
}
