@charset "UTF-8";

/*----------------------------------------
c-d-list
----------------------------------------*/
.c-d-list {
  text-align: justify;
  margin: -25px 0;

  @include is-mobile {
    margin: -15px 0;
  }

  &__row {
    padding: 25px 10px;

    @include is-mobile {
      padding: 15px 5px;
    }
  }

  &__ttl {
    font-weight: $bold;
    padding: 10px 0;

    @include is-mobile {
      padding: 5px 0;
    }
  }

  &__desc {
    line-height: 1.8;
    padding: 10px 0;

    @include is-mobile {
      padding: 5px 0;
    }
  }
}
