@charset "UTF-8";

/*----------------------------------------
c-breadcrumbs
----------------------------------------*/
.c-breadcrumbs {
  padding: 0 #{$side-padding * 2};

  @include is-tablet {
    padding: 0 $side-padding;
  }

  @include is-mobile {
    padding: 0 $side-padding-mobile;
  }

  &__list {
    text-align: left;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
    margin: 0 -8px;

    @include is-mobile {
      margin: 0 -4px;
    }

    &-item {
      padding: 10px 4px;
      display: inline-block;
      position: relative;

      @include is-mobile {
        padding: 8px 0;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }

      &::after {
        content: "/";
        display: block;
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translateX(-25%) translateY(-50%);
      }

      &-body {
        @include fz(14);
        padding: 8px 4px;
        display: block;
        transition: color .2s ease;

        @include is-mobile {
          @include fz(13);
        }
  

        &:hover {
          color: $blue;
        }

        &.is-link-none {
          color: $gray-black;
          cursor: default;

          &:hover {
            color: $gray-black;
          }

          &::after {
            content: none;
          }
        }
      }
    }
  }
}
