@charset "UTF-8";

/*----------------------------------------
c-favorite-btn
----------------------------------------*/
.c-favorite-btn {
  $this: &;
  color: $color-border-gray;
  background: none;
  width: 30px;
  height: 30px;
  border: 0;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  outline: none;
  cursor: pointer;

  @include is-tablet {
    top: 0;
    right: 0;
  }

  .summary & {
    width: 25px;
    height: 25px;
    top: 0;
    right: 0;

    @include is-mobile {
      right: -3px;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    border: 17px solid $red;
    transform: scale(0);
    transform-origin: center;
  }

  &::after {
    @include fz(17);
    content: '';
    font-family: "Font Awesome 5 Free";
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    transform-origin: center;
    transition: color .5s $ease;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2258%22%20height%3D%2258%22%20viewBox%3D%220%200%2058%2058%22%3E%20%20%20%3Cdefs%3E%20%20%20%20%20%3Cstyle%3E%20%20%20%20%20%20%20.g-header__menu-list-item-link-wish-path%20%7B%20%20%20%20%20%20%20%20%20fill%3A%20%23333%3B%20%20%20%20%20%20%20%20%20fill-rule%3A%20evenodd%3B%20%20%20%20%20%20%20%7D%20%20%20%20%20%20%20%20%20%3C%2Fstyle%3E%20%20%20%3C%2Fdefs%3E%20%20%20%3Cpath%20class%3D%22g-header__menu-list-item-link-wish-path%22%20d%3D%22M43.4%2C0.009A15.329%2C15.329%2C0%2C0%2C0%2C28.991%2C9.3%2C15.375%2C15.375%2C0%2C0%2C0%2C14.588.009C6.544%2C0.009%2C0%2C6.8%2C0%2C15.155%2C0%2C24.78%2C5.212%2C31.909%2C10.552%2C38.388c5.206%2C6.29%2C15.261%2C16.941%2C17.645%2C19.326L28.485%2C58h0.953l0.288-.287c2.566-2.567%2C12.643-13.2%2C17.717-19.328%2C5.338-6.477%2C10.551-13.606%2C10.551-23.231C57.993%2C6.8%2C51.449.009%2C43.4%2C0.009Zm0.428%2C35.663C39.579%2C40.828%2C32%2C49%2C29%2C52.1c-3.086-3.163-10.675-11.3-14.845-16.435-4.895-5.767-9.674-12.1-9.674-20.505%2C0-5.245%2C3.782-10.67%2C10.112-10.67%2C6.308%2C0%2C10.661%2C4.105%2C11.941%2C11.261h0a2.494%2C2.494%2C0%2C0%2C0%2C4.934%2C0h0C32.744%2C8.59%2C37.1%2C4.485%2C43.4%2C4.485c6.329%2C0%2C10.112%2C5.425%2C10.112%2C10.67C53.516%2C23.42%2C48.938%2C29.574%2C43.833%2C35.672Z%22%3E%3C%2Fpath%3E%20%3C%2Fsvg%3E");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
    line-height: 20px;

    @include is-mobile {
      @include fz(16);
      width: 16px;
      height: 16px;
      line-height: 16px;
    }
  }

  &:hover {
    color: $red;

    &::after {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2258%22%20height%3D%2258%22%20viewBox%3D%220%200%2058%2058%22%3E%20%20%20%3Cdefs%3E%20%20%20%20%20%3Cstyle%3E%20%20%20%20%20%20%20.g-header__menu-list-item-link-wish-path%20%7B%20%20%20%20%20%20%20%20%20fill%3A%20%23cb4e4e%3B%20%20%20%20%20%20%20%20%20fill-rule%3A%20evenodd%3B%20%20%20%20%20%20%20%7D%20%20%20%20%20%20%20%20%20%3C%2Fstyle%3E%20%20%20%3C%2Fdefs%3E%20%20%20%3Cpath%20class%3D%22g-header__menu-list-item-link-wish-path%22%20d%3D%22M43.4%2C0.009A15.329%2C15.329%2C0%2C0%2C0%2C28.991%2C9.3%2C15.375%2C15.375%2C0%2C0%2C0%2C14.588.009C6.544%2C0.009%2C0%2C6.8%2C0%2C15.155%2C0%2C24.78%2C5.212%2C31.909%2C10.552%2C38.388c5.206%2C6.29%2C15.261%2C16.941%2C17.645%2C19.326L28.485%2C58h0.953l0.288-.287c2.566-2.567%2C12.643-13.2%2C17.717-19.328%2C5.338-6.477%2C10.551-13.606%2C10.551-23.231C57.993%2C6.8%2C51.449.009%2C43.4%2C0.009Zm0.428%2C35.663C39.579%2C40.828%2C32%2C49%2C29%2C52.1c-3.086-3.163-10.675-11.3-14.845-16.435-4.895-5.767-9.674-12.1-9.674-20.505%2C0-5.245%2C3.782-10.67%2C10.112-10.67%2C6.308%2C0%2C10.661%2C4.105%2C11.941%2C11.261h0a2.494%2C2.494%2C0%2C0%2C0%2C4.934%2C0h0C32.744%2C8.59%2C37.1%2C4.485%2C43.4%2C4.485c6.329%2C0%2C10.112%2C5.425%2C10.112%2C10.67C53.516%2C23.42%2C48.938%2C29.574%2C43.833%2C35.672Z%22%3E%3C%2Fpath%3E%20%3C%2Fsvg%3E");
    }
  }

  &[data-status="loading"] {
    color: $color-border-gray;
    animation: activeBtn .5s $ease;

    &::after {
      content: "\f110";
      font-weight: 700;
      animation: rotate 1s linear infinite;
      background: none;
    }
  }

  &[data-status="true"] {
    color: $red;

    &::before {
      animation: activeBtnCircle .8s $ease;
    }

    &::after {
      font-weight: 900;
      animation: activeBtnIcon .8s $ease;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2246%22%20height%3D%2246%22%20viewBox%3D%220%200%2046%2046%22%3E%20%3Cdefs%3E%20%20%20%20%20%3Cstyle%3E%20%20%20%20%20%20%20.g-header__menu-list-item-link-wish2-path%20%7B%20%20%20%20%20%20%20%20%20fill%3A%20%23cb4e4e%3B%20%20%20%20%20%20%20%20%20fill-rule%3A%20evenodd%3B%20%20%20%20%20%20%20%7D%20%20%20%20%20%3C%2Fstyle%3E%20%20%20%3C%2Fdefs%3E%20%20%20%3Cpath%20class%3D%22g-header__menu-list-item-link-wish2-path%22%20d%3D%22M34.424%2C0.006A12.159%2C12.159%2C0%2C0%2C0%2C22.993%2C7.375%2C12.194%2C12.194%2C0%2C0%2C0%2C11.57.006%2C11.812%2C11.812%2C0%2C0%2C0%2C0%2C12.019c0%2C7.634%2C4.134%2C13.289%2C8.369%2C18.426%2C4.129%2C4.989%2C12.1%2C13.437%2C13.994%2C15.328L22.592%2C46h0.756l0.228-.229C25.611%2C43.738%2C33.6%2C35.307%2C37.627%2C30.444c4.234-5.136%2C8.368-10.791%2C8.368-18.425A11.812%2C11.812%2C0%2C0%2C0%2C34.424.006Z%22%2F%3E%20%3C%2Fsvg%3E");
    }
  }

  &-container {
    position: relative;
  }

}
