@charset "UTF-8";

/*----------------------------------------
select2
----------------------------------------*/

.select2 {
  display: block;

  &.select2-container .select2-selection--single {
    height: 48px;
    border-color: $black;
    border-radius: 0;
  }

  &.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 48px;
    padding-left: 20px;
    padding-right: 20px;
  }

  &.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 48px;
  }

  .select2-container .select2-selection--single .select2-selection__rendered,
  .select2-container--default .select2-selection--single .select2-selection__rendered,
  .select2.select2-container--default .select2-selection--single .select2-selection__rendered{
    font-family: $font;
    width: 100%;
    border: 1px solid $primary;
    background: $white;
    padding: 10px 40px 10px 20px;

    @include is-mobile {
      padding: 10px 40px 10px 15px;
    }

    &:disabled {
      background: $white-gray;
      opacity: .3;
    }
  }

  .select2-container .select2-selection--single{
    margin: 0;
  }

  .select2.select2-container .select2-selection--single {
    height: auto;
  }

  .select2-container--default .select2-selection--single {
    background-color: transparent;
    border: none;
    border-radius: 0;
  }

  .select2.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: normal;
  }

  .select2.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 100%;
  }
}

.select2-results__option {
  text-align: left;
}