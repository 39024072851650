@charset "UTF-8";

/*----------------------------------------
g-floating
----------------------------------------*/

.g-floating__banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 240px;
  z-index: 5;
  opacity: 0;
  pointer-events: none;
  transition: opacity .2s ease;

  &.is-active {
    opacity: 1;
    pointer-events: initial;
  }

  @include is-mobile {
    width: 140px;
  }

  &-close {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(50%) translateY(-50%);
    padding: 14px;
    border: none;
    background: none;

    &-inner {
      background: $primary-white;
      border-radius: 100%;
      width: 26px;
      height: 26px;
      display: block;

      @include is-mobile {
        width: 16px;
        height: 16px;
      }

      &::before {
        content: '';
        width: 3px;
        height: 12px;
        background: $white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%) rotate(-45deg);

        @include is-mobile {
          width: 2px;
          height: 7px;
        }
      }

      &::after {
        content: '';
        width: 3px;
        height: 12px;
        background: $white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%) rotate(45deg);

        @include is-mobile {
          width: 2px;
          height: 7px;
        }
      }
    }
  }

  &-img {
    vertical-align: bottom;
  }

}
