@charset "UTF-8";

/*----------------------------------------
c-guid-desc
----------------------------------------*/
.c-guid-desc {
  @include fz(15);
  line-height: 1.8;
  margin: 0 0 20px;

  @include is-mobile {
    @include fz(14);
  }

  .p-privacy-block & {
    text-align: left;
    padding-left: 10px;

    @include is-tablet {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .c-section.is-guide & {
    text-align: left;
    padding-left: 10px;

    @include is-tablet {
      padding-left: 5px;
      padding-right: 5px;
    }

  }

  .mw_wp_form_complete & {
    margin-bottom: 70px;

    @include is-mobile {
      margin-bottom: 40px;
    }
  }

  @include is-tablet {
    text-align: left;
  }

  :not(:last-child) > & {

    &:last-child {
      margin-bottom: 60px;

      .c-form__read & {
        margin-bottom: 0;
      }

      @include is-mobile {
        margin-bottom: 40px;
      }
    }
  }

  .c-form-container & {
    text-align: center;

    @include is-tablet {
      text-align: left;
    }
  }

  &.is-center {
    text-align: center;

    @include is-mobile {
      text-align: justify;
    }
  }
}
