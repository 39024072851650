@charset "UTF-8";

/*----------------------------------------
index
----------------------------------------*/
.p-index {
  &__visual {
    margin-top: -$header_height_tablet;

    @include is-mobile {
      margin-top: -$header_height_mobile;
    }

    &-container {
      height: 90vh;
      min-height: 800px;
      position: relative;
      z-index: 2;

      @include is-tablet {
        height: auto;
        min-height: auto;
      }
    }

    &-main {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 40px;
      left: 0;

      @include is-mobile {
        bottom: 25px;
      }

      &::after {
        content: '';
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%226%22%20height%3D%226%22%20viewBox%3D%220%200%202%202%22%20preserveAspectRatio%3D%22none%22%3E%20%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M-1-1v4h4v-4z%22%2F%3E%20%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M1-1h.10l-4%204v-.10zM3-1v.10l-4%204h-.10z%22%2F%3E%20%3C%2Fsvg%3E");
        background-repeat: repeat;
        position: absolute;
        top: 20px;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: .3;
        z-index: 1;

        @include is-mobile {
          top: $side-padding-mobile;
        }
      }

      &-box {
        position: absolute;
        top: $header_height_tablet;
        bottom: #{$header_height_tablet - 25};
        right: 0;
        padding: 0 0 0 20px;
        overflow: hidden;
        width: 820px;

        @include is-pc {
          width: 720px;
        }

        @include is-hamburger {
          width: 520px;
        }

        @include is-tablet {
          bottom: auto;
          width: calc(100% - #{$side-padding});
        }

        @include is-mobile {
          top: $header_height_mobile;
          width: calc(100% - #{$side-padding-mobile});
          padding-left: $side-padding-mobile;
        }

        &.is-single-post {
          bottom: 120px;

          @include is-tablet {
            bottom: auto;
          }
        }

        &.is-double-post {
          bottom: 195px;

          @include is-tablet {
            bottom: auto;
          }
        }

      }

      &-wrapper {
        width: 100%;
        height: 100%;

        @include is-tablet {
          padding-bottom: 105%;
        }

        .p-index__visual-main-box.is-single-post & ,
        .p-index__visual-main-box.is-double-post & {
          @include is-tablet {
            padding-bottom: 67.5%;
          }
        }

      }

      &-container {
        height: 100%;
        z-index: 2;
        overflow: visible;
        padding: 0 0 20px 20px;

        @include is-mobile {
          padding: 0 0 $side-padding-mobile $side-padding-mobile;

        }
      }

      &-slide {
        height: 100%;
        // transform: translateY(20px);
        // opacity: 0;
        // animation: slider-fade-reverse .5s ease forwards;

        @include is-mobile {
          transform: translateX($side-padding);
        }

        &.swiper-slide-active, &.swiper-slide-duplicate-active {
          z-index: 2;
          // animation: slider-fade 1s ease forwards;
        }

        &-link {
          height: 100%;
          transform: translateY(20px);
          display: block;
          animation: slider-fade-reverse .5s ease forwards;

          .swiper-slide-active &, .swiper-slide-duplicate-active & {
            animation: slider-fade 1s ease forwards;
          }

        }
      }
    }

    &-sub {
      height: 100%;
      width: 100%;

      .p-index__visual-sub-wrapper.is-single-post & {
        height: calc(100% - 70px);

        @include is-tablet {
          height: 100%;
        }
      }

      .p-index__visual-sub-wrapper.is-double-post & {
        height: calc(100% - 140px);

        @include is-tablet {
          height: 100%;
        }
      }

      &-wrapper {
        width: 100%;
        padding-right: #{$side-padding * 2};
        height: 100%;
        display: flex;
        flex-direction: column;
        
        @include is-tablet {
          padding-right: $side-padding;
          height: auto;
        }

        @include is-mobile {
          padding-right: #{$side-padding-mobile * 2};
        }
      }


      &-slide {
        height: 100%;

        &-txt {
          width: calc(100% - 820px + #{$side-padding * 2});
          position: relative;
          z-index: 2;
          padding: 80px 0 80px $side-padding;
          margin-right: 20px;
          text-align: left;
          flex-wrap: wrap;
          opacity: 0;
          animation: slider-txt-reverse .3s ease forwards;

          @include is-pc {
            width: calc(100% - 720px + #{$side-padding * 2});
          }

          @include is-hamburger {
            width: calc(100% - 520px + #{$side-padding * 2});
          }

          @include is-tablet {
            width: 100%;
            margin-top: calc(105% + #{$header_height_tablet + 15px});
            margin-bottom: 160px;
            margin-right: 0;
          }

          @include is-mobile {
            padding: 20px 0 20px $side-padding-mobile;
            margin-top: calc(105% + #{$header_height_mobile + 15px});
            margin-bottom: 80px;
          }

          .p-index__visual-sub-wrapper.is-single-post & ,
          .p-index__visual-sub-wrapper.is-double-post & {
            @include is-tablet {
              margin-top: calc(67.5% + #{$header_height_tablet + 15px});
            }

            @include is-mobile {
              margin-top: calc(67.5% + #{$header_height_mobile + 15px});
            }
          }

          &::after {
            content: '';
            position: absolute;
            background: rgba($green-gray2, .3);
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            @include is-tablet {
              right: #{$side-padding / 2};
            }

            @include is-mobile {
              right: 28px;
            }
          }

          .swiper-slide-active &, .swiper-slide-duplicate-active & {
            animation: slider-txt 1s ease .6s forwards;
          }

          &-container {
            border-bottom: 1px solid $white;
            padding-bottom: 40px;
            padding-right: 20px;
            position: relative;
            z-index: 1;

            @include is-mobile {
              padding-right: 10px;
              padding-bottom: $side-padding-mobile;
            }
          }

          &-inner {
            @include fz(45);
            font-weight: $bold;
            display: inline-block;
            padding: 15px;
            line-height: 1;
            background: $white;
            color: $green-gray;

            @include is-tablet {
              box-shadow: 0px 0px 16px -6px rgba($black,.3);
            }

            @include is-mobile {
              @include fz(22);
              padding: 10px $side-padding-mobile;
            }

            &:not(:last-child) {
              margin-bottom: 20px;

              @include is-mobile {
                margin-bottom: 10px;
              }
            }
          }
        }

        &-btn {
          @include fz(15);
          text-indent: -.01rem;
          display: inline-block;
          padding: 15px 70px;
          border: 1px solid $white;
          margin-top: -1px;
          color: $white;
          font-family: $font-en;
          background: none;
          line-height: 1;
          position: relative;
          z-index: 1;

          @include is-mobile {
            @include fz(11);
            padding: 10px #{$side-padding-mobile * 2};
          }

        }
      }

    }

    &-slide {
      &-link {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        position: relative;
        height: 100%;
  
        &-img {
          overflow: hidden;
          position: absolute;
          background-size: cover;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-position: left bottom;

          &.is-sub {
            @include is-tablet {
              right: #{$side-padding / 2};
            }

            @include is-mobile {
              right: 28px;
            }
          }
        }
      }
    }

    &-nav {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
      width: 120px;
      display: flex;
      pointer-events: initial;

      @include is-tablet {
        pointer-events: initial;
        justify-content: flex-end;
        bottom: auto;
        top: 0;
        transform: translateY(-50%);
      }

      @include is-mobile {
        width: 80px;
      }

      &-next {
        @include is-mobile {
          width: 40px;
          height: 40px;
        }
      }

      &-prev {
        @include is-mobile {
          width: 40px;
          height: 40px;
        }
      }
    }

    &-pagination {
      display: flex;
      align-items: center;
      padding: 0 20px;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;

      .swiper-pagination-bullet {
        background: $white;
        opacity: .3;
        width: 8px;
        height: 8px;

        &:not(:last-child) {
          margin-right: 10px;
        }
      }

      .swiper-pagination-bullet-active {
        opacity: 1;
      }
    }

    &-bottom {
      transform: translateY(-50%);
      position: relative;
      z-index: 2;
      display: flex;
      padding-left: 160px;

      @include is-tablet-large {
        padding-left: 100px;
      }

      @include is-tablet {
        justify-content: flex-end;
        flex-wrap: wrap;
        transform: none;
        padding-left: 0;
      }
    }

    &-scroll {
      @include fz(13);
      @include ls(1.2);
      color: $gray-black;
      font-weight: $bold;
      font-family: $font-en;
      position: absolute;
      bottom: 0;
      left: $side-padding - 5px;
      padding-left: 25px;
      padding-bottom: 20px;
      padding-top: 15px;
      padding-right: 70px;
      line-height: 1;
      overflow: hidden;
      transform: rotate(90deg) translateX(-100%);
      transform-origin: left bottom;

      @include is-hamburger {
        left: $side-padding-break1 - 5px;

      }

      @include is-tablet-large {
        left: $side-padding-break2 - 5px;
      }

      @include is-tablet {
        bottom: auto;
        top: -18px;
      }

      @include is-mobile {
        @include fz(10);
        top: -13px;
        left: $side-padding-mobile - 6px;
        padding-bottom: 15px;
        padding-top: 8px;
        padding-right: 20px;
      }

      &::before {
        content: '';
        position: absolute;
        right: 14px;
        bottom: 13px;
        width: 1px;
        height: 15px;
        background: $gray-black;
        transform: rotate(115deg);
        transform-origin: right bottom;
        display: inline-block;
        animation: scroll-small 2s cubic-bezier(0.075, 0.82, 0.165, 1) infinite;
      }
  
      &::after {
        position: absolute;
        bottom: 6px;
        right: 0;
        content: "";
        height: 150px;
        width: 1px;
        background: $gray-black;
        transform-origin: top;
        display: inline-block;
        transition: transform .5s ease;
        animation: scroll-large 2s cubic-bezier(0.075, 0.82, 0.165, 1) infinite;
        transform-origin: left bottom;
        transform: rotate(-90deg);

        @include is-mobile {
          height: 75px;
        }
  
      }
    }

  }

  &__campaign,
  &__news-top {
    padding-left: #{$side-padding * 2};
    position: relative;
    overflow: hidden;
    height: 70px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    &:last-child {
      border-top: 1px solid $gray-light03;
    }

    @include is-tablet {
      padding-left: $side-padding;
      margin-right: 40px;
      padding-bottom: 24px;
      height: auto;
    }

    @include is-mobile {
      padding-left: $side-padding-mobile;
      margin-right: 28px;
      padding-bottom: 12px;
    }

    &::after {
      content: '';
      height: auto;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: transparent;
      opacity: .3;
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%224%22%20height%3D%224%22%20viewBox%3D%220%200%202%202%22%20preserveAspectRatio%3D%22none%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M1-1h.10l-4%204v-.10zM3-1v.10l-4%204h-.10z%22%2F%3E%20%3C%2Fsvg%3E");
    }

    &-list {
      position: relative;
      z-index: 1;

      &-item {
        @include is-tablet {
          display: flex;
          justify-content: flex-end;
          flex-direction: column;
          align-items: flex-end;
        }


        &-link {
          display: flex;
          padding: 20px 160px 20px 0;

          @include is-tablet {
            width: 100%;
            flex-wrap: wrap;
            align-items: flex-start;
            padding: 24px $side-padding 24px 0;
          }

          @include is-mobile {
            padding: 5px $side-padding-mobile 12px 0;
          }
        }

        &-txt {
          width: calc(100% - 180px);
          display: flex;
          align-items: center;

          @include is-tablet {
            padding-left: 0;
            flex-wrap: wrap;
            width: 100%;
          }
        }

        &-cat {
          @include ls(1);
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          text-indent: .1rem;
          background: $blue-dark;
          line-height: 1;
          color: $white;
          font-family: $font-en;
          font-weight: $semibold;
          text-align: center;
          padding: 8px 16px 5px;
          margin-right: 20px;
          white-space: nowrap;

          @include is-mobile {
            @include fz(11);
            margin-right: 10px;
            padding: 4px 8px 1px;
          }

        }

        &-btn {
          @include fz(13);
          position: absolute;
          top: 50%;
          right: 40px;
          transform: translateY(-50%);
          color: $blue-dark;
          padding: 0 50px 3px 5px;
          border-bottom: 1px solid $blue-dark;
          font-family: $font-en;
          transition: transform .2s ease, color .2s ease, border-color .2s ease;

          @include is-tablet {
            position: relative;
            transform: translateY(0);
            right: 0;
          }

          @include is-mobile {
            @include fz(10);
            padding: 0 30px 2px 5px;
          }

          &:hover {
            @include is-laptop {
              color: $blue;
              transform: translateY(-50%) translateY(2px) translateX(1px);
              border-color: $blue;

              &::after {
                border-color: transparent $blue transparent transparent;
              }
            }
          }

          &::after {
            position: absolute;
            right: 5px;
            bottom: 5px;
            width: 0;
            height: 0;
            border-width: 10px 10px 0 0;
            border-style: solid;
            border-color: transparent $blue-dark transparent transparent;
            content: '';
            transition: color .2s ease, border-color .2s ease;

            @include is-mobile {
              right: 4px;
              bottom: 4px;
              border-width: 8px 8px 0;
            }
          }
        }

        &-time {
          @include fz(15);
          @include ls(2);
          text-indent: .2rem;
          display: flex;
          align-items: center;
          font-family: $font-en;
          padding-top: 3px;

          @include is-mobile {
            @include fz(14);
          }
        }

        &-desc {
          @include ls(1);
          padding-left: 20px;
          line-height: 1;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          position: relative;
          transition: color .2s ease;

          @include is-laptop {
            .p-index__campaign-list-item-link:hover & ,
            .p-index__news-top-list-item-link:hover & {
              color: $blue;
            }
          }

          &::after {
            content: '';
            height: 1px;
            position: absolute;
            bottom: 0;
            left: 20px;
            right: 0;
            background: $blue;
            transform: scale(0);
            transition: transform .2s ease;
            transform-origin: right;

            @include is-laptop {
              .p-index__campaign-list-item-link:hover & ,
              .p-index__news-top-list-item-link:hover & {
                transform: scaleX(1);
                transform-origin: left;
              }
            }
          }

          @include is-tablet {
            width: 100%;
            padding-top: 18px;
            padding-left: 0;
            text-align: left;
          }

          @include is-mobile {
            padding-top: 9px;
          }
        }

      }

    }

  }

  &__news-top-list-item-cat {
    background: $blue-gray-dark;
  }

  &__contents {
    margin-bottom: 85px;

    @include is-mobile {
      margin-bottom: 60px;
    }

    &.is-about {
      padding-top: 60px;

      @include is-mobile {
        padding-top: 30px;
      }
    }

    &-details {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin-right: $side-padding;

      @include is-hamburger {
        margin-right: $side-padding-break1;

      }

      @include is-tablet-large {
        margin-right: $side-padding-break2;

      }

      @include is-tablet {
        flex-wrap: wrap;
        margin-right: 0;
      }

      &.is-img-none {
        margin-left: $side-padding;

        @include is-hamburger {
          margin-left: $side-padding-break1;
  
        }
  
        @include is-tablet-large {
          margin-left: $side-padding-break2;
  
        }

        @include is-tablet {
          margin-left: 0;
        }
      }

      &:not(:first-child) {
        margin-left: $side-padding;

        @include is-hamburger {
          margin-left: $side-padding-break1;

        }

        @include is-tablet-large {
          margin-left: $side-padding-break2;

        }

        @include is-tablet {
          margin-left: 0;
        }

      }

      &:not(:last-child) {
        margin-bottom: 50px;
      }

      &-figure {
        @extend %img-clip;
        width: 55%;

        @include is-tablet {
          width: 100%;
        }

        .p-index__contents-details:nth-child(even) & {
          order: 2;

          @include is-tablet {
            order: 1;
          }
        }

        &::after {
          padding-bottom: 45%;

          @include is-mobile {
            padding-bottom: 65%;
          }
        }
      }

      &-bg {

        @include is-tablet {
          .p-index__contents-details & {
            right: 15%;
          }

          .p-index__contents-details:nth-child(even) & {
            left: 15%;
            right: 0  ;
          }
        }
      }

      &-txt {
        @include fz(15);
        @include ls(1.2);
        line-height: 2.5;
        background: $white;
        padding: 60px 80px;
        position: relative;
        z-index: 2;
        text-align: left;

        @include is-mobile {
          @include fz(13);
          line-height: 1.8;
          padding: 20px;
        }

        &-container {
          width: 54%;
          position: relative;
          margin-left: -40px;
          margin-bottom: 20px;

          @include is-tablet {
            width: 100%;
            margin: -130px $side-padding-break2 20px;
          }

          @include is-mobile {
            margin: -130px $side-padding-mobile 5px;
          }

          &::after {
            content: '';
            position: absolute;
            top: 20px;
            bottom: -20px;
            left: -20px;
            right: 20px;
            background: rgba($blue-light, .2);
            z-index: 1;

            @include is-mobile {
              top: 5px;
              bottom: -5px;
              left: -5px;
              right: 5px;
            }

            .is-img-none & {
              top: -20px;
              right: -20px;

              @include is-mobile {
                top: -5px;
                right: -5px;
              }
            }
          }

          .p-index__contents-details:nth-child(even) & {
            order: 1;
            margin-left: 0;
            margin-right: -40px;

            @include is-tablet {
              order: 2;
              margin: -130px $side-padding-break2 20px;
            }

            @include is-mobile {
              margin: -130px $side-padding-mobile 5px;
            }

            &::after {
              left: 20px;
              right: -20px;

              @include is-mobile {
                left: 5px;
                right: -5px;
              }
            }
          }

          .p-index__contents-details:nth-child(even).is-img-none & {
            margin-top: 20px;
            margin-right: 20px;
            margin-left: 20px;
            width: 100%;

            @include is-tablet {
              margin: 20px $side-padding-break2;
            }

            @include is-mobile {
              margin: 5px $side-padding-mobile;
            }

            &::after {
              left: -20px;

              @include is-mobile {
                left: -5px;
              }
            }
          }

          .is-img-none & {
            margin-right: 20px;
            margin-left: 20px;
            margin-top: 20px;
            width: 100%;

            @include is-tablet {
              margin: 20px $side-padding-break2;
            }

            @include is-mobile {
              margin: 5px $side-padding-mobile;
            }
          }

        }
      }
    }
  }

  &__category {

    &-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      &.is-overed {
        .p-index__category-list-item {
          width: 25%;

          @include is-hamburger {
            width: 50%;
          }
        }
      }

      &-container {
        position: relative;
        z-index: 2;
      }

      &-item {
        width: 33.3333%;
      }

      &-figure {
        @extend %img-clip;

        &::after {
          padding-bottom: 50%;
        }
      }

      &-bg {
        transition: opacity .2s ease;

          @include is-laptop {
          .p-index__category-list-link:hover & {
            opacity: .7;
          }
        }
      }

    }

  }

  &__news {
    background-size: cover;

    &-container {
      &.p-index__section-container {
        padding-top: 180px;
        padding-bottom: 100px;

        @include is-mobile {
          padding-bottom: 50px;
        }
      }

      &-inner {
        width: 62.5%;
        margin: 0 auto;

        @include is-tablet {
          width: 100%;
        }
      }
    }

    &-meta {
      &.p-index__section-meta {
        justify-content: center;
      }
    }

    &-ttl {
      @include fz(15);
      font-family: $font-en;
      font-weight: $bold;
      line-height: 1.4;
      padding: 25px 0;
      color: $black;
      max-width: 100px;

      @include is-tablet {
        padding: 25px 30px 25px 0;
        padding-right: 30px;
      }

      @include is-mobile {
        padding: 10px 15px 10px 0;
      }
    }

    &-link {
      display: inline-block;
      width: calc(100% - 60px);
      vertical-align: middle;
    }

    &-data {
      display: flex;

      @include is-tablet {
        flex-wrap: wrap;
      }

      &-date {
        font-family: $font-en;
        font-weight: $bold;
        padding-right: 20px;
        line-height: 1.4;
        white-space: nowrap;
        text-align: left;
        color: $black;

        @include is-tablet {
          padding-right: 0;
          width: 100%;
        }

        @include is-mobile {
          @include fz(14);
          padding-right: 60px;
        }
      }

      &-ttl {
        text-decoration: underline;
        line-height: 1.4;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 82%;
        overflow: hidden;
        text-align: left;
        transition: color .2s ease;

        @include is-laptop {
          .p-index__news-link:hover & {
            color: $blue;
          }
        }

        @include is-tablet {
          width: 100%;
          margin-top: 20px;
        }

        @include is-mobile {
          @include fz(14);
          margin-top: 10px;
        }
      }
    }
  }

  &__section {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 80px;

      @include is-mobile {
        margin-bottom: 40px;
        
      }
    }

    &-meta {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      z-index: 1;
      margin-bottom: 30px;

      @include is-tablet {
        position: static;
      }

      @include is-mobile {
        margin-bottom: 15px;
      }

      .is-ranking &,
      .is-category & {
        justify-content: center;
      }

      .p-index__blog & {
        @include is-tablet {
          justify-content: center;
        }
      }
    }

    &-container {
      padding: 50px #{$side-padding * 2} 0;
      position: relative;

      @include is-tablet {
        padding: 50px $side-padding 0;

        &.is-btn-show {
          padding-bottom: 85px;

          @include is-mobile {
            padding-bottom: 55px;
          }
        }
      }

      @include is-mobile {
        padding: 30px $side-padding-mobile 25px;
      }

      &.is-product-list {
        &::after {
          content: '';
          background: $blue-pale;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          height: 390px;

          @include is-tablet {
            bottom: 350px;
            height: auto;
          }

          @include is-mobile {
            bottom: 175px;
          }

        }

        &.is-btn-show {
          &::after {
            @include is-tablet {
              bottom: 260px;
            }

            @include is-mobile {
              bottom: 130px;
            }
          }
        }
      }

      &.is-bg-line {
        padding: 50px #{$side-padding * 2} 25px;

        @include is-tablet {
          padding: 50px $side-padding 25px;
        }

        @include is-mobile {
          padding: 30px $side-padding-mobile 25px;
        }

        &::after {
          content: '';
          height: auto;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          background-color: transparent;
          opacity: .3;
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%224%22%20height%3D%224%22%20viewBox%3D%220%200%202%202%22%20preserveAspectRatio%3D%22none%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M1-1h.10l-4%204v-.10zM3-1v.10l-4%204h-.10z%22%2F%3E%20%3C%2Fsvg%3E");
        }

        &.p-index__slider-container {
          &::after {
            right: #{$side-padding * 2};

            @include is-tablet {
              right: 0;
            }
          }
        }
      }

      &.is-bg-unset {
        padding-top: 0;
        padding-bottom: 0;
      }

      &.is-subpage {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    &-btn {
      &.c-btn {
        position: relative;
        z-index: 1;

        @include is-tablet {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }

        &.is-bottom {
          padding: 40px #{$side-padding * 2} 0;
          
          @include is-tablet {
            position: relative;
          }

          @include is-mobile {
            padding: 25px #{$side-padding-mobile * 2} 0;
          }
        }

        &.is-all-product {
          padding-top: 40px;

          @include is-tablet {
            position: relative;
          }

          @include is-mobile {
            width: 100%;
            padding: 25px #{$side-padding-mobile * 2} 0;
          }

          &.is-btn-second {
            @include is-tablet {
              padding-top: 50px;

              
            }
          }

          .c-btn__body {
            @include is-mobile {
              @include fz(14);
              width: 100%;
              padding: $side-padding-mobile $side-padding-mobile #{$side-padding-mobile - 4px};
            }
          }
        }

        &.is-news {
          @include is-tablet {
            position: static;
            transform: translateX(0);
          }
        }
      }
    }
  }

  &__blog {
    position: relative;
    margin-bottom: -115px;

    &-container {
      position: relative;
      padding-top: 0;
      padding-bottom: 0;
  
      &-inner {
        margin: 0 -10px;
      }
    }
  }

  &__banner {
    &-list {
      display: flex;
      margin: -10px -10px 0;

      @include is-tablet {
        flex-wrap: wrap;
      }
    }

    &-item {
      width: 50%;
      padding: 10px;

      @include is-tablet {
        width: 100%;
      }

      &-link {
        width: 100%;
        height: 240px;
        padding: 20px;
        position: relative;
        display: block;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-size: cover;
        transition: opacity .2s ease;

        &:hover {
          @include is-laptop {
            opacity: .7;
          }
        }

        @include is-mobile {
          height: 115px;
          padding: 10px;
        }

        &::before {
          display: block;
          content: '';
          position: absolute;
          top: 10px;
          right: 10px;
          bottom: 10px;
          left: 10px;
          border: 1px solid $white;

          @include is-mobile {
            top: 5px;
            right: 5px;
            bottom: 5px;
            left: 5px;
          }
        }

        &::after {
          content: '';
          position: absolute;
          right: 10px;
          bottom: 10px;
          width: 0;
          height: 0;
          border-width: 30px 30px 0 0;
          border-style: solid;
          border-color: transparent $white transparent transparent;

          @include is-mobile {
            right: 5px;
            bottom: 5px;
            border-width: 20px 20px 0 0;

          }
        }
      }
    }

    &-ttl {
      &-main {
        @include fz(25);
        color: $white;
        display: block;

        @include is-mobile {
          @include fz(18);
        }
      }

      &-sub {
        @include fz(15);
        display: block;
        font-family: $font-en;
        font-weight: $semibold;
        padding-top: 25px;
        position: relative;
        color: $white;

        @include is-mobile {
          @include fz(11);
          padding-top: 15px;
        }

        &::before {
          content: '';
          position: absolute;
          top: 12.5px;
          left: 50%;
          height: 2px;
          width: 40px;
          transform: translateX(-50%);
          background: $white;

          @include is-mobile {
            top: 6px;
          }
        }
      }
    }

  }

  &__slider {

    &-container {
      &.p-index__section-container {
        padding-right: 0;
        padding-bottom: 50px;
        padding-left: #{$side-padding * 2};

        @include is-tablet {
          padding-left: $side-padding;
        }

        @include is-mobile {
          padding-left: $side-padding-mobile;
        }
      }

      .swiper-container-horizontal .p-index__slider-pagination-container .swiper-pagination-bullets.p-index__slider-pagination {
        position: absolute;
        left: auto;
        right: 50vw;
        bottom: 0;
        text-align: left;
        line-height: 0;
        transform: translateX(100%) translateX(20px);

        @include is-tablet {
          position: static;
          margin-top: 70%;
          padding-top: 40px;
          padding-left: 0;
          transform: translateX(0);
        }

        @include is-mobile {
          padding-top: #{$side-padding-mobile / 2 + 10};
        }
      }

      .swiper-pagination-bullet {
        background: rgba($blue-dark, .3);

        &:not(:last-child) {
          margin-right: 12px;
        }
      }

      .swiper-pagination-bullet-active {
        background: rgba($blue-dark, 1);
      }

    }

    &-list {
      padding-bottom: 45px;

      @include is-tablet {
        padding-bottom: 0;
      }
    }

    &-item {

      &-txt {
        width: calc(100% - 50vw);
        padding-top: 40px;
        padding-right: 85px;
        text-align: left;
        opacity: 0;
        animation: slider-txt-reverse .1s ease forwards;

        @include is-tablet {
          width: 100%;
          order: 2;
          padding-top: 80px;
        }

        @include is-mobile {
          padding-top: 40px;
          padding-right: $side-padding-mobile;
        }

        .swiper-slide-active &, .swiper-slide-duplicate-active & {
          animation: slider-txt .8s ease 1s forwards;
        }
      }

      &-figure {
        height: 100%;
      }

      &-img {
        width: 50vw;
        padding-left: 20px;
        position: relative;
        transform: translateY(20px);
        opacity: 0;
        animation: slider-fade-reverse .5s ease forwards;

        @include is-tablet {
          width: 100%;
          order: 1;
        }

        @include is-mobile {
          padding-left: #{$side-padding-mobile / 2};
          transform: translateY(#{$side-padding-mobile / 2});
        }

        .swiper-slide-active &, .swiper-slide-duplicate-active & {
          z-index: 2;
          animation: slider-fade 1s ease forwards;
        }

        &::after {
          content: '';
          top: 20px;
          right: 20px;
          bottom: -20px;
          left: 0;
          position: absolute;
          background: rgba($blue-dark, .3);

          @include is-mobile {
            top: #{$side-padding-mobile / 2};
            right: #{$side-padding-mobile / 2};
            bottom: -#{$side-padding-mobile / 2};
          }
        }
      }

      &-figure {
        @extend %img-clip;
      }

      &-bg {
        z-index: 2;
      }

      &-desc {
        @include fz(15);
        line-height: 2;

        @include is-mobile {
          @include fz(13);
        }
      }

      &-link {
        margin-top: 30px;

        &-outer {
          display: flex;
    
          @include is-tablet {
            flex-wrap: wrap;
          }

          &:hover {

            @include is-laptop {
              .p-index__slider-item-link {
                background: $blue-dark;
                color: $white;
                transition: background-color .2s ease, color .2s ease;

                &::before {
                  border-color: transparent $white transparent transparent;
                }

              }
            }
          }
        }
      }

      &-btn {
        @include is-tablet {
          text-align: center;
        }
      }
    }

    &-pagination-container {

      @include is-tablet {
        position: absolute;
        z-index: 1;
        width: calc(100% - 20px);
        top: 0;
        left: 0;
      }

      @include is-mobile {
        width: calc(100% - #{$side-padding-mobile / 2});
      }
    }

  }
}

@keyframes scroll-large {
  0% {
    transform: rotate(-90deg) translateY(-102%);
  }
  80% {
    transform: rotate(-90deg) translateY(0);
  }
  100% {
    transform: rotate(-90deg) translateY(102%);
  }
}

@keyframes scroll-small {
  0% {
    transform: rotate(115deg) scaleY(0) translateY(0);
  }

  80% {
    transform: rotate(115deg) scaleY(1) translateY(0);
  }

  100% {
    transform: rotate(115deg) scaleY(1) translateY(-102%);
  }
}

@keyframes slider-fade {
  0% {
    opacity: 0;
    transform: translateY(20px) translateX(-20px);
  }

  1% {
    opacity: 0;
    transform: translateY(20px) translateX(-20px);
  }


  80% {
    opacity: 0;
    transform: translateY(20px) translateX(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0) translateX(0);
  }
}

@keyframes slider-fade-reverse {
  0% {
    opacity: 1;
    transform: translateY(0) translateX(0);
  }

  20% {
    opacity: 0;
  }


  50% {
    opacity: 0;
    transform: translateY(20px) translateX(-20px);
  }

  100% {
    opacity: 0;
    transform: translateY(20px) translateX(-20px);
  }
}

@keyframes slider-txt {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  
  100% {
    opacity: 1;
    transform: translateY(0);
  }  
}

@keyframes slider-txt-reverse {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  
  100% {
    opacity: 0;
    transform: translateY(10px);
  }  
}

.is-btn-show + .p-index__section-btn.c-btn.is-all-product.is-btn-second {
  @include is-tablet {
    padding-top: 20px;
  }
}