.l-detail {
  position: relative;
  z-index: 1;
  max-width: $outer-width;
  margin: 0 auto;
  padding: 0 40px 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  @include is-tablet {
    display: block;
    padding: 0;
    margin: -20px 0 0;
  }

  &__head {
    width: 50%;
    padding: 0 40px 40px 80px;
    position: sticky;
    top: 0;

    @include is-tablet {
      position: static;
      width: 100%;
      padding: 0 $side-padding 60px;
      order: 0;
    }

    @include is-mobile {
      padding: 0 $side-padding-mobile 60px;
    }
  }

  &__main {
    width: 50%;
    padding: 0 40px;

    @include is-tablet {
      width: 100%;
      padding: 0;
    }
  }
}
