@charset "UTF-8";

/*----------------------------------------
g-footer
----------------------------------------*/

.g-footer {
  display: flex;
  justify-content: space-between;
  color: $white;
  background: $blue-dark;

  &__container {
    display: flex;
    padding: 60px #{$side-padding * 2};
    width: 100%;

    @include is-hamburger {
      padding: 60px $side-padding;
    }
    
    @include is-mobile {
      padding: 40px $side-padding-mobile;
    }
  }

  &__left {
    padding-right: 80px;
    border-right: 1px solid $white;
    width: 320px;

    @include is-hamburger {
      padding-right: 60px;
    }

    @include is-tablet {
      width: 100%;
      padding-right: 0;
      border-right: none;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }

  &__right {
    padding: 0 100px;
    width: calc(100% - 320px);

    @include is-hamburger {
      padding: 0 0 0 60px;
    }

    @include is-tablet {
      display: none;
    }
  }

  .c-nav {
    &__pages {

      &-list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;

        &-item {
          width: 33.33333%;

          &-link {
            @include fz(15);
            padding: 10px;
            color: $white;
            display: block;
            text-align: left;
            transition: opacity .2s ease;

            @include is-laptop {
              &:hover {
                opacity: .5;
              }
            }
          }
        }
      }
    }
  }

  &__logo {
    text-align: left;
    margin: -20px 0 0 -20px;

    @include is-tablet {
      text-align: center;
      margin-left: 0;
    }

    &-link {
      color: $white;
      line-height: 1;
      padding: 20px;
      display: inline-block;
      transition: opacity .3s $ease;

      &:hover {
        opacity: .5;
      }
    }

    &-txt {
      @include fz(26);
      @include ls(4);
      font-family: $font-en;
    }

    &-img {
      max-height: 45px;

      @include is-mobile {
        max-height: 35px;
      }
    }
  }

  &__help {
    margin-top: 30px;

    &-list {
      margin: 0 -10px;

      &-item {
        text-align: left;

        &-link {
          text-align: left;
          color: $white;
          padding: 10px;
          display: inline-block;
          text-decoration: underline;
          transition: opacity .2s ease;

          @include is-laptop {
            &:hover {
              opacity: .8;
            }
          }

          @include is-mobile {
            @include fz(13);
          }
        }
      }
    }
  }

  &__sns {
    display: flex;

    @include is-tablet {
      justify-content: center;
    }

    &-list {
      display: flex;
      align-items: center;
      margin: 0 -10px;

      &-item {
        &-link {
          font-size: 0;
          color: $white;
          padding: 10px;
          display: block;
          position: relative;
          transition: opacity .2s ease;

          &:hover {
            opacity: .8;
          }

          &-icon {
            @include fz(20);
            letter-spacing: normal;
          }
        }
      }
    }
  }

  &__copyright {
    margin-top: 40px;
    text-align: left;

    @include is-mobile {
      margin-top: 30px;
    }

    &-txt {
      @include fz(12);
      font-family: $font-en;
      color: $white;
    }
  }
}
