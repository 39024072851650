.l-content {
  position: relative;
  z-index: 1;
  margin: 0 auto;
  padding-bottom: 100px;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;

  @include is-mobile {
    padding-bottom: 50px;
  }

  &.is-home {
    padding-top: 80px;
    padding-bottom: 0;

    @include is-mobile {
      padding-top: 30px;
    }
  }

  &.p-single-product-container {
    position: static;
    overflow: visible;

    @include is-tablet {
      overflow: hidden;
    }
  }

  &__main {
    width: 100%;

  }
}
