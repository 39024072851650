@charset "UTF-8";

/*----------------------------------------
c-btn
----------------------------------------*/
.c-btn {

  &.c-item__btn {
    margin-top: 50px;
  }

  .c-section.is-contact & {
    margin-top: 50px;

    @include is-mobile {
      margin-top: 30px; 
    }
  }

  &__body {
    @include fz(15);
    @include ls(1);
    text-indent: .1rem;
    padding: 18px 30px 15px;
    min-width: 240px;
    text-align: center;
    font-family: $font-en;
    position: relative;
    line-height: 1.5;
    display: inline-block;
    color: $blue-dark;
    border: 1px solid $blue-dark;
    background-color: transparent;
    cursor: pointer;
    transition: background-color .2s ease, color .2s ease;

    &:hover {
      @include is-laptop {
        background: $blue-dark;
        color: $white;

        &::before {
          border-color: transparent $white transparent transparent;
        }
      }
    }

    .c-section.is-contact & {
      font-weight: $bold;
    }

    @include is-mobile {
      @include fz(13);
      padding: 10px $side-padding-mobile 8px;
      min-width: 140px;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 7px;
      right: 7px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 0 0;
      border-color: transparent $blue-dark transparent transparent;
      transition: border-color .2s ease;

      @include is-mobile {
        bottom: 5px;
        right: 5px;

      }
    }
  }
}


.c-slider-nav {

  &-next {
    width: 60px;
    height: 60px;
    background: $gray-black;
    cursor: pointer;
    position: relative;
    border-left: 1px solid $gray-light;

    &:hover {
      @include is-laptop {
        &::before {
          transform: translateY(-50%) translateX(-75%) rotate(135deg) scale(1.2);
        }
      }
    }

    &::before {
      content: "";
      display: inline-block;
      width: 18px;
      height: 18px;
      border: solid $white;
      border-width: 1px 0 0 1px;
      transform: translateY(-50%) translateX(-75%) rotate(135deg) scale(1);
      transform-origin: center center;
      position: absolute;
      top: 50%;
      left: 50%;
      transition: transform .2s ease;

      @include is-mobile {
        width: 10px;
        height: 10px;

      }

    }
  }

  &-prev {
    width: 60px;
    height: 60px;
    background: $gray-black;
    cursor: pointer;
    position: relative;

    @include is-mobile {

    }

    &:hover {
      @include is-laptop {
        &::before {
          transform: translateY(-50%) translateX(-25%) rotate(315deg) scale(1.2);
        }
      }
    }

    &::before {
      content: "";
      display: inline-block;
      width: 18px;
      height: 18px;
      border: solid $white;
      border-width: 1px 0 0 1px;
      transform: translateY(-50%) translateX(-25%) rotate(315deg) scale(1);
      transform-origin: center center;
      position: absolute;
      top: 50%;
      left: 50%;
      transition: transform .2s ease;

      @include is-mobile {
        width: 10px;
        height: 10px;

      }
    }
  }
}
