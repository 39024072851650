@charset "UTF-8";

/*----------------------------------------
c-section
----------------------------------------*/
.c-section {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 80px;

    &.is-contact {
      margin-bottom: 0;
    }

    @include is-mobile {
      margin-bottom: 50px;
    }
  }

  &__container {
    padding: 0 #{$side-padding * 2};
    position: relative;

    @include is-tablet {
      padding: 0 $side-padding;
    }

    @include is-mobile {
      padding: 0 $side-padding-mobile;
    }

    &.is-small {
      padding: 0 #{$side-padding * 3 + 60};

      @include is-tablet-large {
        padding: 0 #{$side-padding * 2};
      }

      @include is-tablet {
        padding: 0 $side-padding;
      }
  
      @include is-mobile {
        padding: 0 $side-padding-mobile;
      }
    }

    .c-section.is-contact & {
      padding: 0 400px;

      @include is-hamburger {
        padding: 0 #{$side-padding * 2};
      }

      @include is-tablet {
        padding: 0 $side-padding;
      }
  
      @include is-mobile {
        padding: 0 $side-padding-mobile;
      }

    }

    .c-section.is-mypage & {
      padding: 0 #{$side-padding * 3 + 60};

      @include is-pc {
        padding: 0 #{$side-padding * 2};
      }

      @include is-tablet {
        padding: 0 $side-padding;
      }
  
      @include is-mobile {
        padding: 0 $side-padding-mobile;
      }
    }
  }

  &__header {
    margin-bottom: 40px;

    @include is-mobile {
      margin-bottom: 20px;
    }
  }

  &__list {
    position: relative;
    padding: 40px $side-padding 0;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 80px;

      @include is-mobile {
        margin-bottom: 60px;
      }

      &.is-pages {
        margin-bottom: 40px;
      }
    }

    &::after {
      content: '';
      position: absolute;
      background: $gray-pale2;
      top: 0;
      right: 0;
      bottom: 60px;
      left: 0;
      z-index: -1;

      @include is-tablet {
        bottom: 0;
      }
    }

    &.is-category {
      padding-top: 0;
      padding-bottom: 0;

      &:not(:last-child) {
        margin-bottom: 100px;

        @include is-mobile {
          margin-bottom: 60px;
        }
      }

      &::after {
        content: none;
      }

      &.is-product {
        &:not(:last-child) {
          margin-bottom: 80px;
  
          @include is-mobile {
            margin-bottom: 40px;
          }
        }

      }
    }
  }
}

.c-two-column {
  &__box {
    padding-left: 10px;

    @include is-tablet {
      padding-left: 5px;
      padding-right: 5px;
    }

    &:not(:last-child) {
      margin-bottom: 20px;

      @include is-tablet {
        margin-bottom: 30px;
      }
    }
  }

  &__outer-ttl {
    @include fz(16);
    @include ls(1);
    font-weight: $bold;
    margin-bottom: 15px;
    text-align: left;

    @include is-mobile {
      @include fz(15);
    }
  }

  &__container {
    display: flex;

    @include is-tablet {
      flex-wrap: wrap;
    }
  }

  &__txt {
    width: 80%;
    padding-right: 60px;

    @include is-tablet {
      width: 100%;
      margin-top: 20px;
      padding-right: 0;
      order: 2;
    }
  }

  &__desc {
    @include fz(15);
    @include ls(1);
    text-align: left;

    @include is-mobile {
      @include fz(14);
    }
  }

  &__figure {
    @extend %img-clip;
    width: 20%;

    @include is-tablet {
      width: 100%;
      order: 1;

    }
  }

}
