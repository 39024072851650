@charset "UTF-8";

/*----------------------------------------
g-pagetop
----------------------------------------*/

.g-pagetop {
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 10;
  pointer-events: none;
  transition: opacity .2s ease, transform .2s ease;
  opacity: 0;
  transform: translateY(2px);

  &:hover {
    @include is-laptop {
      animation: buttonPop .4s ease forwards;
    }
  }

  @include is-mobile {
    right: $side-padding-mobile;
  }

  &.is-active {
    pointer-events: initial;
    opacity: 1;
    transform: translateY(0);
  }

  &-btn {
    width: 80px;
    height: 80px;
    background: $gray-light;
    outline: none;
    box-shadow: none;
    border: 0;
    cursor: pointer;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color .5s ease;

    @include is-mobile {
      width: 36px;
      height: 36px;
    }

    .g-pagetop:hover & {

      @include is-laptop {
        background: $blue-dark;
      }
    }

    &::before {
      content: "";
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 12px 12px 12px;
      border-color: transparent transparent $white transparent;

      @include is-mobile {
        border-width: 0 6px 6px 6px;
      }
    }
  }
}


@keyframes buttonPop {
  0% {
    transform: scale(.8);
  }

  30% {
    transform: scale(1.1);
  }

  60% {
    transform: scale(.9);
  }

  100% {
    transform: scale(1);
  }

}