@charset "UTF-8";

/*----------------------------------------
option
----------------------------------------*/

.p-option {
  padding-bottom: 100px;

  @include is-mobile {
    padding-bottom: 50px;
  }
}
