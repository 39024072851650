@charset "UTF-8";

/*----------------------------------------
c-cart-btn
----------------------------------------*/
.c-cart-btn {
  $this: &;

  &.is-small {
    #{$this}__body {
      padding: 10px 0;

      &-icon {
        @include fz(18);

        @include is-mobile {
          @include fz(14);
          margin: 0 10px 0 0;
        }
      }

      &-txt {
        @include fz(14);

        @include is-mobile {
          @include ls(1);
        }

        @include is-mobile-small {
          @include fz(12);
        }
      }
    }
  }

  &__body {
    @extend %btn-hover-animation;
    width: 100%;
    border: 0;
    outline: none;
    color: $white;
    background: $red;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    position: relative;
    cursor: pointer;

    &::before {
      background: $red-black;
    }

    &.is-sold-out {
      background: $primary-white;
      pointer-events: none;

      &:hover {
        background: $primary-white;
      }
    }

    &-icon {
      @include fz(28);
      margin: 0 20px 0 0;
      position: relative;
      z-index: 1;
    }

    &-txt {
      @include fz(20);
      @include ls(2);
      font-family: $font;
      font-weight: $bold;
      position: relative;
      z-index: 1;
    }
  }
}
