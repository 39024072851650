@charset "UTF-8";

/*----------------------------------------
c-grid
----------------------------------------*/
.c-grid {
  $this: &;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  position: relative;
  z-index: 1;

  @include is-mobile {
    margin: 0 -6px;
  }

  &.is-no-padding {
    margin: 0;

    #{$this}__item {
      padding: 0;
    }
  }

  &.is-blog {
    margin: 0;
    flex-wrap: nowrap;

    @include is-hamburger {
      flex-wrap: wrap;
      justify-content: center;
    }

    #{$this}__item {
      width: 33.3333%;

      @include is-hamburger {
        width: 50%;
      }

      @include is-tablet {
        width: 100%;
      }
    }
  }

  &.is-blog-page {
    margin: -20px -10px;

    @include is-mobile {
      margin: -10px;
    }

    #{$this}__item {
      width: 50%;
      padding: 20px 10px;

      @include is-tablet {
        width: 100%;

      }

      @include is-mobile {
        padding: 10px;
      }

    }
  }

  &.is-campaign-page {
    margin: -20px -10px;

    @include is-mobile {
      margin: -10px;
    }

    #{$this}__item {
      width: 33.3333%;
      padding: 20px 10px;

      @include is-hamburger {
        width: 50%;
      }

      @include is-tablet {
        width: 100%;

      }

      @include is-mobile {
        padding: 10px;
      }

    }
  }

  &.is-news {
    display: block;
    border-top: 1px solid $blue-dark;
    margin: 0 0 50px;

    @include is-mobile {
      margin-bottom: 25px;
    }
  }

  &__item {
    padding: 15px;
    position: relative;

    @include is-mobile {
      padding: 10px 6px;
    }

    .is-news & {
      padding: 0;
      border-bottom: 1px solid $blue-dark;
    }

    &.is-ranking {
      @include is-tablet {
        width: 100%;
      }
    }
  }

  &[data-grid="5"] {
    #{$this}__item {
      width: 20%;

      @include is-tablet-small {
        width: 50%;
      }
    }
  }

  &[data-grid="4-5"] {
    #{$this}__item {
      width: 25%;

      @include is-tablet-small {
        width: 50%;
      }

      &:nth-child(9n-4),
      &:nth-child(9n-3),
      &:nth-child(9n-2),
      &:nth-child(9n-1),
      &:nth-child(9n) {
        width: 20%;

        @include is-tablet-small {
          width: 50%;
        }
      }
    }
  }

  &[data-grid="4"] {
    #{$this}__item {
      width: 25%;

      @include is-hamburger {
        width: 50%;
      }

    }

    &.is-ranking {
      #{$this}__item {
        @include is-hamburger {
          width: 100%;
        }
      }
    }
  }

  &[data-grid="3-4"] {
    #{$this}__item {
      width: 33.33333%;

      @include is-hamburger {
        width: 50%;
      }

      @include is-mobile {
        width: 100%;
      }

      &:nth-child(7n-3),
      &:nth-child(7n-2),
      &:nth-child(7n-1),
      &:nth-child(7n) {
        width: 25%;

        @include is-hamburger {
          width: 50%;
        }

        @include is-mobile {
          width: 50%;
        }
      }

      .c-item {

        &__figure {
          &-img {
            &::before {
              padding: 70% 0 0;
            }
          }
        }
      }
    }
  }

  &[data-grid="3"] {

    #{$this}__item {
      width: 33.33333%;

      @include is-hamburger {
        width: 50%;
      }

      @include is-tablet {
        width: 100%;
      }
    }

    &.is-blog {
      #{$this}__item {
        &:nth-child(-n+2) {
          width: 50%;

          @include is-hamburger {
            width: 100%;
          }
        }
      }

      &.is-under-page {
        #{$this}__item {
          &:nth-child(-n+2) {
            width: 33.33333%;

            @include is-tablet {
              width: 50%;
            }
          }
        }
      }
    }
  }

  &[data-grid="2"] {
    #{$this}__item {
      width: 50%;

      @include is-mobile {
        width: 100%;
      }

      .c-item {
        @include is-mobile {
          padding: 12px 0;
        }

        &__favorite {
          top: 25px;
          right: 35px;
        }

        &__figure {
          &-img {
            &::before {
              padding: 60% 0 0;
            }
          }
        }
      }
    }
  }
}
