@charset "UTF-8";

/*----------------------------------------
c-o-list-child
----------------------------------------*/
.c-o-list-child {
  text-align: justify;
  position: relative;

  &:not(:first-child) {
    margin-top: 30px;

    @include is-mobile {
      margin-top: 10px;
    }
  }

  &__item {
    counter-increment: c-o-list-child-number;
    margin: 0 0 10px;
    padding: 0 0 0 25px;
    position: relative;
    z-index: 1;

    &::before {
      @include fz(14);
      content: "(" counter(c-o-list-child-number) ")";
      text-align: center;
      line-height: 1.8;
      display: block;
      position: absolute;
      top: 0;
      left: 0;

      @include is-mobile {
        @include fz(12);
      }
    }

    &-txt {
      @include fz(14);
      line-height: 1.8;

      @include is-mobile {
        @include fz(12);
      }
    }
  }
}
