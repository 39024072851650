@charset "UTF-8";

/*----------------------------------------
g-cart
----------------------------------------*/

body {
  &[data-cart="active"] {
    .g-cart {
      pointer-events: auto;
      background: rgba($black, .7);

      &__container {
        overflow-y: auto;
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}

.g-cart {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  pointer-events: none;
  transition: background $ease .3s;

  &__container {
    height: 100%;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    position: relative;
    z-index: 1;
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity $ease .3s, transform $ease .3s;
    padding: 80px 0;

    @include is-mobile {
      padding: 0;
    }
  }

  &__burger {
    width: 80px;
    height: 80px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    background: none;
    border: 0;
    cursor: pointer;

    @include is-mobile {
      width: 60px;
      height: 60px;
    }

    &-line {
      background: $gray;
      width: 30px;
      height: 1px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-15px);
      border-radius: 10px;
      transform-origin: center;

      &:first-child {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:last-child {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  &__enabled {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
  }

  &__inner {
    background: $white;
    max-width: $content-width;
    margin: 0 auto;
    padding: 70px 80px;
    position: relative;
    z-index: 1;

    @include is-mobile {
      min-height: 100vh;
      padding: 60px 20px 20px;
    }
  }

  &__header {
    &-ttl {
      @include fz(24);
      @include ls(2);
      font-weight: $bold;
      color: $red;
      padding: 0 0 20px;
      margin: 0 0 30px;
      border-bottom: 1px solid $color-border;
      text-align: left;

      @include is-tablet {
        @include fz(16);
      }
    }
  }

  &__body {
    padding: 0 0 30px;
    margin: 0 0 30px;
    border-bottom: 1px solid $color-border;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include is-tablet {
      display: block;
    }

    &-detail {
      display: flex;
      align-items: center;

      &-img {
        width: 160px;
        background: no-repeat center;
        background-size: cover;
        position: relative;

        @include is-tablet {
          width: 100px;
        }

        &::before {
          content: "";
          display: block;
          padding: 120% 0 0;
        }

        &-src {
          opacity: 0;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
      }

      &-data {
        text-align: justify;
        padding: 0 0 0 30px;

        @include is-tablet {
          padding: 0 0 0 15px;
        }

        &-name {
          @include ls(1);
          font-weight: $bold;
          margin: 0 0 5px;
          padding: 0 5px;

          @include is-tablet {
            @include fz(13);
          }
        }

        &-list {
          display: table;
          width: 100%;

          &-row {
            display: table-row;
          }

          &-ttl,
          &-desc {
            display: table-cell;
            padding: 5px;

            @include is-tablet {
              @include fz(13);
            }
          }
        }
      }
    }

    &-price {
      @include is-tablet {
        padding: 0 0 0 120px;
        text-align: left;
      }

      #js-modal_purchased_price {
        @include fz(20);
      }

      &-txt {
        @include fz(24);
        @include ls(2);
        font-weight: $bold;

        @include is-tablet {
          @include fz(15);
        }
      }

      &-tax {
        @include ls(2);
        font-weight: $bold;

        @include is-tablet {
          @include fz(11);
        }
      }
    }
  }
}
