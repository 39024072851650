@charset "UTF-8";

/*----------------------------------------
c-form
----------------------------------------*/

.c-form {
  width: 100%;
  display: block;

  &__table,
  &__tbody,
  &__row,
  &__ttl,
  &__cnt {
    @include is-mobile {
      display: block;
    }
  }

  &__read {
    padding: 60px $side-padding 0;
    text-align: center;
    position: relative;

    @include is-hamburger {
      padding: 60px $side-padding-break1 0;
    }

    @include is-tablet-large {
      padding: 60px $side-padding-break2 0;
    }

    @include is-tablet {
      padding: 60px $side-padding-break2 0;
    }

    @include is-mobile {
      padding: 20px $side-padding-mobile 0;

    }

    &::after {
      content: '';
      background: $gray-pale2;
      top: 0;
      right: 0;
      bottom: -170px;
      left: 0;
      position: absolute;
    }

    .c-guid-desc {
      @include fz(16);
      text-align: center;
      position: relative;
      z-index: 1;

      @include is-tablet {
        text-align: left;
      }

      @include is-mobile {
        @include fz(12);
      }
    }
  }

  &__table {
    width: 100%;
  }

  &__ttl,
  &__cnt {
    display: block;
    vertical-align: top;
    padding: 7.5px 0;
    text-align: left;

    @include is-mobile {
      padding: 5px 0;
    }
  }

  &__ttl {
    white-space: nowrap;

    .c-form__row:not(:first-child) & {
      padding-top: 22.5px;

      @include is-mobile {
        padding-top: 20px;
      }
    }

  }

  &__cnt {
    .error {
      margin: 10px 0 0;
    }

    .horizontal-item {
      padding: 5px 0;
      display: inline-block;
    }
  }

  &__label {
    display: flex;
    justify-content: left;
    align-items: center;

    &-txt,
    &-val {
      display: block;
    }

    &-txt {
      @include ls(1);
    }

    &-val {
      @include fz(12);
      @include ls(1);
      padding: 8px 10px;
      margin: 0 15px 0 0;
      background: $red;
      color: $white;
      line-height: 1;
      text-indent: .1rem;

      @include is-mobile {
        @include fz(11);
        padding: 4px 5px 3px;
        margin: 0 10px 0 0;
      }

      &.is-any {
        background: $gray-light04;
      }
    }
  }

  input:not([type="radio"]):not([type="checkbox"]),
  textarea,
  select {
    @include fz(14);
    @include ls(.5);
    font-family: $font;
    background: $white;
    width: 100%;
    appearance: none;
    padding: 15px 20px;
    border: 1px solid transparent;
    transition: border-color .5s $ease;
    border-color: $blue-pale;
    background: $blue-pale;

    @include is-mobile {
      padding: 10px;
    }

    &::placeholder {
      color: $gray-dark;

      @include is-mobile {
        @include fz(12);
      }
    }

    &:hover,
    &:focus {
      border-color: $primary;
    }
  }

  textarea {
    width: 100%;
    min-height: 80px;
    resize: vertical;
  }

  input {
    &[type="radio"],
    &[type="checkbox"] {
      display: none;

      + span {
        position: relative;
        cursor: pointer;
        padding: 0 0 0 35px;
        margin: 0 30px 0 0;
        display: inline-block;
        line-height: 24px;
        text-align: left;

        @include is-mobile {
          line-height: 1.7;
        }

        &::before {
          content: "";
          display: inline-block;
          vertical-align: sub;
          border: 1px solid $gray;
          background: $white;
          width: 20px;
          height: 20px;
          position: absolute;
          top: 0;
          left: 0;
        }

        &::after {
          content: "";
          display: inline-block;
          width: 10px;
          height: 10px;
          position: absolute;
          top: 4px;
          left: 4px;
          transition: opacity .3s $ease;
          opacity: 0;
        }
      }

      &:checked + span {
        &::after {
          opacity: 1;
        }
      }
    }

    &[type="radio"] {
      + span {
        &::before {
          border-radius: 50%;
        }

        &::after {
          border-radius: 50%;
          background: $black;
        }
      }
    }

    &[type="checkbox"] {
      + span {
        &::before {
          border-width: 2px;
          border-radius: 1px;
        }

        &::after {
          width: 5px;
          left: 8px;
          top: 4px;
          border: solid $black;
          border-width: 0 3px 3px 0;
          transform: rotate(45deg);
        }
      }
    }
  }

  &__consent {
    @include ls(1);
    text-indent: .1rem;
    text-align: center;
    padding: 42.5px 0 0;

    &:not(:first-child) {
      padding-top: 25px;
    }

    a {
      text-decoration: underline;
      color: $blue-dull;

      &:hover {
        color: $red;
      }
    }

    &-check {
      margin-top: 30px;



      input[type="checkbox"] + span {
        margin-right: 0;
      }
    }

    .error {
      margin: 10px 0 0;
    }

    input {
      &[type="checkbox"],
      &[type="radio"] {
        + span {
          margin: 0;
        }
      }
    }

    &-link {
      color: $red;
    }
  }

}

.c-form-btn {
  margin-top: 80px;
  display: flex;
  justify-content: center;
  padding: 0 150px;

  @include is-tablet {
    padding: 0 100px;
  }

  @include is-mobile {
    margin-top: 40px;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 0 16px;
  }

  &__body {
    padding: 30px 80px;
    background: $black;
    border: none;
    min-width: 260px;
    cursor: pointer;
    transition: background-color .2s ease;

    &:hover {

      @include is-laptop {
        background: $primary;
      }
    }

    @include is-mobile {
      padding: 20px 40px;
    }

    &:not(:first-child) {
      margin-left: 20px;

      @include is-mobile {
        margin-top: 20px;
        margin-left: 0;
      }
    }

    &-txt {
      @include fz(18);
      @include ls(2);
      text-indent: .2rem;
      color: $white;
      line-height: 1;
      font-family: $font;

      @include is-mobile {
        @include fz(13);
        @include ls(1);
      }
    }
  }
}

// scss-lint:disable SelectorFormat
.mw_wp_form_confirm {
  .is-confirm-none {
    display: none;
  }

  .c-form-ttl {
    padding: 20px 0;
  }

  .c-form-cnt {
    background: $white-gray2;
    padding: 20px 0 20px 40px;

    @include is-mobile {
      padding: 20px;
    }
  }

  .c-form-label {
    &-val {
      display: none;
    }
  }

  .c-form-lead {
    display: none;

    &__confirm {
      display: block;
    }
  }
}

// scss-lint:disable SelectorFormat
.mw_wp_form_input .is-input-none {
  display: none;
}
