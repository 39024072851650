@charset "UTF-8";

/*----------------------------------------
c-u-list
----------------------------------------*/
.c-u-list {
  text-align: justify;
  padding: 0 5px;

  &__item {
    line-height: 1.8;
    padding: 0 0 0 15px;
    margin: 0 0 35px;
    position: relative;

    @include is-mobile {
      margin: 0 0 25px;
      padding: 0 0 0 15px;
    }

    &::before {
      content: "";
      display: block;
      width: 3px;
      height: 3px;
      border-radius: 50px;
      background: $primary;
      position: absolute;
      top: 8px;
      left: 0;
    }
  }
}
