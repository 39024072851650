@charset "UTF-8";

/*----------------------------------------
g-search
----------------------------------------*/

body {
  &[data-search="active"] {
    .g-search {
      pointer-events: auto;
      background: rgba($black, .7);

      &__container {
        overflow-y: auto;
        opacity: 1;
        transform: translateY(0);
      }

      &__form-submit-btn {
        display: inline-block;
      }
    }
  }
}

.g-search {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  pointer-events: none;
  transition: background $ease .3s;

  &__container {
    height: 100%;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    position: relative;
    z-index: 1;
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity $ease .3s, transform $ease .3s;
    padding: 80px 0;

    @include is-mobile {
      padding: 0;
    }
  }

  &__burger {
    width: 80px;
    height: 80px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    background: none;
    border: 0;
    cursor: pointer;

    @include is-mobile {
      width: 60px;
      height: 60px;
    }

    &-line {
      background: $gray02;
      width: 30px;
      height: 1px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-15px);
      border-radius: 10px;
      transform-origin: center;

      &:first-child {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:last-child {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  &__enabled {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
  }

  &__inner {
    background: $white;
    max-width: $content-width;
    margin: 0 auto;
    padding: 70px 80px;
    position: relative;
    z-index: 1;

    @include is-mobile {
      padding: 40px 20px;
    }
  }

  &__form {
    $this: &;

    &-txt {
      position: relative;
      padding: 10px 0;
      margin: 0 0 20px;

      @include is-mobile {
        margin: 0;
      }

      &-icon {
        color: $gray02;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
      }
    }


    #{$this}-txt-icon + input {
      &[type="text"] {
        padding: 15px 60px 15px 20px;

        @include is-mobile {
          padding: 10px 45px 10px 15px;
        }
      }
    }

    input {
      &[type="text"] {
        @include fz(16);
        font-family: $font;
        width: 100%;
        border: 1px solid $gray02;
        padding: 15px 20px;

        @include is-mobile {
          padding: 10px 15px;
        }

        &:not(:placeholder-shown) {
          border-color: $blue;
        }
      }
    }

    select {
      @include fz(16);
      font-family: $font;
      width: 100%;
      border: 1px solid $gray02;
      background: $white;
      padding: 15px 40px 15px 20px;

      @include is-mobile {
        padding: 10px 40px 10px 15px;
      }

      &:disabled {
        background: $white-gray;
        opacity: .3;
      }
    }

    &-select {
      position: relative;

      &::after {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        border: solid $gray02;
        border-width: 0 1px 1px 0;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-75%) rotate(45deg);
        pointer-events: none;
      }
    }

    &-checkbox,
    &-radio {
      display: block;

      input {
        display: none;

        + span {
          @include fz(16);
          position: relative;
          display: inline-flex;
          align-items: center;
          text-align: left;
          margin: 0 40px 10px 0;
          cursor: pointer;

          &::before {
            content: "";
            display: inline-block;
            border: 1px solid $gray02;
            background: $white;
            width: 30px;
            height: 30px;
            vertical-align: middle;
            margin: 0 10px 0 0;
            transition: background .3s $ease, border .3s $ease;
          }

          &::after {
            content: "";
            display: inline-block;
            width: 7px;
            height: 14px;
            position: absolute;
            top: 6px;
            left: 11px;
            transition: opacity .3s $ease;
            opacity: 0;
            border: solid $white;
            border-width: 0 3px 3px 0;
            transform: rotate(45deg);
          }
        }

        &:checked + span {
          &::before {
            border-color: $red;
            background: $red;
          }

          &::after {
            opacity: 1;
          }
        }

        &[type="radio"] {
          + span {
            &::before {
              border-radius: 50%;
            }
          }
        }

        &[type="checkbox"] {
          + span {
            &::before {
              border-radius: 1px;
            }
          }
        }
      }
    }

    &-submit {
      text-align: center;
      padding: 40px 0;

      @include is-mobile {
        padding: 20px 0;
      }

      &-btn {
        width: 320px;
        max-width: 90%;
        padding: 20px 0;
        color: $white;
        background: $black;
        border: 0;
        box-shadow: none;
        position: relative;
        display: none;
        cursor: pointer;
        transition: background-color .2s ease;

        @include is-laptop {
          &:hover {
            background: $gray-black2;
          }
        }

        @include is-mobile {
          width: 248px;
          padding: 12px 0;
        }

        &:disabled {
          opacity: .5;
          cursor: auto;

          &::before {
            display: none;
          }
        }

        &-txt {
          @include fz(16);
          @include ls(1.2);
          text-indent: .12rem;
          font-family: $font;
          padding: 0 20px;
          position: relative;
          z-index: 1;

          @include is-mobile {
            @include fz(14);
          }
        }
      }

      &-svg {
        position: absolute;
        height: 25px;
        width: 30px;
        fill: $white;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);

        @include is-mobile {
          width: 20px;
          height: 15px;
        }
  
        &-path {
          fill-rule: evenodd;
        }
  
        &-circle {
          fill: none;
          stroke: $white;
          stroke-linejoin: round;
          stroke-width: 4px;
        }
      }
    }

    &-ttl {
      margin: 30px 0 10px;
      position: relative;
      display: none;

      @include is-mobile {
        display: block;
      }

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        right: -20px;
        left: -20px;
        background: $color-border;
        height: 1px;
      }

      &-txt {
        @include fz(16);
        font-weight: $bold;
        position: relative;
        z-index: 1;
        background: $white;
        padding: 0 20px;
      }
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      padding: 10px 0 0;

      @include is-mobile {
        padding: 0;
      }
    }

    &-hyphen {
      padding: 0 10px;
    }

    &-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 10px 0;

      @include is-mobile {
        padding: 0;
      }
    }

    &-cell {
      width: 48%;
      padding: 20px 0;
      text-align: left;

      @include is-mobile {
        width: 100%;
        padding: 10px 0;
      }

      &-label {
        @include fz(18);
        font-weight: $bold;
        display: block;
        margin: 0 0 10px;

        @include is-mobile {
          @include fz(14);
          margin: 0 0 5px;
        }
      }
    }

    &-price {
      display: flex;
      align-items: center;
      justify-content: space-between;

      input[type="text"] {
        width: calc(50% - 17px);
      }
    }

    &-svg {
      position: absolute;
      height: 25px;
      width: 30px;
      fill: $gray02;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);

      @include is-mobile {
        width: 20px;
        height: 15px;
        right: 10px;
      }

      &-path {
        fill-rule: evenodd;
      }

      &-circle {
        fill: none;
        stroke: $gray02;
        stroke-linejoin: round;
        stroke-width: 4px;
      }
    }
  }
}
