
// Media

// breakpoint
$breakpoint-max: 1024px;
$breakpoint-tablet: 1023px;
$breakpoint-min: 767px;
$breakpoint-extra-min: 374px;
$hamburger: 1330px;
$break-point-tablet-large: 1199px;
$break-point-pc: 1600px;

// PC
@mixin is-laptop() {
  @media screen and (min-width: $break-point-tablet-large) {
    @content;
  }
}

@mixin is-pc() {
  @media screen and (max-width: $break-point-pc) {
    @content;
  }
}

// hamburger Menu
@mixin is-hamburger() {
  @media screen and (max-width: $hamburger) {
    @content;
  }
}

// Tablet Large
@mixin is-tablet-large() {
  @media screen and (max-width: $break-point-tablet-large) {
    @content;
  }
}

// Tablet Large
@mixin is-tablet() {
  @media screen and (max-width: $breakpoint-max) {
    @content;
  }
}

// Tablet
@mixin is-tablet-small() {
  @media screen and (max-width: $breakpoint-tablet) {
    @content;
  }
}

// Mobile
@mixin is-mobile() {
  @media screen and (max-width: $breakpoint-min) {
    @content;
  }
}

// Mobile Small
@mixin is-mobile-small() {
  @media screen and (max-width: $breakpoint-extra-min) {
    @content;
  }
}

// custom
@mixin mediaquery-max($breakpoint) {
  @media screen and (max-width: $breakpoint) {
    @content;
  }
}