@charset "UTF-8";

/*----------------------------------------
single
----------------------------------------*/

.p-single {
  text-align: justify;
  background: $white;
  position: relative;
  z-index: 1;  
  padding: 0 #{$side-padding + 60};

  @include is-tablet {
    padding: 0;
  }

  &.is-two-column {
    padding: 0;
  }

  &__container {
    margin-bottom: 100px;

    @include is-mobile {
      margin-bottom: 50px;
    }
  }

  &__meta {
    display: flex;
    align-items: center;
    margin: 0 0 10px;

    @include is-tablet {
      display: block;
    }

    @include is-mobile {
      margin: 0 0 5px;
    }

    &-item {
      margin-right: 15px;

      @include is-mobile {
        margin-right: 10px;
      }

      &-child {
        &-link {
          @include fz(12);
          padding: 8px 0;
          position: relative;
          color: $blue-dark;
          display: flex;
          align-items: center;
          transition: opacity .2s ease;

          @include is-mobile {
            @include fz(11);
            padding: 3px 0;
          }

          &:hover {
            @include is-laptop {
              opacity: .7;
            }
          }

          &.is-present {
            color: $cat01;

            &::before {
              border-color: transparent transparent $cat01 transparent;

            }
          }
    
          &.is-product {
            color: $cat02;

            &::before {
              border-color: transparent transparent $cat02 transparent;

            }
          }

          &::before {
            content: "";
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 4px 4px 4px;
            border-color: transparent transparent $blue-dark transparent;
            transform: rotate(90deg);
            margin-right: 3px;

            @include is-mobile {
              border-width: 0 3px 3px 3px;  
            }
          }
        }
      }
    }

    &-cat {

      &-container {
        margin-bottom: 30px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        @include is-mobile {
          margin-bottom: 20px;
        }
      }
    }

    &-link {
      @include fz(14);
      @include ls(2);
      text-indent: .2rem;
      padding: 10px 18px;
      display: inline-block;
      color: $white;
      background: $blue-dark;
      line-height: 1;
      transition: opacity .2s ease;

      &:hover {
        @include is-laptop {
          opacity: .7;
        }
      }
  
      @include is-mobile {
        @include fz(12);
        padding: 5px 8px;
      }

      &.is-present {
        background: $cat01;
      }

      &.is-product {
        background: $cat02;
      }
    }

    &-date {
      @include fz(18);
      color: $gray-light02;
      font-weight: $semibold;
      display: inline-block;
      vertical-align: middle;
      font-family: $font-en;

      @include is-mobile {
        @include fz(13);
      }
    }
  }

  &-ttl {
    margin-bottom: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid $blue-dark;

    @include is-mobile {
      margin-bottom: 5px;
      padding-bottom: 10px;
    }

    &.is-news {
      margin-bottom: 40px;

      @include is-mobile {
        margin-bottom: 20px;
      }
    }

    &__txt {
      @include fz(24);
      font-weight: $bold;

      @include is-mobile {
        @include fz(15);

      }
    }
  }

  &__content {
    @include fz(14);
    @include ls(1);
    line-height: 2;

    &.is-not-cat {
      margin-top: 30px;

      @include is-mobile {
        margin-top: 20px;
      }
    }

    &-eyecatch {
      text-align: center;

      .c-img {
        vertical-align: middle;
      }
    }

    &-inner {
      position: relative;
      z-index: 1;

      > [class|='wp'] {
        margin: 24px 0 0;
      }

      > ol:not([class]) {
        counter-reset: order 0;
        margin: 24px 0 0;
        padding: 0 0 0 1em;
  
        @include is-mobile {
          margin: 12px 0 0;
        }
  
        > li:not([class]) {
          display: flex;
          line-height: 1.5;
  
          &::before {
            @include fz(14);
            font-family: $font-en;
            align-self: center;
            color: $blue-dark;
            content: counter(order, decimal-leading-zero) "";
            counter-increment: order 1;
            display: inline-block;
            margin: 0 .5em 0 0;
          }
  
          + li:not([class]) {
            margin: .5em 0 0;
          }
        }
      }
  
      > ul:not(.wp-block-gallery) {
        margin: 24px 0 0;
        padding: 0 0 0 1em;
  
        @include is-mobile {
          margin: 12px 0 0;
        }
  
        > li:not([class]) {
          display: flex;
          line-height: 1.5;
  
          &::before {
            align-self: center;
            background: $blue-dark;
            border-radius: 50%;
            content: "";
            display: inline-block;
            width: 4px;
            height: 4px;
            margin: 0 .5em 0 0;
          }
  
          + li:not([class]) {
            margin: .5em 0 0;
          }
        }
      }

      section {
        margin-top: 60px;
      }
    }

    p:not([class]) {
      @include fz(13);
      line-height: 1.8;
      margin: 24px 0 0;

      + p:not([class]) {
        margin: 12px 0 0;
      }
    }

    .wp-block-quote {
      p:not([class]):first-child {
        margin: 0;
      }
    }

    h2:not([class]) {
      @include fz(18);
      line-height: 1.5;
      padding: 20px 0;
      border-bottom: 1px solid $blue-dark;

      &:not(:first-child) {
        margin-top: 60px;

        @include is-mobile {
          margin-top: 30px;
        }

      }

      @include is-mobile {
        @include fz(16);
        padding: 10px 0;
      }
    }

    h3:not([class]) {
      @include fz(16);
      line-height: 1.5;
      font-weight: $bold;

      &:not(:first-child) {
        margin-top: 60px;

        @include is-mobile {
          margin-top: 30px;
        }

      }

      @include is-mobile {
        @include fz(15);
      }

    }

    h4:not([class]) {
      font-weight: $bold;
      line-height: 1.5;

      &:not(:first-child) {
        margin-top: 24px;

        @include is-mobile {
          margin-top: 12px;
        }

      }

      @include is-mobile {
        @include fz(14);
      }
    }

    figure:not([class]) {
      margin-top: 24px;

      @include is-mobile {
        margin-top: 12px;
      }
    }

    b,
    strong {
      &:not([class]) {
        background: linear-gradient(transparent 55%, rgba($primary, .2) 45%);
      }
    }

    em {
      &:not([class]) {
        font-style: italic;
      }
    }

    .wp-block {
      &-image {
        font-size: 0;
        position: relative;
        text-align: center;

        figcaption {
          @include fz(14);
          color: $white;
          background: rgba($black, .3);
          line-height: 1.5;
          margin: 0;
          padding: 1em 1.5em;
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          z-index: 1;
        }

        figure {
          display: block;
        }
      }

      &-quote {
        background: $white-gray;
        border-color: $gray;
        padding: 2em;
      }

      &-audio {
        audio {
          @include is-mobile {
            min-width: 100%;
          }
        }

        figcaption {
          margin: .5em 0 0;
        }
      }

      // scss-lint:disable SelectorFormat
      &-file .wp-block-file__button {
        @include fz(14);
        @include ls(2.5);
        font-family: $font-en;
        font-weight: $bold;
        // scss-lint:disable ImportantRule
        color: $blue-dark !important;
        background: $white;
        padding: 10px 25px;
        border: 1px solid $blue-dark;
        display: inline-block;
        position: relative;
        overflow: hidden;
        text-align: center;
        vertical-align: middle;
        opacity: 1 !important;
        border-radius: 0;
        margin: 0 0 0 20px;

        @include is-mobile {
          width: 100%;
          margin: 10px 0 0;
        }

        &:hover {
          // scss-lint:disable ImportantRule
          color: $white !important;
          background: $blue-dark !important;
        }
      }

      &-code {
        background: $blue-dark;
        border: 0;
        border-radius: 0;
        color: $white-gray;
        padding: 2em;
      }

      &-pullquote {
        border-color: $blue-dark;
        color: $blue-dark;
        
        p:not([class]) {
          @include fz(24);

          &:first-child {
            margin-top: 0;
          }

          @include is-mobile {
            @include fz(18);
          }
        }

        cite:not([class]) {
          color: $primary;
        }
      }

      &-button__link {
        @include fz(14);
        @include ls(2.5);
        font-family: $font-en;
        font-weight: $semibold;
        color: $blue-dark;
        background: $white;
        padding: 10px 45px;
        border: 1px solid $blue-dark;
        display: inline-block;
        position: relative;
        overflow: hidden;
        text-align: center;
        vertical-align: middle;
        border-radius: 0;

        @include is-mobile {
          width: 100%;
          margin: 10px 0 0;
        }

        &:hover {
          color: $white;
          background: $blue-dark;
        }
      }

      &-separator {
        border-color: $blue-dark;
        margin-right: auto;
        margin-left: auto;

        &::before {
          color: $blue-dark;
        }
      }

      &-calendar {
        caption {
          color: $blue-dark;
        }

        th:not([class]) {
          color: $white;
          background: $blue-dark;
          border-color: $blue-dark;
        }

        td:not([class]) {
          border-color: $blue-dark;
        }

        a {
          color: $blue-dark;
          display: block;
          padding: 5px 0;
        }

        tbody td, th {
          border-color: $blue-dark;

        }
      }

      &-embed__wrapper {
        text-align: center;
      }

      &-embed-youtube .wp-block-embed__wrapper {
        overflow: hidden;
        position: relative;

        &::after {
          content: "";
          display: block;
          padding-top: 56.25%;
        }

        iframe {
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }
      }
    }

    .a2a_svg svg, .a2a_svg path, .a2a_svg g {
      fill: $gray-black;
    }

    // scss-lint:disable SelectorFormat
    .wp-embed-aspect-4-3 .wp-block-embed-youtube .wp-block-embed__wrapper::after {
      padding-top: 75%;
    }

    // scss-lint:disable SelectorFormat
    .wp-embed-aspect-16-9 .wp-block-embed-youtube .wp-block-embed__wrapper::after {
      padding-top: 56.25%;
    }

    .wp-block-embed-twitter {
      text-align: center;

      .wp-block-embed__wrapper {
        display: inline-block;
        border: 1px solid $color-border;
      }
    }

    .addtoany_share_save_container,
    .addtoany_shortcode {
      text-align: center;
      margin-top: 60px;

      @include is-mobile {
        margin-top: 40px;
      }
    }
  }

  &__btn {
    margin-top: 40px;

    @include is-mobile {
      margin-top: 20px;
    }
  }
}

.wp-block-table {
  width: 100%;
  min-width: 240px;
  border-collapse: collapse;

  td {
    padding: 5px;
    border: 1px solid $blue-dark;
    word-break: break-all;
  }

  th {
    padding: 5px;
    border: 1px solid $blue-dark;
    word-break: break-all;
  }
}

.p-seo {
  &-nav {
    display: inline-block;
    padding: 30px $side-padding;
    margin: 40px 0;
    position: relative;
    width: 100%;
    color: $gray02;

    @include is-mobile {
      padding: $side-padding-mobile;
      margin: 30px 0;
    }

    &::after {
      content: '';
      height: auto;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: transparent;
      opacity: .3;
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%224%22%20height%3D%224%22%20viewBox%3D%220%200%202%202%22%20preserveAspectRatio%3D%22none%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M1-1h.10l-4%204v-.10zM3-1v.10l-4%204h-.10z%22%2F%3E%20%3C%2Fsvg%3E");
    }

    &-list {
      position: relative;
      z-index: 1;

      &-item {
        @include fz(18);
        @include ls(2);

        &:not(:first-child) {
          margin-top: 30px;
        }

        @include is-mobile {
          @include fz(15);
        }

        &-child {
          padding: 10px 0 0 10px;
          position: relative;

          @include is-mobile {
            @include fz(14);
            padding: 5px 0 0 10px;
          }

          .p-seo-nav-link {
            color: $gray02;
          }

          &::before {
            content: '';
            width: 4px;
            height: 1px;
            position: absolute;
            top: calc(50% + 5px);
            left: 0;
            background: $gray02;
          }
        }
      }
    }

    &-ttl {
      @include fz(21);
      @include ls(2);
      font-family: $font-en;
      margin-bottom: 10px;
      font-weight: $semibold;
      position: relative;
      z-index: 1;

      @include is-mobile {
        @include fz(18);
        margin-bottom: 5px;
      }

    }
  }

}
