@charset "UTF-8";

/*----------------------------------------
g-header
----------------------------------------*/

.g-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 20;
  transition: background-color .2s ease;

  &.is-overed,
  &.is-subpage {
    background: $blue-dark;
  }

  @include is-tablet {
    z-index: 101;
  }

  &__main {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 0 0 20px;
    position: relative;
    height: $header_height_tablet;

    @include is-mobile {
      padding-left: $side-padding-mobile;
      height: $header_height_mobile;
    }
  }

  &__logo {
    $this: &;
    margin-left: 20px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;

    @include is-mobile {
      margin-left: 0;
    }

    &-link {
      color: $white;
      line-height: 1;
      padding: 4px 25px;
      display: block;
      transition: opacity .3s $ease;

      @include is-mobile {
        padding: 4px 16px;
      }

      &:hover {
        opacity: .7;
      }
    }

    &-desc {
      @include fz(12);
      text-align: left;
      padding: 5px 25px;
      display: flex;
      align-items: center;
      border-left: 1px solid $white;
      color: $white;
      display: block;

      @include is-tablet {
        display: none;
      }
    }

    &-img {
      max-height: 35px;
      max-width: 200px;
      vertical-align: middle;

      @include is-mobile {
        max-height: 25px;
      }
    }
  }

  &__menu {
    display: flex;
    align-items: center;
    height: 100%;

    &-container {
      display: flex;
    }

    &-list {
      display: flex;
      padding: 0 25px 0 0;
      height: 100%;

      @include is-tablet {
        padding: 0;
      }

      &-item {
        display: flex;
        align-items: center;

        @include is-tablet {
          width: $header_height_tablet;
          height: $header_height_tablet;
        }

        @include is-mobile {
          width: $header_height_mobile;
          height: $header_height_mobile;
        }

        &-link {
          color: $white;
          padding: 10px;
          margin: 15px;
          display: flex;
          align-items: center;
          position: relative;
          font-family: $font-en;
          transition: transform .2s ease;

          @include is-laptop {
            &:hover {
              transform: translateY(2px);

              &::after {
                transform: scaleX(1);
              }
            }
          }

          &::after {
            content: '';
            position: absolute;
            bottom: 10px;
            left: 10px;
            right: 10px;
            height: 2px;
            transform: scaleX(0);
            transition: transform .2s ease;
            background: $white;
            z-index: 1;
          }

          @include is-tablet {
            height: 100%;
            width: 100%;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
          }



          .g-header.is-overed &,
          .g-header.is-subpage & {
            @include is-tablet {
              background: rgba($white, .3);
            }
          }

          svg {
            margin-top: -2px;

            &:not(:last-child) {
              margin-right: 5px;
            }
          }

          &-icon {
            @include fz(26);
            position: relative;
            z-index: 1;
            vertical-align: middle;

            @include is-mobile {
              @include fz(22);
            }
          }

          &-img {
            fill: $black;
            width: 30px;
            height: 30px;
            vertical-align: middle;
            position: relative;
            z-index: 1;

            @include is-mobile {
              width: 20px;
              height: 20px;
            }
          }

        }

        &-cart {
          &-svg {
            width: 16px;
            height: 16px;

            @include is-tablet {
              width: 24px;
              height: 25px;
            }
          }
  
          &-path {
            fill: $white;
            fill-rule: evenodd;
          }
        }

        &-favorite {
          
          &-svg {
            width: 14px;
            height: 14px;
          }
  
          &-path {
            fill: $white;
            fill-rule: evenodd;
          }
        }

        &-mypage {
          
          &-svg {
            width: 14px;
            height: 14px;
          }
  
          &-path {
            fill: $white;
            fill-rule: evenodd;
          }
        }
      }
    }

    &-count {
      @include fz(10);
      color: $white;
      background: $red;
      min-width: 18px;
      height: 18px;
      line-height: 18px;
      position: absolute;
      top: -5px;
      right: -5px;
      z-index: 1;
      padding: 0 3px;
      border-radius: 50px;
      letter-spacing: normal;
      font-family: $font;

      @include is-tablet {
        top: 8px;
        right: 8px;
      }

      &[data-count="0"] {
        display: none;
      }
    }
  }

  &__burger {
    width: #{$side-padding * 2};
    height: $header_height_tablet;
    position: relative;
    background: $white;
    border: 0;
    cursor: pointer;

    @include is-tablet {
      width: 120px;
    }

    @include is-mobile {
      height: $header_height_mobile;
      width: $header_height_mobile;
    }

    &-line {
      background: $blue-dark;
      width: 40px;
      height: 2px;
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translateX(50%);

      @include is-mobile {
        width: 25px;
      }

      &:first-child {
        margin: -10px 0 0;

        @include is-mobile {
          margin-top: -9px;
        }
      }

      &:nth-child(2) {
        width: 30px;
        transform: translateX(10px);

        @include is-mobile {
          width: 20px;
          transform: translateX(7px);
        }
      }

      &:last-child {
        width: 20px;
        margin: 10px 0 0;
        transform: translateX(0);

        @include is-mobile {
          margin-top: 9px;
          width: 15px;
          transform: translateX(2px);
        }

      }
    }
  }

  &__nav {
    background: $white;
    padding: 14px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 0 10px rgba($black, .1);

    @include is-hamburger {
      padding: 0;
    }

    &-categories {
      overflow-x: auto;
      overflow-y: hidden;
      height: 100%;
      display: flex;
      align-items: center;

      &::-webkit-scrollbar {
        width: 5px;
        height: 2px;
      
        &-track {
          background: rgba(#f8f8f8, 1);
        }
      
        &-thumb {
          background: rgba(#6c6c6c, 1);
        }
      }

      @include is-hamburger {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: $header_height_tablet;
        transform: translateY(100%);
        background: $gray-pale;
        margin: 0;
      }

      @include is-mobile {
        height: $header_height_mobile;

      }

      &-list {
        white-space: nowrap;

        @include is-hamburger {
          height: 100%;
          display: flex;
          padding: 15px 25px 15px 15px;
          align-items: center;
        }
        
        @include is-mobile {
          padding-left: 6px;
        }

        &-item {
          display: inline-block;

          &-link {
            @include fz(14);
            color: $white;
            font-family: $font-jp;
            display: block;
            padding: 0 20px;
            transition: opacity .5s ease;
            opacity: 1;

            @include is-laptop {
              &:hover {
                opacity: .7;
              }
            }
  
            @include is-hamburger {
              padding: 0 10px;
            }

            @include is-hamburger {
              color: $black;
            }

            @include is-mobile {
              @include fz(13);

            }

            &.is-current {
              opacity: .7;

            }
          }
        }
      }
    }

    &-search {
      display: flex;
      padding: 0 25px 0 0;

      @include is-hamburger {
        width: 100%;
        display: block;
        padding: 0;
      }

      &-form {
        $this: &;
        width: calc(100% - 240px);
        position: relative;

        @include is-hamburger {
          display: none;
        }

        &-btn {
          background: none;
          border: 0;
          padding: 10px;
          cursor: pointer;
          position: absolute;
          top: 50%;
          right: 20px;
          transform: translateY(-50%);
          z-index: 1;

          &::after {
            content: "";
            display: block;
            background: rgba($primary, .1);
            border-radius: 50%;
            transform: scale(0);
            transition: transform .5s $ease;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
          }
        }

        &-icon {
          @include fz(20);
          color: $color-border-gray;
        }

        &-input {
          @include fz(16);
          font-family: $font;
          width: 480px;
          padding: 12px 70px 12px 16px;
          border: 1px solid $color-border-gray;

          &:not(:placeholder-shown) {
            border-color: $red;

            + #{$this}-btn {
              &::after {
                transform: scale(1);
              }
            }
          }
        }
      }

      &-btn {
        @extend %btn-hover-animation;
        color: $white;
        background: $gray;
        width: 240px;
        border: 0;
        margin: 0 0 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        @include is-hamburger {
          display: none;
        }

        &-txt {
          @include fz(16);
          @include ls(2);
          font-family: $font;
          font-weight: $bold;
          margin: 0 20px;
          position: relative;
          z-index: 1;
        }

        &-icon {
          @include fz(20);
          position: relative;
          z-index: 1;
        }
      }

      &-btn-mobile {
        color: $black;
        background: $white;
        width: 100%;
        padding: 24px 0;
        border: 0;
        display: none;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        @include is-hamburger {
          display: flex;
        }

        @include is-mobile {
          padding: 15px 0;
        }

        &-txt {
          @include fz(16);
          @include ls(2);
          font-family: $font;
          font-weight: $bold;
          margin: 0 20px;
        }

        &-icon {
          @include fz(18);
        }
      }
    }
  }
}
