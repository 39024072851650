@charset "UTF-8";

/*----------------------------------------
g-select
----------------------------------------*/

body {
  &[data-select="active"] {
    overflow: hidden;
    height: 100%;

    .g-select {
      pointer-events: auto;
      overflow-y: auto;

      &__enabled {
        opacity: 1;
      }

      &__container {
        opacity: 1;
      }
    }
  }
}

.g-select {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
  pointer-events: none;
  -webkit-overflow-scrolling: touch;

  @include is-hamburger {
    display: block;
  }

  &::before {
    content: "";
    display: block;
    padding: 151px 0 0;

    @include is-hamburger {
      padding: 143px 0 0;
    }

    @include is-mobile {
      padding: 114px 0 0;
    }
  }

  &__container {
    position: relative;
    z-index: 1;
    opacity: 0;
    transition: opacity $ease .3s .1s;
  }

  &__enabled {
    background: rgba($black, .8);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    opacity: 0;
    transition: opacity $ease .3s .1s;
  }

  &__category {
    position: relative;
    z-index: 1;

    &-list {
      &-item {
        border-bottom: 1px solid $gray;

        &-link {
          color: $black;
          background: $white;
          padding: 15px 20px;
          text-align: center;
          display: block;

          &.is-current {
            color: $white;
            background: $red;
          }

          &-txt {
            @include fz(16);
            font-weight: $bold;
          }
        }
      }
    }
  }
}
