.c-refusal {
  text-align: right;
  margin-top: 30px;

  img {
    width: 100%;
    max-width: 300px;
    margin-left: auto;

    @include is-mobile {
      max-width: 270px;
    }
  }
}
