@charset "UTF-8";

/*----------------------------------------
c-detail-data
----------------------------------------*/
.c-detail-data {
  text-align: left;
  border-top: 1px solid $blue-dark;
  margin-top: 80px;

  @include is-mobile {
    margin-top: 25px;
  }

  &__ttl {
    @include fz(18);
    @include ls(2);
    padding: 20px 40px 20px 0;
    position: relative;
    font-family: $font-jp;
    border-bottom: 1px solid $blue-dark;

    @include is-mobile {
      @include fz(13);
      padding: 20px 30px 20px 0;
    }

    &::before,
    &::after {
      background: $primary;
      width: $side-padding-mobile;
      height: 1px;
      position: absolute;
      top: 50%;
      right: 10px;
      transition: transform .3s $ease, opacity .3s $ease;

      @include is-mobile {
        width: 15px;
        right: 5px;
      }
    }

    &::after {
      transform: translateY(0) rotate(90deg);
      opacity: 1;
    }

    &.is-active {
      &::after {
        transform: translateY(10px) rotate(90deg);
        opacity: 0;
      }
    }

    &.js-accrodion-btn {
      cursor: pointer;

      &::before,
      &::after {
        content: "";
        display: block;
      }
    }

    &-container {

      &::before {
        content: '';
        bottom: -1px;
        left: 0;
        width: 180px;
        height: 1px;
        background: $black;
        position: absolute;

      }
    }
  }

  &__desc {
    margin: 20px 0 0;

    .c-detail-data__row:not(:last-child) & {
      margin-bottom: 40px;

      @include is-mobile {
        margin-bottom: 20px;
      }
    }

    @include is-mobile {
      margin-top: 10px;
    }

    table {
      width: 100%;
      table-layout: fixed;
    }

    p:not([class]) {
      @include ls(1);
      text-align: justify;
    }
  }
}
