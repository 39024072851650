@charset "UTF-8";


/*----------------------------------------
Variables
----------------------------------------*/
/*
# Color */
/* $black: #111; */
/* $white: #fff; */
/* $gray-black: #333; */
/* $gray-black2: #555555; */
/* $primary: #343434; */
/* $primary-white: #777; */
/* $white-gray: #f5f5f5; */
/* $white-gray2: #f9f9f9; */
/* $white-gray3: #eee; */
/* $gray-pale2: #efefef; */
/* $gray-pale3: #e2e2e2; */
/* $gray-pale: #e9e9e9; */
/* $gray-light04: #BFBFBF; */
/* $gray-light: #d0d0d0; */
/* $gray-light02: #b0b0b0; */
/* $gray-light03: #dfdfdf; */
/* $gray: #cccccc; */
/* $gray02: #666; */
/* $gray-dull: #707070; */
/* $gray-dark: #909090; */
/* $blue: #0086d3; */
/* $blue-pale: #e7ecef; */
/* $blue-light: #d5e8f3; */
/* $blue-gray: #88919e; */
/* $blue-gray: #cad5d9; */
/* $blue-deepdark: #1a0dab; */
/* $blue-dark: #38475e; */
/* $blue-dull: #4C6FA5; */
/* $red: #ab2d2d; */
/* $red-gray: #8C514E; */
/* $red-light: #bf3333; */
/* $red-black: #751313; */
/* $red-white: #d23d3d; */
/* $orange: #ff5722; */
/* $pink1: #dd4688; */
/* $pink2: #d46abf; */
/* $pink3: #cc8ef5; */
/* $green: #4caf50; */
/* $green-gray: #81a3a1;*/
/* $green-gray2: #91b3b9; */
/* $yellow-gray: #BE9456; */
/* $gold: #d2a727; */
/* $silver: #b1aea5; */
/* $bronze: #d89056; */
/*

# Border */
/* $color-border: #ddd; */
/* $color-border-black: #111; */
/* $color-border-gray: #707070; */
/*

/*

# Cat */
/*

# Shadow */
/* $shadow: rgba(#777, .1); */
/* $shadow-hover: rgba(#777, .3); */
/*

# Hover */
/* $opacity: .7; */
/*

# Font Family */
/* $font: 游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif */
/* $font-en: "Playfair Display", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif; */
/* $font-jp: "Noto Sans JP", Arial, Helvetica, sans-serif; */
/* $font-cn: "Microsoft Yahei", "PingHei"; */
/*

# Font Weight */
/* $light: 300; */
/* $regular:400; */
/* $medium: 500; */
/* $medium: 700; */
/* $bold: 900; */
/*

# Width */
/* $outer-width: 1920px; */
/* $content-width: 1280px; */
/*

# Padding */
/*$side-double-padding: 200px;*/
/* $side-padding: 80px; */
/* $side-double-padding-break: 150px; */
/*$side-padding-break1: 75px; */
/* $side-double-padding-break: 100px; */
/*$side-padding-break1: 50px; */
/* $side-padding-mobile: 16px; */
/*

# Easing */
/* $ease: cubic-bezier(.19, 1, .22, 1); */
/*----------------------------------------
Extend
----------------------------------------*/
.g-header__nav-search-btn, .c-cart-btn__body {
  position: relative;
}

.g-header__nav-search-btn::before, .c-cart-btn__body::before {
  content: "";
  display: block;
  background: #343434;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  opacity: 0;
  transform: scale(0.8);
  transform-origin: center;
  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

@media screen and (min-width: 1199px) {
  .g-header__nav-search-btn:hover::before, .c-cart-btn__body:hover::before {
    opacity: 1;
    transform: scale(1);
  }
}

.c-img-hover-item {
  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.c-two-column__figure, .c-sidebar__new-figure, .c-style-book-list__figure, .c-style-book-photo-thumb-figure, .c-style-book-photo-figure, .p-about__intro-figure, .p-about__feature-list-item-figure, .p-category__list-item-figure, .p-index__contents-details-figure, .p-index__category-list-figure, .p-index__slider-item-figure {
  position: relative;
  overflow: hidden;
}

.c-two-column__figure::after, .c-sidebar__new-figure::after, .c-style-book-list__figure::after, .c-style-book-photo-thumb-figure::after, .c-style-book-photo-figure::after, .p-about__intro-figure::after, .p-about__feature-list-item-figure::after, .p-category__list-item-figure::after, .p-index__contents-details-figure::after, .p-index__category-list-figure::after, .p-index__slider-item-figure::after {
  content: "";
  display: block;
  padding-bottom: 70%;
}

.c-two-column__figure .c-img, .c-sidebar__new-figure .c-img, .c-style-book-list__figure .c-img, .c-style-book-photo-thumb-figure .c-img, .c-style-book-photo-figure .c-img, .p-about__intro-figure .c-img, .p-about__feature-list-item-figure .c-img, .p-category__list-item-figure .c-img, .p-index__contents-details-figure .c-img, .p-index__category-list-figure .c-img, .p-index__slider-item-figure .c-img {
  display: none;
}

.c-two-column__figure .c-img-bg, .c-sidebar__new-figure .c-img-bg, .c-style-book-list__figure .c-img-bg, .c-style-book-photo-thumb-figure .c-img-bg, .c-style-book-photo-figure .c-img-bg, .p-about__intro-figure .c-img-bg, .p-about__feature-list-item-figure .c-img-bg, .p-category__list-item-figure .c-img-bg, .p-index__contents-details-figure .c-img-bg, .p-index__category-list-figure .c-img-bg, .p-index__slider-item-figure .c-img-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
}

/*----------------------------------------
Keyframes
----------------------------------------*/
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes activeBtn {
  0%,
  100% {
    color: #cb4e4e;
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    color: #dd4688;
  }
}

@keyframes activeBtnCircle {
  0% {
    transform: scale(0);
  }
  50% {
    border-color: #dd4688;
    opacity: 1;
  }
  80% {
    border-color: #d46abf;
    border-width: 0;
    transform: scale(1);
    opacity: 0;
  }
  100% {
    border-color: #cc8ef5;
    border-width: 0;
    transform: scale(0.8);
    opacity: 0;
  }
}

@keyframes activeBtnIcon {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  25% {
    transform: translate(-50%, -50%) scale(0);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.2);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

/*----------------------------------------
add_to_any
----------------------------------------*/
.a2a_kit a {
  padding: 0;
  margin: 0 10px;
}

.a2a_mini {
  width: 312px !important;
}

.a2a_svg {
  background: none !important;
}

.a2a_svg svg {
  width: 28px;
  height: 28px;
}

.a2a_svg svg,
.a2a_svg path,
.a2a_svg g {
  fill: #000;
}

.a2a_full {
  height: 340px !important;
}

.a2a_full_footer {
  padding: 18px 14px !important;
}

.a2a_menu a {
  font-family: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif !important;
  color: #000 !important;
}

.a2a_mini_services {
  padding: 6px !important;
  overflow: hidden;
}

.a2a_menu_show_more_less span {
  font-size: 14px;
  font-size: 1.4rem;
  font-family: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif !important;
}

/*----------------------------------------
select2
----------------------------------------*/
.select2 {
  display: block;
}

.select2.select2-container .select2-selection--single {
  height: 48px;
  border-color: #000;
  border-radius: 0;
}

.select2.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 48px;
  padding-left: 20px;
  padding-right: 20px;
}

.select2.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 48px;
}

.select2 .select2-container .select2-selection--single .select2-selection__rendered,
.select2 .select2-container--default .select2-selection--single .select2-selection__rendered,
.select2 .select2.select2-container--default .select2-selection--single .select2-selection__rendered {
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  width: 100%;
  border: 1px solid #343434;
  background: #fff;
  padding: 10px 40px 10px 20px;
}

@media screen and (max-width: 767px) {
  .select2 .select2-container .select2-selection--single .select2-selection__rendered,
  .select2 .select2-container--default .select2-selection--single .select2-selection__rendered,
  .select2 .select2.select2-container--default .select2-selection--single .select2-selection__rendered {
    padding: 10px 40px 10px 15px;
  }
}

.select2 .select2-container .select2-selection--single .select2-selection__rendered:disabled,
.select2 .select2-container--default .select2-selection--single .select2-selection__rendered:disabled,
.select2 .select2.select2-container--default .select2-selection--single .select2-selection__rendered:disabled {
  background: #f5f5f5;
  opacity: .3;
}

.select2 .select2-container .select2-selection--single {
  margin: 0;
}

.select2 .select2.select2-container .select2-selection--single {
  height: auto;
}

.select2 .select2-container--default .select2-selection--single {
  background-color: transparent;
  border: none;
  border-radius: 0;
}

.select2 .select2.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: normal;
}

.select2 .select2.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 100%;
}

.select2-results__option {
  text-align: left;
}

/*--------------------
Swiper 4.5.1
--------------------*/
.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}

.swiper-container-no-flexbox .swiper-slide {
  float: left;
}

.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  -webkit-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0);
}

.swiper-container-multirow > .swiper-wrapper {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto;
}

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

.swiper-slide-invisible-blank {
  visibility: hidden;
}

.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto;
}

.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-transition-property: height, -webkit-transform;
  transition-property: height, -webkit-transform;
  -o-transition-property: transform, height;
  transition-property: transform, height;
  transition-property: transform, height, -webkit-transform;
}

.swiper-container-3d {
  -webkit-perspective: 1200px;
  perspective: 1200px;
}

.swiper-container-3d .swiper-wrapper,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-cube-shadow {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.swiper-container-wp8-horizontal,
.swiper-container-wp8-horizontal > .swiper-wrapper {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.swiper-container-wp8-vertical,
.swiper-container-wp8-vertical > .swiper-wrapper {
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto;
}

.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-lock {
  display: none;
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: 300ms opacity;
  -o-transition: 300ms opacity;
  transition: 300ms opacity;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10;
}

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 10px;
  left: 0;
  width: 100%;
}

.swiper-pagination-bullets-dynamic {
  overflow: hidden;
  font-size: 0;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33);
  position: relative;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  -webkit-transform: scale(0.66);
  -ms-transform: scale(0.66);
  transform: scale(0.66);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  -webkit-transform: scale(0.66);
  -ms-transform: scale(0.66);
  transform: scale(0.66);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33);
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2;
}

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff;
}

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0px, -50%, 0);
  transform: translate3d(0px, -50%, 0);
}

.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 6px 0;
  display: block;
}

.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 8px;
}

.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  display: inline-block;
  -webkit-transition: 200ms top, 200ms -webkit-transform;
  transition: 200ms top, 200ms -webkit-transform;
  -o-transition: 200ms transform, 200ms top;
  transition: 200ms transform, 200ms top;
  transition: 200ms transform, 200ms top, 200ms -webkit-transform;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 4px;
}

.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  white-space: nowrap;
}

.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transition: 200ms left, 200ms -webkit-transform;
  transition: 200ms left, 200ms -webkit-transform;
  -o-transition: 200ms transform, 200ms left;
  transition: 200ms transform, 200ms left;
  transition: 200ms transform, 200ms left, 200ms -webkit-transform;
}

.swiper-container-horizontal.swiper-container-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transition: 200ms right, 200ms -webkit-transform;
  transition: 200ms right, 200ms -webkit-transform;
  -o-transition: 200ms transform, 200ms right;
  transition: 200ms transform, 200ms right;
  transition: 200ms transform, 200ms right, 200ms -webkit-transform;
}

.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
}

.swiper-container-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  -webkit-transform-origin: right top;
  -ms-transform-origin: right top;
  transform-origin: right top;
}

.swiper-container-horizontal > .swiper-pagination-progressbar,
.swiper-container-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
}

.swiper-container-vertical > .swiper-pagination-progressbar,
.swiper-container-horizontal > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #ffffff;
}

.swiper-pagination-progressbar.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.25);
}

.swiper-pagination-progressbar.swiper-pagination-white .swiper-pagination-progressbar-fill {
  background: #ffffff;
}

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000000;
}

.swiper-pagination-progressbar.swiper-pagination-black {
  background: rgba(0, 0, 0, 0.25);
}

.swiper-pagination-progressbar.swiper-pagination-black .swiper-pagination-progressbar-fill {
  background: #000000;
}

.swiper-pagination-lock {
  display: none;
}

.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1);
}

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%;
}

.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%;
}

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0;
}

.swiper-scrollbar-cursor-drag {
  cursor: move;
}

.swiper-scrollbar-lock {
  display: none;
}

.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.swiper-zoom-container > img,
.swiper-zoom-container > svg,
.swiper-zoom-container > canvas {
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.swiper-slide-zoomed {
  cursor: move;
}

.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
}

.swiper-lazy-preloader:after {
  display: block;
  content: '';
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  background-size: 100%;
  background-repeat: no-repeat;
}

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
}

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-container-cube {
  overflow: visible;
}

.swiper-container-cube .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}

.swiper-container-cube .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-prev,
.swiper-container-cube .swiper-slide-next + .swiper-slide {
  pointer-events: auto;
  visibility: visible;
}

.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0;
}

.swiper-container-flip {
  overflow: visible;
}

.swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
}

.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-flip .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-container-flip .swiper-slide-shadow-top,
.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.swiper-container-coverflow .swiper-wrapper {
  /* Windows 8 IE 10 fix */
  -ms-perspective: 1200px;
}

/*----------------------------------------
g-cart
----------------------------------------*/
body[data-cart="active"] .g-cart {
  pointer-events: auto;
  background: rgba(0, 0, 0, 0.7);
}

body[data-cart="active"] .g-cart__container {
  overflow-y: auto;
  opacity: 1;
  transform: translateY(0);
}

.g-cart {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  pointer-events: none;
  transition: background cubic-bezier(0.19, 1, 0.22, 1) 0.3s;
}

.g-cart__container {
  height: 100%;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  position: relative;
  z-index: 1;
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity cubic-bezier(0.19, 1, 0.22, 1) 0.3s, transform cubic-bezier(0.19, 1, 0.22, 1) 0.3s;
  padding: 80px 0;
}

@media screen and (max-width: 767px) {
  .g-cart__container {
    padding: 0;
  }
}

.g-cart__burger {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  background: none;
  border: 0;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .g-cart__burger {
    width: 60px;
    height: 60px;
  }
}

.g-cart__burger-line {
  background: #cccccc;
  width: 30px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-15px);
  border-radius: 10px;
  transform-origin: center;
}

.g-cart__burger-line:first-child {
  transform: translate(-50%, -50%) rotate(45deg);
}

.g-cart__burger-line:last-child {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.g-cart__enabled {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.g-cart__inner {
  background: #fff;
  max-width: 1280px;
  margin: 0 auto;
  padding: 70px 80px;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .g-cart__inner {
    min-height: 100vh;
    padding: 60px 20px 20px;
  }
}

.g-cart__header-ttl {
  font-size: 24px;
  font-size: 2.4rem;
  letter-spacing: 2px;
  letter-spacing: 0.2rem;
  font-weight: 900;
  color: #cb4e4e;
  padding: 0 0 20px;
  margin: 0 0 30px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

@media screen and (max-width: 1024px) {
  .g-cart__header-ttl {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.g-cart__body {
  padding: 0 0 30px;
  margin: 0 0 30px;
  border-bottom: 1px solid #ddd;
}

.g-cart__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .g-cart__item {
    display: block;
  }
}

.g-cart__item-detail {
  display: flex;
  align-items: center;
}

.g-cart__item-detail-img {
  width: 160px;
  background: no-repeat center;
  background-size: cover;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .g-cart__item-detail-img {
    width: 100px;
  }
}

.g-cart__item-detail-img::before {
  content: "";
  display: block;
  padding: 120% 0 0;
}

.g-cart__item-detail-img-src {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.g-cart__item-detail-data {
  text-align: justify;
  padding: 0 0 0 30px;
}

@media screen and (max-width: 1024px) {
  .g-cart__item-detail-data {
    padding: 0 0 0 15px;
  }
}

.g-cart__item-detail-data-name {
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
  font-weight: 900;
  margin: 0 0 5px;
  padding: 0 5px;
}

@media screen and (max-width: 1024px) {
  .g-cart__item-detail-data-name {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.g-cart__item-detail-data-list {
  display: table;
  width: 100%;
}

.g-cart__item-detail-data-list-row {
  display: table-row;
}

.g-cart__item-detail-data-list-ttl, .g-cart__item-detail-data-list-desc {
  display: table-cell;
  padding: 5px;
}

@media screen and (max-width: 1024px) {
  .g-cart__item-detail-data-list-ttl, .g-cart__item-detail-data-list-desc {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 1024px) {
  .g-cart__item-price {
    padding: 0 0 0 120px;
    text-align: left;
  }
}

.g-cart__item-price #js-modal_purchased_price {
  font-size: 20px;
  font-size: 2rem;
}

.g-cart__item-price-txt {
  font-size: 24px;
  font-size: 2.4rem;
  letter-spacing: 2px;
  letter-spacing: 0.2rem;
  font-weight: 900;
}

@media screen and (max-width: 1024px) {
  .g-cart__item-price-txt {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.g-cart__item-price-tax {
  letter-spacing: 2px;
  letter-spacing: 0.2rem;
  font-weight: 900;
}

@media screen and (max-width: 1024px) {
  .g-cart__item-price-tax {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

/*----------------------------------------
common
----------------------------------------*/
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 14px;
  font-size: 1.4rem;
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
  color: #333;
  line-height: 1.5;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-weight: 400;
  font-feature-settings: "palt";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  word-break: normal;
  overflow-wrap: break-word;
  position: relative;
  text-align: center;
}

@media screen and (max-width: 767px) {
  body {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 374px) {
  body {
    min-width: 320px;
    overflow-x: auto;
  }
}

body::before {
  content: "";
  display: block;
  padding: 100px 0 0;
}

@media screen and (max-width: 767px) {
  body::before {
    padding-top: 60px;
  }
}

body.is-not-before::before {
  padding: 100px 0 0;
}

@media screen and (max-width: 767px) {
  body.is-not-before::before {
    padding-top: 60px;
  }
}

a {
  color: #343434;
  text-decoration: none;
}

a:not([class]) {
  color: #4C6FA5;
}

a:not([class]):hover {
  text-decoration: underline;
  color: #cb4e4e;
}

select,
input,
textarea {
  line-height: normal;
  appearance: none;
  padding: 0;
  border: 0;
  border-radius: 0;
  outline: none;
}

select::-ms-expand {
  display: none;
}

img {
  max-width: 100%;
  height: auto;
}

::selection {
  background-color: #343434;
  color: #fff;
}

/* スライダーチラツキ防止 */
.swiper-wrapper {
  backface-visibility: hidden;
  transition-delay: 0s;
}

.swiper-slide {
  transition-delay: 0s;
  backface-visibility: hidden;
}

strong, b {
  font-weight: 900;
}

/*----------------------------------------
Media
----------------------------------------*/
@media screen and (max-width: 1024px) {
  .is-pc,
  .pc {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .is-pc,
  .pc {
    display: none !important;
  }
}

.is-sp,
.sp {
  display: none !important;
}

@media screen and (max-width: 767px) {
  .is-sp,
  .sp {
    display: block !important;
  }
}

.is-tablet,
.tablet {
  display: none !important;
}

@media screen and (max-width: 1024px) {
  .is-tablet,
  .tablet {
    display: block !important;
  }
}

/*----------------------------------------
g-floating
----------------------------------------*/
.g-floating__banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 240px;
  z-index: 5;
  opacity: 0;
  pointer-events: none;
  transition: opacity .2s ease;
}

.g-floating__banner.is-active {
  opacity: 1;
  pointer-events: initial;
}

@media screen and (max-width: 767px) {
  .g-floating__banner {
    width: 140px;
  }
}

.g-floating__banner-close {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(50%) translateY(-50%);
  padding: 14px;
  border: none;
  background: none;
}

.g-floating__banner-close-inner {
  background: #777;
  border-radius: 100%;
  width: 26px;
  height: 26px;
  display: block;
}

@media screen and (max-width: 767px) {
  .g-floating__banner-close-inner {
    width: 16px;
    height: 16px;
  }
}

.g-floating__banner-close-inner::before {
  content: '';
  width: 3px;
  height: 12px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(-45deg);
}

@media screen and (max-width: 767px) {
  .g-floating__banner-close-inner::before {
    width: 2px;
    height: 7px;
  }
}

.g-floating__banner-close-inner::after {
  content: '';
  width: 3px;
  height: 12px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}

@media screen and (max-width: 767px) {
  .g-floating__banner-close-inner::after {
    width: 2px;
    height: 7px;
  }
}

.g-floating__banner-img {
  vertical-align: bottom;
}

/*----------------------------------------
g-footer
----------------------------------------*/
.g-footer {
  display: flex;
  justify-content: space-between;
  color: #fff;
  background: #38475e;
}

.g-footer__container {
  display: flex;
  padding: 60px 160px;
  width: 100%;
}

@media screen and (max-width: 1330px) {
  .g-footer__container {
    padding: 60px 80px;
  }
}

@media screen and (max-width: 767px) {
  .g-footer__container {
    padding: 40px 16px;
  }
}

.g-footer__left {
  padding-right: 80px;
  border-right: 1px solid #fff;
  width: 320px;
}

@media screen and (max-width: 1330px) {
  .g-footer__left {
    padding-right: 60px;
  }
}

@media screen and (max-width: 1024px) {
  .g-footer__left {
    width: 100%;
    padding-right: 0;
    border-right: none;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.g-footer__right {
  padding: 0 100px;
  width: calc(100% - 320px);
}

@media screen and (max-width: 1330px) {
  .g-footer__right {
    padding: 0 0 0 60px;
  }
}

@media screen and (max-width: 1024px) {
  .g-footer__right {
    display: none;
  }
}

.g-footer .c-nav__pages-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.g-footer .c-nav__pages-list-item {
  width: 33.33333%;
}

.g-footer .c-nav__pages-list-item-link {
  font-size: 15px;
  font-size: 1.5rem;
  padding: 10px;
  color: #fff;
  display: block;
  text-align: left;
  transition: opacity .2s ease;
}

@media screen and (min-width: 1199px) {
  .g-footer .c-nav__pages-list-item-link:hover {
    opacity: .5;
  }
}

.g-footer__logo {
  text-align: left;
  margin: -20px 0 0 -20px;
}

@media screen and (max-width: 1024px) {
  .g-footer__logo {
    text-align: center;
    margin-left: 0;
  }
}

.g-footer__logo-link {
  color: #fff;
  line-height: 1;
  padding: 20px;
  display: inline-block;
  transition: opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.g-footer__logo-link:hover {
  opacity: .5;
}

.g-footer__logo-txt {
  font-size: 26px;
  font-size: 2.6rem;
  letter-spacing: 4px;
  letter-spacing: 0.4rem;
  font-family: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
}

.g-footer__logo-img {
  max-height: 45px;
}

@media screen and (max-width: 767px) {
  .g-footer__logo-img {
    max-height: 35px;
  }
}

.g-footer__help {
  margin-top: 30px;
}

.g-footer__help-list {
  margin: 0 -10px;
}

.g-footer__help-list-item {
  text-align: left;
}

.g-footer__help-list-item-link {
  text-align: left;
  color: #fff;
  padding: 10px;
  display: inline-block;
  text-decoration: underline;
  transition: opacity .2s ease;
}

@media screen and (min-width: 1199px) {
  .g-footer__help-list-item-link:hover {
    opacity: .8;
  }
}

@media screen and (max-width: 767px) {
  .g-footer__help-list-item-link {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.g-footer__sns {
  display: flex;
}

@media screen and (max-width: 1024px) {
  .g-footer__sns {
    justify-content: center;
  }
}

.g-footer__sns-list {
  display: flex;
  align-items: center;
  margin: 0 -10px;
}

.g-footer__sns-list-item-link {
  font-size: 0;
  color: #fff;
  padding: 10px;
  display: block;
  position: relative;
  transition: opacity .2s ease;
}

.g-footer__sns-list-item-link:hover {
  opacity: .8;
}

.g-footer__sns-list-item-link-icon {
  font-size: 20px;
  font-size: 2rem;
  letter-spacing: normal;
}

.g-footer__copyright {
  margin-top: 40px;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .g-footer__copyright {
    margin-top: 30px;
  }
}

.g-footer__copyright-txt {
  font-size: 12px;
  font-size: 1.2rem;
  font-family: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  color: #fff;
}

.g-hamburger__container {
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  transition: opacity .4s ease;
  opacity: 0;
  padding: 210px 160px 120px;
  display: flex;
  align-items: center;
  z-index: 100;
}

@media screen and (max-width: 1330px) {
  .g-hamburger__container {
    padding: 210px 80px 120px;
  }
}

@media screen and (max-width: 1024px) {
  .g-hamburger__container {
    padding: 100px 80px;
  }
}

@media screen and (max-width: 767px) {
  .g-hamburger__container {
    padding: 60px 16px 25px;
  }
}

[data-menu='active'] .g-hamburger__container {
  pointer-events: initial;
  opacity: 1;
}

.g-hamburger__container::before {
  content: '';
  background: #333;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #38475e;
}

.g-hamburger__logo {
  text-align: left;
  position: absolute;
  top: 0;
  left: 0;
  height: 100px;
  display: flex;
  align-items: center;
  padding: 0 0 0 20px;
}

@media screen and (max-width: 767px) {
  .g-hamburger__logo {
    height: 60px;
    padding-left: 0;
  }
}

.g-hamburger__logo-link {
  display: block;
  padding: 4px 25px;
  opacity: 1;
  transition: opacity .2s ease;
  line-height: 1;
}

@media screen and (max-width: 767px) {
  .g-hamburger__logo-link {
    padding: 4px 16px;
  }
}

.g-hamburger__logo-link:hover {
  opacity: .7;
}

.g-hamburger__logo-img {
  max-width: 480px;
  max-height: 35px;
}

@media screen and (max-width: 767px) {
  .g-hamburger__logo-img {
    max-width: 250px;
    max-height: 25px;
  }
}

.g-hamburger__othermenu {
  margin-top: 25px;
  padding-top: 30px;
  position: relative;
  display: flex;
}

@media screen and (max-width: 1024px) {
  .g-hamburger__othermenu {
    margin-top: 40px;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media screen and (max-width: 767px) {
  .g-hamburger__othermenu {
    margin-top: 15px;
    padding-top: 20px;
  }
}

.g-hamburger__othermenu::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 1px;
  background: #fff;
  opacity: .3;
}

.g-hamburger__help {
  margin-left: 40px;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .g-hamburger__help {
    width: 100%;
    margin-top: 10px;
    margin-left: 0;
  }
}

.g-hamburger__help-list {
  margin: -10px;
  display: flex;
}

@media screen and (max-width: 1024px) {
  .g-hamburger__help-list {
    margin: -10px -10px 0;
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) {
  .g-hamburger__help-list {
    margin: -6px -6px 0;
  }
}

.g-hamburger__help-list-item:not(:last-child) {
  margin-right: 15px;
}

@media screen and (max-width: 1024px) {
  .g-hamburger__help-list-item:not(:last-child) {
    margin-right: 0;
  }
}

.g-hamburger__help-list-item-link {
  font-size: 13px;
  font-size: 1.3rem;
  text-align: left;
  color: #fff;
  display: block;
  padding: 10px;
  transition: opacity .2s ease;
  opacity: 1;
  text-decoration: underline;
}

@media screen and (max-width: 1024px) {
  .g-hamburger__help-list-item-link {
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .g-hamburger__help-list-item-link {
    font-size: 12px;
    font-size: 1.2rem;
    padding: 6px;
  }
}

@media screen and (min-width: 1199px) {
  .g-hamburger__help-list-item-link:hover {
    opacity: .7;
  }
}

.g-hamburger__sns-list {
  display: flex;
  margin: -10px;
}

@media screen and (max-width: 1024px) {
  .g-hamburger__sns-list {
    justify-content: center;
    margin: -10px -10px 0;
  }
}

.g-hamburger__sns-list-item-link {
  font-size: 0;
  color: #fff;
  padding: 10px;
  display: block;
  position: relative;
  transition: opacity .2s ease;
}

@media screen and (min-width: 1199px) {
  .g-hamburger__sns-list-item-link:hover {
    opacity: .8;
  }
}

.g-hamburger__sns-list-item-link-icon {
  font-size: 20px;
  font-size: 2rem;
  letter-spacing: normal;
}

.g-hamburger__menu {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .g-hamburger__menu {
    width: 100%;
  }
}

.g-hamburger__menu-container {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 20px;
  height: 100%;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .g-hamburger__menu-container {
    padding: 0;
  }
}

.g-hamburger__menu-toplist {
  display: none;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

@media screen and (max-width: 1024px) {
  .g-hamburger__menu-toplist {
    display: flex;
    justify-content: center;
    margin: 0 0 40px;
  }
}

@media screen and (max-width: 767px) {
  .g-hamburger__menu-toplist {
    margin-bottom: 15px;
  }
}

.g-hamburger__menu-toplist-item-link {
  font-size: 20px;
  font-size: 2rem;
  display: flex;
  align-items: center;
  text-indent: .1rem;
  padding: 30px 30px 26px;
  color: #fff;
  font-family: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-weight: 700;
}

@media screen and (max-width: 767px) {
  .g-hamburger__menu-toplist-item-link {
    font-size: 13px;
    font-size: 1.3rem;
    padding: 15px 15px 12px;
  }
}

.g-hamburger__menu-toplist-item svg {
  margin-top: -2px;
}

.g-hamburger__menu-toplist-item svg:not(:last-child) {
  margin-right: 5px;
}

.g-hamburger__menu-toplist-item-favorite-svg {
  width: 14px;
  height: 14px;
}

.g-hamburger__menu-toplist-item-favorite-path {
  fill: #fff;
  fill-rule: evenodd;
}

.g-hamburger__menu-toplist-item-mypage-svg {
  width: 14px;
  height: 14px;
}

.g-hamburger__menu-toplist-item-mypage-path {
  fill: #fff;
  fill-rule: evenodd;
}

.g-hamburger__menu-list {
  display: flex;
  flex-wrap: wrap;
  margin: -25px -45px 0;
}

@media screen and (max-width: 1330px) {
  .g-hamburger__menu-list {
    margin: -20px -20px 0;
  }
}

@media screen and (max-width: 1199px) {
  .g-hamburger__menu-list {
    margin: -15px -15px 0;
  }
}

@media screen and (max-width: 767px) {
  .g-hamburger__menu-list {
    margin: -7.5px -7.5px 0;
  }
}

.g-hamburger__menu-list-item {
  width: 33.3333%;
}

.g-hamburger__menu-toplist .g-hamburger__menu-list-item {
  width: auto;
  display: inline-block;
}

@media screen and (max-width: 1024px) {
  .g-hamburger__menu-list-item {
    width: 100%;
    justify-content: center;
  }
}

.g-hamburger__menu-list-item-link {
  padding: 25px 45px;
  display: inline-block;
  text-align: left;
  width: 100%;
  transition: opacity .2s ease;
}

.g-hamburger__menu-list-item-link:hover {
  opacity: .7;
}

.g-hamburger__menu-list-item-link::after {
  width: calc(100% - 80px);
  left: 45px;
}

@media screen and (max-width: 1330px) {
  .g-hamburger__menu-list-item-link::after {
    width: calc(100% - 40px);
    left: 20px;
  }
}

@media screen and (max-width: 1330px) {
  .g-hamburger__menu-list-item-link {
    padding: 20px;
  }
}

@media screen and (max-width: 1199px) {
  .g-hamburger__menu-list-item-link {
    padding: 15px;
  }
}

@media screen and (max-width: 767px) {
  .g-hamburger__menu-list-item-link {
    padding: 7.5px;
  }
}

@media screen and (max-width: 1199px) {
  .g-hamburger__menu-toplist .g-hamburger__menu-list-item-link {
    padding: 30px;
  }
}

@media screen and (max-width: 767px) {
  .g-hamburger__menu-toplist .g-hamburger__menu-list-item-link {
    padding: 15px;
  }
}

.g-hamburger__menu-list-txt-main {
  font-size: 23px;
  font-size: 2.3rem;
  letter-spacing: 0.5px;
  letter-spacing: 0.05rem;
  line-height: 1.2;
  display: block;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .g-hamburger__menu-list-txt-main {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.g-hamburger__menu-list-txt-sub {
  font-size: 11px;
  font-size: 1.1rem;
  line-height: 1;
  font-family: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  display: block;
  font-weight: 900;
  color: rgba(255, 255, 255, 0.3);
  padding-top: 20px;
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .g-hamburger__menu-list-txt-sub {
    font-size: 10px;
    font-size: 1rem;
    padding-top: 10px;
  }
}

.g-hamburger__menu-list-txt-sub::before {
  content: "";
  width: 40px;
  height: 1px;
  background: #fff;
  position: absolute;
  top: 8px;
  left: 0;
  transform-origin: left;
}

@media screen and (min-width: 1199px) {
  .g-hamburger__menu-list-item-link:hover .g-hamburger__menu-list-txt-sub::before {
    animation: border-reverse .5s ease forwards;
  }
}

@media screen and (max-width: 767px) {
  .g-hamburger__menu-list-txt-sub::before {
    top: 3px;
    width: 25px;
  }
}

@keyframes border-reverse {
  0% {
    transform: scaleX(1);
    transform-origin: right;
  }
  50% {
    transform: scaleX(0);
    transform-origin: right;
  }
  51% {
    transform-origin: left;
  }
  100% {
    transform: scaleX(1);
    transform-origin: left;
  }
}

/*----------------------------------------
g-header
----------------------------------------*/
.g-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 20;
  transition: background-color .2s ease;
}

.g-header.is-overed, .g-header.is-subpage {
  background: #38475e;
}

@media screen and (max-width: 1024px) {
  .g-header {
    z-index: 101;
  }
}

.g-header__main {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 0 0 20px;
  position: relative;
  height: 100px;
}

@media screen and (max-width: 767px) {
  .g-header__main {
    padding-left: 16px;
    height: 60px;
  }
}

.g-header__logo {
  margin-left: 20px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .g-header__logo {
    margin-left: 0;
  }
}

.g-header__logo-link {
  color: #fff;
  line-height: 1;
  padding: 4px 25px;
  display: block;
  transition: opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

@media screen and (max-width: 767px) {
  .g-header__logo-link {
    padding: 4px 16px;
  }
}

.g-header__logo-link:hover {
  opacity: .7;
}

.g-header__logo-desc {
  font-size: 12px;
  font-size: 1.2rem;
  text-align: left;
  padding: 5px 25px;
  display: flex;
  align-items: center;
  border-left: 1px solid #fff;
  color: #fff;
  display: block;
}

@media screen and (max-width: 1024px) {
  .g-header__logo-desc {
    display: none;
  }
}

.g-header__logo-img {
  max-height: 35px;
  max-width: 200px;
  vertical-align: middle;
}

@media screen and (max-width: 767px) {
  .g-header__logo-img {
    max-height: 25px;
  }
}

.g-header__menu {
  display: flex;
  align-items: center;
  height: 100%;
}

.g-header__menu-container {
  display: flex;
}

.g-header__menu-list {
  display: flex;
  padding: 0 25px 0 0;
  height: 100%;
}

@media screen and (max-width: 1024px) {
  .g-header__menu-list {
    padding: 0;
  }
}

.g-header__menu-list-item {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .g-header__menu-list-item {
    width: 100px;
    height: 100px;
  }
}

@media screen and (max-width: 767px) {
  .g-header__menu-list-item {
    width: 60px;
    height: 60px;
  }
}

.g-header__menu-list-item-link {
  color: #fff;
  padding: 10px;
  margin: 15px;
  display: flex;
  align-items: center;
  position: relative;
  font-family: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  transition: transform .2s ease;
}

@media screen and (min-width: 1199px) {
  .g-header__menu-list-item-link:hover {
    transform: translateY(2px);
  }
  .g-header__menu-list-item-link:hover::after {
    transform: scaleX(1);
  }
}

.g-header__menu-list-item-link::after {
  content: '';
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  height: 2px;
  transform: scaleX(0);
  transition: transform .2s ease;
  background: #fff;
  z-index: 1;
}

@media screen and (max-width: 1024px) {
  .g-header__menu-list-item-link {
    height: 100%;
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 1024px) {
  .g-header.is-overed .g-header__menu-list-item-link,
  .g-header.is-subpage .g-header__menu-list-item-link {
    background: rgba(255, 255, 255, 0.3);
  }
}

.g-header__menu-list-item-link svg {
  margin-top: -2px;
}

.g-header__menu-list-item-link svg:not(:last-child) {
  margin-right: 5px;
}

.g-header__menu-list-item-link-icon {
  font-size: 26px;
  font-size: 2.6rem;
  position: relative;
  z-index: 1;
  vertical-align: middle;
}

@media screen and (max-width: 767px) {
  .g-header__menu-list-item-link-icon {
    font-size: 22px;
    font-size: 2.2rem;
  }
}

.g-header__menu-list-item-link-img {
  fill: #000;
  width: 30px;
  height: 30px;
  vertical-align: middle;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .g-header__menu-list-item-link-img {
    width: 20px;
    height: 20px;
  }
}

.g-header__menu-list-item-cart-svg {
  width: 16px;
  height: 16px;
}

@media screen and (max-width: 1024px) {
  .g-header__menu-list-item-cart-svg {
    width: 24px;
    height: 25px;
  }
}

.g-header__menu-list-item-cart-path {
  fill: #fff;
  fill-rule: evenodd;
}

.g-header__menu-list-item-favorite-svg {
  width: 14px;
  height: 14px;
}

.g-header__menu-list-item-favorite-path {
  fill: #fff;
  fill-rule: evenodd;
}

.g-header__menu-list-item-mypage-svg {
  width: 14px;
  height: 14px;
}

.g-header__menu-list-item-mypage-path {
  fill: #fff;
  fill-rule: evenodd;
}

.g-header__menu-count {
  font-size: 10px;
  font-size: 1rem;
  color: #fff;
  background: #cb4e4e;
  min-width: 18px;
  height: 18px;
  line-height: 18px;
  position: absolute;
  top: -5px;
  right: -5px;
  z-index: 1;
  padding: 0 3px;
  border-radius: 50px;
  letter-spacing: normal;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
}

@media screen and (max-width: 1024px) {
  .g-header__menu-count {
    top: 8px;
    right: 8px;
  }
}

.g-header__menu-count[data-count="0"] {
  display: none;
}

.g-header__burger {
  width: 160px;
  height: 100px;
  position: relative;
  background: #fff;
  border: 0;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .g-header__burger {
    width: 120px;
  }
}

@media screen and (max-width: 767px) {
  .g-header__burger {
    height: 60px;
    width: 60px;
  }
}

.g-header__burger-line {
  background: #38475e;
  width: 40px;
  height: 2px;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translateX(50%);
}

@media screen and (max-width: 767px) {
  .g-header__burger-line {
    width: 25px;
  }
}

.g-header__burger-line:first-child {
  margin: -10px 0 0;
}

@media screen and (max-width: 767px) {
  .g-header__burger-line:first-child {
    margin-top: -9px;
  }
}

.g-header__burger-line:nth-child(2) {
  width: 30px;
  transform: translateX(10px);
}

@media screen and (max-width: 767px) {
  .g-header__burger-line:nth-child(2) {
    width: 20px;
    transform: translateX(7px);
  }
}

.g-header__burger-line:last-child {
  width: 20px;
  margin: 10px 0 0;
  transform: translateX(0);
}

@media screen and (max-width: 767px) {
  .g-header__burger-line:last-child {
    margin-top: 9px;
    width: 15px;
    transform: translateX(2px);
  }
}

.g-header__nav {
  background: #fff;
  padding: 14px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 1330px) {
  .g-header__nav {
    padding: 0;
  }
}

.g-header__nav-categories {
  overflow-x: auto;
  overflow-y: hidden;
  height: 100%;
  display: flex;
  align-items: center;
}

.g-header__nav-categories::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}

.g-header__nav-categories::-webkit-scrollbar-track {
  background: #f8f8f8;
}

.g-header__nav-categories::-webkit-scrollbar-thumb {
  background: #6c6c6c;
}

@media screen and (max-width: 1330px) {
  .g-header__nav-categories {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100px;
    transform: translateY(100%);
    background: #e3e5e8;
    margin: 0;
  }
}

@media screen and (max-width: 767px) {
  .g-header__nav-categories {
    height: 60px;
  }
}

.g-header__nav-categories-list {
  white-space: nowrap;
}

@media screen and (max-width: 1330px) {
  .g-header__nav-categories-list {
    height: 100%;
    display: flex;
    padding: 15px 25px 15px 15px;
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  .g-header__nav-categories-list {
    padding-left: 6px;
  }
}

.g-header__nav-categories-list-item {
  display: inline-block;
}

.g-header__nav-categories-list-item-link {
  font-size: 14px;
  font-size: 1.4rem;
  color: #fff;
  font-family: "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  display: block;
  padding: 0 20px;
  transition: opacity .5s ease;
  opacity: 1;
}

@media screen and (min-width: 1199px) {
  .g-header__nav-categories-list-item-link:hover {
    opacity: .7;
  }
}

@media screen and (max-width: 1330px) {
  .g-header__nav-categories-list-item-link {
    padding: 0 10px;
  }
}

@media screen and (max-width: 1330px) {
  .g-header__nav-categories-list-item-link {
    color: #000;
  }
}

@media screen and (max-width: 767px) {
  .g-header__nav-categories-list-item-link {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.g-header__nav-categories-list-item-link.is-current {
  opacity: .7;
}

.g-header__nav-search {
  display: flex;
  padding: 0 25px 0 0;
}

@media screen and (max-width: 1330px) {
  .g-header__nav-search {
    width: 100%;
    display: block;
    padding: 0;
  }
}

.g-header__nav-search-form {
  width: calc(100% - 240px);
  position: relative;
}

@media screen and (max-width: 1330px) {
  .g-header__nav-search-form {
    display: none;
  }
}

.g-header__nav-search-form-btn {
  background: none;
  border: 0;
  padding: 10px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  z-index: 1;
}

.g-header__nav-search-form-btn::after {
  content: "";
  display: block;
  background: rgba(52, 52, 52, 0.1);
  border-radius: 50%;
  transform: scale(0);
  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.g-header__nav-search-form-icon {
  font-size: 20px;
  font-size: 2rem;
  color: #707070;
}

.g-header__nav-search-form-input {
  font-size: 16px;
  font-size: 1.6rem;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  width: 480px;
  padding: 12px 70px 12px 16px;
  border: 1px solid #707070;
}

.g-header__nav-search-form-input:not(:placeholder-shown) {
  border-color: #cb4e4e;
}

.g-header__nav-search-form-input:not(:placeholder-shown) + .g-header__nav-search-form-btn::after {
  transform: scale(1);
}

.g-header__nav-search-btn {
  color: #fff;
  background: #cccccc;
  width: 240px;
  border: 0;
  margin: 0 0 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media screen and (max-width: 1330px) {
  .g-header__nav-search-btn {
    display: none;
  }
}

.g-header__nav-search-btn-txt {
  font-size: 16px;
  font-size: 1.6rem;
  letter-spacing: 2px;
  letter-spacing: 0.2rem;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-weight: 900;
  margin: 0 20px;
  position: relative;
  z-index: 1;
}

.g-header__nav-search-btn-icon {
  font-size: 20px;
  font-size: 2rem;
  position: relative;
  z-index: 1;
}

.g-header__nav-search-btn-mobile {
  color: #000;
  background: #fff;
  width: 100%;
  padding: 24px 0;
  border: 0;
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media screen and (max-width: 1330px) {
  .g-header__nav-search-btn-mobile {
    display: flex;
  }
}

@media screen and (max-width: 767px) {
  .g-header__nav-search-btn-mobile {
    padding: 15px 0;
  }
}

.g-header__nav-search-btn-mobile-txt {
  font-size: 16px;
  font-size: 1.6rem;
  letter-spacing: 2px;
  letter-spacing: 0.2rem;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-weight: 900;
  margin: 0 20px;
}

.g-header__nav-search-btn-mobile-icon {
  font-size: 18px;
  font-size: 1.8rem;
}

/*----------------------------------------
g-menu
----------------------------------------*/
body[data-menu="active"] {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
}

body[data-menu="active"] .g-menu {
  pointer-events: auto;
}

body[data-menu="active"] .g-menu__header {
  opacity: 1;
}

body[data-menu="active"] .g-menu__container {
  overflow-y: auto;
  opacity: 1;
}

body[data-search="active"] {
  overflow: hidden;
  height: 100%;
}

.g-menu {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  pointer-events: none;
}

@media screen and (max-width: 1330px) {
  .g-menu {
    display: block;
  }
}

.g-menu__header {
  background: #343434;
  box-shadow: 0 -1px 0 #343434 inset;
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  transition: opacity cubic-bezier(0.19, 1, 0.22, 1) 0.3s 0.1s;
}

.g-menu__header__burger {
  width: 160px;
  height: 100px;
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: 0;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .g-menu__header__burger {
    width: 120px;
  }
}

@media screen and (max-width: 767px) {
  .g-menu__header__burger {
    height: 60px;
    width: 60px;
  }
}

.g-menu__header__burger-line {
  background: #fff;
  width: 40px;
  height: 3px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  transform-origin: center;
}

@media screen and (max-width: 767px) {
  .g-menu__header__burger-line {
    width: 25px;
    height: 2px;
  }
}

.g-menu__header__burger-line:first-child {
  transform: translate(-50%, -50%) rotate(45deg);
}

.g-menu__header__burger-line:last-child {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.g-menu__container {
  height: calc(100% - 70px);
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  color: #fff;
  background: #343434;
  position: relative;
  z-index: 1;
  opacity: 0;
  transition: opacity cubic-bezier(0.19, 1, 0.22, 1) 0.3s 0.1s;
}

@media screen and (max-width: 767px) {
  .g-menu__container {
    height: calc(100% - 58px);
  }
}

.g-menu__account-list-item {
  border-bottom: 1px solid #343434;
}

.g-menu__account-list-item-link {
  color: #fff;
  background: #cccccc;
  padding: 15px 20px;
  display: flex;
  align-items: center;
}

.g-menu__account-list-item-link-icon {
  font-size: 24px;
  font-size: 2.4rem;
  width: 28px;
  margin: 0 20px 0 0;
}

.g-menu__account-list-item-link-img {
  fill: #fff;
  width: 30px;
  height: 30px;
  margin: 0 20px 0 0;
  vertical-align: middle;
  position: relative;
  z-index: 1;
}

.g-menu__account-list-item-link-txt {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 900;
}

.g-menu .c-nav__categories {
  width: 100%;
  display: block;
}

.g-menu .c-nav__categories-ttl {
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 900;
  text-align: left;
  padding: 20px;
  border-bottom: 1px solid #fff;
  width: 100%;
  display: none;
}

@media screen and (max-width: 767px) {
  .g-menu .c-nav__categories-ttl {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.g-menu .c-nav__categories-child {
  width: 100%;
  text-align: left;
  padding: 0;
}

.g-menu .c-nav__categories-child-ttl {
  color: #fff;
  font-weight: 900;
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.g-menu .c-nav__categories-child-ttl::before, .g-menu .c-nav__categories-child-ttl::after {
  width: 14px;
  height: 2px;
  background: #fff;
  position: absolute;
  top: 50%;
  right: 20px;
  margin: -1px 0 0;
}

.g-menu .c-nav__categories-child-ttl::after {
  transform: rotate(90deg);
  transition: transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.g-menu .c-nav__categories-child-ttl.is-active::after {
  transform: rotate(0deg);
}

.g-menu .c-nav__categories-child-ttl.js-accrodion-btn::before, .g-menu .c-nav__categories-child-ttl.js-accrodion-btn::after {
  content: "";
  display: block;
}

.g-menu .c-nav__categories-child-ttl.js-accrodion-btn .c-nav__categories-child-ttl-link {
  pointer-events: none;
}

.g-menu .c-nav__categories-child-ttl-link {
  color: #fff;
  display: block;
  padding: 20px;
}

@media screen and (max-width: 767px) {
  .g-menu .c-nav__categories-child-ttl-link {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.g-menu .c-nav__categories-list {
  display: none;
  background: #fff;
  border-bottom: 1px solid #343434;
  padding: 10px 0;
}

.g-menu .c-nav__categories-list-item-link {
  color: #000;
  padding: 10px 20px;
  display: block;
}

@media screen and (max-width: 767px) {
  .g-menu .c-nav__categories-list-item-link {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.g-menu .c-nav__categories-list-item-link::before {
  content: "";
  width: 10px;
  height: 1px;
  background: #000;
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px 0 0;
}

@media screen and (max-width: 767px) {
  .g-menu .c-nav__categories-list-item-link::before {
    width: 8px;
    margin: 0 8px 0 0;
  }
}

.g-menu .c-nav__pages {
  width: 100%;
}

.g-menu .c-nav__pages-list-item-link {
  font-size: 18px;
  font-size: 1.8rem;
  color: #fff;
  padding: 20px;
  display: block;
  text-align: left;
  border-bottom: 1px solid #fff;
}

@media screen and (max-width: 767px) {
  .g-menu .c-nav__pages-list-item-link {
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: 900;
  }
}

.g-side__menu {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 100;
  animation: sidemenu_reverse 1s ease forwards;
}

[data-menu="active"] .g-side__menu {
  animation: sidemenu 1s ease forwards;
}

@media screen and (max-width: 1024px) {
  .g-side__menu {
    left: 0;
    animation: none;
    pointer-events: none;
  }
}

.g-side__menu-list {
  background: #000;
}

@media screen and (max-width: 1024px) {
  .g-side__menu-list {
    display: flex;
    opacity: 0;
    transform: translateY(10%);
    transition: transform .6s ease, opacity .6s ease;
    pointer-events: none;
    justify-content: space-around;
  }
}

@media screen and (max-width: 1024px) {
  .g-side__menu-list.is-reverse {
    transform: translateY(0);
    opacity: 1;
    pointer-events: initial;
  }
}

.g-side__menu-list-item-link {
  padding: 20px;
  display: block;
  line-height: 0;
  position: relative;
}

@media screen and (min-width: 1199px) {
  .g-side__menu-list-item-link:hover .g-side__menu-list-item-link-img {
    transform: scale(1.2);
  }
}

@media screen and (max-width: 1330px) {
  .g-side__menu-list-item-link {
    padding: 15px;
  }
}

.g-side__menu-list-item-link-img {
  width: 22px;
  height: 22px;
  transition: transform .2s ease;
  transform: scale(1);
}

.g-side__menu-list-item-link-login {
  width: 25px;
  height: 25px;
}

.g-side__menu-list-item-link-login-g {
  fill: none;
  stroke: #fff;
}

.g-side__menu-list-item-link-login-rect-01 {
  stroke: none;
}

.g-side__menu-list-item-link-login-rect-02 {
  fill: none;
}

.g-side__menu-list-item-link-login-path {
  fill: #fff;
  stroke: #000;
}

.g-side__menu-list-item-link-search-g {
  fill: none;
  stroke: #fff;
}

.g-side__menu-list-item-link-search-line {
  fill: none;
  stroke: #fff;
}

.g-side__menu-list-item-link-search-circle-01 {
  stroke: none;
}

.g-side__menu-list-item-link-search-circle-02 {
  fill: none;
}

.g-side__menu-list-item-link-news-path {
  fill: none;
  stroke: #fff;
}

.g-side__menu-list-item-link-wish-path {
  fill: #fff;
}

.g-side__menu-list-item-link-cart {
  width: 25px;
  height: 25px;
}

.g-side__menu-list-item-link-cart-path {
  fill: #fff;
  stroke: #000;
}

.g-side__menu-count {
  position: absolute;
  top: 16px;
  right: 16px;
  border-radius: 100%;
  background: #cb4e4e;
  color: #fff;
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1330px) {
  .g-side__menu-count {
    top: 11px;
    right: 11px;
  }
}

@keyframes sidemenu {
  0% {
    transform: translateX(0);
  }
  30% {
    transform: translateX(100%);
  }
  40% {
    transform: translateX(100%) translateY(65px);
  }
  100% {
    opacity: 1;
    transform: translateX(0) translateY(65px);
  }
}

@keyframes sidemenu_reverse {
  0% {
    transform: translateX(0) translateY(65px);
  }
  30% {
    transform: translateX(100%) translateY(65px);
  }
  40% {
    transform: translateX(100%) translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateX(0) translateY(0);
  }
}

@media screen and (max-width: 1330px) {
  @keyframes sidemenu {
    0% {
      transform: translateX(0);
    }
    30% {
      transform: translateX(100%);
    }
    40% {
      transform: translateX(100%) translateY(55px);
    }
    100% {
      opacity: 1;
      transform: translateX(0) translateY(55px);
    }
  }
  @keyframes sidemenu_reverse {
    0% {
      transform: translateX(0) translateY(55px);
    }
    30% {
      transform: translateX(100%) translateY(55px);
    }
    40% {
      transform: translateX(100%) translateY(0);
    }
    100% {
      opacity: 1;
      transform: translateX(0) translateY(0);
    }
  }
}

/*----------------------------------------
g-pagetop
----------------------------------------*/
.g-pagetop {
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 10;
  pointer-events: none;
  transition: opacity .2s ease, transform .2s ease;
  opacity: 0;
  transform: translateY(2px);
}

@media screen and (min-width: 1199px) {
  .g-pagetop:hover {
    animation: buttonPop .4s ease forwards;
  }
}

@media screen and (max-width: 767px) {
  .g-pagetop {
    right: 16px;
  }
}

.g-pagetop.is-active {
  pointer-events: initial;
  opacity: 1;
  transform: translateY(0);
}

.g-pagetop-btn {
  width: 80px;
  height: 80px;
  background: #c4c8cf;
  outline: none;
  box-shadow: none;
  border: 0;
  cursor: pointer;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color .5s ease;
}

@media screen and (max-width: 767px) {
  .g-pagetop-btn {
    width: 36px;
    height: 36px;
  }
}

@media screen and (min-width: 1199px) {
  .g-pagetop:hover .g-pagetop-btn {
    background: #38475e;
  }
}

.g-pagetop-btn::before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 12px 12px 12px;
  border-color: transparent transparent #fff transparent;
}

@media screen and (max-width: 767px) {
  .g-pagetop-btn::before {
    border-width: 0 6px 6px 6px;
  }
}

@keyframes buttonPop {
  0% {
    transform: scale(0.8);
  }
  30% {
    transform: scale(1.1);
  }
  60% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

/*----------------------------------------
g-search
----------------------------------------*/
body[data-search="active"] .g-search {
  pointer-events: auto;
  background: rgba(0, 0, 0, 0.7);
}

body[data-search="active"] .g-search__container {
  overflow-y: auto;
  opacity: 1;
  transform: translateY(0);
}

body[data-search="active"] .g-search__form-submit-btn {
  display: inline-block;
}

.g-search {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  pointer-events: none;
  transition: background cubic-bezier(0.19, 1, 0.22, 1) 0.3s;
}

.g-search__container {
  height: 100%;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  position: relative;
  z-index: 1;
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity cubic-bezier(0.19, 1, 0.22, 1) 0.3s, transform cubic-bezier(0.19, 1, 0.22, 1) 0.3s;
  padding: 80px 0;
}

@media screen and (max-width: 767px) {
  .g-search__container {
    padding: 0;
  }
}

.g-search__burger {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  background: none;
  border: 0;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .g-search__burger {
    width: 60px;
    height: 60px;
  }
}

.g-search__burger-line {
  background: #666;
  width: 30px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-15px);
  border-radius: 10px;
  transform-origin: center;
}

.g-search__burger-line:first-child {
  transform: translate(-50%, -50%) rotate(45deg);
}

.g-search__burger-line:last-child {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.g-search__enabled {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.g-search__inner {
  background: #fff;
  max-width: 1280px;
  margin: 0 auto;
  padding: 70px 80px;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .g-search__inner {
    padding: 40px 20px;
  }
}

.g-search__form-txt {
  position: relative;
  padding: 10px 0;
  margin: 0 0 20px;
}

@media screen and (max-width: 767px) {
  .g-search__form-txt {
    margin: 0;
  }
}

.g-search__form-txt-icon {
  color: #666;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.g-search__form .g-search__form-txt-icon + input[type="text"] {
  padding: 15px 60px 15px 20px;
}

@media screen and (max-width: 767px) {
  .g-search__form .g-search__form-txt-icon + input[type="text"] {
    padding: 10px 45px 10px 15px;
  }
}

.g-search__form input[type="text"] {
  font-size: 16px;
  font-size: 1.6rem;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  width: 100%;
  border: 1px solid #666;
  padding: 15px 20px;
}

@media screen and (max-width: 767px) {
  .g-search__form input[type="text"] {
    padding: 10px 15px;
  }
}

.g-search__form input[type="text"]:not(:placeholder-shown) {
  border-color: #0086d3;
}

.g-search__form select {
  font-size: 16px;
  font-size: 1.6rem;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  width: 100%;
  border: 1px solid #666;
  background: #fff;
  padding: 15px 40px 15px 20px;
}

@media screen and (max-width: 767px) {
  .g-search__form select {
    padding: 10px 40px 10px 15px;
  }
}

.g-search__form select:disabled {
  background: #f5f5f5;
  opacity: .3;
}

.g-search__form-select {
  position: relative;
}

.g-search__form-select::after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border: solid #666;
  border-width: 0 1px 1px 0;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-75%) rotate(45deg);
  pointer-events: none;
}

.g-search__form-checkbox, .g-search__form-radio {
  display: block;
}

.g-search__form-checkbox input, .g-search__form-radio input {
  display: none;
}

.g-search__form-checkbox input + span, .g-search__form-radio input + span {
  font-size: 16px;
  font-size: 1.6rem;
  position: relative;
  display: inline-flex;
  align-items: center;
  text-align: left;
  margin: 0 40px 10px 0;
  cursor: pointer;
}

.g-search__form-checkbox input + span::before, .g-search__form-radio input + span::before {
  content: "";
  display: inline-block;
  border: 1px solid #666;
  background: #fff;
  width: 30px;
  height: 30px;
  vertical-align: middle;
  margin: 0 10px 0 0;
  transition: background 0.3s cubic-bezier(0.19, 1, 0.22, 1), border 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.g-search__form-checkbox input + span::after, .g-search__form-radio input + span::after {
  content: "";
  display: inline-block;
  width: 7px;
  height: 14px;
  position: absolute;
  top: 6px;
  left: 11px;
  transition: opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.g-search__form-checkbox input:checked + span::before, .g-search__form-radio input:checked + span::before {
  border-color: #cb4e4e;
  background: #cb4e4e;
}

.g-search__form-checkbox input:checked + span::after, .g-search__form-radio input:checked + span::after {
  opacity: 1;
}

.g-search__form-checkbox input[type="radio"] + span::before, .g-search__form-radio input[type="radio"] + span::before {
  border-radius: 50%;
}

.g-search__form-checkbox input[type="checkbox"] + span::before, .g-search__form-radio input[type="checkbox"] + span::before {
  border-radius: 1px;
}

.g-search__form-submit {
  text-align: center;
  padding: 40px 0;
}

@media screen and (max-width: 767px) {
  .g-search__form-submit {
    padding: 20px 0;
  }
}

.g-search__form-submit-btn {
  width: 320px;
  max-width: 90%;
  padding: 20px 0;
  color: #fff;
  background: #000;
  border: 0;
  box-shadow: none;
  position: relative;
  display: none;
  cursor: pointer;
  transition: background-color .2s ease;
}

@media screen and (min-width: 1199px) {
  .g-search__form-submit-btn:hover {
    background: #555555;
  }
}

@media screen and (max-width: 767px) {
  .g-search__form-submit-btn {
    width: 248px;
    padding: 12px 0;
  }
}

.g-search__form-submit-btn:disabled {
  opacity: .5;
  cursor: auto;
}

.g-search__form-submit-btn:disabled::before {
  display: none;
}

.g-search__form-submit-btn-txt {
  font-size: 16px;
  font-size: 1.6rem;
  letter-spacing: 1.2px;
  letter-spacing: 0.12rem;
  text-indent: .12rem;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  padding: 0 20px;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .g-search__form-submit-btn-txt {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.g-search__form-submit-svg {
  position: absolute;
  height: 25px;
  width: 30px;
  fill: #fff;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

@media screen and (max-width: 767px) {
  .g-search__form-submit-svg {
    width: 20px;
    height: 15px;
  }
}

.g-search__form-submit-svg-path {
  fill-rule: evenodd;
}

.g-search__form-submit-svg-circle {
  fill: none;
  stroke: #fff;
  stroke-linejoin: round;
  stroke-width: 4px;
}

.g-search__form-ttl {
  margin: 30px 0 10px;
  position: relative;
  display: none;
}

@media screen and (max-width: 767px) {
  .g-search__form-ttl {
    display: block;
  }
}

.g-search__form-ttl::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: -20px;
  left: -20px;
  background: #ddd;
  height: 1px;
}

.g-search__form-ttl-txt {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 900;
  position: relative;
  z-index: 1;
  background: #fff;
  padding: 0 20px;
}

.g-search__form-list {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0 0;
}

@media screen and (max-width: 767px) {
  .g-search__form-list {
    padding: 0;
  }
}

.g-search__form-hyphen {
  padding: 0 10px;
}

.g-search__form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 0;
}

@media screen and (max-width: 767px) {
  .g-search__form-row {
    padding: 0;
  }
}

.g-search__form-cell {
  width: 48%;
  padding: 20px 0;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .g-search__form-cell {
    width: 100%;
    padding: 10px 0;
  }
}

.g-search__form-cell-label {
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 900;
  display: block;
  margin: 0 0 10px;
}

@media screen and (max-width: 767px) {
  .g-search__form-cell-label {
    font-size: 14px;
    font-size: 1.4rem;
    margin: 0 0 5px;
  }
}

.g-search__form-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.g-search__form-price input[type="text"] {
  width: calc(50% - 17px);
}

.g-search__form-svg {
  position: absolute;
  height: 25px;
  width: 30px;
  fill: #666;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

@media screen and (max-width: 767px) {
  .g-search__form-svg {
    width: 20px;
    height: 15px;
    right: 10px;
  }
}

.g-search__form-svg-path {
  fill-rule: evenodd;
}

.g-search__form-svg-circle {
  fill: none;
  stroke: #666;
  stroke-linejoin: round;
  stroke-width: 4px;
}

/*----------------------------------------
g-select
----------------------------------------*/
body[data-select="active"] {
  overflow: hidden;
  height: 100%;
}

body[data-select="active"] .g-select {
  pointer-events: auto;
  overflow-y: auto;
}

body[data-select="active"] .g-select__enabled {
  opacity: 1;
}

body[data-select="active"] .g-select__container {
  opacity: 1;
}

.g-select {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
  pointer-events: none;
  -webkit-overflow-scrolling: touch;
}

@media screen and (max-width: 1330px) {
  .g-select {
    display: block;
  }
}

.g-select::before {
  content: "";
  display: block;
  padding: 151px 0 0;
}

@media screen and (max-width: 1330px) {
  .g-select::before {
    padding: 143px 0 0;
  }
}

@media screen and (max-width: 767px) {
  .g-select::before {
    padding: 114px 0 0;
  }
}

.g-select__container {
  position: relative;
  z-index: 1;
  opacity: 0;
  transition: opacity cubic-bezier(0.19, 1, 0.22, 1) 0.3s 0.1s;
}

.g-select__enabled {
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  opacity: 0;
  transition: opacity cubic-bezier(0.19, 1, 0.22, 1) 0.3s 0.1s;
}

.g-select__category {
  position: relative;
  z-index: 1;
}

.g-select__category-list-item {
  border-bottom: 1px solid #cccccc;
}

.g-select__category-list-item-link {
  color: #000;
  background: #fff;
  padding: 15px 20px;
  text-align: center;
  display: block;
}

.g-select__category-list-item-link.is-current {
  color: #fff;
  background: #cb4e4e;
}

.g-select__category-list-item-link-txt {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 900;
}

/*----------------------------------------
c-blog-categories
----------------------------------------*/
.c-blog-categories {
  margin: 0 0 30px;
  position: relative;
  z-index: 2;
  text-align: left;
  background: #fff;
}

@media screen and (max-width: 1024px) {
  .c-blog-categories {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .c-blog-categories {
    margin: 0 0 15px;
  }
}

.c-blog-categories__list {
  display: inline-flex;
  overflow-x: auto;
  overflow-y: hidden;
  max-width: 100%;
  margin: 0 -5px;
}

@media screen and (max-width: 1024px) {
  .c-blog-categories__list {
    width: 100%;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 767px) {
  .c-blog-categories__list {
    margin: 0 -3px;
  }
}

.c-blog-categories__list-item {
  position: relative;
  padding: 5px;
}

@media screen and (max-width: 767px) {
  .c-blog-categories__list-item {
    padding: 3px;
  }
}

.c-blog-categories__list-item-link {
  display: block;
  padding: 10px 30px;
  color: #38475e;
  border: 1px solid #38475e;
  transition: color .2s ease, background-color .2s ease;
}

@media screen and (max-width: 767px) {
  .c-blog-categories__list-item-link {
    padding: 6px 20px;
  }
}

.c-blog-categories__list-item-link.is-current {
  color: #fff;
  background: #38475e;
}

@media screen and (min-width: 1199px) {
  .c-blog-categories__list-item-link:hover {
    color: #fff;
    background: #38475e;
  }
}

@media screen and (max-width: 767px) {
  .c-blog-categories__list-item-link-txt {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

/*----------------------------------------
c-breadcrumbs
----------------------------------------*/
.c-breadcrumbs {
  padding: 0 160px;
}

@media screen and (max-width: 1024px) {
  .c-breadcrumbs {
    padding: 0 80px;
  }
}

@media screen and (max-width: 767px) {
  .c-breadcrumbs {
    padding: 0 16px;
  }
}

.c-breadcrumbs__list {
  text-align: left;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: auto;
  margin: 0 -8px;
}

@media screen and (max-width: 767px) {
  .c-breadcrumbs__list {
    margin: 0 -4px;
  }
}

.c-breadcrumbs__list-item {
  padding: 10px 4px;
  display: inline-block;
  position: relative;
}

@media screen and (max-width: 767px) {
  .c-breadcrumbs__list-item {
    padding: 8px 0;
  }
}

.c-breadcrumbs__list-item:last-child::after {
  display: none;
}

.c-breadcrumbs__list-item::after {
  content: "/";
  display: block;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateX(-25%) translateY(-50%);
}

.c-breadcrumbs__list-item-body {
  font-size: 14px;
  font-size: 1.4rem;
  padding: 8px 4px;
  display: block;
  transition: color .2s ease;
}

@media screen and (max-width: 767px) {
  .c-breadcrumbs__list-item-body {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.c-breadcrumbs__list-item-body:hover {
  color: #0086d3;
}

.c-breadcrumbs__list-item-body.is-link-none {
  color: #333;
  cursor: default;
}

.c-breadcrumbs__list-item-body.is-link-none:hover {
  color: #333;
}

.c-breadcrumbs__list-item-body.is-link-none::after {
  content: none;
}

/*----------------------------------------
c-btn-choices
----------------------------------------*/
.c-btn-choices {
  font-size: 0;
  text-align: center;
  padding: 20px 0;
}

@media screen and (max-width: 767px) {
  .c-btn-choices {
    padding: 0;
  }
}

.c-btn-choices__body {
  display: inline-block;
  width: 320px;
  max-width: 100%;
  padding: 20px 0;
  margin: 0 20px;
  color: #fff;
  background: #555555;
  border: 0;
  box-shadow: none;
  position: relative;
  cursor: pointer;
  transition: background-color .2s ease;
}

@media screen and (min-width: 1199px) {
  .c-btn-choices__body:hover {
    background-color: #343434;
  }
}

@media screen and (max-width: 1024px) {
  .c-btn-choices__body {
    width: 100%;
    margin: 10px 0;
  }
}

.c-btn-choices__body.is-primary {
  background: #cb4e4e;
}

@media screen and (min-width: 1199px) {
  .c-btn-choices__body.is-primary:hover {
    background-color: #751313;
  }
}

.c-btn-choices__body-txt {
  font-size: 18px;
  font-size: 1.8rem;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  padding: 0 20px;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .c-btn-choices__body-txt {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

/*----------------------------------------
c-btn
----------------------------------------*/
.c-btn.c-item__btn {
  margin-top: 50px;
}

.c-section.is-contact .c-btn {
  margin-top: 50px;
}

@media screen and (max-width: 767px) {
  .c-section.is-contact .c-btn {
    margin-top: 30px;
  }
}

.c-btn__body {
  font-size: 15px;
  font-size: 1.5rem;
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
  text-indent: .1rem;
  padding: 18px 30px 15px;
  min-width: 240px;
  text-align: center;
  font-family: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  position: relative;
  line-height: 1.5;
  display: inline-block;
  color: #38475e;
  border: 1px solid #38475e;
  background-color: transparent;
  cursor: pointer;
  transition: background-color .2s ease, color .2s ease;
}

@media screen and (min-width: 1199px) {
  .c-btn__body:hover {
    background: #38475e;
    color: #fff;
  }
  .c-btn__body:hover::before {
    border-color: transparent #fff transparent transparent;
  }
}

.c-section.is-contact .c-btn__body {
  font-weight: 900;
}

@media screen and (max-width: 767px) {
  .c-btn__body {
    font-size: 13px;
    font-size: 1.3rem;
    padding: 10px 16px 8px;
    min-width: 140px;
  }
}

.c-btn__body::before {
  content: '';
  position: absolute;
  bottom: 7px;
  right: 7px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 0;
  border-color: transparent #38475e transparent transparent;
  transition: border-color .2s ease;
}

@media screen and (max-width: 767px) {
  .c-btn__body::before {
    bottom: 5px;
    right: 5px;
  }
}

.c-slider-nav-next {
  width: 60px;
  height: 60px;
  background: #333;
  cursor: pointer;
  position: relative;
  border-left: 1px solid #c4c8cf;
}

@media screen and (min-width: 1199px) {
  .c-slider-nav-next:hover::before {
    transform: translateY(-50%) translateX(-75%) rotate(135deg) scale(1.2);
  }
}

.c-slider-nav-next::before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  border: solid #fff;
  border-width: 1px 0 0 1px;
  transform: translateY(-50%) translateX(-75%) rotate(135deg) scale(1);
  transform-origin: center center;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: transform .2s ease;
}

@media screen and (max-width: 767px) {
  .c-slider-nav-next::before {
    width: 10px;
    height: 10px;
  }
}

.c-slider-nav-prev {
  width: 60px;
  height: 60px;
  background: #333;
  cursor: pointer;
  position: relative;
}

@media screen and (min-width: 1199px) {
  .c-slider-nav-prev:hover::before {
    transform: translateY(-50%) translateX(-25%) rotate(315deg) scale(1.2);
  }
}

.c-slider-nav-prev::before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  border: solid #fff;
  border-width: 1px 0 0 1px;
  transform: translateY(-50%) translateX(-25%) rotate(315deg) scale(1);
  transform-origin: center center;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: transform .2s ease;
}

@media screen and (max-width: 767px) {
  .c-slider-nav-prev::before {
    width: 10px;
    height: 10px;
  }
}

/*----------------------------------------
c-cart-btn
----------------------------------------*/
.c-cart-btn.is-small .c-cart-btn__body {
  padding: 10px 0;
}

.c-cart-btn.is-small .c-cart-btn__body-icon {
  font-size: 18px;
  font-size: 1.8rem;
}

@media screen and (max-width: 767px) {
  .c-cart-btn.is-small .c-cart-btn__body-icon {
    font-size: 14px;
    font-size: 1.4rem;
    margin: 0 10px 0 0;
  }
}

.c-cart-btn.is-small .c-cart-btn__body-txt {
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 767px) {
  .c-cart-btn.is-small .c-cart-btn__body-txt {
    letter-spacing: 1px;
    letter-spacing: 0.1rem;
  }
}

@media screen and (max-width: 374px) {
  .c-cart-btn.is-small .c-cart-btn__body-txt {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.c-cart-btn__body {
  width: 100%;
  border: 0;
  outline: none;
  color: #fff;
  background: #cb4e4e;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  position: relative;
  cursor: pointer;
}

.c-cart-btn__body::before {
  background: #751313;
}

.c-cart-btn__body.is-sold-out {
  background: #777;
  pointer-events: none;
}

.c-cart-btn__body.is-sold-out:hover {
  background: #777;
}

.c-cart-btn__body-icon {
  font-size: 28px;
  font-size: 2.8rem;
  margin: 0 20px 0 0;
  position: relative;
  z-index: 1;
}

.c-cart-btn__body-txt {
  font-size: 20px;
  font-size: 2rem;
  letter-spacing: 2px;
  letter-spacing: 0.2rem;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-weight: 900;
  position: relative;
  z-index: 1;
}

/*----------------------------------------
c-cart-price
----------------------------------------*/
.c-cart-price {
  background: #f9f9f9;
  padding: 30px 20px;
}

.c-cart-price__detail {
  width: 100%;
  display: table;
}

.c-cart-price__detail-row {
  display: table-row;
}

.c-cart-price__detail-cell-ttl, .c-cart-price__detail-cell-desc {
  display: table-cell;
  padding: 5px;
}

.c-cart-price__detail-cell-ttl {
  font-weight: 900;
  text-align: left;
}

.c-cart-price__detail-cell-desc {
  text-align: right;
}

.c-cart-price__all {
  margin: 20px 0;
  padding: 20px 0;
  border-top: 1px solid #707070;
}

.c-cart-price__all-ttl, .c-cart-price__all-desc {
  display: inline-block;
  padding: 5px;
}

/*----------------------------------------
c-category-header
----------------------------------------*/
.c-category-header {
  color: #343434;
  margin: 0 0 26px;
}

@media screen and (max-width: 1024px) {
  .c-category-header {
    padding: 10px 0 0;
  }
}

@media screen and (max-width: 767px) {
  .c-category-header {
    padding: 0;
    margin: 0 0 20px;
  }
}

.c-category-header__form {
  display: table;
  width: 100%;
}

.c-category-header__row {
  width: 100%;
  display: table-row;
}

.c-category-header__cell {
  display: table-cell;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .c-category-header__category {
    max-height: 72px;
    overflow: hidden;
  }
}

.c-category-header__category.is-active {
  max-height: 100%;
}

.c-category-header__category-item {
  background: #f9f9f9;
  display: inline-block;
  padding: 4px 16px;
  margin: 0 20px 10px 0;
  border-radius: 50px;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .c-category-header__category-item {
    margin: 0 5px 10px 0;
  }
}

.c-category-header__category-item:hover {
  background: #eee;
}

.c-category-header__category-item-txt {
  font-size: 14px;
  font-size: 1.4rem;
}

.c-category-header__category-item-icon {
  color: #333;
}

/*----------------------------------------
c-data-table
----------------------------------------*/
.c-data-table {
  text-align: justify;
}

.c-data-table__row {
  text-align: justify;
}

.c-data-table__ttl {
  font-size: 18px;
  font-size: 1.8rem;
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
  padding: 0 5px 10px;
  position: relative;
  border-bottom: 1px solid #38475e;
}

@media screen and (max-width: 767px) {
  .c-data-table__ttl {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.c-data-table__desc {
  line-height: 1.8;
  padding: 10px 10px 30px;
}

@media screen and (max-width: 767px) {
  .c-data-table__desc {
    padding: 10px 5px 20px;
  }
}

.c-data-table__desc a:not([class]) {
  text-decoration: underline;
  color: #4C6FA5;
}

.c-data-table__desc a:not([class]):hover {
  color: #cb4e4e;
}

/*----------------------------------------
c-d-list
----------------------------------------*/
.c-d-list {
  text-align: justify;
  margin: -25px 0;
}

@media screen and (max-width: 767px) {
  .c-d-list {
    margin: -15px 0;
  }
}

.c-d-list__row {
  padding: 25px 10px;
}

@media screen and (max-width: 767px) {
  .c-d-list__row {
    padding: 15px 5px;
  }
}

.c-d-list__ttl {
  font-weight: 900;
  padding: 10px 0;
}

@media screen and (max-width: 767px) {
  .c-d-list__ttl {
    padding: 5px 0;
  }
}

.c-d-list__desc {
  line-height: 1.8;
  padding: 10px 0;
}

@media screen and (max-width: 767px) {
  .c-d-list__desc {
    padding: 5px 0;
  }
}

/*----------------------------------------
c-detail-data
----------------------------------------*/
.c-detail-data {
  text-align: left;
  border-top: 1px solid #38475e;
  margin-top: 80px;
}

@media screen and (max-width: 767px) {
  .c-detail-data {
    margin-top: 25px;
  }
}

.c-detail-data__ttl {
  font-size: 18px;
  font-size: 1.8rem;
  letter-spacing: 2px;
  letter-spacing: 0.2rem;
  padding: 20px 40px 20px 0;
  position: relative;
  font-family: "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  border-bottom: 1px solid #38475e;
}

@media screen and (max-width: 767px) {
  .c-detail-data__ttl {
    font-size: 13px;
    font-size: 1.3rem;
    padding: 20px 30px 20px 0;
  }
}

.c-detail-data__ttl::before, .c-detail-data__ttl::after {
  background: #343434;
  width: 16px;
  height: 1px;
  position: absolute;
  top: 50%;
  right: 10px;
  transition: transform 0.3s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

@media screen and (max-width: 767px) {
  .c-detail-data__ttl::before, .c-detail-data__ttl::after {
    width: 15px;
    right: 5px;
  }
}

.c-detail-data__ttl::after {
  transform: translateY(0) rotate(90deg);
  opacity: 1;
}

.c-detail-data__ttl.is-active::after {
  transform: translateY(10px) rotate(90deg);
  opacity: 0;
}

.c-detail-data__ttl.js-accrodion-btn {
  cursor: pointer;
}

.c-detail-data__ttl.js-accrodion-btn::before, .c-detail-data__ttl.js-accrodion-btn::after {
  content: "";
  display: block;
}

.c-detail-data__ttl-container::before {
  content: '';
  bottom: -1px;
  left: 0;
  width: 180px;
  height: 1px;
  background: #000;
  position: absolute;
}

.c-detail-data__desc {
  margin: 20px 0 0;
}

.c-detail-data__row:not(:last-child) .c-detail-data__desc {
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .c-detail-data__row:not(:last-child) .c-detail-data__desc {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .c-detail-data__desc {
    margin-top: 10px;
  }
}

.c-detail-data__desc table {
  width: 100%;
  table-layout: fixed;
}

.c-detail-data__desc p:not([class]) {
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
  text-align: justify;
}

/*----------------------------------------
c-detail-header
----------------------------------------*/
.c-detail-header {
  text-align: left;
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

@media screen and (max-width: 1024px) {
  .c-detail-header {
    padding: 20px 0 0;
  }
}

.c-detail-header[data-status="fixed"] {
  position: fixed;
}

@media screen and (max-width: 1024px) {
  .c-detail-header[data-status="fixed"] {
    position: static;
  }
}

.c-detail-header[data-status="fadeout"] {
  pointer-events: none;
  position: fixed;
  opacity: 0;
}

@media screen and (max-width: 1024px) {
  .c-detail-header[data-status="fadeout"] {
    pointer-events: auto;
    position: static;
    opacity: 1;
  }
}

.c-detail-header__ttl {
  font-size: 22px;
  font-size: 2.2rem;
  margin: 0 0 10px;
}

@media screen and (max-width: 1024px) {
  .c-detail-header__ttl {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.c-detail-header__price {
  font-size: 28px;
  font-size: 2.8rem;
  letter-spacing: 2px;
  letter-spacing: 0.2rem;
  margin: 0 0 10px;
  font-weight: 900;
}

@media screen and (max-width: 1024px) {
  .c-detail-header__price {
    font-size: 22px;
    font-size: 2.2rem;
  }
}

.c-detail-header__price.is-variation {
  font-size: 20px;
  font-size: 2rem;
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
  line-height: 1.2;
  padding: 8px 20px 10px;
  background: #f5f5f5;
}

@media screen and (max-width: 1024px) {
  .c-detail-header__price.is-variation {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.c-detail-header__price.is-variation small {
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 1024px) {
  .c-detail-header__price.is-variation small {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.c-detail-header__price.is-discount {
  font-size: 22px;
  font-size: 2.2rem;
  text-decoration: line-through;
  margin: 0;
}

@media screen and (max-width: 1024px) {
  .c-detail-header__price.is-discount {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.c-detail-header__price.is-discount small {
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 1024px) {
  .c-detail-header__price.is-discount small {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.c-detail-header__price.is-sale {
  color: #cb4e4e;
}

.c-detail-header__price + :not([class^="c-detail-header__price"]) {
  padding: 20px 0 0;
}

.c-detail-header__price small {
  font-size: 18px;
  font-size: 1.8rem;
}

@media screen and (max-width: 1024px) {
  .c-detail-header__price small {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.c-detail-header__price small:last-child {
  margin: 0 0 0 5px;
}

.c-detail-header__colors {
  margin: 0 0 10px;
}

@media screen and (max-width: 1024px) {
  .c-detail-header__colors {
    margin: 0;
  }
}

.c-detail-header__colors-ttl {
  font-weight: 900;
}

.c-detail-header__colors-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.c-detail-header__colors-list-item {
  padding: 10px;
}

.c-detail-header__colors-list-item-btn {
  display: block;
  border: 1px solid #ddd;
  background: none;
  outline: none;
  width: 42px;
  height: 42px;
  cursor: pointer;
}

.c-detail-header__colors-list-item-btn-radio {
  display: none;
}

.c-detail-header__colors-list-item-btn-radio:checked + .c-detail-header__colors-list-item-btn-color {
  border: 3px solid #cb4e4e;
  box-shadow: 0 0 0 4px #fff inset;
}

.c-detail-header__colors-list-item-btn-color {
  width: 100%;
  height: 100%;
  display: block;
  transition: border 0.3s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.c-detail-header__form {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px 40px;
}

@media screen and (max-width: 1024px) {
  .c-detail-header__form {
    margin: 0 -10px 20px;
  }
}

.c-detail-header__form .c-error {
  margin: 10px 0 0;
}

.c-detail-header__form-item {
  width: 50%;
  padding: 10px 20px;
}

@media screen and (max-width: 1024px) {
  .c-detail-header__form-item {
    padding: 10px;
  }
}

.c-detail-header__form-item-ttl {
  font-weight: 900;
  display: block;
  margin: 0 0 10px;
}

.c-detail-header__form input[type="number"] {
  font-size: 16px;
  font-size: 1.6rem;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  width: 100%;
  border: 1px solid #cccccc;
  padding: 10px 20px;
}

@media screen and (max-width: 767px) {
  .c-detail-header__form input[type="number"] {
    font-size: 14px;
    font-size: 1.4rem;
    padding: 10px 15px;
  }
}

.c-detail-header__form select {
  font-size: 16px;
  font-size: 1.6rem;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  width: 100%;
  border: 1px solid #cccccc;
  background: #fff;
  padding: 10px 40px 10px 20px;
}

@media screen and (max-width: 767px) {
  .c-detail-header__form select {
    padding: 10px 40px 10px 15px;
  }
}

.c-detail-header__form-select {
  position: relative;
}

.c-detail-header__form-select::after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border: solid #cccccc;
  border-width: 0 1px 1px 0;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-75%) rotate(45deg);
  pointer-events: none;
}

.c-detail-header .c-cart-btn {
  margin: 0 0 20px;
}

.c-detail-header__nav-item {
  padding: 10px 0;
}

.c-detail-header__nav-item-link {
  text-decoration: underline;
}

/*----------------------------------------
c-detail-images
----------------------------------------*/
.c-detail-images__outer {
  position: relative;
}

.c-detail-images__outer .c-favorite-btn {
  width: 68px;
  height: 68px;
  top: 20px;
  right: 20px;
}

@media screen and (max-width: 1024px) {
  .c-detail-images__outer .c-favorite-btn {
    width: 46px;
    height: 46px;
    top: 10px;
    right: 10px;
  }
}

.c-detail-images__outer .c-favorite-btn::before {
  border-width: 34px;
}

@media screen and (max-width: 767px) {
  .c-detail-images__outer .c-favorite-btn::before {
    border-width: 23px;
  }
}

.c-detail-images__outer .c-favorite-btn::after {
  font-size: 30px;
  font-size: 3rem;
}

@media screen and (max-width: 1024px) {
  .c-detail-images__outer .c-favorite-btn::after {
    font-size: 24px;
    font-size: 2.4rem;
  }
}

.c-detail-images__slide-link {
  background: no-repeat center;
  background-size: cover;
  display: block;
}

.c-detail-images__slide-link::after {
  content: "";
  display: block;
  padding: 120% 0 0;
}

.c-detail-images__thumbnails {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
  margin: 0 -10px;
}

@media screen and (max-width: 1024px) {
  .c-detail-images__thumbnails {
    display: block;
    padding: 6px;
    margin: 0;
    overflow-x: auto;
    white-space: nowrap;
  }
}

.c-detail-images__thumbnails-item {
  width: 20%;
  padding: 10px;
}

@media screen and (max-width: 1024px) {
  .c-detail-images__thumbnails-item {
    width: 100px;
    display: inline-block;
    white-space: normal;
    padding: 6px;
  }
}

.c-detail-images__thumbnails-item-btn {
  width: 100%;
  display: block;
  background: none;
  border: 0;
  outline: none;
  cursor: pointer;
}

.c-detail-images__thumbnails-item-btn.is-active .c-detail-images__thumbnails-item-figure-img {
  box-shadow: 0 0 0 3px #cb4e4e;
}

.c-detail-images__thumbnails-item-figure {
  position: relative;
}

.c-detail-images__thumbnails-item-figure-img {
  background: no-repeat center;
  background-size: cover;
  margin: 0 0 10px;
  transition: box-shadow 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.c-detail-images__thumbnails-item-figure-img::after {
  content: "";
  display: block;
  padding: 120% 0 0;
}

.c-detail-images__thumbnails-item-figure-src {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.c-detail-images__thumbnails-item-figure-caption {
  font-size: 14px;
  font-size: 1.4rem;
  text-align: left;
}

@media screen and (max-width: 1024px) {
  .c-detail-images__thumbnails-item-figure-caption {
    font-size: 10px;
    font-size: 1rem;
  }
}

.c-detail-images__controls {
  position: absolute;
  top: 50%;
  right: -50px;
  left: -50px;
  pointer-events: none;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  z-index: 10;
}

@media screen and (max-width: 1024px) {
  .c-detail-images__controls {
    right: 0;
    left: 0;
  }
}

.c-detail-images__controls-next, .c-detail-images__controls-prev {
  width: 50px;
  height: 50px;
  display: block;
  background: none;
  border: 0;
  outline: none;
  cursor: pointer;
  pointer-events: auto;
}

@media screen and (max-width: 1024px) {
  .c-detail-images__controls-next, .c-detail-images__controls-prev {
    background: rgba(255, 255, 255, 0.5);
    width: 40px;
    height: 80px;
  }
}

.c-detail-images__controls-next::after, .c-detail-images__controls-prev::after {
  content: "";
  display: inline-block;
  width: 30px;
  height: 30px;
  border: solid #707070;
  border-width: 2px 2px 0 0;
  transform: rotate(45deg);
}

@media screen and (max-width: 1024px) {
  .c-detail-images__controls-next::after, .c-detail-images__controls-prev::after {
    width: 18px;
    height: 18px;
    border-width: 1px 1px 0 0;
  }
}

@media screen and (max-width: 1024px) {
  .c-detail-images__controls-next {
    padding: 0 10px 0 0;
  }
}

@media screen and (max-width: 1024px) {
  .c-detail-images__controls-prev {
    padding: 0 0 0 10px;
  }
}

.c-detail-images__controls-prev::after {
  transform: rotate(-135deg);
}

/*----------------------------------------
c-error
----------------------------------------*/
.c-error {
  color: #cb4e4e;
  margin: 5px 0;
}

/*----------------------------------------
c-faq
----------------------------------------*/
.c-faq {
  text-align: left;
}

.c-faq__item {
  margin: 0 0 20px;
}

.c-faq__ttl {
  font-size: 18px;
  font-size: 1.8rem;
  font-family: "Noto Sans JP", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-weight: 900;
  position: relative;
  padding: 25px 50px 25px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .c-faq__ttl {
    font-size: 14px;
    font-size: 1.4rem;
    padding: 10px 30px 10px 0;
  }
}

.c-faq__ttl::before, .c-faq__ttl::after {
  background: #343434;
  width: 20px;
  height: 1px;
  position: absolute;
  top: 50%;
  right: 7.5px;
  transition: transform 0.3s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

@media screen and (max-width: 767px) {
  .c-faq__ttl::before, .c-faq__ttl::after {
    right: 5px;
    width: 15px;
  }
}

.c-faq__ttl::after {
  transform: translateY(0) rotate(90deg);
  opacity: 1;
}

.c-faq__ttl.is-active::after {
  transform: translateY(10px) rotate(90deg);
  opacity: 0;
}

.c-faq__ttl.js-accrodion-btn {
  cursor: pointer;
}

.c-faq__ttl.js-accrodion-btn::before, .c-faq__ttl.js-accrodion-btn::after {
  content: "";
  display: block;
}

.c-faq__ttl-icon {
  font-size: 28px;
  font-size: 2.8rem;
  color: #0086d3;
  line-height: 1;
  font-family: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-weight: 900;
  margin: 0 25px 0 0;
}

@media screen and (max-width: 767px) {
  .c-faq__ttl-icon {
    font-size: 20px;
    font-size: 2rem;
    margin-right: 10px;
  }
}

.c-faq__desc {
  padding: 25px 0;
}

@media screen and (max-width: 767px) {
  .c-faq__desc {
    padding: 10px 0;
  }
}

.c-faq__desc.js-accrodion-target {
  display: none;
}

.c-faq__desc-inner {
  display: flex;
}

.c-faq__desc p:not([class]) {
  font-size: 14px;
  font-size: 1.4rem;
  text-align: justify;
}

@media screen and (max-width: 767px) {
  .c-faq__desc p:not([class]) {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.c-faq__desc a:not([class]) {
  text-decoration: underline;
}

.c-faq__desc a:not([class]):hover {
  color: #cb4e4e;
}

/*----------------------------------------
c-favorite-btn
----------------------------------------*/
.c-favorite-btn {
  color: #707070;
  background: none;
  width: 30px;
  height: 30px;
  border: 0;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  outline: none;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .c-favorite-btn {
    top: 0;
    right: 0;
  }
}

.summary .c-favorite-btn {
  width: 25px;
  height: 25px;
  top: 0;
  right: 0;
}

@media screen and (max-width: 767px) {
  .summary .c-favorite-btn {
    right: -3px;
  }
}

.c-favorite-btn::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  border: 17px solid #cb4e4e;
  transform: scale(0);
  transform-origin: center;
}

.c-favorite-btn::after {
  font-size: 17px;
  font-size: 1.7rem;
  content: '';
  font-family: "Font Awesome 5 Free";
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  transform-origin: center;
  transition: color 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2258%22%20height%3D%2258%22%20viewBox%3D%220%200%2058%2058%22%3E%20%20%20%3Cdefs%3E%20%20%20%20%20%3Cstyle%3E%20%20%20%20%20%20%20.g-header__menu-list-item-link-wish-path%20%7B%20%20%20%20%20%20%20%20%20fill%3A%20%23333%3B%20%20%20%20%20%20%20%20%20fill-rule%3A%20evenodd%3B%20%20%20%20%20%20%20%7D%20%20%20%20%20%20%20%20%20%3C%2Fstyle%3E%20%20%20%3C%2Fdefs%3E%20%20%20%3Cpath%20class%3D%22g-header__menu-list-item-link-wish-path%22%20d%3D%22M43.4%2C0.009A15.329%2C15.329%2C0%2C0%2C0%2C28.991%2C9.3%2C15.375%2C15.375%2C0%2C0%2C0%2C14.588.009C6.544%2C0.009%2C0%2C6.8%2C0%2C15.155%2C0%2C24.78%2C5.212%2C31.909%2C10.552%2C38.388c5.206%2C6.29%2C15.261%2C16.941%2C17.645%2C19.326L28.485%2C58h0.953l0.288-.287c2.566-2.567%2C12.643-13.2%2C17.717-19.328%2C5.338-6.477%2C10.551-13.606%2C10.551-23.231C57.993%2C6.8%2C51.449.009%2C43.4%2C0.009Zm0.428%2C35.663C39.579%2C40.828%2C32%2C49%2C29%2C52.1c-3.086-3.163-10.675-11.3-14.845-16.435-4.895-5.767-9.674-12.1-9.674-20.505%2C0-5.245%2C3.782-10.67%2C10.112-10.67%2C6.308%2C0%2C10.661%2C4.105%2C11.941%2C11.261h0a2.494%2C2.494%2C0%2C0%2C0%2C4.934%2C0h0C32.744%2C8.59%2C37.1%2C4.485%2C43.4%2C4.485c6.329%2C0%2C10.112%2C5.425%2C10.112%2C10.67C53.516%2C23.42%2C48.938%2C29.574%2C43.833%2C35.672Z%22%3E%3C%2Fpath%3E%20%3C%2Fsvg%3E");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  line-height: 20px;
}

@media screen and (max-width: 767px) {
  .c-favorite-btn::after {
    font-size: 16px;
    font-size: 1.6rem;
    width: 16px;
    height: 16px;
    line-height: 16px;
  }
}

.c-favorite-btn:hover {
  color: #cb4e4e;
}

.c-favorite-btn:hover::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2258%22%20height%3D%2258%22%20viewBox%3D%220%200%2058%2058%22%3E%20%20%20%3Cdefs%3E%20%20%20%20%20%3Cstyle%3E%20%20%20%20%20%20%20.g-header__menu-list-item-link-wish-path%20%7B%20%20%20%20%20%20%20%20%20fill%3A%20%23cb4e4e%3B%20%20%20%20%20%20%20%20%20fill-rule%3A%20evenodd%3B%20%20%20%20%20%20%20%7D%20%20%20%20%20%20%20%20%20%3C%2Fstyle%3E%20%20%20%3C%2Fdefs%3E%20%20%20%3Cpath%20class%3D%22g-header__menu-list-item-link-wish-path%22%20d%3D%22M43.4%2C0.009A15.329%2C15.329%2C0%2C0%2C0%2C28.991%2C9.3%2C15.375%2C15.375%2C0%2C0%2C0%2C14.588.009C6.544%2C0.009%2C0%2C6.8%2C0%2C15.155%2C0%2C24.78%2C5.212%2C31.909%2C10.552%2C38.388c5.206%2C6.29%2C15.261%2C16.941%2C17.645%2C19.326L28.485%2C58h0.953l0.288-.287c2.566-2.567%2C12.643-13.2%2C17.717-19.328%2C5.338-6.477%2C10.551-13.606%2C10.551-23.231C57.993%2C6.8%2C51.449.009%2C43.4%2C0.009Zm0.428%2C35.663C39.579%2C40.828%2C32%2C49%2C29%2C52.1c-3.086-3.163-10.675-11.3-14.845-16.435-4.895-5.767-9.674-12.1-9.674-20.505%2C0-5.245%2C3.782-10.67%2C10.112-10.67%2C6.308%2C0%2C10.661%2C4.105%2C11.941%2C11.261h0a2.494%2C2.494%2C0%2C0%2C0%2C4.934%2C0h0C32.744%2C8.59%2C37.1%2C4.485%2C43.4%2C4.485c6.329%2C0%2C10.112%2C5.425%2C10.112%2C10.67C53.516%2C23.42%2C48.938%2C29.574%2C43.833%2C35.672Z%22%3E%3C%2Fpath%3E%20%3C%2Fsvg%3E");
}

.c-favorite-btn[data-status="loading"] {
  color: #707070;
  animation: activeBtn 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.c-favorite-btn[data-status="loading"]::after {
  content: "\f110";
  font-weight: 700;
  animation: rotate 1s linear infinite;
  background: none;
}

.c-favorite-btn[data-status="true"] {
  color: #cb4e4e;
}

.c-favorite-btn[data-status="true"]::before {
  animation: activeBtnCircle 0.8s cubic-bezier(0.19, 1, 0.22, 1);
}

.c-favorite-btn[data-status="true"]::after {
  font-weight: 900;
  animation: activeBtnIcon 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2246%22%20height%3D%2246%22%20viewBox%3D%220%200%2046%2046%22%3E%20%3Cdefs%3E%20%20%20%20%20%3Cstyle%3E%20%20%20%20%20%20%20.g-header__menu-list-item-link-wish2-path%20%7B%20%20%20%20%20%20%20%20%20fill%3A%20%23cb4e4e%3B%20%20%20%20%20%20%20%20%20fill-rule%3A%20evenodd%3B%20%20%20%20%20%20%20%7D%20%20%20%20%20%3C%2Fstyle%3E%20%20%20%3C%2Fdefs%3E%20%20%20%3Cpath%20class%3D%22g-header__menu-list-item-link-wish2-path%22%20d%3D%22M34.424%2C0.006A12.159%2C12.159%2C0%2C0%2C0%2C22.993%2C7.375%2C12.194%2C12.194%2C0%2C0%2C0%2C11.57.006%2C11.812%2C11.812%2C0%2C0%2C0%2C0%2C12.019c0%2C7.634%2C4.134%2C13.289%2C8.369%2C18.426%2C4.129%2C4.989%2C12.1%2C13.437%2C13.994%2C15.328L22.592%2C46h0.756l0.228-.229C25.611%2C43.738%2C33.6%2C35.307%2C37.627%2C30.444c4.234-5.136%2C8.368-10.791%2C8.368-18.425A11.812%2C11.812%2C0%2C0%2C0%2C34.424.006Z%22%2F%3E%20%3C%2Fsvg%3E");
}

.c-favorite-btn-container {
  position: relative;
}

/*----------------------------------------
c-flow-list
----------------------------------------*/
.c-flow-list {
  text-align: justify;
  position: relative;
  margin: -25px 0;
}

@media screen and (max-width: 767px) {
  .c-flow-list {
    margin: -15px 0;
  }
}

.c-flow-list__row {
  position: relative;
  z-index: 1;
  padding: 25px 10px;
  counter-increment: c-o-list-child-number;
}

@media screen and (max-width: 767px) {
  .c-flow-list__row {
    padding: 15px 5px;
  }
}

.c-flow-list__txt {
  overflow: hidden;
}

.c-flow-list__ttl {
  font-size: 16px;
  font-size: 1.6rem;
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
  margin-bottom: 20px;
  padding-left: 40px;
  position: relative;
  line-height: 30px;
}

@media screen and (max-width: 767px) {
  .c-flow-list__ttl {
    font-size: 15px;
    font-size: 1.5rem;
    margin-bottom: 15px;
    padding-left: 30px;
    line-height: 20px;
  }
}

.c-flow-list__ttl::before {
  font-size: 18px;
  font-size: 1.8rem;
  text-indent: .12rem;
  content: counter(c-o-list-child-number);
  background: #38475e;
  font-family: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  width: 30px;
  height: 30px;
  color: #fff;
  line-height: 32px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 767px) {
  .c-flow-list__ttl::before {
    font-size: 13px;
    font-size: 1.3rem;
    width: 20px;
    height: 20px;
    line-height: 22px;
  }
}

.c-flow-list__desc {
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
  line-height: 1.8;
  padding-left: 40px;
}

@media screen and (max-width: 1024px) {
  .c-flow-list__desc {
    padding-left: 0;
  }
}

.c-flow-list__desc-sub {
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
  line-height: 1.5;
  margin: 50px 0 5px;
  display: block;
  font-weight: 900;
}

@media screen and (max-width: 1024px) {
  .c-flow-list__desc-sub {
    margin: 20px 0 0;
  }
}

.c-flow-list__desc-sub:first-child {
  margin: 0 0 10px;
}

/*----------------------------------------
c-form
----------------------------------------*/
.c-form {
  width: 100%;
  display: block;
}

@media screen and (max-width: 767px) {
  .c-form__table, .c-form__tbody, .c-form__row, .c-form__ttl, .c-form__cnt {
    display: block;
  }
}

.c-form__read {
  padding: 60px 80px 0;
  text-align: center;
  position: relative;
}

@media screen and (max-width: 1330px) {
  .c-form__read {
    padding: 60px 75px 0;
  }
}

@media screen and (max-width: 1199px) {
  .c-form__read {
    padding: 60px 50px 0;
  }
}

@media screen and (max-width: 1024px) {
  .c-form__read {
    padding: 60px 50px 0;
  }
}

@media screen and (max-width: 767px) {
  .c-form__read {
    padding: 20px 16px 0;
  }
}

.c-form__read::after {
  content: '';
  background: #efefef;
  top: 0;
  right: 0;
  bottom: -170px;
  left: 0;
  position: absolute;
}

.c-form__read .c-guid-desc {
  font-size: 16px;
  font-size: 1.6rem;
  text-align: center;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 1024px) {
  .c-form__read .c-guid-desc {
    text-align: left;
  }
}

@media screen and (max-width: 767px) {
  .c-form__read .c-guid-desc {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.c-form__table {
  width: 100%;
}

.c-form__ttl, .c-form__cnt {
  display: block;
  vertical-align: top;
  padding: 7.5px 0;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .c-form__ttl, .c-form__cnt {
    padding: 5px 0;
  }
}

.c-form__ttl {
  white-space: nowrap;
}

.c-form__row:not(:first-child) .c-form__ttl {
  padding-top: 22.5px;
}

@media screen and (max-width: 767px) {
  .c-form__row:not(:first-child) .c-form__ttl {
    padding-top: 20px;
  }
}

.c-form__cnt .error {
  margin: 10px 0 0;
}

.c-form__cnt .horizontal-item {
  padding: 5px 0;
  display: inline-block;
}

.c-form__label {
  display: flex;
  justify-content: left;
  align-items: center;
}

.c-form__label-txt, .c-form__label-val {
  display: block;
}

.c-form__label-txt {
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
}

.c-form__label-val {
  font-size: 12px;
  font-size: 1.2rem;
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
  padding: 8px 10px;
  margin: 0 15px 0 0;
  background: #cb4e4e;
  color: #fff;
  line-height: 1;
  text-indent: .1rem;
}

@media screen and (max-width: 767px) {
  .c-form__label-val {
    font-size: 11px;
    font-size: 1.1rem;
    padding: 4px 5px 3px;
    margin: 0 10px 0 0;
  }
}

.c-form__label-val.is-any {
  background: #BFBFBF;
}

.c-form input:not([type="radio"]):not([type="checkbox"]),
.c-form textarea,
.c-form select {
  font-size: 14px;
  font-size: 1.4rem;
  letter-spacing: 0.5px;
  letter-spacing: 0.05rem;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  background: #fff;
  width: 100%;
  appearance: none;
  padding: 15px 20px;
  border: 1px solid transparent;
  transition: border-color 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  border-color: #e7ecef;
  background: #e7ecef;
}

@media screen and (max-width: 767px) {
  .c-form input:not([type="radio"]):not([type="checkbox"]),
  .c-form textarea,
  .c-form select {
    padding: 10px;
  }
}

.c-form input:not([type="radio"]):not([type="checkbox"])::placeholder,
.c-form textarea::placeholder,
.c-form select::placeholder {
  color: #909090;
}

@media screen and (max-width: 767px) {
  .c-form input:not([type="radio"]):not([type="checkbox"])::placeholder,
  .c-form textarea::placeholder,
  .c-form select::placeholder {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.c-form input:not([type="radio"]):not([type="checkbox"]):hover, .c-form input:not([type="radio"]):not([type="checkbox"]):focus,
.c-form textarea:hover,
.c-form textarea:focus,
.c-form select:hover,
.c-form select:focus {
  border-color: #343434;
}

.c-form textarea {
  width: 100%;
  min-height: 80px;
  resize: vertical;
}

.c-form input[type="radio"], .c-form input[type="checkbox"] {
  display: none;
}

.c-form input[type="radio"] + span, .c-form input[type="checkbox"] + span {
  position: relative;
  cursor: pointer;
  padding: 0 0 0 35px;
  margin: 0 30px 0 0;
  display: inline-block;
  line-height: 24px;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .c-form input[type="radio"] + span, .c-form input[type="checkbox"] + span {
    line-height: 1.7;
  }
}

.c-form input[type="radio"] + span::before, .c-form input[type="checkbox"] + span::before {
  content: "";
  display: inline-block;
  vertical-align: sub;
  border: 1px solid #cccccc;
  background: #fff;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.c-form input[type="radio"] + span::after, .c-form input[type="checkbox"] + span::after {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 4px;
  left: 4px;
  transition: opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0;
}

.c-form input[type="radio"]:checked + span::after, .c-form input[type="checkbox"]:checked + span::after {
  opacity: 1;
}

.c-form input[type="radio"] + span::before {
  border-radius: 50%;
}

.c-form input[type="radio"] + span::after {
  border-radius: 50%;
  background: #000;
}

.c-form input[type="checkbox"] + span::before {
  border-width: 2px;
  border-radius: 1px;
}

.c-form input[type="checkbox"] + span::after {
  width: 5px;
  left: 8px;
  top: 4px;
  border: solid #000;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.c-form__consent {
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
  text-indent: .1rem;
  text-align: center;
  padding: 42.5px 0 0;
}

.c-form__consent:not(:first-child) {
  padding-top: 25px;
}

.c-form__consent a {
  text-decoration: underline;
  color: #4C6FA5;
}

.c-form__consent a:hover {
  color: #cb4e4e;
}

.c-form__consent-check {
  margin-top: 30px;
}

.c-form__consent-check input[type="checkbox"] + span {
  margin-right: 0;
}

.c-form__consent .error {
  margin: 10px 0 0;
}

.c-form__consent input[type="checkbox"] + span, .c-form__consent input[type="radio"] + span {
  margin: 0;
}

.c-form__consent-link {
  color: #cb4e4e;
}

.c-form-btn {
  margin-top: 80px;
  display: flex;
  justify-content: center;
  padding: 0 150px;
}

@media screen and (max-width: 1024px) {
  .c-form-btn {
    padding: 0 100px;
  }
}

@media screen and (max-width: 767px) {
  .c-form-btn {
    margin-top: 40px;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 0 16px;
  }
}

.c-form-btn__body {
  padding: 30px 80px;
  background: #000;
  border: none;
  min-width: 260px;
  cursor: pointer;
  transition: background-color .2s ease;
}

@media screen and (min-width: 1199px) {
  .c-form-btn__body:hover {
    background: #343434;
  }
}

@media screen and (max-width: 767px) {
  .c-form-btn__body {
    padding: 20px 40px;
  }
}

.c-form-btn__body:not(:first-child) {
  margin-left: 20px;
}

@media screen and (max-width: 767px) {
  .c-form-btn__body:not(:first-child) {
    margin-top: 20px;
    margin-left: 0;
  }
}

.c-form-btn__body-txt {
  font-size: 18px;
  font-size: 1.8rem;
  letter-spacing: 2px;
  letter-spacing: 0.2rem;
  text-indent: .2rem;
  color: #fff;
  line-height: 1;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
}

@media screen and (max-width: 767px) {
  .c-form-btn__body-txt {
    font-size: 13px;
    font-size: 1.3rem;
    letter-spacing: 1px;
    letter-spacing: 0.1rem;
  }
}

.mw_wp_form_confirm .is-confirm-none {
  display: none;
}

.mw_wp_form_confirm .c-form-ttl {
  padding: 20px 0;
}

.mw_wp_form_confirm .c-form-cnt {
  background: #f9f9f9;
  padding: 20px 0 20px 40px;
}

@media screen and (max-width: 767px) {
  .mw_wp_form_confirm .c-form-cnt {
    padding: 20px;
  }
}

.mw_wp_form_confirm .c-form-label-val {
  display: none;
}

.mw_wp_form_confirm .c-form-lead {
  display: none;
}

.mw_wp_form_confirm .c-form-lead__confirm {
  display: block;
}

.mw_wp_form_input .is-input-none {
  display: none;
}

/*----------------------------------------
c-gmap
----------------------------------------*/
.c-gmap {
  padding: 30px 0;
}

.c-gmap iframe {
  height: 300px;
}

/*----------------------------------------
c-grid
----------------------------------------*/
.c-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .c-grid {
    margin: 0 -6px;
  }
}

.c-grid.is-no-padding {
  margin: 0;
}

.c-grid.is-no-padding .c-grid__item {
  padding: 0;
}

.c-grid.is-blog {
  margin: 0;
  flex-wrap: nowrap;
}

@media screen and (max-width: 1330px) {
  .c-grid.is-blog {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.c-grid.is-blog .c-grid__item {
  width: 33.3333%;
}

@media screen and (max-width: 1330px) {
  .c-grid.is-blog .c-grid__item {
    width: 50%;
  }
}

@media screen and (max-width: 1024px) {
  .c-grid.is-blog .c-grid__item {
    width: 100%;
  }
}

.c-grid.is-blog-page {
  margin: -20px -10px;
}

@media screen and (max-width: 767px) {
  .c-grid.is-blog-page {
    margin: -10px;
  }
}

.c-grid.is-blog-page .c-grid__item {
  width: 50%;
  padding: 20px 10px;
}

@media screen and (max-width: 1024px) {
  .c-grid.is-blog-page .c-grid__item {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .c-grid.is-blog-page .c-grid__item {
    padding: 10px;
  }
}

.c-grid.is-campaign-page {
  margin: -20px -10px;
}

@media screen and (max-width: 767px) {
  .c-grid.is-campaign-page {
    margin: -10px;
  }
}

.c-grid.is-campaign-page .c-grid__item {
  width: 33.3333%;
  padding: 20px 10px;
}

@media screen and (max-width: 1330px) {
  .c-grid.is-campaign-page .c-grid__item {
    width: 50%;
  }
}

@media screen and (max-width: 1024px) {
  .c-grid.is-campaign-page .c-grid__item {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .c-grid.is-campaign-page .c-grid__item {
    padding: 10px;
  }
}

.c-grid.is-news {
  display: block;
  border-top: 1px solid #38475e;
  margin: 0 0 50px;
}

@media screen and (max-width: 767px) {
  .c-grid.is-news {
    margin-bottom: 25px;
  }
}

.c-grid__item {
  padding: 15px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .c-grid__item {
    padding: 10px 6px;
  }
}

.is-news .c-grid__item {
  padding: 0;
  border-bottom: 1px solid #38475e;
}

@media screen and (max-width: 1024px) {
  .c-grid__item.is-ranking {
    width: 100%;
  }
}

.c-grid[data-grid="5"] .c-grid__item {
  width: 20%;
}

@media screen and (max-width: 1023px) {
  .c-grid[data-grid="5"] .c-grid__item {
    width: 50%;
  }
}

.c-grid[data-grid="4-5"] .c-grid__item {
  width: 25%;
}

@media screen and (max-width: 1023px) {
  .c-grid[data-grid="4-5"] .c-grid__item {
    width: 50%;
  }
}

.c-grid[data-grid="4-5"] .c-grid__item:nth-child(9n-4), .c-grid[data-grid="4-5"] .c-grid__item:nth-child(9n-3), .c-grid[data-grid="4-5"] .c-grid__item:nth-child(9n-2), .c-grid[data-grid="4-5"] .c-grid__item:nth-child(9n-1), .c-grid[data-grid="4-5"] .c-grid__item:nth-child(9n) {
  width: 20%;
}

@media screen and (max-width: 1023px) {
  .c-grid[data-grid="4-5"] .c-grid__item:nth-child(9n-4), .c-grid[data-grid="4-5"] .c-grid__item:nth-child(9n-3), .c-grid[data-grid="4-5"] .c-grid__item:nth-child(9n-2), .c-grid[data-grid="4-5"] .c-grid__item:nth-child(9n-1), .c-grid[data-grid="4-5"] .c-grid__item:nth-child(9n) {
    width: 50%;
  }
}

.c-grid[data-grid="4"] .c-grid__item {
  width: 25%;
}

@media screen and (max-width: 1330px) {
  .c-grid[data-grid="4"] .c-grid__item {
    width: 50%;
  }
}

@media screen and (max-width: 1330px) {
  .c-grid[data-grid="4"].is-ranking .c-grid__item {
    width: 100%;
  }
}

.c-grid[data-grid="3-4"] .c-grid__item {
  width: 33.33333%;
}

@media screen and (max-width: 1330px) {
  .c-grid[data-grid="3-4"] .c-grid__item {
    width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .c-grid[data-grid="3-4"] .c-grid__item {
    width: 100%;
  }
}

.c-grid[data-grid="3-4"] .c-grid__item:nth-child(7n-3), .c-grid[data-grid="3-4"] .c-grid__item:nth-child(7n-2), .c-grid[data-grid="3-4"] .c-grid__item:nth-child(7n-1), .c-grid[data-grid="3-4"] .c-grid__item:nth-child(7n) {
  width: 25%;
}

@media screen and (max-width: 1330px) {
  .c-grid[data-grid="3-4"] .c-grid__item:nth-child(7n-3), .c-grid[data-grid="3-4"] .c-grid__item:nth-child(7n-2), .c-grid[data-grid="3-4"] .c-grid__item:nth-child(7n-1), .c-grid[data-grid="3-4"] .c-grid__item:nth-child(7n) {
    width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .c-grid[data-grid="3-4"] .c-grid__item:nth-child(7n-3), .c-grid[data-grid="3-4"] .c-grid__item:nth-child(7n-2), .c-grid[data-grid="3-4"] .c-grid__item:nth-child(7n-1), .c-grid[data-grid="3-4"] .c-grid__item:nth-child(7n) {
    width: 50%;
  }
}

.c-grid[data-grid="3-4"] .c-grid__item .c-item__figure-img::before {
  padding: 70% 0 0;
}

.c-grid[data-grid="3"] .c-grid__item {
  width: 33.33333%;
}

@media screen and (max-width: 1330px) {
  .c-grid[data-grid="3"] .c-grid__item {
    width: 50%;
  }
}

@media screen and (max-width: 1024px) {
  .c-grid[data-grid="3"] .c-grid__item {
    width: 100%;
  }
}

.c-grid[data-grid="3"].is-blog .c-grid__item:nth-child(-n+2) {
  width: 50%;
}

@media screen and (max-width: 1330px) {
  .c-grid[data-grid="3"].is-blog .c-grid__item:nth-child(-n+2) {
    width: 100%;
  }
}

.c-grid[data-grid="3"].is-blog.is-under-page .c-grid__item:nth-child(-n+2) {
  width: 33.33333%;
}

@media screen and (max-width: 1024px) {
  .c-grid[data-grid="3"].is-blog.is-under-page .c-grid__item:nth-child(-n+2) {
    width: 50%;
  }
}

.c-grid[data-grid="2"] .c-grid__item {
  width: 50%;
}

@media screen and (max-width: 767px) {
  .c-grid[data-grid="2"] .c-grid__item {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .c-grid[data-grid="2"] .c-grid__item .c-item {
    padding: 12px 0;
  }
}

.c-grid[data-grid="2"] .c-grid__item .c-item__favorite {
  top: 25px;
  right: 35px;
}

.c-grid[data-grid="2"] .c-grid__item .c-item__figure-img::before {
  padding: 60% 0 0;
}

/*----------------------------------------
c-guid-desc
----------------------------------------*/
.c-guid-desc {
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 1.8;
  margin: 0 0 20px;
}

@media screen and (max-width: 767px) {
  .c-guid-desc {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.p-privacy-block .c-guid-desc {
  text-align: left;
  padding-left: 10px;
}

@media screen and (max-width: 1024px) {
  .p-privacy-block .c-guid-desc {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.c-section.is-guide .c-guid-desc {
  text-align: left;
  padding-left: 10px;
}

@media screen and (max-width: 1024px) {
  .c-section.is-guide .c-guid-desc {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.mw_wp_form_complete .c-guid-desc {
  margin-bottom: 70px;
}

@media screen and (max-width: 767px) {
  .mw_wp_form_complete .c-guid-desc {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 1024px) {
  .c-guid-desc {
    text-align: left;
  }
}

:not(:last-child) > .c-guid-desc:last-child {
  margin-bottom: 60px;
}

.c-form__read :not(:last-child) > .c-guid-desc:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  :not(:last-child) > .c-guid-desc:last-child {
    margin-bottom: 40px;
  }
}

.c-form-container .c-guid-desc {
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .c-form-container .c-guid-desc {
    text-align: left;
  }
}

.c-guid-desc.is-center {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .c-guid-desc.is-center {
    text-align: justify;
  }
}

/*----------------------------------------
c-item-style
----------------------------------------*/
.c-item-style {
  position: relative;
}

.c-item-style.is-sold-out .c-item-style__figure::after {
  font-size: 20px;
  font-size: 2rem;
  letter-spacing: 4px;
  letter-spacing: 0.4rem;
  content: "SOLD OUT";
  font-family: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  color: #fff;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 0;
  transform: translateY(-50%);
  text-align: center;
}

@media screen and (max-width: 767px) {
  .c-item-style.is-sold-out .c-item-style__figure::after {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 374px) {
  .c-item-style.is-sold-out .c-item-style__figure::after {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.c-item-style.is-sold-out .c-item-style__figure-img::before {
  background: rgba(0, 0, 0, 0.3);
}

.c-item-style__favorite {
  width: 40%;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 1330px) {
  .c-item-style__favorite {
    width: 200px;
  }
}

@media screen and (max-width: 1024px) {
  .c-item-style__favorite {
    width: 120px;
  }
}

.c-item-style__link {
  display: flex;
  justify-content: space-between;
  text-align: left;
}

.c-item-style__link:hover .c-item-style__figure-img {
  transform: scale(1.1);
}

.c-item-style__figure {
  width: 40%;
  overflow: hidden;
  position: relative;
}

@media screen and (max-width: 1330px) {
  .c-item-style__figure {
    width: 200px;
  }
}

@media screen and (max-width: 1024px) {
  .c-item-style__figure {
    width: 120px;
  }
}

.c-item-style__figure-img {
  background: no-repeat center;
  background-size: cover;
  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.c-item-style__figure-img::before {
  content: "";
  display: block;
  padding: 118% 0 0;
}

.c-item-style__figure-src {
  display: none;
}

.c-item-style__txt {
  width: 55%;
}

@media screen and (max-width: 1330px) {
  .c-item-style__txt {
    width: calc(100% - 200px);
    padding: 0 0 0 40px;
  }
}

@media screen and (max-width: 1024px) {
  .c-item-style__txt {
    width: calc(100% - 120px);
    padding: 0 0 0 20px;
  }
}

.c-item-style__ttl {
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: 900;
}

@media screen and (max-width: 767px) {
  .c-item-style__ttl {
    font-size: 11px;
    font-size: 1.1rem;
    line-height: 1.8;
  }
}

.c-item-style__desc {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 2;
  padding: 15px 0;
}

@media screen and (max-width: 767px) {
  .c-item-style__desc {
    font-size: 11px;
    font-size: 1.1rem;
    line-height: 1.8;
    padding: 6px 0;
  }
}

.c-item-style__price {
  font-size: 16px;
  font-size: 1.6rem;
  letter-spacing: 1.5px;
  letter-spacing: 0.15rem;
}

@media screen and (max-width: 767px) {
  .c-item-style__price {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.c-item-style__price.is-discount {
  text-decoration: line-through;
}

.c-item-style__price small {
  font-size: 12px;
  font-size: 1.2rem;
  margin: 0 0 0 5px;
}

@media screen and (max-width: 767px) {
  .c-item-style__price small {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.c-item-style__discount {
  font-size: 16px;
  font-size: 1.6rem;
  letter-spacing: 1.5px;
  letter-spacing: 0.15rem;
  color: #cb4e4e;
  font-weight: 900;
}

@media screen and (max-width: 767px) {
  .c-item-style__discount {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.c-item-style__discount span:not([class]) {
  display: inline-block;
  margin: 0 5px 0 0;
}

.c-item-style__discount small {
  font-size: 12px;
  font-size: 1.2rem;
  margin: 0 0 0 2px;
}

@media screen and (max-width: 767px) {
  .c-item-style__discount small {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

/*----------------------------------------
c-item
----------------------------------------*/
.c-item {
  position: relative;
  height: 100%;
  box-shadow: 0px 6px 10px -4px rgba(0, 0, 0, 0.05);
}

.c-item.is-sold-out .c-item__figure::after {
  font-size: 20px;
  font-size: 2rem;
  letter-spacing: 4px;
  letter-spacing: 0.4rem;
  content: "SOLD OUT";
  font-family: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  color: #fff;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 0;
  transform: translateY(-50%);
  text-align: center;
}

@media screen and (max-width: 767px) {
  .c-item.is-sold-out .c-item__figure::after {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 374px) {
  .c-item.is-sold-out .c-item__figure::after {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.c-item.is-sold-out .c-item__figure-img::before {
  background: rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 767px) {
  .c-item.is-sold-out .c-item__figure-img::before {
    height: 100%;
  }
}

.c-item__rank {
  font-size: 24px;
  font-size: 2.4rem;
  font-family: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  color: #fff;
  line-height: 1;
  display: block;
  position: absolute;
  top: 5px;
  left: 10px;
  z-index: 1;
  margin-top: 0;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .c-item__rank {
    font-size: 16px;
    font-size: 1.6rem;
    left: 5px;
  }
}

.c-item__link {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  background-color: #fff;
}

.is-ranking .c-item__link::after {
  content: '';
}

.c-item__link::after {
  content: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 70px 70px 0 0;
  border-color: #38475e transparent transparent transparent;
}

@media screen and (max-width: 767px) {
  .c-item__link::after {
    border-width: 50px 50px 0 0;
  }
}

.c-grid__item:nth-child(1) .c-item__link::after {
  border-top-color: #d2a727;
}

.c-grid__item:nth-child(2) .c-item__link::after {
  border-top-color: #81a3a1;
}

.c-grid__item:nth-child(3) .c-item__link::after {
  border-top-color: #38475e;
}

.c-item__figure {
  overflow: hidden;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .c-grid__item.is-ranking .c-item__figure {
    height: 100%;
    width: 100%;
  }
}

.c-item__figure-img {
  background: no-repeat center #fff;
  background-size: contain;
  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

@media screen and (min-width: 1199px) {
  .c-item__link:hover .c-item__figure-img {
    transform: scale(1.1);
  }
}

@media screen and (max-width: 767px) {
  .c-grid__item.is-ranking .c-item__figure-img {
    height: 100%;
  }
}

.c-item__figure-img.is-contain {
  background-color: #f5f5f5;
  background-size: contain;
}

.c-item__figure-img::before {
  content: "";
  display: block;
  padding: 56% 0 0;
}

@media screen and (max-width: 767px) {
  .c-item__figure-img::before {
    padding-top: 56.8%;
  }
}

.c-item__figure-src {
  display: none;
}

.c-item__txt {
  padding: 9px 9px 16px;
  position: relative;
  text-align: left;
}

.is-ranking .c-item__txt {
  padding: 18px 18px 25px;
}

@media screen and (max-width: 1024px) {
  .is-ranking .c-item__txt {
    padding: 18px 18px 25px;
    width: 100%;
  }
}

.is-ranking .c-item__txt::before {
  content: '';
}

.c-item__txt::before {
  content: none;
  border: 1px solid #e3e5e8;
  position: absolute;
  top: 9px;
  right: 9px;
  bottom: 9px;
  left: 9px;
}

.c-item__ttl {
  letter-spacing: 1.2px;
  letter-spacing: 0.12rem;
  margin: 0 0 10px;
}

.c-grid.is-new .c-item__ttl::before {
  font-size: 11px;
  font-size: 1.1rem;
  letter-spacing: 2px;
  letter-spacing: 0.2rem;
  text-indent: .2rem;
  font-weight: 900;
  content: 'NEW';
  color: #cb4e4e;
  border: 1px solid #cb4e4e;
  line-height: 1;
  padding: 3px 6px;
  margin-right: 5px;
}

@media screen and (max-width: 767px) {
  .c-grid.is-new .c-item__ttl::before {
    font-size: 10px;
    font-size: 1rem;
    padding: 2px 5px 1px;
    vertical-align: middle;
  }
}

.c-item__ttl.is-sales {
  margin: 0;
  padding: 20px;
  font-weight: 900;
}

@media screen and (max-width: 767px) {
  .c-item__ttl.is-sales {
    padding: 20px 16px 8px;
  }
}

@media screen and (max-width: 767px) {
  .c-item__ttl {
    margin-bottom: 10px;
  }
}

.c-item__desc {
  font-size: 12px;
  font-size: 1.2rem;
  margin-top: 15px;
}

.c-item__price, .c-item__discount {
  font-size: 15px;
  font-size: 1.5rem;
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
}

@media screen and (max-width: 1024px) {
  .c-item__price, .c-item__discount {
    padding-right: 0;
  }
}

@media screen and (max-width: 767px) {
  .c-item__price, .c-item__discount {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.c-item__price.is-nearly, .c-item__discount.is-nearly {
  font-size: 13px;
  font-size: 1.3rem;
  letter-spacing: 2px;
  letter-spacing: 0.2rem;
}

@media screen and (max-width: 767px) {
  .c-item__price.is-nearly, .c-item__discount.is-nearly {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.c-item__price span:not([class]), .c-item__discount span:not([class]) {
  display: inline-block;
  margin: 0 5px 0 0;
}

.c-item__price small, .c-item__discount small {
  font-size: 12px;
  font-size: 1.2rem;
  letter-spacing: 0.12px;
  letter-spacing: 0.012rem;
  font-weight: normal;
}

@media screen and (max-width: 767px) {
  .c-item__price small, .c-item__discount small {
    font-size: 10px;
    font-size: 1rem;
  }
}

@media screen and (max-width: 1024px) {
  .c-item__price-price, .c-item__discount-price {
    display: block;
  }
}

.c-item__price-parcent, .c-item__discount-parcent {
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
  text-indent: .1rem;
  text-align: center;
  vertical-align: middle;
  padding: 8px 10px;
  background: #cb4e4e;
  color: #fff;
  line-height: 1;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  font-weight: 500;
}

@media screen and (max-width: 767px) {
  .c-item__price-parcent, .c-item__discount-parcent {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.c-item__price-parcent small, .c-item__discount-parcent small {
  font-size: 14px;
  font-size: 1.4rem;
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
  text-indent: .1rem;
}

@media screen and (max-width: 767px) {
  .c-item__price-parcent small, .c-item__discount-parcent small {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.c-item__discount {
  color: #cb4e4e;
  font-weight: 900;
}

.c-item__colors {
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
  padding: 15px 0 0;
}

@media screen and (max-width: 767px) {
  .c-item__colors {
    margin: -2px;
  }
}

.c-item__colors-item {
  width: 14px;
  height: 14px;
  border: 1px solid #707070;
  margin: 4px;
}

.c-item__colors-item.is-last {
  position: relative;
}

.c-item__colors-item.is-last::after {
  position: absolute;
  content: "";
  background: #000;
  width: 10px;
  height: 1px;
  top: 50%;
  right: -20px;
  transform: translateY(-50%);
}

.c-item__colors-item.is-last::before {
  position: absolute;
  content: "";
  background: #000;
  width: 10px;
  height: 1px;
  top: 50%;
  right: -20px;
  transform: translateY(-50%) rotate(90deg);
}

.c-item .c-cart-btn {
  margin: 20px 0 0;
}

.c-item__cat {
  font-size: 12px;
  font-size: 1.2rem;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 5px 8px;
  line-height: 1;
  color: #fff;
  display: inline-block;
  background: #38475e;
}

@media screen and (max-width: 767px) {
  .c-item__cat {
    padding: 5px 8px;
  }
}

.c-item__cat.is-aged-day {
  background: #81A3A1;
}

.c-item__cat.is-birthday {
  background: #93AF71;
}

.c-item__cat.is-father {
  background: #BE9456;
}

.c-item__cat.is-mother {
  background: #BC707B;
}

.c-item__cat-container {
  display: flex;
  flex-wrap: wrap;
}

/*----------------------------------------
news-list
----------------------------------------*/
.c-news__list {
  padding: 0 80px;
}

@media screen and (max-width: 1330px) {
  .c-news__list {
    padding: 0 75px;
  }
}

@media screen and (max-width: 1199px) {
  .c-news__list {
    padding: 0 50px;
  }
}

@media screen and (max-width: 1024px) {
  .c-news__list {
    padding: 0;
  }
}

.c-news__list-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.c-news__list-item:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.c-news__list-link {
  padding: 30px 10px;
  display: block;
}

@media screen and (max-width: 767px) {
  .c-news__list-link {
    padding: 15px 0;
  }
}

.c-news__list-link:hover .c-news__list-ttl {
  color: #0086d3;
}

.c-news__list-link:hover .c-news__list-time {
  color: #0086d3;
}

.c-news__list-time {
  font-size: 17px;
  font-size: 1.7rem;
  position: relative;
  color: #333;
  text-align: left;
  line-height: 1.2;
  transition: color .2s ease;
  width: 110px;
  font-weight: 900;
  font-family: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
}

@media screen and (max-width: 1024px) {
  .c-news__list-time {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .c-news__list-time {
    font-size: 14px;
    font-size: 1.4rem;
    margin-bottom: 10px;
  }
}

.c-news__list-txt {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .c-news__list-txt {
    flex-wrap: wrap;
  }
}

.c-news__list-ttl {
  font-size: 14px;
  font-size: 1.4rem;
  padding-left: 30px;
  text-align: left;
  transition: color .2s ease;
  line-height: 1.2;
}

@media screen and (max-width: 1024px) {
  .c-news__list-ttl {
    padding-left: 0;
  }
}

@media screen and (max-width: 767px) {
  .c-news__list-ttl {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

/*----------------------------------------
c-notice
----------------------------------------*/
.c-notice {
  margin: 0 80px;
  padding: 80px 0;
  background: #f5f5f5;
  border: 1px solid #ddd;
}

@media screen and (max-width: 1199px) {
  .c-notice {
    margin: 0 75px;
  }
}

@media screen and (max-width: 1024px) {
  .c-notice {
    margin: 0 50px;
  }
}

@media screen and (max-width: 767px) {
  .c-notice {
    margin: 0 16px;
  }
}

.c-notice.is-blog {
  margin-top: 30px;
}

/*----------------------------------------
c-o-list-child
----------------------------------------*/
.c-o-list-child {
  text-align: justify;
  position: relative;
}

.c-o-list-child:not(:first-child) {
  margin-top: 30px;
}

@media screen and (max-width: 767px) {
  .c-o-list-child:not(:first-child) {
    margin-top: 10px;
  }
}

.c-o-list-child__item {
  counter-increment: c-o-list-child-number;
  margin: 0 0 10px;
  padding: 0 0 0 25px;
  position: relative;
  z-index: 1;
}

.c-o-list-child__item::before {
  font-size: 14px;
  font-size: 1.4rem;
  content: "(" counter(c-o-list-child-number) ")";
  text-align: center;
  line-height: 1.8;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 767px) {
  .c-o-list-child__item::before {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.c-o-list-child__item-txt {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.8;
}

@media screen and (max-width: 767px) {
  .c-o-list-child__item-txt {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

/*----------------------------------------
c-o-list
----------------------------------------*/
.c-o-list {
  text-align: justify;
  position: relative;
}

.c-o-list:not(:last-child) {
  margin-bottom: 100px;
}

@media screen and (max-width: 767px) {
  .c-o-list:not(:last-child) {
    margin-bottom: 40px;
  }
}

.c-o-list__item {
  counter-increment: c-o-list-number;
  margin: 0 0 35px;
  padding: 0 10px 0 35px;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .c-o-list__item {
    padding-left: 20px;
    margin-bottom: 20px;
  }
}

.c-o-list__item:last-child {
  margin-bottom: 70px;
}

@media screen and (max-width: 767px) {
  .c-o-list__item:last-child {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) {
  .c-o-list__item {
    margin-bottom: 30px;
  }
}

.c-o-list__item::before {
  content: counter(c-o-list-number);
  line-height: 1;
  display: block;
  position: absolute;
  top: 0;
  left: 5px;
}

.c-o-list__item-txt {
  line-height: 1.8;
}

/*----------------------------------------
c-pagination
----------------------------------------*/
.c-pagination {
  margin: 25px 0;
}

@media screen and (max-width: 1024px) {
  .c-pagination {
    margin: 25px 0 0;
  }
}

@media screen and (max-width: 767px) {
  .c-pagination {
    margin: 20px 0 0;
  }
}

.c-pagination:not(:last-child) {
  margin-bottom: 100px;
}

@media screen and (max-width: 767px) {
  .c-pagination:not(:last-child) {
    margin-bottom: 50px;
  }
}

.c-pagination__list {
  display: flex;
  justify-content: center;
  position: relative;
}

.c-pagination__list-dot {
  padding: 13px 0;
  display: block;
  pointer-events: none;
  line-height: 8px;
}

@media screen and (max-width: 767px) {
  .c-pagination__list-dot {
    padding: 6px 0;
  }
}

.c-pagination__list-item {
  position: relative;
  margin: 0 4px;
  height: 30px;
  width: 30px;
}

@media screen and (max-width: 767px) {
  .c-pagination__list-item {
    margin: 0 2px;
  }
}

.c-pagination__list-item-body {
  font-size: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: background-color .2s ease;
}

@media screen and (min-width: 1199px) {
  .c-pagination__list-item-body:hover {
    background: #e7ecef;
  }
}

.is-current .c-pagination__list-item-body {
  background: #e7ecef;
  pointer-events: none;
}

.c-pagination__list-item-body.is-next {
  border: 1px solid #38475e;
}

.c-pagination__list-item-body.is-next:hover {
  background: #38475e;
}

.c-pagination__list-item-body.is-next:hover::before {
  border-color: transparent transparent #fff transparent;
}

.c-pagination__list-item-body.is-next::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%) rotate(90deg);
  display: inline-block;
  width: 0;
  height: 0;
  border-width: 0 6px 6px 6px;
  border-style: solid;
  border-color: transparent transparent #38475e transparent;
}

.c-pagination__list-item-body.is-prev {
  border: 1px solid #38475e;
}

.c-pagination__list-item-body.is-prev:hover {
  background: #38475e;
}

.c-pagination__list-item-body.is-prev:hover::before {
  border-color: transparent transparent #fff transparent;
}

.c-pagination__list-item-body.is-prev::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%) rotate(-90deg);
  display: inline-block;
  width: 0;
  height: 0;
  border-width: 0 6px 6px 6px;
  border-style: solid;
  border-color: transparent transparent #38475e transparent;
}

.c-pagination__list-item-body-txt {
  font-size: 16px;
  font-size: 1.6rem;
  letter-spacing: 2px;
  letter-spacing: 0.2rem;
  text-indent: .2rem;
  font-family: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  line-height: 1;
  transition: color 0.3s cubic-bezier(0.19, 1, 0.22, 1), border-color 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  margin-top: 1px;
}

@media screen and (max-width: 767px) {
  .c-pagination__list-item-body-txt {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.c-pagination__list-item-body-icon {
  font-size: 14px;
  font-size: 1.4rem;
  color: #cccccc;
  transition: color 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

/*----------------------------------------
c-post
----------------------------------------*/
.c-post {
  position: relative;
}

.c-post__container {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1199px) {
  .c-post__container {
    flex-wrap: wrap;
  }
}

.c-post__main {
  width: calc(100% - 420px);
}

@media screen and (max-width: 1199px) {
  .c-post__main {
    width: 100%;
  }
}

.c-post__side {
  width: 420px;
  padding-left: 80px;
}

@media screen and (max-width: 1199px) {
  .c-post__side {
    width: 100%;
    padding-left: 0;
    margin-top: 50px;
  }
}

.c-post__side-block {
  background: #e7ecef;
  padding: 20px;
}

.c-post__side-ttl {
  font-size: 18px;
  font-size: 1.8rem;
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
  font-family: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-weight: 700;
  margin-bottom: 8px;
  padding-bottom: 10px;
  border-bottom: 1px solid #38475e;
  color: #38475e;
  text-align: left;
}

.c-post__side-list-item {
  position: relative;
  padding-left: 15px;
  text-align: left;
}

.c-post__side-list-item::before {
  content: '';
  width: 8px;
  height: 2px;
  position: absolute;
  top: 16px;
  left: 0;
  background: #38475e;
}

.c-post__side-list-item-link {
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
  color: #343434;
  padding: 7.5px 0;
  display: inline-block;
  transition: color .2s ease;
}

.c-post__side-list-item.is-child-show .c-post__side-list-item-link {
  padding: 7.5px 0 4px;
}

@media screen and (min-width: 1199px) {
  .c-post__side-list-item-link:hover {
    color: #cb4e4e;
  }
}

.c-post__side-child-list-item {
  padding-left: 10px;
  position: relative;
}

.c-post__side-child-list-item::before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 3px 3px 3px;
  border-color: transparent transparent #88919e transparent;
  transform: rotate(90deg);
  margin-right: 3px;
  position: absolute;
  top: 13px;
  left: 0;
}

.c-post__side-child-list-item-link {
  padding: 5px 0;
  display: inline-block;
  transition: color .2s ease;
}

@media screen and (min-width: 1199px) {
  .c-post__side-child-list-item-link:hover {
    color: #cb4e4e;
  }
}

.c-post__link {
  position: relative;
  text-align: left;
  display: flex;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
  background: #fff;
  text-overflow: ellipsis;
}

@media screen and (min-width: 1199px) {
  .c-post__link:hover .c-post__figure-img {
    transform: scale(1.1);
  }
}

.c-post__link.is-news {
  height: auto;
  box-shadow: none;
  align-items: center;
  padding: 20px 30px;
  background: rgba(255, 255, 255, 0.5);
  transition: color .2s ease;
}

@media screen and (min-width: 1199px) {
  .c-post__link.is-news:hover {
    color: #0086d3;
  }
}

@media screen and (max-width: 1024px) {
  .c-post__link.is-news {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 767px) {
  .c-post__link.is-news {
    padding: 15px 16px 10px;
  }
}

.is-blog-page .c-post__link {
  display: block;
}

.is-campaign-page .c-post__link {
  display: block;
}

.c-post__meta {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

@media screen and (max-width: 767px) {
  .c-post__meta {
    margin: 10px 0;
  }
}

.c-post__time {
  font-size: 15px;
  font-size: 1.5rem;
  letter-spacing: 2px;
  letter-spacing: 0.2rem;
  font-family: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  color: #BFBFBF;
  display: block;
  font-weight: 700;
  line-height: 1;
  position: absolute;
  left: 20px;
  bottom: 20px;
}

@media screen and (max-width: 767px) {
  .c-post__time {
    font-size: 14px;
    font-size: 1.4rem;
    left: 10px;
  }
}

.is-news .c-post__time {
  width: 142px;
  position: static;
  color: #38475e;
  padding-right: 45px;
}

@media screen and (max-width: 1024px) {
  .is-news .c-post__time {
    width: 100%;
  }
}

.c-post__figure {
  overflow: hidden;
  width: 50%;
  position: relative;
}

.is-blog-page .c-post__figure {
  width: 100%;
}

.is-campaign-page .c-post__figure {
  width: 100%;
}

.c-post__figure::before {
  content: '';
  padding-bottom: 100%;
  display: block;
}

.is-blog-page .c-post__figure::before {
  padding-bottom: 56%;
}

.is-campaign-page .c-post__figure::before {
  padding-bottom: 56%;
}

.c-post__figure-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: no-repeat center;
  background-size: cover;
  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.c-post__figure-src {
  display: none;
}

.c-post__txt {
  width: 50%;
  z-index: 1;
  padding: 20px 20px 70px;
  position: relative;
}

.is-blog-page .c-post__txt {
  width: 100%;
}

.is-campaign-page .c-post__txt {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .c-post__txt {
    padding: 10px 10px 40px;
  }
}

.c-post__category {
  font-size: 12px;
  font-size: 1.2rem;
  padding: 5px 8px;
  display: inline-block;
  color: #fff;
  background: #38475e;
  line-height: 1;
}

@media screen and (max-width: 767px) {
  .c-post__category {
    margin-bottom: 5px;
  }
}

.c-post__category.is-present {
  background: #81A3A1;
}

.c-post__category.is-product {
  background: #93AF71;
}

.c-post__category-container {
  margin-bottom: 10px;
}

.c-post__category-child {
  font-size: 12px;
  font-size: 1.2rem;
  color: #38475e;
  margin-top: 6px;
  display: flex;
  align-items: center;
}

.c-post__category-child.is-present {
  color: #81A3A1;
}

.c-post__category-child.is-present::before {
  border-color: transparent transparent #81A3A1 transparent;
}

.c-post__category-child.is-product {
  color: #93AF71;
}

.c-post__category-child.is-product::before {
  border-color: transparent transparent #93AF71 transparent;
}

.c-post__category-child::before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #38475e transparent;
  transform: rotate(90deg);
  margin-right: 3px;
}

.c-post__ttl {
  width: 100%;
  text-align: justify;
  line-height: 1.8;
  transition: background-size .5s ease;
}

.is-news .c-post__ttl {
  width: calc(100% - 142px);
}

@media screen and (max-width: 1024px) {
  .is-news .c-post__ttl {
    width: 100%;
  }
}

.c-refusal {
  text-align: right;
  margin-top: 30px;
}

.c-refusal img {
  width: 100%;
  max-width: 300px;
  margin-left: auto;
}

@media screen and (max-width: 767px) {
  .c-refusal img {
    max-width: 270px;
  }
}

/*----------------------------------------
c-sale
----------------------------------------*/
.c-sale {
  display: block;
  height: 80px;
}

@media screen and (max-width: 767px) {
  .c-sale {
    height: 50px;
  }
}

.c-sale__body {
  background: repeat-x center #d23d3d;
  background-size: auto 100%;
  height: 80px;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}

@media screen and (max-width: 767px) {
  .c-sale__body {
    height: 50px;
  }
}

/*----------------------------------------
c-search-header
----------------------------------------*/
.c-search-header {
  color: #343434;
  position: relative;
}

.c-search-header__form {
  display: table;
  width: 100%;
  position: relative;
  z-index: 1;
}

.c-search-header__row {
  width: 100%;
  display: table-row;
}

.c-search-header__cell {
  display: table-cell;
  padding: 12.5px 0;
  text-align: left;
}

.c-search-header__cell:not(:last-child) {
  padding-right: 20px;
}

@media screen and (max-width: 767px) {
  .c-search-header__cell:not(:last-child) {
    padding-right: 15px;
  }
}

@media screen and (max-width: 1024px) {
  .c-search-header__cell {
    padding: 10px 0;
  }
}

.c-search-header__ttl {
  font-size: 15px;
  font-size: 1.5rem;
  letter-spacing: 3.2px;
  letter-spacing: 0.32rem;
  white-space: nowrap;
  width: 8%;
}

.c-search-header__result-num {
  font-size: 20px;
  font-size: 2rem;
  font-weight: 900;
}

@media screen and (max-width: 767px) {
  .c-search-header__category {
    max-height: 72px;
    overflow: hidden;
  }
}

.c-search-header__category.is-active {
  max-height: 100%;
  margin-bottom: -10px;
}

.c-search-header__category-toggle {
  font-size: 12px;
  font-size: 1.2rem;
  color: #cccccc;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  text-decoration: underline;
  width: 100%;
  background: none;
  border: 0;
  text-align: right;
  outline: none;
  display: none;
}

@media screen and (max-width: 767px) {
  .c-search-header__category-toggle {
    display: block;
  }
}

.c-search-header__category-item {
  background: #e3e5e8;
  display: inline-block;
  padding: 4px 10px;
  margin: 0 4px 10px 0;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .c-search-header__category-item {
    margin: 0 5px 10px 0;
  }
}

.c-search-header__category-item:hover {
  background: #eee;
}

.c-search-header__category-item-txt {
  font-size: 14px;
  font-size: 1.4rem;
  margin: 0 10px 0 0;
}

.c-search-header__category-item-icon {
  position: relative;
  width: 10px;
  height: 10px;
  display: inline-block;
}

.c-search-header__category-item-icon::before {
  content: '';
  display: block;
  width: 10px;
  height: 1px;
  background: #000;
  transform: translateY(-50%) rotate(45deg);
  position: absolute;
  top: 50%;
  right: 0;
}

.c-search-header__category-item-icon::after {
  content: '';
  display: block;
  width: 10px;
  height: 1px;
  background: #000;
  transform: translateY(-50%) rotate(-45deg);
  position: absolute;
  top: 50%;
  right: 0;
}

.c-search-header__select {
  position: relative;
  display: inline-block;
}

.c-search-header__select::after {
  content: "";
  display: block;
  width: 7px;
  height: 7px;
  border: solid #909090;
  border-width: 0 1px 1px 0;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-75%) rotate(45deg);
  pointer-events: none;
}

.c-search-header__select select {
  font-size: 14px;
  font-size: 1.4rem;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  border: 1px solid #909090;
  padding: 7.5px 30px 7.5px 12.5px;
  background: #fff;
}

@media screen and (max-width: 767px) {
  .c-search-header__select select {
    padding: 6px 25px 6px 12px;
  }
}

/*----------------------------------------
c-section
----------------------------------------*/
.c-section {
  position: relative;
}

.c-section:not(:last-child) {
  margin-bottom: 80px;
}

.c-section:not(:last-child).is-contact {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .c-section:not(:last-child) {
    margin-bottom: 50px;
  }
}

.c-section__container {
  padding: 0 160px;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .c-section__container {
    padding: 0 80px;
  }
}

@media screen and (max-width: 767px) {
  .c-section__container {
    padding: 0 16px;
  }
}

.c-section__container.is-small {
  padding: 0 300px;
}

@media screen and (max-width: 1199px) {
  .c-section__container.is-small {
    padding: 0 160px;
  }
}

@media screen and (max-width: 1024px) {
  .c-section__container.is-small {
    padding: 0 80px;
  }
}

@media screen and (max-width: 767px) {
  .c-section__container.is-small {
    padding: 0 16px;
  }
}

.c-section.is-contact .c-section__container {
  padding: 0 400px;
}

@media screen and (max-width: 1330px) {
  .c-section.is-contact .c-section__container {
    padding: 0 160px;
  }
}

@media screen and (max-width: 1024px) {
  .c-section.is-contact .c-section__container {
    padding: 0 80px;
  }
}

@media screen and (max-width: 767px) {
  .c-section.is-contact .c-section__container {
    padding: 0 16px;
  }
}

.c-section.is-mypage .c-section__container {
  padding: 0 300px;
}

@media screen and (max-width: 1600px) {
  .c-section.is-mypage .c-section__container {
    padding: 0 160px;
  }
}

@media screen and (max-width: 1024px) {
  .c-section.is-mypage .c-section__container {
    padding: 0 80px;
  }
}

@media screen and (max-width: 767px) {
  .c-section.is-mypage .c-section__container {
    padding: 0 16px;
  }
}

.c-section__header {
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .c-section__header {
    margin-bottom: 20px;
  }
}

.c-section__list {
  position: relative;
  padding: 40px 80px 0;
  width: 100%;
}

.c-section__list:not(:last-child) {
  margin-bottom: 80px;
}

@media screen and (max-width: 767px) {
  .c-section__list:not(:last-child) {
    margin-bottom: 60px;
  }
}

.c-section__list:not(:last-child).is-pages {
  margin-bottom: 40px;
}

.c-section__list::after {
  content: '';
  position: absolute;
  background: #efefef;
  top: 0;
  right: 0;
  bottom: 60px;
  left: 0;
  z-index: -1;
}

@media screen and (max-width: 1024px) {
  .c-section__list::after {
    bottom: 0;
  }
}

.c-section__list.is-category {
  padding-top: 0;
  padding-bottom: 0;
}

.c-section__list.is-category:not(:last-child) {
  margin-bottom: 100px;
}

@media screen and (max-width: 767px) {
  .c-section__list.is-category:not(:last-child) {
    margin-bottom: 60px;
  }
}

.c-section__list.is-category::after {
  content: none;
}

.c-section__list.is-category.is-product:not(:last-child) {
  margin-bottom: 80px;
}

@media screen and (max-width: 767px) {
  .c-section__list.is-category.is-product:not(:last-child) {
    margin-bottom: 40px;
  }
}

.c-two-column__box {
  padding-left: 10px;
}

@media screen and (max-width: 1024px) {
  .c-two-column__box {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.c-two-column__box:not(:last-child) {
  margin-bottom: 20px;
}

@media screen and (max-width: 1024px) {
  .c-two-column__box:not(:last-child) {
    margin-bottom: 30px;
  }
}

.c-two-column__outer-ttl {
  font-size: 16px;
  font-size: 1.6rem;
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
  font-weight: 900;
  margin-bottom: 15px;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .c-two-column__outer-ttl {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.c-two-column__container {
  display: flex;
}

@media screen and (max-width: 1024px) {
  .c-two-column__container {
    flex-wrap: wrap;
  }
}

.c-two-column__txt {
  width: 80%;
  padding-right: 60px;
}

@media screen and (max-width: 1024px) {
  .c-two-column__txt {
    width: 100%;
    margin-top: 20px;
    padding-right: 0;
    order: 2;
  }
}

.c-two-column__desc {
  font-size: 15px;
  font-size: 1.5rem;
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .c-two-column__desc {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.c-two-column__figure {
  width: 20%;
}

@media screen and (max-width: 1024px) {
  .c-two-column__figure {
    width: 100%;
    order: 1;
  }
}

/*----------------------------------------
c-sidebar
----------------------------------------*/
.c-sidebar {
  padding: 10px 0 0;
  overflow-y: auto;
  overflow-x: hidden;
}

@media screen and (max-width: 1024px) {
  .c-sidebar {
    padding: 0;
  }
}

.c-sidebar .c-nav__categories {
  width: 100%;
  margin: -20px;
}

@media screen and (max-width: 1024px) {
  .c-sidebar .c-nav__categories {
    display: none;
  }
}

.c-sidebar .c-nav__categories-child {
  width: 100%;
  text-align: left;
  padding: 0;
}

.c-sidebar .c-nav__categories-child-ttl {
  position: relative;
  cursor: pointer;
}

.c-sidebar .c-nav__categories-child-ttl::before, .c-sidebar .c-nav__categories-child-ttl::after {
  background: #666;
  width: 10px;
  height: 1px;
  position: absolute;
  top: 50%;
  right: 0;
  transition: transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.c-sidebar .c-nav__categories-child-ttl::before {
  transform: rotate(90deg);
}

.c-sidebar .c-nav__categories-child-ttl.is-active::before {
  transform: rotate(180deg);
}

.c-sidebar .c-nav__categories-child-ttl.js-accrodion-btn::before, .c-sidebar .c-nav__categories-child-ttl.js-accrodion-btn::after {
  content: "";
  display: block;
}

.c-sidebar .c-nav__categories-child-ttl.js-accrodion-btn:hover .c-nav__categories-child-ttl-link {
  color: #cb4e4e;
}

.c-sidebar .c-nav__categories-child-ttl.js-accrodion-btn .c-nav__categories-child-ttl-link {
  pointer-events: none;
}

.c-sidebar .c-nav__categories-child-ttl-link {
  display: block;
  padding: 20px;
  transition: color 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

@media screen and (max-width: 767px) {
  .c-sidebar .c-nav__categories-child-ttl-link {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.c-sidebar .c-nav__categories-child-ttl-link:hover {
  color: #cb4e4e;
}

.c-sidebar .c-nav__categories-list {
  display: none;
  position: relative;
  z-index: 1;
}

.c-sidebar .c-nav__categories-list-item {
  position: relative;
}

.c-sidebar .c-nav__categories-list-item-link {
  color: #666;
  padding: 10px 20px 10px 40px;
  display: block;
  transition: color 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  position: relative;
}

@media screen and (max-width: 767px) {
  .c-sidebar .c-nav__categories-list-item-link {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.c-sidebar .c-nav__categories-list-item-link:hover {
  color: #cb4e4e;
}

.c-sidebar .c-nav__categories-list-item-link::before {
  content: "";
  height: 1px;
  width: 4px;
  position: absolute;
  top: 50%;
  left: 20px;
  background: #666;
}

.c-sidebar .c-nav__categories-list-item-link-container::before, .c-sidebar .c-nav__categories-list-item-link-container::after {
  background: #666;
  width: 10px;
  height: 1px;
  position: absolute;
  top: 17.5px;
  right: 0;
  transition: transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.c-sidebar .c-nav__categories-list-item-link-container::before {
  transform: rotate(90deg);
}

.c-sidebar .c-nav__categories-list-item-link-container.is-active::before {
  transform: rotate(180deg);
}

.c-sidebar .c-nav__categories-list-item-link-container.js-accrodion-btn {
  cursor: pointer;
}

.c-sidebar .c-nav__categories-list-item-link-container.js-accrodion-btn::before, .c-sidebar .c-nav__categories-list-item-link-container.js-accrodion-btn::after {
  content: "";
  display: block;
}

.c-sidebar .c-nav__categories-list-item-link-container.js-accrodion-btn:hover .c-nav__categories-list-item-link {
  color: #cb4e4e;
}

.c-sidebar .c-nav__categories-list-item-link-container.js-accrodion-btn .c-nav__categories-list-item-link {
  pointer-events: none;
}

.c-sidebar .c-nav__categories-list .c-nav__categories-list {
  padding-left: 20px;
}

.c-sidebar .c-nav__pages {
  width: 100%;
  padding: 20px 0 0;
}

@media screen and (max-width: 1024px) {
  .c-sidebar .c-nav__pages {
    display: none;
  }
}

.c-sidebar .c-nav__pages-list-item {
  margin: 10px 0 0;
}

.c-sidebar .c-nav__pages-list-item-link {
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 900;
  padding: 20px 0;
  display: block;
  text-align: left;
  border-bottom: 1px solid #343434;
  transition: color 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

@media screen and (max-width: 767px) {
  .c-sidebar .c-nav__pages-list-item-link {
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: 900;
  }
}

.c-sidebar .c-nav__pages-list-item-link:hover {
  color: #cb4e4e;
}

.c-sidebar .c-nav__sns {
  margin-top: 45px;
}

@media screen and (max-width: 1024px) {
  .c-sidebar .c-nav__sns {
    display: none;
  }
}

.c-sidebar .c-nav__sns-list {
  margin: -15px;
}

.c-sidebar .c-nav__sns-list-item {
  text-align: left;
}

.c-sidebar .c-nav__sns-list-item-link {
  font-family: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  padding: 15px;
  display: block;
  vertical-align: middle;
  transition: color .2s ease;
}

@media screen and (min-width: 1199px) {
  .c-sidebar .c-nav__sns-list-item-link:hover {
    color: #cb4e4e;
  }
  .c-sidebar .c-nav__sns-list-item-link:hover i {
    color: #cb4e4e;
  }
}

.c-sidebar .c-nav__sns-list-item-link i {
  font-size: 17px;
  font-size: 1.7rem;
  margin-right: 20px;
  width: 17px;
  text-align: center;
  transition: color .2s ease;
}

.c-sidebar__banner {
  padding: 30px 0 0;
}

@media screen and (max-width: 1024px) {
  .c-sidebar__banner {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  }
}

.c-sidebar__banner-item {
  padding: 30px 0 0;
}

@media screen and (max-width: 1024px) {
  .c-sidebar__banner-item {
    width: 100%;
    padding: 0;
  }
  .c-sidebar__banner-item:not(:first-child) {
    margin-top: 30px;
  }
}

.c-sidebar__banner-link {
  font-size: 0;
  display: block;
  transition: opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.c-sidebar__banner-link-img {
  width: 100%;
}

.c-sidebar__banner-link:hover {
  opacity: .8;
}

.c-sidebar__ttl {
  font-size: 24px;
  font-size: 2.4rem;
  letter-spacing: 2px;
  letter-spacing: 0.2rem;
  font-family: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  margin-bottom: 30px;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .c-sidebar__ttl {
    font-size: 20px;
    font-size: 2rem;
  }
}

.c-sidebar__section:not(:first-child) {
  margin-top: 90px;
}

@media screen and (max-width: 767px) {
  .c-sidebar__section:not(:first-child) {
    margin-top: 50px;
  }
}

.c-sidebar__categories-list {
  margin: -15px;
}

@media screen and (max-width: 767px) {
  .c-sidebar__categories-list {
    margin: -12px;
  }
}

.c-sidebar__categories-list-item {
  text-align: left;
}

.c-sidebar__categories-list-item-link {
  font-size: 15px;
  font-size: 1.5rem;
  letter-spacing: 2px;
  letter-spacing: 0.2rem;
  padding: 15px;
  display: block;
  line-height: 1.2;
  transition: color .2s ease;
}

@media screen and (min-width: 1199px) {
  .c-sidebar__categories-list-item-link:hover {
    color: #cb4e4e;
  }
}

@media screen and (max-width: 767px) {
  .c-sidebar__categories-list-item-link {
    padding: 12px;
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.c-sidebar__new-link {
  display: block;
  position: relative;
}

.c-sidebar__new-link:hover .c-sidebar__new-figure-img {
  transform: scale(1.1);
}

.c-sidebar__new-figure-img {
  transform: scale(1);
  transition: transform .2s ease;
}

.c-sidebar__new-category {
  font-size: 13px;
  font-size: 1.3rem;
  letter-spacing: 2px;
  letter-spacing: 0.2rem;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .c-sidebar__new-category {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.c-sidebar__new-category-container {
  position: absolute;
  top: 0;
  left: 0;
  background: #000;
  padding: 10px;
}

.c-sidebar__new-txt {
  padding: 20px 10px 30px;
}

@media screen and (max-width: 767px) {
  .c-sidebar__new-txt {
    padding: 15px 0 30px;
  }
  .c-sidebar__new-item:last-child .c-sidebar__new-txt {
    padding: 15px 0 0;
  }
}

.c-sidebar__new-ttl {
  font-size: 15px;
  font-size: 1.5rem;
  letter-spacing: 2px;
  letter-spacing: 0.2rem;
  margin-bottom: 10px;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .c-sidebar__new-ttl {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.c-sidebar__new-time {
  font-size: 15px;
  font-size: 1.5rem;
  letter-spacing: 2px;
  letter-spacing: 0.2rem;
  text-align: left;
  font-family: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  display: block;
  color: rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 767px) {
  .c-sidebar__new-time {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

/*----------------------------------------
c-style-book
----------------------------------------*/
.c-style-book-list {
  position: relative;
  padding-left: calc(50% + 15px);
  margin: 0 -15px;
}

@media screen and (max-width: 1024px) {
  .c-style-book-list {
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 767px) {
  .c-style-book-list {
    margin: 0 -7.5px;
  }
}

.c-style-book-list__article {
  height: 100%;
}

.c-style-book-list.is-short {
  padding: 0;
}

.c-style-book-list.is-short:nth-child(2n) {
  padding: 0;
}

.c-style-book-list.is-short:nth-child(2n) .c-style-book-list__item {
  float: left;
  width: 25%;
}

@media screen and (max-width: 1024px) {
  .c-style-book-list.is-short:nth-child(2n) .c-style-book-list__item {
    float: none;
    width: 50%;
  }
}

.c-style-book-list.is-short .c-style-book-list__item {
  width: 25%;
}

@media screen and (max-width: 1024px) {
  .c-style-book-list.is-short .c-style-book-list__item {
    width: 50%;
  }
}

.c-style-book-list__item {
  float: left;
  width: 50%;
  padding: 15px;
}

@media screen and (max-width: 1024px) {
  .c-style-book-list__item {
    float: none;
    order: 2;
  }
  .is-short .c-style-book-list__item {
    order: initial;
  }
}

@media screen and (max-width: 767px) {
  .c-style-book-list__item {
    padding: 7.5px;
  }
}

.c-style-book-list__item.is-large-item {
  float: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
}

@media screen and (max-width: 1024px) {
  .c-style-book-list__item.is-large-item {
    position: static;
    width: 100%;
    height: auto;
    order: 1;
  }
}

.c-style-book-list:nth-child(2n) {
  padding-right: calc(50% + 15px);
  padding-left: 0;
}

@media screen and (max-width: 1024px) {
  .c-style-book-list:nth-child(2n) {
    padding-right: 0;
  }
}

.c-style-book-list:nth-child(2n) .c-style-book-list__item {
  float: right;
}

@media screen and (max-width: 1024px) {
  .c-style-book-list:nth-child(2n) .c-style-book-list__item {
    float: none;
  }
}

.c-style-book-list:nth-child(2n) .c-style-book-list__item.is-large-item {
  float: none;
  top: 0;
  left: auto;
  right: 0;
}

.is-large-item .c-style-book-list__figure {
  height: 100%;
}

.is-large-item .c-style-book-list__figure::after {
  padding-bottom: 0;
}

@media screen and (max-width: 1024px) {
  .is-large-item .c-style-book-list__figure::after {
    padding-bottom: 60%;
  }
}

.c-style-book-list__bg.c-img-bg {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #f5f5f5;
}

.c-style-book-list__ttl {
  font-size: 20px;
  font-size: 2rem;
  letter-spacing: 2px;
  letter-spacing: 0.2rem;
  margin-bottom: 30px;
  line-height: 1;
}

@media screen and (max-width: 767px) {
  .c-style-book-list__ttl {
    font-size: 15px;
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
}

.c-style-book-header {
  display: flex;
  margin: 40px 0 60px;
  position: relative;
  height: 700px;
  justify-content: space-between;
}

@media screen and (max-width: 1024px) {
  .c-style-book-header {
    flex-wrap: wrap;
    height: auto;
    width: 100vw;
    margin: 40px -80px 60px;
  }
}

@media screen and (max-width: 767px) {
  .c-style-book-header {
    margin: 30px -16px 40px;
  }
}

.c-style-book-photo {
  width: 78.125%;
  height: 100%;
  position: relative;
  margin: 0;
}

@media screen and (max-width: 1024px) {
  .c-style-book-photo {
    width: 100%;
  }
}

.c-style-book-photo .swiper-wrapper {
  height: 100%;
}

.c-style-book-photo-thumb {
  height: 100%;
  width: 18%;
  margin: 0;
}

@media screen and (max-width: 1024px) {
  .c-style-book-photo-thumb {
    width: 100%;
    margin-top: 30px;
  }
}

.c-style-book-photo-thumb-item {
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .c-style-book-photo-thumb-item {
    width: 29%;
  }
}

.c-style-book-photo-thumb-item::before {
  content: '';
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
  transition: opacity .5s ease;
  pointer-events: none;
}

.c-style-book-photo-thumb-item.swiper-slide-thumb-active::before {
  pointer-events: initial;
  opacity: 1;
}

.c-style-book-photo-thumb-figure {
  height: 100%;
}

.c-style-book-photo-thumb-figure::after {
  padding-bottom: 0;
}

@media screen and (max-width: 1024px) {
  .c-style-book-photo-thumb-figure::after {
    padding-bottom: 60%;
  }
}

.c-style-book-photo-figure {
  height: 100%;
}

.c-style-book-photo-figure::after {
  padding-bottom: 0;
}

@media screen and (max-width: 1024px) {
  .c-style-book-photo-figure::after {
    padding-bottom: 60%;
  }
}

.c-style-book-photo-nav-next {
  position: absolute;
  top: 50%;
  right: 0;
  width: 50px;
  height: 50px;
  background: #000;
  cursor: pointer;
  margin-left: 1px;
  z-index: 1;
  transform: translateY(-50%);
}

@media screen and (max-width: 767px) {
  .c-style-book-photo-nav-next {
    width: 38px;
    height: 38px;
  }
}

@media screen and (min-width: 1199px) {
  .c-style-book-photo-nav-next:hover::before {
    transform: translateY(-50%) translateX(-50%) rotate(135deg);
  }
}

.c-style-book-photo-nav-next::before {
  content: "";
  display: inline-block;
  width: 9px;
  height: 9px;
  border: solid #fff;
  border-width: 1px 0 0 1px;
  transform: translateY(-50%) translateX(-75%) rotate(135deg);
  transform-origin: center center;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: transform .2s ease;
}

.c-style-book-photo-nav-prev {
  position: absolute;
  top: 50%;
  left: 0;
  width: 50px;
  height: 50px;
  background: #000;
  cursor: pointer;
  z-index: 1;
  transform: translateY(-50%);
}

@media screen and (max-width: 767px) {
  .c-style-book-photo-nav-prev {
    width: 38px;
    height: 38px;
  }
}

@media screen and (min-width: 1199px) {
  .c-style-book-photo-nav-prev:hover::before {
    transform: translateY(-50%) translateX(-50%) rotate(315deg);
  }
}

.c-style-book-photo-nav-prev::before {
  content: "";
  display: inline-block;
  width: 9px;
  height: 9px;
  border: solid #fff;
  border-width: 1px 0 0 1px;
  transform: translateY(-50%) translateX(-25%) rotate(315deg);
  transform-origin: center center;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: transform .2s ease;
}

/*----------------------------------------
c-style
----------------------------------------*/
.c-style__link {
  display: block;
}

.c-style__link:hover .c-style__figure-img {
  transform: scale(1.1);
}

.c-style__figure {
  overflow: hidden;
}

.c-style__figure-img {
  background: no-repeat center #f5f5f5;
  background-size: contain;
  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.c-style__figure-img::before {
  content: "";
  display: block;
  padding: 60% 0 0;
}

.c-style__figure-src {
  display: none;
}

/*----------------------------------------
c-ttl
----------------------------------------*/
.c-ttl {
  position: relative;
  z-index: 2;
  text-align: center;
}

.c-ttl.is-left {
  text-align: left;
}

.c-ttl.is-left .c-ttl__sub::before {
  transform: translateX(0);
  left: 0;
}

@media screen and (max-width: 1024px) {
  .p-index__blog .c-ttl.is-left {
    text-align: center;
  }
  .p-index__blog .c-ttl.is-left .c-ttl__sub::before {
    transform: translateX(-50%);
    left: 50%;
  }
}

.c-ttl__main {
  font-size: 25px;
  font-size: 2.5rem;
  display: block;
  line-height: 1.2;
}

@media screen and (max-width: 767px) {
  .c-ttl__main {
    font-size: 20px;
    font-size: 2rem;
  }
}

.c-ttl__sub {
  display: block;
  font-family: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  color: #38475e;
  font-weight: 900;
  padding-top: 30px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .c-ttl__sub {
    font-size: 12px;
    font-size: 1.2rem;
    padding-top: 20px;
  }
}

.c-ttl__sub::before {
  content: '';
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
  width: 40px;
  background: #38475e;
}

@media screen and (max-width: 767px) {
  .c-ttl__sub::before {
    width: 30px;
    top: 10px;
  }
}

.c-page__ttl {
  height: 200px;
  padding: 30px 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #e7ecef;
  flex-direction: column;
}

@media screen and (max-width: 1024px) {
  .c-page__ttl {
    padding: 30px 80px;
  }
}

@media screen and (max-width: 767px) {
  .c-page__ttl {
    height: 150px;
    padding: 40px 16px;
  }
}

.c-page__ttl-main {
  font-size: 25px;
  font-size: 2.5rem;
  text-indent: .05rem;
}

@media screen and (max-width: 767px) {
  .c-page__ttl-main {
    font-size: 20px;
    font-size: 2rem;
  }
}

.c-page__ttl-sub {
  font-size: 14px;
  font-size: 1.4rem;
  text-indent: .05rem;
  font-family: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-weight: 700;
  color: #38475e;
  padding-top: 25px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .c-page__ttl-sub {
    font-size: 12px;
    font-size: 1.2rem;
    padding-top: 20px;
  }
}

.c-page__ttl-sub::before {
  content: '';
  width: 40px;
  height: 2px;
  background: #38475e;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translate(-50%);
}

@media screen and (max-width: 767px) {
  .c-page__ttl-sub::before {
    width: 30px;
    top: 7px;
  }
}

.c-page__content-ttl {
  margin-bottom: 25px;
  padding: 0 10px 15px;
  position: relative;
  border-bottom: 1px solid #38475e;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .c-page__content-ttl {
    padding: 0 5px 15px;
    margin-bottom: 15px;
  }
}

.c-page__content-ttl-txt {
  font-size: 18px;
  font-size: 1.8rem;
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
}

@media screen and (max-width: 767px) {
  .c-page__content-ttl-txt {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

/*----------------------------------------
c-u-list-child
----------------------------------------*/
.c-u-list-child {
  text-align: justify;
  padding: 5px 0;
}

.c-u-list-child__item {
  font-size: 14px;
  font-size: 1.4rem;
  color: #333;
  line-height: 1.8;
  padding: 0 0 0 10px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .c-u-list-child__item {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.c-u-list-child__item::before {
  content: "";
  display: block;
  width: 3px;
  height: 3px;
  border-radius: 50px;
  background: #333;
  position: absolute;
  top: 10px;
  left: 2px;
}

/*----------------------------------------
c-u-list
----------------------------------------*/
.c-u-list {
  text-align: justify;
  padding: 0 5px;
}

.c-u-list__item {
  line-height: 1.8;
  padding: 0 0 0 15px;
  margin: 0 0 35px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .c-u-list__item {
    margin: 0 0 25px;
    padding: 0 0 0 15px;
  }
}

.c-u-list__item::before {
  content: "";
  display: block;
  width: 3px;
  height: 3px;
  border-radius: 50px;
  background: #343434;
  position: absolute;
  top: 8px;
  left: 0;
}

.l-content {
  position: relative;
  z-index: 1;
  margin: 0 auto;
  padding-bottom: 100px;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  .l-content {
    padding-bottom: 50px;
  }
}

.l-content.is-home {
  padding-top: 80px;
  padding-bottom: 0;
}

@media screen and (max-width: 767px) {
  .l-content.is-home {
    padding-top: 30px;
  }
}

.l-content.p-single-product-container {
  position: static;
  overflow: visible;
}

@media screen and (max-width: 1024px) {
  .l-content.p-single-product-container {
    overflow: hidden;
  }
}

.l-content__main {
  width: 100%;
}

.l-detail {
  position: relative;
  z-index: 1;
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 40px 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

@media screen and (max-width: 1024px) {
  .l-detail {
    display: block;
    padding: 0;
    margin: -20px 0 0;
  }
}

.l-detail__head {
  width: 50%;
  padding: 0 40px 40px 80px;
  position: sticky;
  top: 0;
}

@media screen and (max-width: 1024px) {
  .l-detail__head {
    position: static;
    width: 100%;
    padding: 0 80px 60px;
    order: 0;
  }
}

@media screen and (max-width: 767px) {
  .l-detail__head {
    padding: 0 16px 60px;
  }
}

.l-detail__main {
  width: 50%;
  padding: 0 40px;
}

@media screen and (max-width: 1024px) {
  .l-detail__main {
    width: 100%;
    padding: 0;
  }
}

.l-main {
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  padding: 0 80px;
}

@media screen and (max-width: 1330px) {
  .l-main {
    padding: 0 75px;
  }
}

@media screen and (max-width: 1199px) {
  .l-main {
    padding: 0 50px;
  }
}

@media screen and (max-width: 767px) {
  .l-main {
    padding: 0 16px;
  }
}

.l-post {
  width: 100%;
}

/*----------------------------------------
404
----------------------------------------*/
.p-404__section {
  margin: 0 0 40px;
}

@media screen and (max-width: 767px) {
  .p-404__section {
    padding: 20px 0 0;
  }
}

.p-404__section .c-guid-desc {
  margin: 0 0 40px;
}

.p-404__section .c-guid-desc p:not([class]) {
  margin: 0 0 20px;
}

/*----------------------------------------
about
----------------------------------------*/
.p-about__intro {
  padding-right: 160px;
  padding-bottom: 80px;
}

@media screen and (max-width: 1024px) {
  .p-about__intro {
    padding-right: 80px;
  }
}

@media screen and (max-width: 767px) {
  .p-about__intro {
    padding-right: 16px;
    padding-bottom: 50px;
  }
}

.p-about__intro-container {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .p-about__intro-container {
    flex-wrap: wrap;
  }
}

.p-about__intro-img {
  position: relative;
  width: 55%;
}

@media screen and (max-width: 1024px) {
  .p-about__intro-img {
    width: 100%;
  }
}

.p-about__intro-img::before {
  position: absolute;
  content: '';
  top: 20px;
  right: -20px;
  bottom: -20px;
  left: 20px;
  background: rgba(56, 71, 94, 0.3);
}

@media screen and (max-width: 767px) {
  .p-about__intro-img::before {
    top: 8px;
    right: -8px;
    bottom: -8px;
    left: 8px;
  }
}

.p-about__intro-figure {
  position: relative;
}

.p-about__intro-figure::after {
  content: '';
  padding-bottom: 57%;
}

.p-about__intro-txt {
  width: 45%;
  padding-left: 160px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

@media screen and (max-width: 1024px) {
  .p-about__intro-txt {
    width: 100%;
    padding-left: 80px;
  }
}

@media screen and (max-width: 767px) {
  .p-about__intro-txt {
    padding-left: 32px;
    padding-right: 16px;
  }
}

.p-about__intro-ttl {
  font-size: 25px;
  font-size: 2.5rem;
  text-align: left;
}

@media screen and (max-width: 1024px) {
  .p-about__intro-ttl {
    padding-top: 30px;
  }
}

@media screen and (max-width: 767px) {
  .p-about__intro-ttl {
    font-size: 20px;
    font-size: 2rem;
  }
}

.p-about__intro-desc {
  padding-top: 50px;
  position: relative;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .p-about__intro-desc {
    padding-top: 35px;
  }
}

.p-about__intro-desc::before {
  content: '';
  position: absolute;
  top: 10px;
  left: 0;
  width: 40px;
  height: 2px;
  background: #38475e;
}

@media screen and (max-width: 767px) {
  .p-about__intro-desc::before {
    width: 30px;
  }
}

.p-about__feature-list {
  display: flex;
  margin: 0 -20px;
}

@media screen and (max-width: 1330px) {
  .p-about__feature-list {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media screen and (max-width: 1024px) {
  .p-about__feature-list {
    margin: 0 -15px;
  }
}

.p-about__feature-list.is-overed {
  flex-wrap: wrap;
}

.p-about__feature-list.is-overed .p-about__feature-list-item {
  width: 50%;
}

@media screen and (max-width: 1024px) {
  .p-about__feature-list.is-overed .p-about__feature-list-item {
    width: 100%;
  }
}

.p-about__feature-list-item {
  width: 33.333%;
  padding: 20px;
}

@media screen and (max-width: 1330px) {
  .p-about__feature-list-item {
    width: 50%;
  }
}

@media screen and (max-width: 1024px) {
  .p-about__feature-list-item {
    width: 100%;
    padding: 15px;
  }
}

.p-about__feature-list-item-container {
  padding: 40px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .p-about__feature-list-item-container {
    padding: 40px 16px;
  }
}

.p-about__feature-list-item-container::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%224%22%20height%3D%224%22%20viewBox%3D%220%200%202%202%22%20preserveAspectRatio%3D%22none%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M1-1h.10l-4%204v-.10zM3-1v.10l-4%204h-.10z%22%2F%3E%20%3C%2Fsvg%3E");
  opacity: .3;
}

.p-about__feature-list-item-container::before {
  font-size: 14px;
  font-size: 1.4rem;
  text-indent: .05rem;
  font-weight: 700;
  font-family: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  content: attr(data-label);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  color: #fff;
  background: #38475e;
  display: block;
  padding: 6px 20px 3px;
  line-height: 1;
}

@media screen and (max-width: 767px) {
  .p-about__feature-list-item-container::before {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.p-about__feature-list-item-figure {
  position: relative;
  z-index: 1;
}

.p-about__feature-list-item-figure::after {
  padding-bottom: 56%;
}

.p-about__feature-list-item-txt {
  position: relative;
}

.p-about__feature-list-item-ttl {
  font-size: 20px;
  font-size: 2rem;
  padding-top: 25px;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .p-about__feature-list-item-ttl {
    font-size: 17px;
    font-size: 1.7rem;
    padding-top: 20px;
  }
}

.p-about__feature-list-item-desc {
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
  padding-top: 25px;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .p-about__feature-list-item-desc {
    padding-top: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .p-about__access-block:not(:last-child) {
    padding-bottom: 40px;
  }
}

.p-about__access-ttl {
  font-size: 20px;
  font-size: 2rem;
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
  text-align: left;
  padding: 40px 160px;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .p-about__access-ttl {
    padding: 40px 80px;
  }
}

@media screen and (max-width: 767px) {
  .p-about__access-ttl {
    padding: 20px 32px;
  }
}

.p-about__access-ttl::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #e7ecef;
}

@media screen and (max-width: 1024px) {
  .p-about__access-ttl::after {
    bottom: -130px;
  }
}

.p-about__access-ttl-container {
  position: relative;
  z-index: 1;
}

.p-about__access-data {
  width: 62.5%;
  padding-right: 60px;
}

.p-about__access-block:not(:last-child) .p-about__access-data {
  padding-bottom: 110px;
}

@media screen and (max-width: 1024px) {
  .p-about__access-block:not(:last-child) .p-about__access-data {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 1024px) {
  .p-about__access-data {
    width: 100%;
    order: 2;
    padding-right: 0;
    padding-bottom: 0;
  }
}

.p-about__access-data-block {
  padding: 30px 60px 30px 0;
  display: flex;
  border-bottom: 1px solid #38475e;
}

@media screen and (max-width: 1024px) {
  .p-about__access-data-block {
    flex-wrap: wrap;
    padding: 20px 0 0;
    border-bottom: none;
  }
}

.p-about__access-data-ttl {
  font-size: 16px;
  font-size: 1.6rem;
  letter-spacing: 2px;
  letter-spacing: 0.2rem;
  text-align: left;
  width: 20%;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

@media screen and (max-width: 1024px) {
  .p-about__access-data-ttl {
    width: 100%;
    padding-left: 0;
    padding-bottom: 24px;
    position: relative;
  }
}

@media screen and (max-width: 767px) {
  .p-about__access-data-ttl {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.p-about__access-data-ttl::after {
  content: none;
  width: 100%;
  height: 1px;
  background: #38475e;
  position: absolute;
  bottom: 12px;
  left: 0;
}

@media screen and (max-width: 1024px) {
  .p-about__access-data-ttl::after {
    content: '';
  }
}

.p-about__access-data-desc {
  width: 80%;
  display: flex;
  align-items: center;
  text-align: left;
  padding-left: 50px;
  line-height: 2;
}

@media screen and (max-width: 1024px) {
  .p-about__access-data-desc {
    width: 100%;
    padding-left: 0;
    display: block;
  }
}

.p-about__access-map {
  width: 37.5%;
  margin-top: -70px;
}

@media screen and (max-width: 1024px) {
  .p-about__access-map {
    order: 1;
    margin-top: 0;
    width: 100%;
  }
}

.p-about__access-map iframe {
  max-height: 480px;
}

@media screen and (max-width: 767px) {
  .p-about__access-map iframe {
    max-height: 280px;
  }
}

.p-about__access-container {
  display: flex;
}

@media screen and (max-width: 1024px) {
  .p-about__access-container {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 767px) {
  .p-about__access-container {
    padding: 0 32px;
  }
}

/*----------------------------------------
category
----------------------------------------*/
.p-category__list {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}

.p-category__list-item {
  width: 25%;
}

@media screen and (max-width: 1024px) {
  .p-category__list-item {
    width: 50%;
  }
}

.p-category__list-item-link {
  display: block;
  padding: 10px;
}

.p-category__list-item-ttl {
  font-size: 18px;
  font-size: 1.8rem;
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
  padding-top: 15px;
  padding-left: 10px;
  font-weight: 900;
  text-align: left;
  color: #777;
}

@media screen and (max-width: 767px) {
  .p-category__list-item-ttl {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.p-category__list-item-figure::after {
  padding-bottom: 50%;
}

/*----------------------------------------
content
----------------------------------------*/
.p-content__container {
  background: #efefef;
  padding: 60px 80px;
}

.p-content__container:not(:last-child) {
  margin-bottom: 120px;
}

@media screen and (max-width: 767px) {
  .p-content__container:not(:last-child) {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 1330px) {
  .p-content__container {
    padding: 60px 75px;
  }
}

@media screen and (max-width: 1199px) {
  .p-content__container {
    padding: 60px 50px;
  }
}

@media screen and (max-width: 767px) {
  .p-content__container {
    padding: 40px 16px 20px;
  }
}

.p-content__container.is-contact {
  background: none;
  position: relative;
  z-index: 1;
  padding-bottom: 0;
}

.p-content__container.is-blog {
  background: none;
  position: relative;
  z-index: 1;
  padding-top: 30px;
  padding-bottom: 0;
}

.p-content__container.is-blog::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 60px;
  background: #efefef;
}

.p-content__container.is-topics {
  background: none;
  position: relative;
  z-index: 1;
  padding-bottom: 0;
}

@media screen and (max-width: 1024px) {
  .p-content__container.is-topics {
    padding-top: 30px;
  }
}

.p-content__container.is-topics::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 185px;
  background: #efefef;
}

@media screen and (max-width: 1024px) {
  .p-content__container.is-topics::after {
    height: 60px;
  }
}

.p-content__container.is-categories {
  background: none;
  position: relative;
  z-index: 1;
  padding-bottom: 0;
  margin-bottom: 60px;
}

@media screen and (max-width: 1330px) {
  .p-content__container.is-categories {
    padding: 60px 75px 0;
  }
}

@media screen and (max-width: 1199px) {
  .p-content__container.is-categories {
    padding: 0 50px 0;
  }
}

@media screen and (max-width: 767px) {
  .p-content__container.is-categories {
    padding: 0 16px 0;
    margin-bottom: 40px;
  }
}

.p-content__container.is-categories::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 120px;
  background: #efefef;
}

@media screen and (max-width: 1024px) {
  .p-content__container.is-categories::after {
    content: none;
  }
}

.p-content__container-inner {
  background: #fff;
  padding: 95px;
}

@media screen and (max-width: 767px) {
  .p-content__container-inner {
    padding: 35px 16px;
  }
}

.is-contact .p-content__container-inner {
  padding: 65px 150px 0;
  background: #fff;
}

@media screen and (max-width: 1024px) {
  .is-contact .p-content__container-inner {
    padding: 65px 100px 0;
  }
}

@media screen and (max-width: 767px) {
  .is-contact .p-content__container-inner {
    padding: 20px 16px 0;
  }
}

.is-blog .p-content__container-inner {
  padding: 0;
}

.is-topics .p-content__container-inner {
  padding: 0;
}

.is-categories .p-content__container-inner {
  position: relative;
  z-index: 2;
  padding: 20px 20px 0;
}

@media screen and (max-width: 1024px) {
  .is-categories .p-content__container-inner {
    padding: 0;
  }
}

.p-content__section:not(:last-child) {
  margin: 0 0 80px;
}

@media screen and (max-width: 767px) {
  .p-content__section:not(:last-child) {
    margin: 0 0 40px;
  }
}

/*----------------------------------------
faq
----------------------------------------*/
.p-faq__list {
  border-top: 1px solid #38475e;
}

.p-faq__list-item {
  border-bottom: 1px solid #38475e;
  padding: 20px 10px;
}

@media screen and (max-width: 767px) {
  .p-faq__list-item {
    padding: 15px 10px;
  }
}

.p-faq__list-item-ttl {
  position: relative;
  text-align: left;
  cursor: pointer;
  line-height: 30px;
  display: flex;
  align-items: flex-start;
}

@media screen and (max-width: 767px) {
  .p-faq__list-item-ttl {
    line-height: 20px;
  }
}

.p-faq__list-item-ttl::before {
  font-size: 18px;
  font-size: 1.8rem;
  content: 'Q';
  width: 30px;
  height: 30px;
  text-align: center;
  background: #38475e;
  color: #fff;
  font-family: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-weight: 700;
  display: flex;
  justify-content: center;
  line-height: 33px;
}

@media screen and (max-width: 767px) {
  .p-faq__list-item-ttl::before {
    font-size: 15px;
    font-size: 1.5rem;
    width: 20px;
    height: 20px;
    line-height: 23px;
  }
}

.p-faq__list-item-ttl-txt {
  font-size: 18px;
  font-size: 1.8rem;
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
  margin-left: 10px;
  margin-right: 50px;
  display: block;
  width: calc(100% - 60px);
}

@media screen and (max-width: 767px) {
  .p-faq__list-item-ttl-txt {
    font-size: 15px;
    font-size: 1.5rem;
    margin-left: 10px;
    margin-right: 30px;
    width: calc(100% - 40px);
  }
}

.p-faq__list-item-desc {
  text-align: left;
  padding-top: 20px;
  display: none;
}

.p-faq__list-item-desc-txt {
  display: flex;
  align-items: flex-start;
}

.p-faq__list-item-desc-txt::before {
  font-size: 18px;
  font-size: 1.8rem;
  content: 'A';
  width: 30px;
  height: 30px;
  text-align: center;
  background: #e7ecef;
  color: #38475e;
  font-family: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-weight: 700;
  display: flex;
  justify-content: center;
  line-height: 33px;
}

@media screen and (max-width: 767px) {
  .p-faq__list-item-desc-txt::before {
    font-size: 15px;
    font-size: 1.5rem;
    width: 20px;
    height: 20px;
    line-height: 23px;
  }
}

.p-faq__list-item-desc-txt-inner {
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
  margin-left: 10px;
  line-height: 30px;
  display: block;
  width: calc(100% - 40px);
}

@media screen and (max-width: 767px) {
  .p-faq__list-item-desc-txt-inner {
    line-height: 20px;
    width: calc(100% - 30px);
  }
}

.p-faq__list-item-btn {
  position: absolute;
  top: 50%;
  right: 0;
  width: 16px;
  height: 16px;
  border: none;
  background: none;
  transform: translateY(-50%);
}

@media screen and (max-width: 767px) {
  .p-faq__list-item-btn {
    width: 10px;
    height: 10px;
  }
}

.is-active .p-faq__list-item-btn::before {
  transform: rotate(360deg);
}

.is-active .p-faq__list-item-btn::after {
  transform: rotate(360deg);
}

.p-faq__list-item-btn::before {
  content: '';
  width: 100%;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 0;
  background: #333;
  display: block;
  transition: transform .3s ease;
}

.p-faq__list-item-btn::after {
  content: '';
  width: 100%;
  height: 1px;
  position: absolute;
  background: #333;
  top: 50%;
  left: 0;
  transform: rotate(90deg);
  transform-origin: center center;
  display: block;
  transition: transform .3s ease;
}

/*----------------------------------------
index
----------------------------------------*/
.p-index__visual {
  margin-top: -100px;
}

@media screen and (max-width: 767px) {
  .p-index__visual {
    margin-top: -60px;
  }
}

.p-index__visual-container {
  height: 90vh;
  min-height: 800px;
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 1024px) {
  .p-index__visual-container {
    height: auto;
    min-height: auto;
  }
}

.p-index__visual-main {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 40px;
  left: 0;
}

@media screen and (max-width: 767px) {
  .p-index__visual-main {
    bottom: 25px;
  }
}

.p-index__visual-main::after {
  content: '';
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%226%22%20height%3D%226%22%20viewBox%3D%220%200%202%202%22%20preserveAspectRatio%3D%22none%22%3E%20%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M-1-1v4h4v-4z%22%2F%3E%20%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M1-1h.10l-4%204v-.10zM3-1v.10l-4%204h-.10z%22%2F%3E%20%3C%2Fsvg%3E");
  background-repeat: repeat;
  position: absolute;
  top: 20px;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: .3;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .p-index__visual-main::after {
    top: 16px;
  }
}

.p-index__visual-main-box {
  position: absolute;
  top: 100px;
  bottom: 75px;
  right: 0;
  padding: 0 0 0 20px;
  overflow: hidden;
  width: 820px;
}

@media screen and (max-width: 1600px) {
  .p-index__visual-main-box {
    width: 720px;
  }
}

@media screen and (max-width: 1330px) {
  .p-index__visual-main-box {
    width: 520px;
  }
}

@media screen and (max-width: 1024px) {
  .p-index__visual-main-box {
    bottom: auto;
    width: calc(100% - 80px);
  }
}

@media screen and (max-width: 767px) {
  .p-index__visual-main-box {
    top: 60px;
    width: calc(100% - 16px);
    padding-left: 16px;
  }
}

.p-index__visual-main-box.is-single-post {
  bottom: 120px;
}

@media screen and (max-width: 1024px) {
  .p-index__visual-main-box.is-single-post {
    bottom: auto;
  }
}

.p-index__visual-main-box.is-double-post {
  bottom: 195px;
}

@media screen and (max-width: 1024px) {
  .p-index__visual-main-box.is-double-post {
    bottom: auto;
  }
}

.p-index__visual-main-wrapper {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1024px) {
  .p-index__visual-main-wrapper {
    padding-bottom: 105%;
  }
}

@media screen and (max-width: 1024px) {
  .p-index__visual-main-box.is-single-post .p-index__visual-main-wrapper,
  .p-index__visual-main-box.is-double-post .p-index__visual-main-wrapper {
    padding-bottom: 67.5%;
  }
}

.p-index__visual-main-container {
  height: 100%;
  z-index: 2;
  overflow: visible;
  padding: 0 0 20px 20px;
}

@media screen and (max-width: 767px) {
  .p-index__visual-main-container {
    padding: 0 0 16px 16px;
  }
}

.p-index__visual-main-slide {
  height: 100%;
}

@media screen and (max-width: 767px) {
  .p-index__visual-main-slide {
    transform: translateX(80px);
  }
}

.p-index__visual-main-slide.swiper-slide-active, .p-index__visual-main-slide.swiper-slide-duplicate-active {
  z-index: 2;
}

.p-index__visual-main-slide-link {
  height: 100%;
  transform: translateY(20px);
  display: block;
  animation: slider-fade-reverse .5s ease forwards;
}

.swiper-slide-active .p-index__visual-main-slide-link, .swiper-slide-duplicate-active .p-index__visual-main-slide-link {
  animation: slider-fade 1s ease forwards;
}

.p-index__visual-sub {
  height: 100%;
  width: 100%;
}

.p-index__visual-sub-wrapper.is-single-post .p-index__visual-sub {
  height: calc(100% - 70px);
}

@media screen and (max-width: 1024px) {
  .p-index__visual-sub-wrapper.is-single-post .p-index__visual-sub {
    height: 100%;
  }
}

.p-index__visual-sub-wrapper.is-double-post .p-index__visual-sub {
  height: calc(100% - 140px);
}

@media screen and (max-width: 1024px) {
  .p-index__visual-sub-wrapper.is-double-post .p-index__visual-sub {
    height: 100%;
  }
}

.p-index__visual-sub-wrapper {
  width: 100%;
  padding-right: 160px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1024px) {
  .p-index__visual-sub-wrapper {
    padding-right: 80px;
    height: auto;
  }
}

@media screen and (max-width: 767px) {
  .p-index__visual-sub-wrapper {
    padding-right: 32px;
  }
}

.p-index__visual-sub-slide {
  height: 100%;
}

.p-index__visual-sub-slide-txt {
  width: calc(100% - 820px + 160px);
  position: relative;
  z-index: 2;
  padding: 80px 0 80px 80px;
  margin-right: 20px;
  text-align: left;
  flex-wrap: wrap;
  opacity: 0;
  animation: slider-txt-reverse .3s ease forwards;
}

@media screen and (max-width: 1600px) {
  .p-index__visual-sub-slide-txt {
    width: calc(100% - 720px + 160px);
  }
}

@media screen and (max-width: 1330px) {
  .p-index__visual-sub-slide-txt {
    width: calc(100% - 520px + 160px);
  }
}

@media screen and (max-width: 1024px) {
  .p-index__visual-sub-slide-txt {
    width: 100%;
    margin-top: calc(105% + 115px);
    margin-bottom: 160px;
    margin-right: 0;
  }
}

@media screen and (max-width: 767px) {
  .p-index__visual-sub-slide-txt {
    padding: 20px 0 20px 16px;
    margin-top: calc(105% + 75px);
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 1024px) {
  .p-index__visual-sub-wrapper.is-single-post .p-index__visual-sub-slide-txt,
  .p-index__visual-sub-wrapper.is-double-post .p-index__visual-sub-slide-txt {
    margin-top: calc(67.5% + 115px);
  }
}

@media screen and (max-width: 767px) {
  .p-index__visual-sub-wrapper.is-single-post .p-index__visual-sub-slide-txt,
  .p-index__visual-sub-wrapper.is-double-post .p-index__visual-sub-slide-txt {
    margin-top: calc(67.5% + 75px);
  }
}

.p-index__visual-sub-slide-txt::after {
  content: '';
  position: absolute;
  background: rgba(145, 179, 185, 0.3);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@media screen and (max-width: 1024px) {
  .p-index__visual-sub-slide-txt::after {
    right: 40px;
  }
}

@media screen and (max-width: 767px) {
  .p-index__visual-sub-slide-txt::after {
    right: 28px;
  }
}

.swiper-slide-active .p-index__visual-sub-slide-txt, .swiper-slide-duplicate-active .p-index__visual-sub-slide-txt {
  animation: slider-txt 1s ease .6s forwards;
}

.p-index__visual-sub-slide-txt-container {
  border-bottom: 1px solid #fff;
  padding-bottom: 40px;
  padding-right: 20px;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .p-index__visual-sub-slide-txt-container {
    padding-right: 10px;
    padding-bottom: 16px;
  }
}

.p-index__visual-sub-slide-txt-inner {
  font-size: 45px;
  font-size: 4.5rem;
  font-weight: 900;
  display: inline-block;
  padding: 15px;
  line-height: 1;
  background: #fff;
  color: #81a3a1;
}

@media screen and (max-width: 1024px) {
  .p-index__visual-sub-slide-txt-inner {
    box-shadow: 0px 0px 16px -6px rgba(0, 0, 0, 0.3);
  }
}

@media screen and (max-width: 767px) {
  .p-index__visual-sub-slide-txt-inner {
    font-size: 22px;
    font-size: 2.2rem;
    padding: 10px 16px;
  }
}

.p-index__visual-sub-slide-txt-inner:not(:last-child) {
  margin-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .p-index__visual-sub-slide-txt-inner:not(:last-child) {
    margin-bottom: 10px;
  }
}

.p-index__visual-sub-slide-btn {
  font-size: 15px;
  font-size: 1.5rem;
  text-indent: -.01rem;
  display: inline-block;
  padding: 15px 70px;
  border: 1px solid #fff;
  margin-top: -1px;
  color: #fff;
  font-family: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  background: none;
  line-height: 1;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .p-index__visual-sub-slide-btn {
    font-size: 11px;
    font-size: 1.1rem;
    padding: 10px 32px;
  }
}

.p-index__visual-slide-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  height: 100%;
}

.p-index__visual-slide-link-img {
  overflow: hidden;
  position: absolute;
  background-size: cover;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-position: left bottom;
}

@media screen and (max-width: 1024px) {
  .p-index__visual-slide-link-img.is-sub {
    right: 40px;
  }
}

@media screen and (max-width: 767px) {
  .p-index__visual-slide-link-img.is-sub {
    right: 28px;
  }
}

.p-index__visual-nav {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  width: 120px;
  display: flex;
  pointer-events: initial;
}

@media screen and (max-width: 1024px) {
  .p-index__visual-nav {
    pointer-events: initial;
    justify-content: flex-end;
    bottom: auto;
    top: 0;
    transform: translateY(-50%);
  }
}

@media screen and (max-width: 767px) {
  .p-index__visual-nav {
    width: 80px;
  }
}

@media screen and (max-width: 767px) {
  .p-index__visual-nav-next {
    width: 40px;
    height: 40px;
  }
}

@media screen and (max-width: 767px) {
  .p-index__visual-nav-prev {
    width: 40px;
    height: 40px;
  }
}

.p-index__visual-pagination {
  display: flex;
  align-items: center;
  padding: 0 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
}

.p-index__visual-pagination .swiper-pagination-bullet {
  background: #fff;
  opacity: .3;
  width: 8px;
  height: 8px;
}

.p-index__visual-pagination .swiper-pagination-bullet:not(:last-child) {
  margin-right: 10px;
}

.p-index__visual-pagination .swiper-pagination-bullet-active {
  opacity: 1;
}

.p-index__visual-bottom {
  transform: translateY(-50%);
  position: relative;
  z-index: 2;
  display: flex;
  padding-left: 160px;
}

@media screen and (max-width: 1199px) {
  .p-index__visual-bottom {
    padding-left: 100px;
  }
}

@media screen and (max-width: 1024px) {
  .p-index__visual-bottom {
    justify-content: flex-end;
    flex-wrap: wrap;
    transform: none;
    padding-left: 0;
  }
}

.p-index__visual-scroll {
  font-size: 13px;
  font-size: 1.3rem;
  letter-spacing: 1.2px;
  letter-spacing: 0.12rem;
  color: #333;
  font-weight: 900;
  font-family: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  position: absolute;
  bottom: 0;
  left: 75px;
  padding-left: 25px;
  padding-bottom: 20px;
  padding-top: 15px;
  padding-right: 70px;
  line-height: 1;
  overflow: hidden;
  transform: rotate(90deg) translateX(-100%);
  transform-origin: left bottom;
}

@media screen and (max-width: 1330px) {
  .p-index__visual-scroll {
    left: 70px;
  }
}

@media screen and (max-width: 1199px) {
  .p-index__visual-scroll {
    left: 45px;
  }
}

@media screen and (max-width: 1024px) {
  .p-index__visual-scroll {
    bottom: auto;
    top: -18px;
  }
}

@media screen and (max-width: 767px) {
  .p-index__visual-scroll {
    font-size: 10px;
    font-size: 1rem;
    top: -13px;
    left: 10px;
    padding-bottom: 15px;
    padding-top: 8px;
    padding-right: 20px;
  }
}

.p-index__visual-scroll::before {
  content: '';
  position: absolute;
  right: 14px;
  bottom: 13px;
  width: 1px;
  height: 15px;
  background: #333;
  transform: rotate(115deg);
  transform-origin: right bottom;
  display: inline-block;
  animation: scroll-small 2s cubic-bezier(0.075, 0.82, 0.165, 1) infinite;
}

.p-index__visual-scroll::after {
  position: absolute;
  bottom: 6px;
  right: 0;
  content: "";
  height: 150px;
  width: 1px;
  background: #333;
  transform-origin: top;
  display: inline-block;
  transition: transform .5s ease;
  animation: scroll-large 2s cubic-bezier(0.075, 0.82, 0.165, 1) infinite;
  transform-origin: left bottom;
  transform: rotate(-90deg);
}

@media screen and (max-width: 767px) {
  .p-index__visual-scroll::after {
    height: 75px;
  }
}

.p-index__campaign, .p-index__news-top {
  padding-left: 160px;
  position: relative;
  overflow: hidden;
  height: 70px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.p-index__campaign:last-child, .p-index__news-top:last-child {
  border-top: 1px solid #dfdfdf;
}

@media screen and (max-width: 1024px) {
  .p-index__campaign, .p-index__news-top {
    padding-left: 80px;
    margin-right: 40px;
    padding-bottom: 24px;
    height: auto;
  }
}

@media screen and (max-width: 767px) {
  .p-index__campaign, .p-index__news-top {
    padding-left: 16px;
    margin-right: 28px;
    padding-bottom: 12px;
  }
}

.p-index__campaign::after, .p-index__news-top::after {
  content: '';
  height: auto;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: transparent;
  opacity: .3;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%224%22%20height%3D%224%22%20viewBox%3D%220%200%202%202%22%20preserveAspectRatio%3D%22none%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M1-1h.10l-4%204v-.10zM3-1v.10l-4%204h-.10z%22%2F%3E%20%3C%2Fsvg%3E");
}

.p-index__campaign-list, .p-index__news-top-list {
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 1024px) {
  .p-index__campaign-list-item, .p-index__news-top-list-item {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
  }
}

.p-index__campaign-list-item-link, .p-index__news-top-list-item-link {
  display: flex;
  padding: 20px 160px 20px 0;
}

@media screen and (max-width: 1024px) {
  .p-index__campaign-list-item-link, .p-index__news-top-list-item-link {
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 24px 80px 24px 0;
  }
}

@media screen and (max-width: 767px) {
  .p-index__campaign-list-item-link, .p-index__news-top-list-item-link {
    padding: 5px 16px 12px 0;
  }
}

.p-index__campaign-list-item-txt, .p-index__news-top-list-item-txt {
  width: calc(100% - 180px);
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .p-index__campaign-list-item-txt, .p-index__news-top-list-item-txt {
    padding-left: 0;
    flex-wrap: wrap;
    width: 100%;
  }
}

.p-index__campaign-list-item-cat, .p-index__news-top-list-item-cat {
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-indent: .1rem;
  background: #38475e;
  line-height: 1;
  color: #fff;
  font-family: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-weight: 700;
  text-align: center;
  padding: 8px 16px 5px;
  margin-right: 20px;
  white-space: nowrap;
}

@media screen and (max-width: 767px) {
  .p-index__campaign-list-item-cat, .p-index__news-top-list-item-cat {
    font-size: 11px;
    font-size: 1.1rem;
    margin-right: 10px;
    padding: 4px 8px 1px;
  }
}

.p-index__campaign-list-item-btn, .p-index__news-top-list-item-btn {
  font-size: 13px;
  font-size: 1.3rem;
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
  color: #38475e;
  padding: 0 50px 3px 5px;
  border-bottom: 1px solid #38475e;
  font-family: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  transition: transform .2s ease, color .2s ease, border-color .2s ease;
}

@media screen and (max-width: 1024px) {
  .p-index__campaign-list-item-btn, .p-index__news-top-list-item-btn {
    position: relative;
    transform: translateY(0);
    right: 0;
  }
}

@media screen and (max-width: 767px) {
  .p-index__campaign-list-item-btn, .p-index__news-top-list-item-btn {
    font-size: 10px;
    font-size: 1rem;
    padding: 0 30px 2px 5px;
  }
}

@media screen and (min-width: 1199px) {
  .p-index__campaign-list-item-btn:hover, .p-index__news-top-list-item-btn:hover {
    color: #0086d3;
    transform: translateY(-50%) translateY(2px) translateX(1px);
    border-color: #0086d3;
  }
  .p-index__campaign-list-item-btn:hover::after, .p-index__news-top-list-item-btn:hover::after {
    border-color: transparent #0086d3 transparent transparent;
  }
}

.p-index__campaign-list-item-btn::after, .p-index__news-top-list-item-btn::after {
  position: absolute;
  right: 5px;
  bottom: 5px;
  width: 0;
  height: 0;
  border-width: 10px 10px 0 0;
  border-style: solid;
  border-color: transparent #38475e transparent transparent;
  content: '';
  transition: color .2s ease, border-color .2s ease;
}

@media screen and (max-width: 767px) {
  .p-index__campaign-list-item-btn::after, .p-index__news-top-list-item-btn::after {
    right: 4px;
    bottom: 4px;
    border-width: 8px 8px 0;
  }
}

.p-index__campaign-list-item-time, .p-index__news-top-list-item-time {
  font-size: 15px;
  font-size: 1.5rem;
  letter-spacing: 2px;
  letter-spacing: 0.2rem;
  text-indent: .2rem;
  display: flex;
  align-items: center;
  font-family: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  padding-top: 3px;
}

@media screen and (max-width: 767px) {
  .p-index__campaign-list-item-time, .p-index__news-top-list-item-time {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.p-index__campaign-list-item-desc, .p-index__news-top-list-item-desc {
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
  padding-left: 20px;
  line-height: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  transition: color .2s ease;
}

@media screen and (min-width: 1199px) {
  .p-index__campaign-list-item-link:hover .p-index__campaign-list-item-desc,
  .p-index__news-top-list-item-link:hover .p-index__campaign-list-item-desc, .p-index__campaign-list-item-link:hover .p-index__news-top-list-item-desc,
  .p-index__news-top-list-item-link:hover .p-index__news-top-list-item-desc {
    color: #0086d3;
  }
}

.p-index__campaign-list-item-desc::after, .p-index__news-top-list-item-desc::after {
  content: '';
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 20px;
  right: 0;
  background: #0086d3;
  transform: scale(0);
  transition: transform .2s ease;
  transform-origin: right;
}

@media screen and (min-width: 1199px) {
  .p-index__campaign-list-item-link:hover .p-index__campaign-list-item-desc::after,
  .p-index__news-top-list-item-link:hover .p-index__campaign-list-item-desc::after, .p-index__campaign-list-item-link:hover .p-index__news-top-list-item-desc::after,
  .p-index__news-top-list-item-link:hover .p-index__news-top-list-item-desc::after {
    transform: scaleX(1);
    transform-origin: left;
  }
}

@media screen and (max-width: 1024px) {
  .p-index__campaign-list-item-desc, .p-index__news-top-list-item-desc {
    width: 100%;
    padding-top: 18px;
    padding-left: 0;
    text-align: left;
  }
}

@media screen and (max-width: 767px) {
  .p-index__campaign-list-item-desc, .p-index__news-top-list-item-desc {
    padding-top: 9px;
  }
}

.p-index__news-top-list-item-cat {
  background: #88919e;
}

.p-index__contents {
  margin-bottom: 85px;
}

@media screen and (max-width: 767px) {
  .p-index__contents {
    margin-bottom: 60px;
  }
}

.p-index__contents.is-about {
  padding-top: 60px;
}

@media screen and (max-width: 767px) {
  .p-index__contents.is-about {
    padding-top: 30px;
  }
}

.p-index__contents-details {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-right: 80px;
}

@media screen and (max-width: 1330px) {
  .p-index__contents-details {
    margin-right: 75px;
  }
}

@media screen and (max-width: 1199px) {
  .p-index__contents-details {
    margin-right: 50px;
  }
}

@media screen and (max-width: 1024px) {
  .p-index__contents-details {
    flex-wrap: wrap;
    margin-right: 0;
  }
}

.p-index__contents-details.is-img-none {
  margin-left: 80px;
}

@media screen and (max-width: 1330px) {
  .p-index__contents-details.is-img-none {
    margin-left: 75px;
  }
}

@media screen and (max-width: 1199px) {
  .p-index__contents-details.is-img-none {
    margin-left: 50px;
  }
}

@media screen and (max-width: 1024px) {
  .p-index__contents-details.is-img-none {
    margin-left: 0;
  }
}

.p-index__contents-details:not(:first-child) {
  margin-left: 80px;
}

@media screen and (max-width: 1330px) {
  .p-index__contents-details:not(:first-child) {
    margin-left: 75px;
  }
}

@media screen and (max-width: 1199px) {
  .p-index__contents-details:not(:first-child) {
    margin-left: 50px;
  }
}

@media screen and (max-width: 1024px) {
  .p-index__contents-details:not(:first-child) {
    margin-left: 0;
  }
}

.p-index__contents-details:not(:last-child) {
  margin-bottom: 50px;
}

.p-index__contents-details-figure {
  width: 55%;
}

@media screen and (max-width: 1024px) {
  .p-index__contents-details-figure {
    width: 100%;
  }
}

.p-index__contents-details:nth-child(even) .p-index__contents-details-figure {
  order: 2;
}

@media screen and (max-width: 1024px) {
  .p-index__contents-details:nth-child(even) .p-index__contents-details-figure {
    order: 1;
  }
}

.p-index__contents-details-figure::after {
  padding-bottom: 45%;
}

@media screen and (max-width: 767px) {
  .p-index__contents-details-figure::after {
    padding-bottom: 65%;
  }
}

@media screen and (max-width: 1024px) {
  .p-index__contents-details .p-index__contents-details-bg {
    right: 15%;
  }
  .p-index__contents-details:nth-child(even) .p-index__contents-details-bg {
    left: 15%;
    right: 0;
  }
}

.p-index__contents-details-txt {
  font-size: 15px;
  font-size: 1.5rem;
  letter-spacing: 1.2px;
  letter-spacing: 0.12rem;
  line-height: 2.5;
  background: #fff;
  padding: 60px 80px;
  position: relative;
  z-index: 2;
  text-align: left;
}

@media screen and (max-width: 767px) {
  .p-index__contents-details-txt {
    font-size: 13px;
    font-size: 1.3rem;
    line-height: 1.8;
    padding: 20px;
  }
}

.p-index__contents-details-txt-container {
  width: 54%;
  position: relative;
  margin-left: -40px;
  margin-bottom: 20px;
}

@media screen and (max-width: 1024px) {
  .p-index__contents-details-txt-container {
    width: 100%;
    margin: -130px 50px 20px;
  }
}

@media screen and (max-width: 767px) {
  .p-index__contents-details-txt-container {
    margin: -130px 16px 5px;
  }
}

.p-index__contents-details-txt-container::after {
  content: '';
  position: absolute;
  top: 20px;
  bottom: -20px;
  left: -20px;
  right: 20px;
  background: rgba(213, 232, 243, 0.2);
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .p-index__contents-details-txt-container::after {
    top: 5px;
    bottom: -5px;
    left: -5px;
    right: 5px;
  }
}

.is-img-none .p-index__contents-details-txt-container::after {
  top: -20px;
  right: -20px;
}

@media screen and (max-width: 767px) {
  .is-img-none .p-index__contents-details-txt-container::after {
    top: -5px;
    right: -5px;
  }
}

.p-index__contents-details:nth-child(even) .p-index__contents-details-txt-container {
  order: 1;
  margin-left: 0;
  margin-right: -40px;
}

@media screen and (max-width: 1024px) {
  .p-index__contents-details:nth-child(even) .p-index__contents-details-txt-container {
    order: 2;
    margin: -130px 50px 20px;
  }
}

@media screen and (max-width: 767px) {
  .p-index__contents-details:nth-child(even) .p-index__contents-details-txt-container {
    margin: -130px 16px 5px;
  }
}

.p-index__contents-details:nth-child(even) .p-index__contents-details-txt-container::after {
  left: 20px;
  right: -20px;
}

@media screen and (max-width: 767px) {
  .p-index__contents-details:nth-child(even) .p-index__contents-details-txt-container::after {
    left: 5px;
    right: -5px;
  }
}

.p-index__contents-details:nth-child(even).is-img-none .p-index__contents-details-txt-container {
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 20px;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .p-index__contents-details:nth-child(even).is-img-none .p-index__contents-details-txt-container {
    margin: 20px 50px;
  }
}

@media screen and (max-width: 767px) {
  .p-index__contents-details:nth-child(even).is-img-none .p-index__contents-details-txt-container {
    margin: 5px 16px;
  }
}

.p-index__contents-details:nth-child(even).is-img-none .p-index__contents-details-txt-container::after {
  left: -20px;
}

@media screen and (max-width: 767px) {
  .p-index__contents-details:nth-child(even).is-img-none .p-index__contents-details-txt-container::after {
    left: -5px;
  }
}

.is-img-none .p-index__contents-details-txt-container {
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 20px;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .is-img-none .p-index__contents-details-txt-container {
    margin: 20px 50px;
  }
}

@media screen and (max-width: 767px) {
  .is-img-none .p-index__contents-details-txt-container {
    margin: 5px 16px;
  }
}

.p-index__category-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.p-index__category-list.is-overed .p-index__category-list-item {
  width: 25%;
}

@media screen and (max-width: 1330px) {
  .p-index__category-list.is-overed .p-index__category-list-item {
    width: 50%;
  }
}

.p-index__category-list-container {
  position: relative;
  z-index: 2;
}

.p-index__category-list-item {
  width: 33.3333%;
}

.p-index__category-list-figure::after {
  padding-bottom: 50%;
}

.p-index__category-list-bg {
  transition: opacity .2s ease;
}

@media screen and (min-width: 1199px) {
  .p-index__category-list-link:hover .p-index__category-list-bg {
    opacity: .7;
  }
}

.p-index__news {
  background-size: cover;
}

.p-index__news-container.p-index__section-container {
  padding-top: 180px;
  padding-bottom: 100px;
}

@media screen and (max-width: 767px) {
  .p-index__news-container.p-index__section-container {
    padding-bottom: 50px;
  }
}

.p-index__news-container-inner {
  width: 62.5%;
  margin: 0 auto;
}

@media screen and (max-width: 1024px) {
  .p-index__news-container-inner {
    width: 100%;
  }
}

.p-index__news-meta.p-index__section-meta {
  justify-content: center;
}

.p-index__news-ttl {
  font-size: 15px;
  font-size: 1.5rem;
  font-family: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-weight: 900;
  line-height: 1.4;
  padding: 25px 0;
  color: #000;
  max-width: 100px;
}

@media screen and (max-width: 1024px) {
  .p-index__news-ttl {
    padding: 25px 30px 25px 0;
    padding-right: 30px;
  }
}

@media screen and (max-width: 767px) {
  .p-index__news-ttl {
    padding: 10px 15px 10px 0;
  }
}

.p-index__news-link {
  display: inline-block;
  width: calc(100% - 60px);
  vertical-align: middle;
}

.p-index__news-data {
  display: flex;
}

@media screen and (max-width: 1024px) {
  .p-index__news-data {
    flex-wrap: wrap;
  }
}

.p-index__news-data-date {
  font-family: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-weight: 900;
  padding-right: 20px;
  line-height: 1.4;
  white-space: nowrap;
  text-align: left;
  color: #000;
}

@media screen and (max-width: 1024px) {
  .p-index__news-data-date {
    padding-right: 0;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .p-index__news-data-date {
    font-size: 14px;
    font-size: 1.4rem;
    padding-right: 60px;
  }
}

.p-index__news-data-ttl {
  text-decoration: underline;
  line-height: 1.4;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 82%;
  overflow: hidden;
  text-align: left;
  transition: color .2s ease;
}

@media screen and (min-width: 1199px) {
  .p-index__news-link:hover .p-index__news-data-ttl {
    color: #0086d3;
  }
}

@media screen and (max-width: 1024px) {
  .p-index__news-data-ttl {
    width: 100%;
    margin-top: 20px;
  }
}

@media screen and (max-width: 767px) {
  .p-index__news-data-ttl {
    font-size: 14px;
    font-size: 1.4rem;
    margin-top: 10px;
  }
}

.p-index__section {
  position: relative;
}

.p-index__section:not(:last-child) {
  margin-bottom: 80px;
}

@media screen and (max-width: 767px) {
  .p-index__section:not(:last-child) {
    margin-bottom: 40px;
  }
}

.p-index__section-meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}

@media screen and (max-width: 1024px) {
  .p-index__section-meta {
    position: static;
  }
}

@media screen and (max-width: 767px) {
  .p-index__section-meta {
    margin-bottom: 15px;
  }
}

.is-ranking .p-index__section-meta,
.is-category .p-index__section-meta {
  justify-content: center;
}

@media screen and (max-width: 1024px) {
  .p-index__blog .p-index__section-meta {
    justify-content: center;
  }
}

.p-index__section-container {
  padding: 50px 160px 0;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .p-index__section-container {
    padding: 50px 80px 0;
  }
  .p-index__section-container.is-btn-show {
    padding-bottom: 85px;
  }
}

@media screen and (max-width: 1024px) and (max-width: 767px) {
  .p-index__section-container.is-btn-show {
    padding-bottom: 55px;
  }
}

@media screen and (max-width: 767px) {
  .p-index__section-container {
    padding: 30px 16px 25px;
  }
}

.p-index__section-container.is-product-list::after {
  content: '';
  background: #e7ecef;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 390px;
}

@media screen and (max-width: 1024px) {
  .p-index__section-container.is-product-list::after {
    bottom: 350px;
    height: auto;
  }
}

@media screen and (max-width: 767px) {
  .p-index__section-container.is-product-list::after {
    bottom: 175px;
  }
}

@media screen and (max-width: 1024px) {
  .p-index__section-container.is-product-list.is-btn-show::after {
    bottom: 260px;
  }
}

@media screen and (max-width: 767px) {
  .p-index__section-container.is-product-list.is-btn-show::after {
    bottom: 130px;
  }
}

.p-index__section-container.is-bg-line {
  padding: 50px 160px 25px;
}

@media screen and (max-width: 1024px) {
  .p-index__section-container.is-bg-line {
    padding: 50px 80px 25px;
  }
}

@media screen and (max-width: 767px) {
  .p-index__section-container.is-bg-line {
    padding: 30px 16px 25px;
  }
}

.p-index__section-container.is-bg-line::after {
  content: '';
  height: auto;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: transparent;
  opacity: .3;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%224%22%20height%3D%224%22%20viewBox%3D%220%200%202%202%22%20preserveAspectRatio%3D%22none%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M1-1h.10l-4%204v-.10zM3-1v.10l-4%204h-.10z%22%2F%3E%20%3C%2Fsvg%3E");
}

.p-index__section-container.is-bg-line.p-index__slider-container::after {
  right: 160px;
}

@media screen and (max-width: 1024px) {
  .p-index__section-container.is-bg-line.p-index__slider-container::after {
    right: 0;
  }
}

.p-index__section-container.is-bg-unset {
  padding-top: 0;
  padding-bottom: 0;
}

.p-index__section-container.is-subpage {
  padding-top: 0;
  padding-bottom: 0;
}

.p-index__section-btn.c-btn {
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 1024px) {
  .p-index__section-btn.c-btn {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.p-index__section-btn.c-btn.is-bottom {
  padding: 40px 160px 0;
}

@media screen and (max-width: 1024px) {
  .p-index__section-btn.c-btn.is-bottom {
    position: relative;
  }
}

@media screen and (max-width: 767px) {
  .p-index__section-btn.c-btn.is-bottom {
    padding: 25px 32px 0;
  }
}

.p-index__section-btn.c-btn.is-all-product {
  padding-top: 40px;
}

@media screen and (max-width: 1024px) {
  .p-index__section-btn.c-btn.is-all-product {
    position: relative;
  }
}

@media screen and (max-width: 767px) {
  .p-index__section-btn.c-btn.is-all-product {
    width: 100%;
    padding: 25px 32px 0;
  }
}

@media screen and (max-width: 1024px) {
  .p-index__section-btn.c-btn.is-all-product.is-btn-second {
    padding-top: 50px;
  }
}

@media screen and (max-width: 767px) {
  .p-index__section-btn.c-btn.is-all-product .c-btn__body {
    font-size: 14px;
    font-size: 1.4rem;
    width: 100%;
    padding: 16px 16px 12px;
  }
}

@media screen and (max-width: 1024px) {
  .p-index__section-btn.c-btn.is-news {
    position: static;
    transform: translateX(0);
  }
}

.p-index__blog {
  position: relative;
  margin-bottom: -115px;
}

.p-index__blog-container {
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
}

.p-index__blog-container-inner {
  margin: 0 -10px;
}

.p-index__banner-list {
  display: flex;
  margin: -10px -10px 0;
}

@media screen and (max-width: 1024px) {
  .p-index__banner-list {
    flex-wrap: wrap;
  }
}

.p-index__banner-item {
  width: 50%;
  padding: 10px;
}

@media screen and (max-width: 1024px) {
  .p-index__banner-item {
    width: 100%;
  }
}

.p-index__banner-item-link {
  width: 100%;
  height: 240px;
  padding: 20px;
  position: relative;
  display: block;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  transition: opacity .2s ease;
}

@media screen and (min-width: 1199px) {
  .p-index__banner-item-link:hover {
    opacity: .7;
  }
}

@media screen and (max-width: 767px) {
  .p-index__banner-item-link {
    height: 115px;
    padding: 10px;
  }
}

.p-index__banner-item-link::before {
  display: block;
  content: '';
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 10px;
  left: 10px;
  border: 1px solid #fff;
}

@media screen and (max-width: 767px) {
  .p-index__banner-item-link::before {
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px;
  }
}

.p-index__banner-item-link::after {
  content: '';
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 0;
  height: 0;
  border-width: 30px 30px 0 0;
  border-style: solid;
  border-color: transparent #fff transparent transparent;
}

@media screen and (max-width: 767px) {
  .p-index__banner-item-link::after {
    right: 5px;
    bottom: 5px;
    border-width: 20px 20px 0 0;
  }
}

.p-index__banner-ttl-main {
  font-size: 25px;
  font-size: 2.5rem;
  color: #fff;
  display: block;
}

@media screen and (max-width: 767px) {
  .p-index__banner-ttl-main {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.p-index__banner-ttl-sub {
  font-size: 15px;
  font-size: 1.5rem;
  display: block;
  font-family: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-weight: 700;
  padding-top: 25px;
  position: relative;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .p-index__banner-ttl-sub {
    font-size: 11px;
    font-size: 1.1rem;
    padding-top: 15px;
  }
}

.p-index__banner-ttl-sub::before {
  content: '';
  position: absolute;
  top: 12.5px;
  left: 50%;
  height: 2px;
  width: 40px;
  transform: translateX(-50%);
  background: #fff;
}

@media screen and (max-width: 767px) {
  .p-index__banner-ttl-sub::before {
    top: 6px;
  }
}

.p-index__slider-container.p-index__section-container {
  padding-right: 0;
  padding-bottom: 50px;
  padding-left: 160px;
}

@media screen and (max-width: 1024px) {
  .p-index__slider-container.p-index__section-container {
    padding-left: 80px;
  }
}

@media screen and (max-width: 767px) {
  .p-index__slider-container.p-index__section-container {
    padding-left: 16px;
  }
}

.p-index__slider-container .swiper-container-horizontal .p-index__slider-pagination-container .swiper-pagination-bullets.p-index__slider-pagination {
  position: absolute;
  left: auto;
  right: 50vw;
  bottom: 0;
  text-align: left;
  line-height: 0;
  transform: translateX(100%) translateX(20px);
}

@media screen and (max-width: 1024px) {
  .p-index__slider-container .swiper-container-horizontal .p-index__slider-pagination-container .swiper-pagination-bullets.p-index__slider-pagination {
    position: static;
    margin-top: 70%;
    padding-top: 40px;
    padding-left: 0;
    transform: translateX(0);
  }
}

@media screen and (max-width: 767px) {
  .p-index__slider-container .swiper-container-horizontal .p-index__slider-pagination-container .swiper-pagination-bullets.p-index__slider-pagination {
    padding-top: 18px;
  }
}

.p-index__slider-container .swiper-pagination-bullet {
  background: rgba(56, 71, 94, 0.3);
}

.p-index__slider-container .swiper-pagination-bullet:not(:last-child) {
  margin-right: 12px;
}

.p-index__slider-container .swiper-pagination-bullet-active {
  background: #38475e;
}

.p-index__slider-list {
  padding-bottom: 45px;
}

@media screen and (max-width: 1024px) {
  .p-index__slider-list {
    padding-bottom: 0;
  }
}

.p-index__slider-item-txt {
  width: calc(100% - 50vw);
  padding-top: 40px;
  padding-right: 85px;
  text-align: left;
  opacity: 0;
  animation: slider-txt-reverse .1s ease forwards;
}

@media screen and (max-width: 1024px) {
  .p-index__slider-item-txt {
    width: 100%;
    order: 2;
    padding-top: 80px;
  }
}

@media screen and (max-width: 767px) {
  .p-index__slider-item-txt {
    padding-top: 40px;
    padding-right: 16px;
  }
}

.swiper-slide-active .p-index__slider-item-txt, .swiper-slide-duplicate-active .p-index__slider-item-txt {
  animation: slider-txt .8s ease 1s forwards;
}

.p-index__slider-item-figure {
  height: 100%;
}

.p-index__slider-item-img {
  width: 50vw;
  padding-left: 20px;
  position: relative;
  transform: translateY(20px);
  opacity: 0;
  animation: slider-fade-reverse .5s ease forwards;
}

@media screen and (max-width: 1024px) {
  .p-index__slider-item-img {
    width: 100%;
    order: 1;
  }
}

@media screen and (max-width: 767px) {
  .p-index__slider-item-img {
    padding-left: 8px;
    transform: translateY(8px);
  }
}

.swiper-slide-active .p-index__slider-item-img, .swiper-slide-duplicate-active .p-index__slider-item-img {
  z-index: 2;
  animation: slider-fade 1s ease forwards;
}

.p-index__slider-item-img::after {
  content: '';
  top: 20px;
  right: 20px;
  bottom: -20px;
  left: 0;
  position: absolute;
  background: rgba(56, 71, 94, 0.3);
}

@media screen and (max-width: 767px) {
  .p-index__slider-item-img::after {
    top: 8px;
    right: 8px;
    bottom: -8px;
  }
}

.p-index__slider-item-bg {
  z-index: 2;
}

.p-index__slider-item-desc {
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 2;
}

@media screen and (max-width: 767px) {
  .p-index__slider-item-desc {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.p-index__slider-item-link {
  margin-top: 30px;
}

.p-index__slider-item-link-outer {
  display: flex;
}

@media screen and (max-width: 1024px) {
  .p-index__slider-item-link-outer {
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 1199px) {
  .p-index__slider-item-link-outer:hover .p-index__slider-item-link {
    background: #38475e;
    color: #fff;
    transition: background-color .2s ease, color .2s ease;
  }
  .p-index__slider-item-link-outer:hover .p-index__slider-item-link::before {
    border-color: transparent #fff transparent transparent;
  }
}

@media screen and (max-width: 1024px) {
  .p-index__slider-item-btn {
    text-align: center;
  }
}

@media screen and (max-width: 1024px) {
  .p-index__slider-pagination-container {
    position: absolute;
    z-index: 1;
    width: calc(100% - 20px);
    top: 0;
    left: 0;
  }
}

@media screen and (max-width: 767px) {
  .p-index__slider-pagination-container {
    width: calc(100% - 8px);
  }
}

@keyframes scroll-large {
  0% {
    transform: rotate(-90deg) translateY(-102%);
  }
  80% {
    transform: rotate(-90deg) translateY(0);
  }
  100% {
    transform: rotate(-90deg) translateY(102%);
  }
}

@keyframes scroll-small {
  0% {
    transform: rotate(115deg) scaleY(0) translateY(0);
  }
  80% {
    transform: rotate(115deg) scaleY(1) translateY(0);
  }
  100% {
    transform: rotate(115deg) scaleY(1) translateY(-102%);
  }
}

@keyframes slider-fade {
  0% {
    opacity: 0;
    transform: translateY(20px) translateX(-20px);
  }
  1% {
    opacity: 0;
    transform: translateY(20px) translateX(-20px);
  }
  80% {
    opacity: 0;
    transform: translateY(20px) translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0) translateX(0);
  }
}

@keyframes slider-fade-reverse {
  0% {
    opacity: 1;
    transform: translateY(0) translateX(0);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 0;
    transform: translateY(20px) translateX(-20px);
  }
  100% {
    opacity: 0;
    transform: translateY(20px) translateX(-20px);
  }
}

@keyframes slider-txt {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slider-txt-reverse {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(10px);
  }
}

@media screen and (max-width: 1024px) {
  .is-btn-show + .p-index__section-btn.c-btn.is-all-product.is-btn-second {
    padding-top: 20px;
  }
}

/*----------------------------------------
option
----------------------------------------*/
.p-option {
  padding-bottom: 100px;
}

@media screen and (max-width: 767px) {
  .p-option {
    padding-bottom: 50px;
  }
}

/*----------------------------------------
single
----------------------------------------*/
.p-single {
  text-align: justify;
  background: #fff;
  position: relative;
  z-index: 1;
  padding: 0 140px;
}

@media screen and (max-width: 1024px) {
  .p-single {
    padding: 0;
  }
}

.p-single.is-two-column {
  padding: 0;
}

.p-single__container {
  margin-bottom: 100px;
}

@media screen and (max-width: 767px) {
  .p-single__container {
    margin-bottom: 50px;
  }
}

.p-single__meta {
  display: flex;
  align-items: center;
  margin: 0 0 10px;
}

@media screen and (max-width: 1024px) {
  .p-single__meta {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .p-single__meta {
    margin: 0 0 5px;
  }
}

.p-single__meta-item {
  margin-right: 15px;
}

@media screen and (max-width: 767px) {
  .p-single__meta-item {
    margin-right: 10px;
  }
}

.p-single__meta-item-child-link {
  font-size: 12px;
  font-size: 1.2rem;
  padding: 8px 0;
  position: relative;
  color: #38475e;
  display: flex;
  align-items: center;
  transition: opacity .2s ease;
}

@media screen and (max-width: 767px) {
  .p-single__meta-item-child-link {
    font-size: 11px;
    font-size: 1.1rem;
    padding: 3px 0;
  }
}

@media screen and (min-width: 1199px) {
  .p-single__meta-item-child-link:hover {
    opacity: .7;
  }
}

.p-single__meta-item-child-link.is-present {
  color: #81A3A1;
}

.p-single__meta-item-child-link.is-present::before {
  border-color: transparent transparent #81A3A1 transparent;
}

.p-single__meta-item-child-link.is-product {
  color: #93AF71;
}

.p-single__meta-item-child-link.is-product::before {
  border-color: transparent transparent #93AF71 transparent;
}

.p-single__meta-item-child-link::before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 4px 4px 4px;
  border-color: transparent transparent #38475e transparent;
  transform: rotate(90deg);
  margin-right: 3px;
}

@media screen and (max-width: 767px) {
  .p-single__meta-item-child-link::before {
    border-width: 0 3px 3px 3px;
  }
}

.p-single__meta-cat-container {
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .p-single__meta-cat-container {
    margin-bottom: 20px;
  }
}

.p-single__meta-link {
  font-size: 14px;
  font-size: 1.4rem;
  letter-spacing: 2px;
  letter-spacing: 0.2rem;
  text-indent: .2rem;
  padding: 10px 18px;
  display: inline-block;
  color: #fff;
  background: #38475e;
  line-height: 1;
  transition: opacity .2s ease;
}

@media screen and (min-width: 1199px) {
  .p-single__meta-link:hover {
    opacity: .7;
  }
}

@media screen and (max-width: 767px) {
  .p-single__meta-link {
    font-size: 12px;
    font-size: 1.2rem;
    padding: 5px 8px;
  }
}

.p-single__meta-link.is-present {
  background: #81A3A1;
}

.p-single__meta-link.is-product {
  background: #93AF71;
}

.p-single__meta-date {
  font-size: 18px;
  font-size: 1.8rem;
  color: #a2a2a2;
  font-weight: 700;
  display: inline-block;
  vertical-align: middle;
  font-family: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
}

@media screen and (max-width: 767px) {
  .p-single__meta-date {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.p-single-ttl {
  margin-bottom: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid #38475e;
}

@media screen and (max-width: 767px) {
  .p-single-ttl {
    margin-bottom: 5px;
    padding-bottom: 10px;
  }
}

.p-single-ttl.is-news {
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .p-single-ttl.is-news {
    margin-bottom: 20px;
  }
}

.p-single-ttl__txt {
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: 900;
}

@media screen and (max-width: 767px) {
  .p-single-ttl__txt {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.p-single__content {
  font-size: 14px;
  font-size: 1.4rem;
  letter-spacing: 1px;
  letter-spacing: 0.1rem;
  line-height: 2;
}

.p-single__content.is-not-cat {
  margin-top: 30px;
}

@media screen and (max-width: 767px) {
  .p-single__content.is-not-cat {
    margin-top: 20px;
  }
}

.p-single__content-eyecatch {
  text-align: center;
}

.p-single__content-eyecatch .c-img {
  vertical-align: middle;
}

.p-single__content-inner {
  position: relative;
  z-index: 1;
}

.p-single__content-inner > [class|='wp'] {
  margin: 24px 0 0;
}

.p-single__content-inner > ol:not([class]) {
  counter-reset: order 0;
  margin: 24px 0 0;
  padding: 0 0 0 1em;
}

@media screen and (max-width: 767px) {
  .p-single__content-inner > ol:not([class]) {
    margin: 12px 0 0;
  }
}

.p-single__content-inner > ol:not([class]) > li:not([class]) {
  display: flex;
  line-height: 1.5;
}

.p-single__content-inner > ol:not([class]) > li:not([class])::before {
  font-size: 14px;
  font-size: 1.4rem;
  font-family: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  align-self: center;
  color: #38475e;
  content: counter(order, decimal-leading-zero) "";
  counter-increment: order 1;
  display: inline-block;
  margin: 0 .5em 0 0;
}

.p-single__content-inner > ol:not([class]) > li:not([class]) + li:not([class]) {
  margin: .5em 0 0;
}

.p-single__content-inner > ul:not(.wp-block-gallery) {
  margin: 24px 0 0;
  padding: 0 0 0 1em;
}

@media screen and (max-width: 767px) {
  .p-single__content-inner > ul:not(.wp-block-gallery) {
    margin: 12px 0 0;
  }
}

.p-single__content-inner > ul:not(.wp-block-gallery) > li:not([class]) {
  display: flex;
  line-height: 1.5;
}

.p-single__content-inner > ul:not(.wp-block-gallery) > li:not([class])::before {
  align-self: center;
  background: #38475e;
  border-radius: 50%;
  content: "";
  display: inline-block;
  width: 4px;
  height: 4px;
  margin: 0 .5em 0 0;
}

.p-single__content-inner > ul:not(.wp-block-gallery) > li:not([class]) + li:not([class]) {
  margin: .5em 0 0;
}

.p-single__content-inner section {
  margin-top: 60px;
}

.p-single__content p:not([class]) {
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1.8;
  margin: 24px 0 0;
}

.p-single__content p:not([class]) + p:not([class]) {
  margin: 12px 0 0;
}

.p-single__content .wp-block-quote p:not([class]):first-child {
  margin: 0;
}

.p-single__content h2:not([class]) {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.5;
  padding: 20px 0;
  border-bottom: 1px solid #38475e;
}

.p-single__content h2:not([class]):not(:first-child) {
  margin-top: 60px;
}

@media screen and (max-width: 767px) {
  .p-single__content h2:not([class]):not(:first-child) {
    margin-top: 30px;
  }
}

@media screen and (max-width: 767px) {
  .p-single__content h2:not([class]) {
    font-size: 16px;
    font-size: 1.6rem;
    padding: 10px 0;
  }
}

.p-single__content h3:not([class]) {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 900;
}

.p-single__content h3:not([class]):not(:first-child) {
  margin-top: 60px;
}

@media screen and (max-width: 767px) {
  .p-single__content h3:not([class]):not(:first-child) {
    margin-top: 30px;
  }
}

@media screen and (max-width: 767px) {
  .p-single__content h3:not([class]) {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.p-single__content h4:not([class]) {
  font-weight: 900;
  line-height: 1.5;
}

.p-single__content h4:not([class]):not(:first-child) {
  margin-top: 24px;
}

@media screen and (max-width: 767px) {
  .p-single__content h4:not([class]):not(:first-child) {
    margin-top: 12px;
  }
}

@media screen and (max-width: 767px) {
  .p-single__content h4:not([class]) {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.p-single__content figure:not([class]) {
  margin-top: 24px;
}

@media screen and (max-width: 767px) {
  .p-single__content figure:not([class]) {
    margin-top: 12px;
  }
}

.p-single__content b:not([class]),
.p-single__content strong:not([class]) {
  background: linear-gradient(transparent 55%, rgba(52, 52, 52, 0.2) 45%);
}

.p-single__content em:not([class]) {
  font-style: italic;
}

.p-single__content .wp-block-image {
  font-size: 0;
  position: relative;
  text-align: center;
}

.p-single__content .wp-block-image figcaption {
  font-size: 14px;
  font-size: 1.4rem;
  color: #fff;
  background: rgba(0, 0, 0, 0.3);
  line-height: 1.5;
  margin: 0;
  padding: 1em 1.5em;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
}

.p-single__content .wp-block-image figure {
  display: block;
}

.p-single__content .wp-block-quote {
  background: #f5f5f5;
  border-color: #cccccc;
  padding: 2em;
}

@media screen and (max-width: 767px) {
  .p-single__content .wp-block-audio audio {
    min-width: 100%;
  }
}

.p-single__content .wp-block-audio figcaption {
  margin: .5em 0 0;
}

.p-single__content .wp-block-file .wp-block-file__button {
  font-size: 14px;
  font-size: 1.4rem;
  letter-spacing: 2.5px;
  letter-spacing: 0.25rem;
  font-family: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-weight: 900;
  color: #38475e !important;
  background: #fff;
  padding: 10px 25px;
  border: 1px solid #38475e;
  display: inline-block;
  position: relative;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  opacity: 1 !important;
  border-radius: 0;
  margin: 0 0 0 20px;
}

@media screen and (max-width: 767px) {
  .p-single__content .wp-block-file .wp-block-file__button {
    width: 100%;
    margin: 10px 0 0;
  }
}

.p-single__content .wp-block-file .wp-block-file__button:hover {
  color: #fff !important;
  background: #38475e !important;
}

.p-single__content .wp-block-code {
  background: #38475e;
  border: 0;
  border-radius: 0;
  color: #f5f5f5;
  padding: 2em;
}

.p-single__content .wp-block-pullquote {
  border-color: #38475e;
  color: #38475e;
}

.p-single__content .wp-block-pullquote p:not([class]) {
  font-size: 24px;
  font-size: 2.4rem;
}

.p-single__content .wp-block-pullquote p:not([class]):first-child {
  margin-top: 0;
}

@media screen and (max-width: 767px) {
  .p-single__content .wp-block-pullquote p:not([class]) {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.p-single__content .wp-block-pullquote cite:not([class]) {
  color: #343434;
}

.p-single__content .wp-block-button__link {
  font-size: 14px;
  font-size: 1.4rem;
  letter-spacing: 2.5px;
  letter-spacing: 0.25rem;
  font-family: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-weight: 700;
  color: #38475e;
  background: #fff;
  padding: 10px 45px;
  border: 1px solid #38475e;
  display: inline-block;
  position: relative;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  border-radius: 0;
}

@media screen and (max-width: 767px) {
  .p-single__content .wp-block-button__link {
    width: 100%;
    margin: 10px 0 0;
  }
}

.p-single__content .wp-block-button__link:hover {
  color: #fff;
  background: #38475e;
}

.p-single__content .wp-block-separator {
  border-color: #38475e;
  margin-right: auto;
  margin-left: auto;
}

.p-single__content .wp-block-separator::before {
  color: #38475e;
}

.p-single__content .wp-block-calendar caption {
  color: #38475e;
}

.p-single__content .wp-block-calendar th:not([class]) {
  color: #fff;
  background: #38475e;
  border-color: #38475e;
}

.p-single__content .wp-block-calendar td:not([class]) {
  border-color: #38475e;
}

.p-single__content .wp-block-calendar a {
  color: #38475e;
  display: block;
  padding: 5px 0;
}

.p-single__content .wp-block-calendar tbody td, .p-single__content .wp-block-calendar th {
  border-color: #38475e;
}

.p-single__content .wp-block-embed__wrapper {
  text-align: center;
}

.p-single__content .wp-block-embed-youtube .wp-block-embed__wrapper {
  overflow: hidden;
  position: relative;
}

.p-single__content .wp-block-embed-youtube .wp-block-embed__wrapper::after {
  content: "";
  display: block;
  padding-top: 56.25%;
}

.p-single__content .wp-block-embed-youtube .wp-block-embed__wrapper iframe {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.p-single__content .a2a_svg svg, .p-single__content .a2a_svg path, .p-single__content .a2a_svg g {
  fill: #333;
}

.p-single__content .wp-embed-aspect-4-3 .wp-block-embed-youtube .wp-block-embed__wrapper::after {
  padding-top: 75%;
}

.p-single__content .wp-embed-aspect-16-9 .wp-block-embed-youtube .wp-block-embed__wrapper::after {
  padding-top: 56.25%;
}

.p-single__content .wp-block-embed-twitter {
  text-align: center;
}

.p-single__content .wp-block-embed-twitter .wp-block-embed__wrapper {
  display: inline-block;
  border: 1px solid #ddd;
}

.p-single__content .addtoany_share_save_container,
.p-single__content .addtoany_shortcode {
  text-align: center;
  margin-top: 60px;
}

@media screen and (max-width: 767px) {
  .p-single__content .addtoany_share_save_container,
  .p-single__content .addtoany_shortcode {
    margin-top: 40px;
  }
}

.p-single__btn {
  margin-top: 40px;
}

@media screen and (max-width: 767px) {
  .p-single__btn {
    margin-top: 20px;
  }
}

.wp-block-table {
  width: 100%;
  min-width: 240px;
  border-collapse: collapse;
}

.wp-block-table td {
  padding: 5px;
  border: 1px solid #38475e;
  word-break: break-all;
}

.wp-block-table th {
  padding: 5px;
  border: 1px solid #38475e;
  word-break: break-all;
}

.p-seo-nav {
  display: inline-block;
  padding: 30px 80px;
  margin: 40px 0;
  position: relative;
  width: 100%;
  color: #666;
}

@media screen and (max-width: 767px) {
  .p-seo-nav {
    padding: 16px;
    margin: 30px 0;
  }
}

.p-seo-nav::after {
  content: '';
  height: auto;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: transparent;
  opacity: .3;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%224%22%20height%3D%224%22%20viewBox%3D%220%200%202%202%22%20preserveAspectRatio%3D%22none%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M1-1h.10l-4%204v-.10zM3-1v.10l-4%204h-.10z%22%2F%3E%20%3C%2Fsvg%3E");
}

.p-seo-nav-list {
  position: relative;
  z-index: 1;
}

.p-seo-nav-list-item {
  font-size: 18px;
  font-size: 1.8rem;
  letter-spacing: 2px;
  letter-spacing: 0.2rem;
}

.p-seo-nav-list-item:not(:first-child) {
  margin-top: 30px;
}

@media screen and (max-width: 767px) {
  .p-seo-nav-list-item {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.p-seo-nav-list-item-child {
  padding: 10px 0 0 10px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .p-seo-nav-list-item-child {
    font-size: 14px;
    font-size: 1.4rem;
    padding: 5px 0 0 10px;
  }
}

.p-seo-nav-list-item-child .p-seo-nav-link {
  color: #666;
}

.p-seo-nav-list-item-child::before {
  content: '';
  width: 4px;
  height: 1px;
  position: absolute;
  top: calc(50% + 5px);
  left: 0;
  background: #666;
}

.p-seo-nav-ttl {
  font-size: 21px;
  font-size: 2.1rem;
  letter-spacing: 2px;
  letter-spacing: 0.2rem;
  font-family: "Josefin Sans", Arial, Helvetica, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  margin-bottom: 10px;
  font-weight: 700;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .p-seo-nav-ttl {
    font-size: 18px;
    font-size: 1.8rem;
    margin-bottom: 5px;
  }
}
