@charset "UTF-8";

/*----------------------------------------
c-cart-price
----------------------------------------*/
.c-cart-price {
  background: $white-gray2;
  padding: 30px 20px;

  &__detail {
    width: 100%;
    display: table;

    &-row {
      display: table-row;
    }

    &-cell {
      &-ttl,
      &-desc {
        display: table-cell;
        padding: 5px;
      }

      &-ttl {
        font-weight: $bold;
        text-align: left;
      }

      &-desc {
        text-align: right;
      }
    }
  }

  &__all {
    margin: 20px 0;
    padding: 20px 0;
    border-top: 1px solid $color-border-gray;

    &-ttl,
    &-desc {
      display: inline-block;
      padding: 5px;
    }
  }
}
