@charset "UTF-8";

/*----------------------------------------
about
----------------------------------------*/
.p-about {

  &__intro {
    padding-right: #{$side-padding * 2};
    padding-bottom: 80px;

    @include is-tablet {
      padding-right: $side-padding;
    }

    @include is-mobile {
      padding-right: $side-padding-mobile;
      padding-bottom: 50px;
    }

    &-container {
      display: flex;
      align-items: center;

      @include is-tablet {
        flex-wrap: wrap;
      }
    }

    &-img {
      position: relative;
      width: 55%;

      @include is-tablet {
        width: 100%;
      }

      &::before {
        position: absolute;
        content: '';
        top: 20px;
        right: -20px;
        bottom: -20px;
        left: 20px;
        background: rgba($blue-dark,.3);

        @include is-mobile {
          top: #{$side-padding-mobile / 2};
          right: -#{$side-padding-mobile / 2};
          bottom: -#{$side-padding-mobile / 2};
          left: #{$side-padding-mobile / 2};
        }
      }
    }

    &-figure {
      @extend %img-clip;
      position: relative;

      &::after {
        content: '';
        padding-bottom: 57%;
      }

    }

    &-txt {
      width: 45%;
      padding-left: #{$side-padding * 2};
      display: flex;
      justify-content: center;
      flex-direction: column;

      @include is-tablet {
        width: 100%;
        padding-left: $side-padding;
      }

      @include is-mobile {
        padding-left: #{$side-padding-mobile * 2};
        padding-right: $side-padding-mobile;
      }
    }

    &-ttl {
      @include fz(25);
      text-align: left;

      @include is-tablet {
        padding-top: 30px;
      }

      @include is-mobile {
        @include fz(20);
      }
    }

    &-desc {
      padding-top: 50px;
      position: relative;
      text-align: left;

      @include is-mobile {
        padding-top: 35px;
      }

      &::before {
        content: '';
        position: absolute;
        top: 10px;
        left: 0;
        width: 40px;
        height: 2px;
        background: $blue-dark;

        @include is-mobile {
          width: 30px;
        }
      }
    }
  }

  &__feature {
    &-list {
      display: flex;
      margin: 0 -20px;

      @include is-hamburger {
        flex-wrap: wrap;
        justify-content: center;
      }

      @include is-tablet {
        margin: 0 -15px;
      }

      &.is-overed {
        flex-wrap: wrap;

        .p-about__feature-list-item {
          width: 50%;

          @include is-tablet {
            width: 100%;
          }
        }
      }

      &-item {
        width: 33.333%;
        padding: 20px;

        @include is-hamburger {
          width: 50%;
        }

        @include is-tablet {
          width: 100%;
          padding: 15px;
        }

        &-container {
          padding: 40px;
          position: relative;

          @include is-mobile {
            padding: 40px $side-padding-mobile;
          }

          &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%224%22%20height%3D%224%22%20viewBox%3D%220%200%202%202%22%20preserveAspectRatio%3D%22none%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M1-1h.10l-4%204v-.10zM3-1v.10l-4%204h-.10z%22%2F%3E%20%3C%2Fsvg%3E");
            opacity: .3;
          }

          &::before {
            @include fz(14);
            text-indent: .05rem;
            font-weight: $semibold;
            font-family: $font-en;
            content: attr(data-label);
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            color: $white;
            background: $blue-dark;
            display: block;
            padding: 6px 20px 3px;
            line-height: 1;

            @include is-mobile {
              @include fz(12);
            }
          }
        }

        &-figure {
          @extend %img-clip;
          position: relative;
          z-index: 1;

          &::after {
            padding-bottom: 56%;
          }
        }

        &-txt {
          position: relative;
        }

        &-ttl {
          @include fz(20);
          padding-top: 25px;
          text-align: left;

          @include is-mobile {
            @include fz(17);
            padding-top: 20px;
          }

        }

        &-desc {
          @include ls(1);
          padding-top: 25px;
          text-align: left;

          @include is-mobile {
            padding-top: 20px;
          }
        }
      }
    }
  }

  &__access {

    &-block {
      &:not(:last-child) {

        @include is-tablet {
          padding-bottom: 40px;
        }
      }
    }

    &-ttl {
      @include fz(20);
      @include ls(1);
      text-align: left;
      padding: 40px #{$side-padding * 2};
      position: relative;

      @include is-tablet {
        padding: 40px $side-padding;
      }

      @include is-mobile {
        padding: 20px #{$side-padding-mobile * 2};

      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: $blue-pale;

        @include is-tablet {
          bottom: -130px;
        }
      }

      &-container {
        position: relative;
        z-index: 1;
      }
    }

    &-data {
      width: 62.5%;
      padding-right: 60px;

      .p-about__access-block:not(:last-child) & {
        padding-bottom: 110px;

        @include is-tablet {
          padding-bottom: 0;
        }
      }

      @include is-tablet {
        width: 100%;
        order: 2;
        padding-right: 0;
        padding-bottom: 0;
      }

      &-block {
        padding: 30px 60px 30px 0;
        display: flex;
        border-bottom: 1px solid $blue-dark;

        @include is-tablet {
          flex-wrap: wrap;
          padding: 20px 0 0;
          border-bottom: none;

        }
      }

      &-ttl {
        @include fz(16);
        @include ls(2);
        text-align: left;
        width: 20%;
        display: flex;
        align-items: center;
        padding-left: 10px;

        @include is-tablet {
          width: 100%;
          padding-left: 0;
          padding-bottom: 24px;
          position: relative;
        }

        @include is-mobile {
          @include fz(15);
        }

        &::after {
          content: none;
          width: 100%;
          height: 1px;
          background: $blue-dark;
          position: absolute;
          bottom: 12px;
          left: 0;

          @include is-tablet {
            content: '';
          }
        }
      }

      &-desc {
        width: 80%;
        display: flex;
        align-items: center;
        text-align: left;
        padding-left: 50px;
        line-height: 2;

        @include is-tablet {
          width: 100%;
          padding-left: 0;
          display: block;
        }
      }
    }

    &-map {
      width: 37.5%;
      margin-top: -70px;

      @include is-tablet {
        order: 1;
        margin-top: 0;
        width: 100%;
      }

      iframe {
        max-height: 480px;

        @include is-mobile {
          max-height: 280px;
        }
      }
    }

    &-container {
      display: flex;

      @include is-tablet {
        flex-wrap: wrap;
      }

      @include is-mobile {
        padding: 0 #{$side-padding-mobile * 2};
      }
    }
  }

}
