@charset "UTF-8";

/*----------------------------------------
g-menu
----------------------------------------*/

body {
  &[data-menu="active"] {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: scroll;

    .g-menu {
      pointer-events: auto;

      &__header {
        opacity: 1;
      }

      &__container {
        overflow-y: auto;
        opacity: 1;
      }
    }
  }

  &[data-search="active"] {
    overflow: hidden;
    height: 100%;
  }
}

.g-menu {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  pointer-events: none;

  @include is-hamburger {
    display: block;
  }

  &__header {
    background: $primary;
    box-shadow: 0 -1px 0 $primary  inset;
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: 0;
    transition: opacity $ease .3s .1s;

    &__burger {
      width: #{$side-padding * 2};
      height: $header_height_tablet;
      position: absolute;
      top: 0;
      right: 0;
      background: none;
      border: 0;
      cursor: pointer;

      @include is-tablet {
        width: 120px;
      }

      @include is-mobile {
        height: $header_height_mobile;
        width: $header_height_mobile;
      }

      &-line {
        background: $white;
        width: 40px;
        height: 3px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 10px;
        transform-origin: center;

        @include is-mobile {
          width: 25px;
          height: 2px;
        }

        &:first-child {
          transform: translate(-50%, -50%) rotate(45deg);
        }

        &:last-child {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }
  }

  &__container {
    height: calc(100% - 70px);
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    color: $white;
    background: $primary;
    position: relative;
    z-index: 1;
    opacity: 0;
    transition: opacity $ease .3s .1s;

    @include is-mobile {
      height: calc(100% - 58px);
    }
  }

  &__account {
    &-list {
      &-item {
        border-bottom: 1px solid $primary;

        &-link {
          color: $white;
          background: $gray;
          padding: 15px 20px;
          display: flex;
          align-items: center;

          &-icon {
            @include fz(24);
            width: 28px;
            margin: 0 20px 0 0;
          }

          &-img {
            fill: $white;
            width: 30px;
            height: 30px;
            margin: 0 20px 0 0;
            vertical-align: middle;
            position: relative;
            z-index: 1;
          }

          &-txt {
            @include fz(16);
            font-weight: $bold;
          }
        }
      }
    }
  }

  .c-nav {
    &__categories {
      width: 100%;
      display: block;

      &-ttl {
        @include fz(18);
        font-weight: $bold;
        text-align: left;
        padding: 20px;
        border-bottom: 1px solid $white;
        width: 100%;
        display: none;

        @include is-mobile {
          @include fz(16);
        }
      }

      &-child {
        width: 100%;
        text-align: left;
        padding: 0;

        &-ttl {
          $this: &;
          color: $white;
          font-weight: $bold;
          position: relative;
          cursor: pointer;
          border-bottom: 1px solid rgba($white, .5);

          &::before,
          &::after {
            width: 14px;
            height: 2px;
            background: $white;
            position: absolute;
            top: 50%;
            right: 20px;
            margin: -1px 0 0;
          }

          &::after {
            transform: rotate(90deg);
            transition: transform .3s $ease;
          }

          &.is-active {
            &::after {
              transform: rotate(0deg);
            }
          }

          &.js-accrodion-btn {
            &::before,
            &::after {
              content: "";
              display: block;
            }

            .c-nav__categories-child-ttl-link {
              pointer-events: none;
            }
          }

          &-link {
            color: $white;
            display: block;
            padding: 20px;

            @include is-mobile {
              @include fz(14);
            }
          }
        }
      }

      &-list {
        display: none;
        background: $white;
        border-bottom: 1px solid $primary;
        padding: 10px 0;

        &-item {
          &-link {
            color: $black;
            padding: 10px 20px;
            display: block;

            @include is-mobile {
              @include fz(14);
            }

            &::before {
              content: "";
              width: 10px;
              height: 1px;
              background: $black;
              display: inline-block;
              vertical-align: middle;
              margin: 0 10px 0 0;

              @include is-mobile {
                width: 8px;
                margin: 0 8px 0 0;
              }
            }
          }
        }
      }
    }

    &__pages {
      width: 100%;

      &-list {
        &-item {
          &-link {
            @include fz(18);
            color: $white;
            padding: 20px;
            display: block;
            text-align: left;
            border-bottom: 1px solid $white;

            @include is-mobile {
              @include fz(16);
              font-weight: $bold;
            }
          }
        }
      }
    }
  }
}

.g-side {

  &__menu {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 100;
    animation: sidemenu_reverse 1s ease forwards;

    [data-menu="active"] & {
      animation: sidemenu 1s ease forwards;
      
    }

    @include is-tablet {
      left: 0;
      animation: none;
      pointer-events: none;
    }

    &-list {
      background: $black;

      @include is-tablet {
        display: flex;
        opacity: 0;
        transform: translateY(10%);
        transition: transform .6s ease, opacity .6s ease;
        pointer-events: none;
        justify-content: space-around;
      }

      &.is-reverse {
        @include is-tablet {
          transform: translateY(0);
          opacity: 1;
          pointer-events: initial;
        }
      }

      &-item {

        &-link {
          padding: 20px;
          display: block;
          line-height: 0;
          position: relative;

          &:hover {
            @include is-laptop {
              .g-side__menu-list-item-link-img {
                transform: scale(1.2);
              }
            }
          }

          @include is-hamburger {
            padding: 15px;
          }

          &-img {
            width: 22px;
            height: 22px;
            transition: transform .2s ease;
            transform: scale(1);
          }

          &-login {
            width: 25px;
            height: 25px;

            &-g {
              fill: none;
              stroke: $white;
            }

            &-rect {

              &-01 {
                stroke: none;
              }

              &-02 {
                fill: none;
              }
            }

            &-path {
              fill: $white;
              stroke: $black;

            }
          }

          &-search {

            &-g {
              fill: none;
              stroke: $white;
            }

            &-line {
              fill: none;
              stroke: $white;
            }

            &-circle {

              &-01 {
                stroke: none;
              }

              &-02 {
                fill: none;
              }
            }
          }

          &-news {

            &-path {
              fill: none;
              stroke: $white;
            }

          }

          &-wish {

            &-path {
              fill: $white;
            }

          }

          &-cart {
            width: 25px;
            height: 25px;

            &-path {
              fill: $white;
              stroke: $black;

            }
          }

        }

      }

    }

    &-count {
      position: absolute;
      top: 16px;
      right: 16px;
      border-radius: 100%;
      background: $red;
      color: $white;
      width: 10px;
      height: 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include is-hamburger {
        top: 11px;
        right: 11px;
      }
    }
  }

}

@keyframes sidemenu {
  0% {
    transform: translateX(0);
  }

  30% {
    transform: translateX(100%);
  }

  40% {
    transform: translateX(100%) translateY(65px);
  }

  100% {
    opacity: 1;
    transform: translateX(0) translateY(65px);
  }
}

@keyframes sidemenu_reverse {
  0% {
    transform: translateX(0) translateY(65px);
  }

  30% {
    transform: translateX(100%) translateY(65px);
  }

  40% {
    transform: translateX(100%) translateY(0);
  }

  100% {
    opacity: 1;
    transform: translateX(0) translateY(0);
  }
}

@include is-hamburger {
  @keyframes sidemenu {
    0% {
      transform: translateX(0);
    }

    30% {
      transform: translateX(100%);
    }

    40% {
      transform: translateX(100%) translateY(55px);
    }

    100% {
      opacity: 1;
      transform: translateX(0) translateY(55px);
    }
  }

  @keyframes sidemenu_reverse {
    0% {
      transform: translateX(0) translateY(55px);
    }

    30% {
      transform: translateX(100%) translateY(55px);
    }

    40% {
      transform: translateX(100%) translateY(0);
    }

    100% {
      opacity: 1;
      transform: translateX(0) translateY(0);
    }
  }
}
