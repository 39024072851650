@charset "UTF-8";

/*----------------------------------------
c-pagination
----------------------------------------*/
.c-pagination {
  margin: 25px 0;

  @include is-tablet {
    margin: 25px 0 0;
  }

  @include is-mobile {
    margin: 20px 0 0;
  }

  &:not(:last-child) {
    margin-bottom: 100px;

    @include is-mobile {
      margin-bottom: 50px;
    }
  }


  &__list {
    display: flex;
    justify-content: center;
    position: relative;

    &-dot {
      padding: 13px 0;
      display: block;
      pointer-events: none;
      line-height: 8px;

      @include is-mobile {
        padding: 6px 0;
      }
    }

    &-item {
      $this: &;
      position: relative;
      margin: 0 4px;
      height: 30px;
      width: 30px;

      @include is-mobile {
        margin: 0 2px;
      }

      &-body {
        font-size: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        transition: background-color .2s ease;

        &:hover {
          @include is-laptop {
            background: $blue-pale;

          }
        }

        .is-current & {
          background: $blue-pale;
          pointer-events: none;
        }

        &.is-next {
          border: 1px solid $blue-dark;

          &:hover {
            background: $blue-dark;

            &::before {
              border-color: transparent transparent $white transparent;
            }
          }

          &::before {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateY(-50%) translateX(-50%) rotate(90deg);
            display: inline-block;
            width: 0;
            height: 0;
            border-width: 0 6px 6px 6px;
            border-style: solid;
            border-color: transparent transparent $blue-dark transparent;
          }
        }
  
        &.is-prev {
          border: 1px solid $blue-dark;

          &:hover {
            background: $blue-dark;

            &::before {
              border-color: transparent transparent $white transparent;
            }
          }

          &::before {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateY(-50%) translateX(-50%) rotate(-90deg);
            display: inline-block;
            width: 0;
            height: 0;
            border-width: 0 6px 6px 6px;
            border-style: solid;
            border-color: transparent transparent $blue-dark transparent;

          }
        }

        &-txt {
          @include fz(16);
          @include ls(2);
          text-indent: .2rem;
          font-family: $font-en;
          line-height: 1;
          transition: color .3s $ease, border-color .3s $ease;
          margin-top: 1px;

          @include is-mobile {
            @include fz(14);
          }
        }

        &-icon {
          @include fz(14);
          color: $gray;
          transition: color .3s $ease;
        }
      }
    }
  }
}
